import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Router, Route, Switch } from 'react-router-dom';

import SignIn from 'components/SignIn';
import SignUp from 'components/SignUp';

import UtilService from 'services/util';
import { history } from 'util/history';
import { loadable } from 'util/loadable';

import { LOCAL_STORAGE_VAR } from 'constants/Common';

import AppLocale from 'lngProvider';

import FrontRoute from './Routes';

import 'assets/scss/global.css';
import 'assets/less/global.less';

import 'styles/App.less';
import 'components/auth.less';

import 'styles/Header.less';
import 'styles/DiamondList.less';
import 'styles/DiamondDetail.less';
import 'styles/Account.less';
import 'styles/QuickSearch.less';
import 'styles/Compare.less';
import 'styles/DiamondListingSidePopup.less';
import 'styles/OfficePopup.less';
import 'styles/savedSeach.less';
import 'styles/DiamondSearch.less';
import 'styles/mobileHeader.less';
import 'styles/Dashboard.less';
import 'styles/sidebarHeader.less';

import 'styles/DiamondListMobile.less';
import 'styles/DiamondDetailMobile.less';
import 'styles/DiamondSearchMobile.less';
import 'styles/mobileAccount.less';
import 'styles/notificationSetting.less';
import 'styles/tab.less';

const AboutUs = loadable(() => import('components/AboutUs'));
const ContactUs = loadable(() => import('components/ContactUs'));
const DeviceHome = loadable(() => import('components/Device/Home'));
const DeviceAboutUs = loadable(() => import('components/Device/AboutUs'));
const DeviceOurDiamond = loadable(() => import('components/Device/OurDiamond'));
const DeviceServices = loadable(() => import('components/Device/Services'));
const DeviceContactUs = loadable(() => import('components/Device/Home'));
const DiamondDetail = loadable(() => import('components/DiamondDetail'));
const EmailVerify = loadable(() => import('components/EmailVerify'));
const ForgotPassword = loadable(() => import('components/Auth/ForgotPassword'));
const GDPRCompliance = loadable(() => import('components/GDPRCompliance'));
const GuestLogin = loadable(() => import('components/GuestLogin'));
const Home = loadable(() => import('components/Home'));
const Jewelry = loadable(() => import('components/Jewelry'));
const NoDataFound = loadable(() => import('components/common/NoDataFound'));
const OurDiamond = loadable(() => import('components/OurDiamond'));
const PrivacyPolicy = loadable(() => import('components/PrivacyPolicy'));
const QuickSignUp = loadable(() => import('components/QuickSignUp'));
const ResetPassword = loadable(() => import('components/Auth/ResetPassword'));
const Services = loadable(() => import('components/Services'));
const ShippingPolicy = loadable(() => import('components/ShippingPolicy'));
const SigninBackup = loadable(() => import('components/SigninBackup'));
const TermsOfUse = loadable(() => import('components/TermsOfUse'));
const ThankYouAfterSignup = loadable(() => import('components/ThankYou'));
const VerifyDevice = loadable(() => import('components/VerifyDevice'));
const VerifyOtp = loadable(() => import('components/VerifyOtp'));

const RestrictedRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/dashboard'} />
        )
      }
    />
  );
};

class App extends Component {
  render() {
    const isMobile = window.innerWidth <= 991;

    if (window.drift) {
      window.drift.on('campaign:dismiss', () => {});

      if (window.drift && window.drift.api) {
        window.drift.api.widget.hide();
      }
    }

    const lan = this.props.commonData?.locale?.locale ?? 'en';
    const currentAppLocale = AppLocale[lan];

    return (
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Router history={history}>
          <Switch>
            <RestrictedRoutes path="/" component={SignIn} exact />
            <RestrictedRoutes path="/signin" component={SignIn} />
            <RestrictedRoutes path="/signinbackup" component={SigninBackup} />
            <Route exact path="/verify-otp" component={VerifyOtp} />
            <RestrictedRoutes path="/signup" component={SignUp} />
            <RestrictedRoutes path="/quick-signup" component={QuickSignUp} />
            <RestrictedRoutes path="/device/signup" component={SignUp} />
            <Route exact path="/no-datafound" component={NoDataFound} />
            <RestrictedRoutes path="/guest-login" component={GuestLogin} />
            <RestrictedRoutes path="/forgot-password" component={ForgotPassword} />
            <RestrictedRoutes path="/reset-password/:token" component={ResetPassword} />
            <Route path="/thank-you" component={ThankYouAfterSignup} />
            <Route path="/verify-email" component={EmailVerify} />
            <Route path="/verify-device" component={VerifyDevice} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/device/home" component={DeviceHome} />
            <Route path="/device/about-us" component={DeviceAboutUs} />
            <Route path="/device/our-diamond" component={DeviceOurDiamond} />
            <Route path="/device/services" component={DeviceServices} />
            <Route path="/device/contact-us" component={DeviceContactUs} />
            <Route exact path="/terms-condition" component={TermsOfUse} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/shipping-policy" component={ShippingPolicy} />
            <Route exact path="/GDPR-compliance" component={GDPRCompliance} />

            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/our-diamond" component={OurDiamond} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/jewelry" component={Jewelry} />

            <Route exact={isMobile} path="/diamond-detail/:id" component={DiamondDetail} />
            <FrontRoute />
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

export default connect((store) => ({
  commonData: store.commonData,
}))(App);
