import React, { Component } from "react";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import UtilService from "../../services/util";
import { withRouter } from 'react-router-dom'
class Logo extends Component {
  imageClick = () => {
    // let redictToPage =
    //   localStorage[`${LOCAL_STORAGE_VAR}-token`] &&
    //   localStorage[`${LOCAL_STORAGE_VAR}-token`].length
    //     ? "/dashboard"
    //     : "/";
    let redictToPage =
      UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) &&
        UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`).length
        ? "/dashboard"
        : "/";
    return this.props.history.push(redictToPage)

    // window.location.assign(redictToPage);
  };

  render() {
    return (
      <img
        className="imgHover"
        src={require("../../assets/img/login/GoGreenDiamondsLogo.png")}
        alt=""
        onClick={() => this.imageClick()}
      />
    );
  }
}

export default withRouter(Logo);
