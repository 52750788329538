import React from 'react';
import { STATUS_COLOR } from '../common/DiamondListing/Status';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import { getParams, LISTINGPAGES, getStoneInfo } from './DiamondListFunctions';
import { DIAMOND_BID } from '../../constants/Common';
import Countdown from '../Offer/Timer';
import moment from 'moment';
import { OFFERSTATUS } from '../Offer';

const MobileViewListCard = (props) => {
  return (
    <div
      className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'} ${
        props.activeClass ? props.activeClass : null
      } ${props.quotePage ? 'quotePageWrapper' : null}`}
    >
      {!props.quotePage && (
        <>
          <div className="mobileListHeade" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <a target="_blank" className="mobileListItem" href={'/' + LISTINGPAGES.DETAIL + '/' + props.data.id}>
                <span>{props.data.vStnId}</span>
              </a>
              <div className="mobileListItem">
                <span>{props.data.shpNm}</span>
              </div>
              <div className="mobileListItem">
                <span>{formatNumber(parseFloat(props.data.back))}%</span>
              </div>
              <div className="mobileListItem themeColor">
                <span>{formatNumber(parseFloat(props.data.ctPr))} $/Cts</span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <div className="mobileListItem basicDetail">
                <span>
                  {getParams(props.data).join(' ')} {props.data.lbNm}
                </span>
              </div>
              <div className="mobileListItem">
                <span>{formatNumber(parseFloat(props.data.crt))}</span>
              </div>
              <div className="mobileListItem">
                <span>{props.data.msrmnt}</span>
              </div>
              <div className="mobileListItem totalPrice">
                <span>{formatNumber(parseFloat(props.data.amt))} $/Amt</span>
              </div>
            </div>
          </div>
        </>
      )}
      {props.quote && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Dis.: </span>
            <span className="mobileInputField">{props.quote.disIp(props.data)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Pr/Ct: </span>
            <span className="mobileInputField">{props.quote.prIp(props.data)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Amount: </span>
            <span className="mobileInputField">{props.quote.getAmt(props.data)}</span>
          </div>
        </div>
      )}
      {props.bid && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Type: </span>
            <span>{props.data.bidType === DIAMOND_BID.TYPE.OPEN ? 'Look' : 'Blind'}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Status: </span>
            <span>{props.data.status === DIAMOND_BID.STATUS.WIN ? 'Win' : 'Loss'}</span>
          </div>
        </div>
      )}
      {props.finalCalc && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Final Disc%: </span>
            <span>{parseFloat(props.data.calcDiscount)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Final Rate: </span>
            <span>${parseFloat(props.data.calcPricePerCarat)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Final Value: </span>
            <span>${parseFloat(props.data.calcAmount)}</span>
          </div>
        </div>
      )}
      {props.bidPopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">{props.bidPopup.getBidPrCell(props.data)}</div>
          <div className="quoteExtraItem">
            <span>Bid Disc(%): </span>
            <span>{parseFloat(props.data.bidDiscount || 0)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Value: </span>
            <span>${parseFloat(props.data.bidAmount || 0)}</span>
          </div>
        </div>
      )}
      {props.watchpopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Exp. Disc(%): </span>
            <span>{props.watchpopup(props.data)}</span>
          </div>
        </div>
      )}
      {props.note && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Notes: </span>
            <span>{props.data.remarks || '-'}</span>
          </div>
        </div>
      )}
      {props.quotePage && (
        <>
          <div className="mobileListHeade" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <div className="mobileListItem">
                <span>{getStoneInfo(props.data).join(' ')}</span>
              </div>
              <div className="mobileListItem activeStatus pending">
                <span>{OFFERSTATUS[props.data.offerStatus]}</span>
              </div>
            </div>
          </div>
          <div className="mobileDiamondListBottom" onClick={props.onClick}>
            <div className="mobileListHeadeBlock">
              <div className="mobileListItem">
                <span>Disc% / Pr/Ct : </span>
                <span>
                  {(props.data.back ? parseFloat(props.data.back) + ' / ' : '') + parseFloat(props.data.ctPr)}
                </span>
              </div>
              <div className="mobileListItem themeColor">
                <span>Quote% / Pr/Ct : </span>
                <span>
                  {(props.data.newDiscount ? parseFloat(props.data.newDiscount) + ' / ' : '') +
                    parseFloat(props.data.newPricePerCarat)}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default MobileViewListCard;
