import React from 'react';
import { useSelector } from 'react-redux';

export const ConnectedProgress = React.memo(() => {
  const loading = useSelector((state) => state?.loader?.loading);

  React.useEffect(() => {
    const hasClass = document.body.classList.contains('progress');
    loading > 0
      ? !hasClass && document.body.classList.add('progress')
      : hasClass && document.body.classList.remove('progress');
  }, [loading]);

  return loading ? <div className="loading-indicator" /> : null;
});

ConnectedProgress.displayName = 'ConnectedProgress';
