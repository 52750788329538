import React, { useState, useEffect } from "react";
import IntlMessages from "../../../util/IntlMessages";
import UtilService from '../../../services/util'

import Table from '../../DiamondList/Table';
// import {
//     handleDownloadExcel,
//     downloadFile,
//     downloadVideo,
//     getColumn,
//     handleDownloadMultiple,
// } from "../../DiamondList/DiamondListFunctions";
import _ from "lodash";
import OpenNotification from "../CommonButton/OpenNotification";
import { DOWNLOAD_ZIP_EXCEL } from "../../../constants/apiConstant";
import { BASE_URL } from "../../../constants/Common";

const DownloadDropdown = (props) => {

  const [downloadPath, setDownloadPath] = useState('')
  useEffect(() => {
    if (downloadPath !== '') {
      window.open(downloadPath, 'Download')
      setDownloadPath('')
    }
  }, [downloadPath])
  useEffect(() => {
    let checked = []
    if (props.checked
      && props.checked
        .length !== 0) {
      checked = props.checked
    } else if (props.noCheckedForDownload && props.noCheckedForDownload.length !== 0) {
      checked = props.noCheckedForDownload
    }
    if (checked && checked.length !== 0) {
      let temp = option
      if (true) {
        temp[1] = { index: 2, title: "Real Image" };
      }
      if (true) {
        temp[3] = { index: 5, title: "Arrow" };
      }
      if (true) {
        temp[2] = { index: 4, title: "Heart" };
      }
      if (true) {
        temp[4] = { index: 6, title: "Video" };
      }
      if (true) {
        temp[0] = { index: 1, title: "Certificate" };
      }
      if (true) {
        temp[5] = { index: 7, title: "Light" };
      }
      setOption([...temp])
      // checked.map(che => {
      //     // if (true) {
      //     //     temp[2] = { index: 2, title: 'Real Image' }
      //     // }
      //     // if (che.arrowFile) {
      //     //     temp[4] = { index: 5, title: 'Arrow' }
      //     // }
      //     // if (che.hAFile) {
      //     //     temp[3] = { index: 4, title: 'Heart' }

      //     // }
      //     // if (che.videoFile) {
      //     //     temp[5] = { index: 6, title: 'Video' }
      //     // }
      //     // if (che.certFile) {
      //     //     temp[1] = { ndex: 1, title: 'Certificate' }
      //     // }


      // })
    }
  }, [props.checked, props.noCheckedForDownload])
  const [option, setOption] = useState([
    // { index: 0, title: 'Excel', },

  ])
  const [ind, setInd] = useState([1])

  const ShareOption = (props) => {
    return (
      <li onClick={props.onClick} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };
  const onDownloadClick = () => {
    let self = this
    let checked = []
    if (props.checked
      && props.checked
        .length !== 0) {
      checked = props.checked
    } else if (props.noCheckedForDownload && props.noCheckedForDownload.length !== 0) {
      if (!props.fromList) {
        checked = props.noCheckedForDownload

      }
    } else {
      OpenNotification({
        type: 'error',
        title: "No stone(stones) to download.",
      })
    }
    // if (checked && checked.length !== 0) {
    let obj = {
      isExcel: false,
      img: false,
      certFile: false,
      videoFile: false,
      hAFile: false,
      arrowFile: false,
      assetFile: false,
      faceUp: false
    }
    ind.map(num => {
      if (num === 0) {
        obj.isExcel = true
      }
      if (num === 1) {
        obj.certFile = true
      }
      if (num === 2) {
        obj.img = true
      }
      if (num === 5) {
        obj.arrowFile = true
      }
      if (num === 4) {
        obj.hAFile = true
      }
      if (num === 7) {
        obj.faceUp = true
      }
      if (num === 6) {
        obj.videoFile = true
      }
    })
    let id = _.map(checked, "vStnId")
    let objData = {
      ...DOWNLOAD_ZIP_EXCEL,
      request: { filter: _.isEmpty(id) ? { isFcCol: false, ...props.filter, wSts: ["A", "M", "H"] } : { vStnId: [...id], isFcCol: false }, ...obj }
    }
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err
      if (await data && data.code === 'OK') {
        let server = BASE_URL
        let file_path = `${server}/${data.data}`
        setDownloadPath(file_path)
        if (props.clearAll) props.clearAll()
        props.onCloseDownloadPopup()
      }
    })
    // handleDownloadMultiple({}, _.map(checked, "id"), { ...obj }, (status) => {
    //     if (status) {
    //         props.clearAll();
    //     }
    // });




    // }


  };

  return (
    <>
      <div>

        <div className="searchPopupCommon">
          <div className="shareOptionSet d-flex">
            <div className="shareOptionSetItem">

              <ul>
                {option.map((op, index) => {
                  return <ShareOption onClick={() => {
                    let tempIndex = ind
                    if (tempIndex.includes(op.index)) {
                      if (tempIndex.length !== 1) {

                        tempIndex = tempIndex.filter(d => d !== op.index)
                      }
                    } else {
                      tempIndex.push(op.index)

                    }
                    setInd([...tempIndex])
                  }} activeClass={ind.includes(op.index) ? 'active' : ''} title={op.title} />

                })}

              </ul>
            </div>
          </div>

          <div className="sideBarPopupButton">
            {ind && ind.length !== 0 &&
              ind.length >= 2 ?
              props.checked && props.checked.length !== 0 ?
                <a
                  onClick={onDownloadClick}
                  className="commonButton"
                >
                  <IntlMessages id="app.Download" />
                </a>
                :
                <a
                  className="commonButton"
                >
                  Please select diamonds
                        </a>
              :
              ind.includes(0) ?
                <a
                  onClick={onDownloadClick}
                  className="commonButton"
                >
                  <IntlMessages id="app.Download" />
                </a>
                :
                props.checked && props.checked.length !== 0 ?
                  <a
                    onClick={onDownloadClick}
                    className="commonButton"
                  >
                    <IntlMessages id="app.Download" />
                  </a>
                  :
                  <a
                    className="commonButton"
                  >
                    Please select diamonds
                        </a>
            }

            <a

              onClick={() => props.onCloseDownloadPopup()}
              className="commonButton"><IntlMessages id="app.Cancel" /></a>







          </div>







        </div>
      </div>
    </>
  );
};
export default DownloadDropdown;
