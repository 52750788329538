import React, { useState } from 'react';
import { Tooltip, Radio } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PopupStoneTable from './PopupStoneTable';
import Heading from '../Heading';
import SendEmailPopup from './SendEmail';
import Table from '../../DiamondList/Table';
import { getColumn, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import { DOWNLOAD_ZIP_EXCEL, EXPORT_EXCEL } from '../../../constants/apiConstant';
// import { getGridHeight } from '../../DiamondList'
import UtilService from '../../../services/util';
import _, { get } from 'lodash';
import OpenNotification from '../CommonButton/OpenNotification';
import { BASE_URL, LOCAL_STORAGE_VAR } from '../../../constants/Common';

const URL_ID = 'vStnId';

let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 25;
  allContainers.headerHeight = document.getElementsByClassName('shareOptionSet')[0];
  allContainers.pageHeadHeight = document.getElementsByClassName('sideBarPopupButton')[0];
  allContainers.actionBtnHeight = document.getElementsByClassName('popupInnerTitle')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

const ShareDropdown = (props) => {
  const [sendEmail, setSendEmail] = useState(false);
  const [value, setValue] = useState(1);
  const [option, setOption] = useState([
    { index: 0, title: 'Stock' },
    { index: 1, title: 'Certificate' },
    { index: 2, title: 'Real Image' },
    { index: 3, title: 'Plotting Image' },
    { index: 4, title: 'Heart & Arrow' },
    { index: 5, title: 'Asset Scope' },
    { index: 6, title: 'Video' },
  ]);
  const [ind, setInd] = useState([0]);
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const ShareOption = (props) => {
    return (
      <li onClick={props.onClick} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };
  const onDownloadClick = (emailData) => {
    let checked = [];
    if (props.checked && props.checked.length !== 0) {
      checked = props.checked;
    } else if (props.noCheckedForDownload && props.noCheckedForDownload.length !== 0) {
      // checked = props.noCheckedForDownload
      checked = [];
    } else {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    }
    // if (checked && checked.length !== 0) {
    let obj = {
      isExcel: false,
      img: false,
      certFile: false,
      videoFile: false,
      hAFile: false,
      arrowFile: false,
      assetFile: false,
    };
    ind.map((num) => {
      if (num === 0) {
        obj.isExcel = true;
      }
      if (num === 1) {
        obj.certFile = true;
      }
      if (num === 2) {
        obj.img = true;
      }
      if (num === 3) {
        obj.arrowFile = true;
      }
      if (num === 4) {
        obj.hAFile = true;
      }
      if (num === 5) {
        obj.assetFile = true;
      }
      if (num === 6) {
        obj.videoFile = true;
      }
    });
    if (sendEmail) {
      obj.sendEmail = true;
      obj.isExcel = true;
      obj.email = {
        ...emailData,
      };
    }
    let id = _.map(checked, 'vStnId');
    let objData = {
      ...EXPORT_EXCEL,
      request: {
        filter: _.isEmpty(id) ? { ...props.filter, wSts: ['A', 'M', 'H'] } : { vStnId: [...id] },
        ...obj,
        zip: false,
      },
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) {
        return OpenNotification({
          type: 'error',
          title: err.data.message || err.data.data.message || 'Failed to Share.',
        });
      }
      if ((await data) && data.code === 'OK') {
        let server = BASE_URL;
        let file_path = `${server}/${data.data}`;
        if (!obj.sendEmail) {
          window.open(file_path, 'Download');
        }
        if (props.onCloseSharePopup) {
          props.onCloseSharePopup();
        }
        setSendEmail(false);
        if (props.clearAll) {
          props.clearAll();
        }
        OpenNotification({
          type: 'success',
          title: data.message,
        });
      } else {
        OpenNotification({
          type: 'error',
          title: data.message,
        });
      }
    });
    // }
  };
  const WhatsAppShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0][URL_ID]}`;
        window.open(`https://web.whatsapp.com/send?text=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = 'Cupid Diamonds : Diamonds Detail%0A%0A';

        props.checked.forEach((element) => {
          let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element[URL_ID]}`;
          msg += path + '%0A%0A';
        });
        window.open(`https://web.whatsapp.com/send?text=${msg}`);
      }
    }
    if (props.onCloseSharePopup) {
      props.onCloseSharePopup();
    }
  };
  const Wechat = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0][URL_ID]}`;
        window.open(`https://web.wechat.com/send?text=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = 'Cupid Diamonds : Diamonds Detail%0A%0A';

        props.checked.forEach((element) => {
          let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element[URL_ID]}`;
          msg += path + '%0A%0A';
        });
        window.open(`https://web.wechat.com/send?text=${msg}`);
      }
    }
    if (props.onCloseSharePopup) {
      props.onCloseSharePopup();
    }
  };
  const SkypeShare = () => {
    if (props.checked && props.checked.length === 0) {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    } else {
      if (props.checked.length === 1) {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0][URL_ID]}`;

        window.open(`skype:?chat&topic=${path}`);
        //window.open(`https://web.skype.com/share?url=${path}`);
      } else if (props.checked.length >= 2) {
        let msg = 'Cupid Diamonds : Diamonds Detail%0A%0A';

        props.checked.forEach((element) => {
          let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element[URL_ID]}`;
          msg += path + '%0A%0A';
        });
        //window.open(`https://web.skype.com/share?url=${msg}`);
        window.open(`skype:?chat&topic=${msg}`);
      }
    }
    if (props.onCloseSharePopup) {
      props.onCloseSharePopup();
    }
  };
  gridHeight = setGridHeightFn();
  return (
    <>
      <div>
        <div className="searchPopupCommon">
          {/* <div className="shareOptionSet">

            <div className="shareOptionSetItem shareOptionImage">
              <ul>
                {option.map((op, index) => {
                  return <ShareOption onClick={() => {
                    let tempIndex = ind
                    if (tempIndex.includes(op.index)) {
                      if (tempIndex.length !== 1) {

                        tempIndex = tempIndex.filter(d => d !== op.index)
                      }
                    } else {
                      tempIndex.push(op.index)

                    }
                    setInd([...tempIndex])
                  }} activeClass={ind.includes(op.index) ? 'active' : ''} title={op.title} />

                })}

              </ul>
            </div>
          </div> */}
          <div className="commonModalButton ">
            {get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) && (
              <button
                className="commonButton"
                onClick={() => {
                  // props.onCloseSharePopup();
                  setSendEmail(!sendEmail);
                }}
              >
                Email
              </button>
            )}
            {get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) && (
              <button onClick={WhatsAppShare} className="commonButton ml-5">
                WhatsApp
              </button>
            )}
            {get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false) && (
              <button onClick={SkypeShare} className="commonButton ml-5">
                Skype
              </button>
            )}
            <button onClick={Wechat} className="commonButton ml-5">
              WeChat
            </button>
          </div>
        </div>
      </div>
      <SendEmailPopup
        // sucess={(data) => {
        //   onDownloadClick(data);
        // }}
        ids={props.checked.map((x) => x.id)}
        sendEmail={sendEmail}
        onCancel={() => setSendEmail(!sendEmail)}
      />
    </>
  );
};
export default connect((props) => {
  return props;
}, {})(withRouter(ShareDropdown));
