import { memoize } from 'lodash';

import {
  catchError,
  forEach,
  formatDecimal,
  formatNumber,
  isArray,
  isEmpty,
  isNotEmpty,
  isObject,
  logWarn,
} from 'util/utils';

import { LAB_LINKS, FLOAT_COLUMNS, ROUND_COLUMNS } from './table-config';

export const getLabLink = (data) => {
  const lab = data?.lbNm?.toUpperCase?.();
  const reportNo = data?.rptNo;
  return LAB_LINKS?.[lab]?.replace?.(/(?:\*\*\*){1}/gm, reportNo);
};

export const formatValues = (value, column) => {
  return !value
    ? '-'
    : FLOAT_COLUMNS.includes(column)
    ? formatDecimal(parseFloat(value))
    : ROUND_COLUMNS.includes(column)
    ? formatNumber(Math.round(value))
    : value;
};

export const updateRecord = (record, options = {}) => {
  if (!isObject(record)) return;
  // options.srNo
  return { ...record, ...options };
};

export const updateList = (list, inBlockList = [], options = {}) => {
  if (!isArray(list)) return;
  return list.map((record) => updateRecord(record, inBlockList, options));
};

export const filterRecord = (record, remove = []) => {
  if (!isObject(record)) return;
  record = { ...record };
  const byUpdater = ['srNo'];
  const byGroupping = ['isFooter', 'isHeader', 'groupData', 'totalDiamonds']; // '_groupBy_'
  const byGroupType = ['isHoldHeader', 'isMatchHeader', 'userGroupHold'];
  const keysToDelete = [...byUpdater, ...byGroupping, ...byGroupType, ...remove];
  keysToDelete.forEach((key) => delete record[key]);
  return record;
};

export const filterList = (list, remove = []) => {
  if (!isArray(list)) return;
  return list.map((record) => filterRecord({ ...record }, remove));
};

export const getStickyStyle = memoize((noStatus = false) => {
  const stickyHeader = { position: 'sticky', zIndex: 2000 };
  const stickyCell = { position: 'sticky', backgroundColor: '#fff', zIndex: 1000 };
  const firstLeft = -10;
  const firstWidth = noStatus ? 40 : 140;
  const secondLeft = firstWidth + firstLeft - 5;
  const secondWidth = 160;
  const thirdLeft = firstWidth + secondWidth + firstLeft - 10;

  return {
    head: {
      first: { left: firstLeft + 'px', ...stickyHeader, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyHeader, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyHeader },
    },
    cell: {
      first: { left: firstLeft + 'px', ...stickyCell, minWidth: firstWidth + 'px' },
      second: { left: secondLeft + 'px', ...stickyCell, minWidth: secondWidth + 'px' },
      third: { left: thirdLeft + 'px', ...stickyCell },
    },
  };
});

export const getGroupTitle = (record = {}, options = {}) => {
  const dateTitle = record?.dateTitle ?? '';
  const country = record?.countryNm ?? '-';
  const salesPerson = record?.seller ?? '-';
  const date = record?.userDate ?? record?.createdAt ?? '-';
  const user = [
    record?.user?.account?.companyName ?? record?.user?.companyName,
    record?.userName,
    record?.userMobile,
    record?.userEmail,
  ]
    .filter(isNotEmpty)
    .join(' | ');

  return options?.user && options?.date
    ? `${dateTitle} ${date} | ${user}`
    : options?.user
    ? user
    : options?.date
    ? `${dateTitle} ${date}`
    : options?.country
    ? country
    : options?.salesPerson
    ? salesPerson
    : options?.stageShow
    ? `${record?.sSts ?? record?.blockCode ?? '-'}`
    : options?.orderShow
    ? `${record?.memoNo ?? '-'}`
    : `${dateTitle} ${date} | ${user}`;
};

export const memoizedGetGroupTitle = memoize(getGroupTitle, (...args) => JSON.stringify(args));

export const filterGridColumns = (columns = [], options = {}) => {
  columns =
    options?.dateList && options?.customerList
      ? columns.filter((el) => el.id !== 'createdAt' && el.id !== 'user')
      : options?.dateList
      ? columns.filter((el) => el.id !== 'createdAt')
      : options?.customerList
      ? columns.filter((el) => el.id !== 'user')
      : columns;

  if (options?.inClient) columns = columns.filter((el) => el.id !== 'user');

  return columns;
};

export const updateRowClassList = (accessor, rowId, select = false) =>
  catchError(
    () => {
      const selector =
        !isEmpty(accessor) && !isEmpty(rowId)
          ? `table.${accessor}-table tr[table-row${accessor ? `="${accessor}${rowId}"` : ''}]`
          : !isEmpty(accessor)
          ? `table.${accessor}-table tr[table-row]`
          : undefined;

      const tableRows = document.querySelectorAll(selector);

      select
        ? forEach(tableRows, (row) => row.classList.add('selectTr'))
        : forEach(tableRows, (row) => row.classList.remove('selectTr'));
    },
    (error) => logWarn({ updateRowClassListError: error }),
  );
