import React, { Component } from 'react'
import { connect } from 'react-redux'
import Heading from '../Heading'
import { Drawer } from 'antd'
import TextArea from "../../common/TextArea"
import DatePicker from "../../common/DatePicker"
import MobileViewListCard from "../MobileDiamondList/MobileViewListCard"
import { ListingCards } from './MobileComfirmStone'

const currentType = 'OfficePopup'

class MobileOfficeSidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            office: false
        }
    }
    officeDrwer = () => {
        this.setState({ office: true })
    }

    onClose = () => {
        this.setState({ office: false })
    }

    render() {
        return (
            <div className='offerWrapper'>
                <div className='d-flex align-items-center justify-space-between offerTopBlock'>
                    <div className='d-flex align-items-center'>
                        <Heading className='popupInnerTitle' title='Office List' />
                    </div>
                    {/* {this.props.parent.offerHeading()} */}
                </div>
                <div className='searchInnerResult tabInnerTable'>
                    <div className='diamondListMobile mobileOfferScroll'>
                    <ListingCards
                            data={this.props.data}
                            currentType={currentType}
                            checked={this.props.data}
                            // quoteClick={(e, dt) => this.setState({ quote: e, quoteSelect: dt })}
                        />
                    </div>
                    <div className='d-flex justify-content-between offerBottomBox flex-wrap'></div>
                </div>
                <div className='sideBarPopupButton'>
                    <a
                        className='commonButton'
                        onClick={this.officeDrwer}
                    >
                        Book Office
                    </a>
                    <a className='commonOutline' onClick={this.props.parent.props.onClose}>
                                    Cancel
                                </a>
                </div>
                {this.state.office && (
                    <Drawer
                        title={false}
                        onClose={this.onClose}
                        visible={true}
                        wrapClassName='diamondListinSidebar smallSidebarMobilePopup'
                    >
                        <div>
                            {this.props.parent.getBottom()}
                            {/* {this.props.parent.getOfferInput()} */}
                            <div className='sideBarPopupButton'>
                                <a className='commonButton' onClick={()=>this.props.parent.handleSave()}>
                                    Apply Offer
                                </a>
                                <a className='commonOutline' onClick={this.onClose}>
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </Drawer>
                )}
            </div>
        )
    }
}

export default MobileOfficeSidebar
