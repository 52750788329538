import React from 'react'
import moment from 'moment'

class Countdown extends React.Component {
    componentDidMount() {
        setInterval(() => this.forceUpdate(), 1000)
    }

    leadingZero = num => {
        return num <= 0 ? '00' : num < 10 && num > 0 ? '0' + num : num
    }

    getses = (num, label) => {
        if (num === 1 || num === '01') return label
        else return label + 's'
    }

    render() {
        let diff = moment.duration(this.props.date.diff(moment()))._data
        if (diff.days) diff.hours += diff.days * 24
        if (diff.hours + diff.minutes + diff.seconds === 0 && this.props.fetch) this.props.fetch()

        return (
            <div className='App'>
                <div className='countDownBlock'>
                    <div className='countdownItem'>
                        <span>{this.leadingZero(diff.hours)}</span>
                        <span>{this.getses(diff.hours, 'hour')}</span>
                    </div>
                    <div className='countdownItem'>
                        <span>{this.leadingZero(diff.minutes)}</span>
                        <span>{this.getses(diff.minutes, 'minute')}</span>
                    </div>
                    <div className='countdownItem'>
                        <span>{this.leadingZero(diff.seconds)} </span>
                        <span>{this.getses(diff.seconds, 'second')}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Countdown
