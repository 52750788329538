import React from "react"
import "./input.less"
import { Tooltip } from 'antd'
const InputBlock = (props) => {
    let groupClass = "from-group inputBlock"
    if (props.hasError) {
        groupClass = groupClass + " error-message"
    }
    const displayTitle = () => {

        // return 
        return (
            <div>
                <span> Password Must Contains At Least 1 Upper Case Alphabet, 1 Lower Case Alphabet, 1 Digit And 1 Special Character.</span>
                <br />
                <span>Special Characters includes : !@#$%^&*()_+</span>
            </div>

        )
    }
    return (
        <div className={groupClass}>
            {props.label ?
                <label>{props.label}
                    {(props.required || props.requireds) &&
                        <span style={{ marginLeft: '2px', color: props.value ? 'green' : 'red' }}>*</span>
                    }
                    {props.info &&
                        <Tooltip overlayClassName='Tool' placement="topLeft" title={displayTitle}>
                            <span>
                                <img style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '5px', width: '16px', height: '16px' }} src={require('../../../assets/svg/infopn.svg')} />


                            </span>

                        </Tooltip>

                    }
                </label>
                : null
            }
            <div className={`inputWrapper ${(props.mobileISD || props.mobileSTD) && "mobileFiled"}`}>
                {props.mobileISD &&
                    <input
                        className="smallInput"
                        placeholder={props.ISDplaceholder}
                    />
                }
                {props.mobileSTD &&
                    <input
                        className="smallInput"
                        placeholder={props.STDplaceholder}
                    />
                }
                <input
                    placeholder={props.placeholder}
                    value={props.value ? props.value : ""}
                    type={props.type ? props.type : "text"}
                    onChange={props.onChange ? props.onChange : null}
                    {...props}
                />

                {/* {props.InputAdd ? 
                <div className="plusInputRemove">
                    <div className="plusInputItem">
                        <img src={require("../../assets/svg/plus.svg")} alt="" className="plusInput"/>
                    </div>
                    <div className="closeInoutItem">
                        <img src={require("../../assets/svg/close.svg")} alt="" className="closeInput"/>
                    </div>
                </div>
                : null } */}
            </div>
            <span style={{ color: 'red' }}>
                {props.hasError ? props.hasError : ''}
            </span>
        </div>
    )
}
export default InputBlock;