import React, { useEffect, memo } from 'react'
import Heading from '../Heading'
import { ListingCards } from './MobileComfirmStone'
import useRowSelect from '../../../util/useRowSelect'
import { HeadingCalc } from './ConfirmStonePopup'

/* eslint jsx-a11y/anchor-is-valid: 0 */

const FinalCalcMobile = props => {
    const { toggleRowSelection } = useRowSelect(props.currentType)
    useEffect(() => {
        props.data.map(row => {
            toggleRowSelection(row, true)
            return true
        })
    }, [])

    return (
        <div className='offerWrapper'>
            <div className='d-flex align-items-center justify-space-between offerTopBlock'>
                <div className='d-flex align-items-center'>
                    <Heading className='popupInnerTitle' title='Final Calculation'></Heading>
                </div>
                {HeadingCalc({ display: 'flex', flexDirection: 'row-reverse', width: '48%' }, props.VALUES)}
            </div>
            <div className='searchInnerResult tabInnerTable'>
                <div className='diamondListMobile mobileOfferScroll'>
                    <ListingCards data={props.data} finalCalc={true} currentType={props.currentType} />
                </div>
                <div className='d-flex justify-content-between offerBottomBox flex-wrap'></div>
            </div>
            <div className='sideBarPopupButton'>
                <a className='commonButton' onClick={props.parent.submit}>
                    Confirm Stone
                </a>
                <a className='commonButton' onClick={props.parent.onClose}>
                    Cancel Stone
                </a>
            </div>
        </div>
    )
}

export default memo(FinalCalcMobile)
