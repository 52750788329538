export const DiamondSearch = (isFancy) => ({
  AdvanceSearch: {
    open: `${isFancy ? "FancySearch" : "DiamondSearch"}/AdvanceSearch/open`,
    close: `${isFancy ? "FancySearch" : "DiamondSearch"}/AdvanceSearch/close`,
  },
  AddDemand: {
    open: `${isFancy ? "FancySearch" : "DiamondSearch"}/AddDemand/open`,
    close: `${isFancy ? "FancySearch" : "DiamondSearch"}/AddDemand/close`,
  },
  SavedSearch: {
    open: `${isFancy ? "FancySearch" : "DiamondSearch"}/SavedSearch/open`,
    close: `${isFancy ? "FancySearch" : "DiamondSearch"}/SavedSearch/close`,
  },
  UploadExcel: `${isFancy ? "FancySearch" : "DiamondSearch"}/UploadExcel/upload`,
  ResetFilter: {
    reset: `${isFancy ? "FancySearch" : "DiamondSearch"}/ResetFilter/reset`,
  },
  ThreeEx: {
    select: `${isFancy ? "FancySearch" : "DiamondSearch"}/ThreeEx/select`,
    unselect: `${isFancy ? "FancySearch" : "DiamondSearch"}/ThreeEx/unselect`,
  },
  TwoEx: {
    select: `${isFancy ? "FancySearch" : "DiamondSearch"}/TwoEx/select`,
    unselect: `${isFancy ? "FancySearch" : "DiamondSearch"}/TwoEx/unselect`,
  },
  ThreeVg: {
    select: `${isFancy ? "FancySearch" : "DiamondSearch"}/ThreeVg/select`,
    unselect: `${isFancy ? "FancySearch" : "DiamondSearch"}/ThreeVg/unselect`,
  },
  CaratSize: {
    custom: `${isFancy ? "FancySearch" : "DiamondSearch"}/CaratSize/custom`,
    existing: `${isFancy ? "FancySearch" : "DiamondSearch"}/CaratSize/existing`,
  },
  Color: {
    fancy: `${isFancy ? "FancySearch" : "DiamondSearch"}/Color/fancy`,
    white: `${isFancy ? "FancySearch" : "DiamondSearch"}/Color/white`,
  },
});

export const listingPageList = {
  "search-result": "SearchResult",
  "upcoming-diamonds": "UpcomingDiamond",
  recommended: "Recommended",
  "new-diamonds": "NewDiamond",
  "my-cart": "MyCart",
  "my-watchlist": "MyWatchlist",
  "my-reminder": "MyReminder",
  "my-comment": "MyComment",
  "my-enquiry": "MyEnquiry",
  "my-demand": "MyDemand",
  "my-order": "MyOrder",
};

export const listingPage = {
  List: (page) => `${page}/Diamonds/list`,
  Modify: (page) => `${page}/Modify/click`,
  Filter: (page) => `${page}/StickyFilter/click`,
  PlaceOrder: {
    open: (page) => `${page}/PlaceOrder/open`,
    close: (page) => `${page}/PlaceOrder/close`,
  },
  Shipment: {
    open: (page) => `${page}/Shipment/open`,
    close: (page) => `${page}/Shipment/close`,
  },
  Enquiry: {
    open: (page) => `${page}/Enquiry/open`,
    close: (page) => `${page}/Enquiry/close`,
  },
  Delete: {
    open: (page) => `${page}/Delete/open`,
    close: (page) => `${page}/Delete/close`,
  },
  Update: {
    open: (page) => `${page}/Update/open`,
    close: (page) => `${page}/Update/close`,
  },
  Export: {
    print: (page) => `${page}/Export/print`,
    excel: (page) => `${page}/Export/excel`,
    emailExcel: (page) => `${page}/Export-Send_Email/excel`,
  },
  ActionHeader: {
    cart: {
      open: (page) => `${page}/AddToCart/open`,
      close: (page) => `${page}/AddToCart/close`,
    },
    watchlist: {
      open: (page) => `${page}/AddToWatchList/open`,
      close: (page) => `${page}/AddToWatchList/close`,
    },
    comment: {
      open: (page) => `${page}/Comment/open`,
      close: (page) => `${page}/Comment/close`,
    },
    reminder: {
      open: (page) => `${page}/Reminder/open`,
      close: (page) => `${page}/Reminder/close`,
    },
    view: {
      grid: (page) => `${page}/View/grid`,
      list: (page) => `${page}/View/list`,
    },
    filter: (page) => `${page}/Filter/click`,
    setting: {
      open: (page) => `${page}/Setting/open`,
      close: (page) => `${page}/Setting/close`,
    },
  },
  Table: {
    cart: {
      open: (page) => `${page}/Table-AddToCart/open`,
      close: (page) => `${page}/Table-AddToCart/close`,
    },
    watchlist: {
      open: (page) => `${page}/Table-AddToWatchList/open`,
      close: (page) => `${page}/Table-AddToWatchList/close`,
    },
    delete: {
      open: (page) => `${page}/Table-Delete/open`,
      close: (page) => `${page}/Table-Delete/close`,
    },
    comment: {
      open: (page) => `${page}/Table-Comment/open`,
      close: (page) => `${page}/Table-Comment/close`,
    },
    reminder: {
      open: (page) => `${page}/Table-Reminder/open`,
      close: (page) => `${page}/Table-Reminder/close`,
    },
    details: {
      video: (page) => `${page}/Table-Details/video`,
      picture: (page) => `${page}/Table-Details/picture`,
      certificate: (page) => `${page}/Table-Details/certificate`,
    },
  },
};

export const MySavedSearchTracker = {
  edit: "MySavedSearch/Edit/click",
  delete: {
    open: `MySavedSearch/Delete/open`,
    close: `MySavedSearch/Delete/close`,
  },
  search: "MySavedSearch/Search/click",
};

export const MyDemandTracker = {
  delete: {
    open: `MyDemand/Delete/open`,
    close: `MyDemand/Delete/close`,
  },
  search: "MyDemand/Search/click",
};

export const MyAccount = {
  changePswd: {
    open: "MyAccount/ChangePassword/open",
    change: "MyAccount/ChangePassword/change",
  },
};

export const HeaderAction = {
  invantory: {
    open: "Header/Inventory/open",
    close: "Header/Inventory/close",
    excel: (range) => `Header/Inventory-Excel_${range}/download`,
    csv: (range) => `Header/Inventory-CSV_${range}/download`,
  },
  cart: "Header/MyCart/click",
  calculator: "Header/Calculator/click",
  trackShipment: "Header/TrackShipment/click",
  locale: (locale) => `Header/Locale/${locale}`,
  search: {
    focus: "Header/Search/focus",
    enter: "Header/Search/search",
    select: "Header/Search-Suggestion/select",
  },
};

export const SearchSuggestionA = {
  search: {
    focus: "Header/Search/focus",
    enter: "Header/Search/search",
    select: "Header/Search-Suggestion/select",
  },
};

export const user = {
  logout: {
    complete: "user/logout/complete",
  },
};

export const parcel = {};
