import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { userSignIn } from '../../appRedux/actions/Auth';
import IntlMessages from '../../util/IntlMessages';
import OpenNotification from '../../components/common/CommonButton/OpenNotification';
import _ from 'lodash';
import { Form, Input, Checkbox, Modal, notification } from 'antd';
import Loader from '../common/Loader';
import UtilService from '../../services/util';
import axios from 'axios';
import InputBlock from '../common/InputBlock';
import { setLoader } from '../../appRedux/actions/Common';
import { LOCAL_STORAGE_VAR, KYC_STATUS } from '../../constants/Common';
import {
  LOGIN_USER,
  RESEND_VERIFY_MAIL,
  UPDATE_USER_TOKEN,
  ACCOUNT_UPDATE_KYC,
  UPLOAD_FILE,
} from '../../constants/apiConstant';
import CommonModal from '../common/CommonModal';
import UpdateProfile from '../Dashboard/UpdateProfile';
import moment from 'moment';
import { store } from 'appRedux/store';
import { USER_DATA, USER_TOKEN_SET } from 'constants/ActionTypes';
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        username: '',
        password: '',
      },
      userData: {},
      rememberMe: localStorage.getItem(`${LOCAL_STORAGE_VAR}-rememberMe`) === 'true',
      checked: true,
      rememberCheckVal: false,
      loading: false,
      error: null,
      verifyEmail: false,
      showModal: false,
      showTCModal: false,
      errors: {},
      Kyc: {
        imageOne: '',
        imageTwo: '',
      },
      visible: false,
    };
  }

  componentDidMount() {
    let rememberedUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-rememberdmeuser`);
    if (rememberedUser) {
      this.setState({
        formData: {
          username: rememberedUser.username,
          password: rememberedUser.password,
        },
      });
    }
    let rememberCheckData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-rememberMe`));
    if (rememberCheckData) {
      this.setState({
        rememberMe: rememberCheckData,
      });
    }
  }

  handleSubmit = () => {
    let self = this;
    if (!self.state.rememberMe) {
      localStorage.removeItem(`${LOCAL_STORAGE_VAR}-rememberdmeuser`);
      localStorage.removeItem(`${LOCAL_STORAGE_VAR}-rememberMe`);
    }
    let reqObj = self.state.formData;
    if (self.handleValidation()) {
      // self.props.setLoader(true);

      let obj = {
        ...LOGIN_USER,
        loginForm: true,
        request: {
          ...reqObj,
          masters: [
            'COLOR',
            'CLARITY',
            'SHAPE',
            'CUT',
            'FLUORESCENCE',
            'SHADE',
            'LAB',
            'POLISH',
            'SYMMETRY',
            'LOCATION',
            'BLACK_INCLUSION',
            'OPEN_TABLE',
            'MILKEY',
            'WHITE_INCLUSION_CROWN',
            'OPEN_CROWN',
            'EYECLEAN',
            'OPEN_PAVILION',
            'ORIGIN',
            'BLACK_INCLUSION_CROWN',
            'H_AND_A',
            'WHITE_INCLUSION_TABLE',
            'FANCY_COLOR',
            'INTENSITY',
            'OVERTONE',
            'KEY_TO_SYMBOLS',
            'GIRDLE',
            'CULET',
            'GIRDLE_COND',
            'CULET_COND',
            'COMPANY_SIZE',
            'MIX_TINT',
            'NATURAL',
            'OPEN_INCLUSION',
            'BUSINESS_TYPE',
            'NATURE_OF_ORG',
            'BRILLIANCY',
            'DOC_TYPE_PERSONAL',
            'DOC_TYPE_BUSINESS',
          ],
        },
      };

      UtilService.callApi(obj, function (err, data) {
        // self.props.setLoader(false);
        // if (res === "EMAIL_NOT_VERIFIED") {
        //   this.setState({ verifyEmail: true });
        // }
        if (err) {
          return OpenNotification({
            type: 'error',
            title:
              (err && err.data && err.data.message) || (err && err.data && err.data.data.message) || 'Failed to Login.',
          });
        }
        if (data && data.code === 'OK') {
          if (self.state.rememberMe) {
            let remData = {
              username: self.state.formData.username,
              password: self.state.formData.password,
            };
            UtilService.setLocalStorageItem(remData, `${LOCAL_STORAGE_VAR}-rememberdmeuser`);
          }
          self.setState({ userData: data }, () => {
            if (self.state.userData && self.state.userData.data.user.kycRequired) {
              if (self.state.userData.data.user.account && isEmpty(self.state.userData.data.user.account.kyc)) {
                self.setState({ visible: true });
              } else {
                self.getRedirection(data);
              }
            } else {
              self.getRedirection(data);
            }
          });
        } else if (data && data.code === 'E_BAD_REQUEST') {
          if (data && data.data && data.data.allowedTime) {
            let today = new Date().getTime();
            let dynamic = new Date(moment(data.data.allowedTime).format('')).getTime();
            let diff = (today - dynamic) / 1000;
            diff /= 60;
            return OpenNotification({
              type: 'error',
              title: `Your account is blocked.Please try again after ${
                Math.abs(Math.round(diff)) !== 0 ? Math.abs(Math.round(diff)) : 'a'
              }
                ${Math.abs(Math.round(diff)) !== 0 ? 'minutes.' : 'minute'}
                `,
            });
          } else {
            return OpenNotification({
              type: 'error',
              title: (data && data.message) || (data && data.message) || 'Failed to Login.',
            });
          }
        }
      });
    }
  };

  handleOnChange = (key, value) => {
    let { formData } = this.state;
    formData[key] = value;
    this.setState(formData);
  };

  handleRememberMe = (e) => {
    this.setState(
      {
        rememberMe: e.target.checked,
      },
      () => {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-rememberMe`, JSON.stringify(e.target.checked));
      },
    );
  };

  handleVerifyEmail = () => {
    let self = this;
    let api = '/admin/user/resend-verification-email';
    let obj = {
      username: this.state.username,
    };
    let objData = {
      // method: "post",
      // url: api,
      ...RESEND_VERIFY_MAIL,
      request: obj,
    };

    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        self.setState({ verifyEmail: false });
      }
    });
  };

  handleUpdateToken = () => {
    let self = this;
    let api = '/auth/update-token';
    let objData = {
      // method: "post",
      // url: api,
      ...UPDATE_USER_TOKEN,
      request: {
        masters: [
          'COLOR',
          'CLARITY',
          'SHAPE',
          'CUT',
          'FLUORESCENCE',
          'SHADE',
          'LAB',
          'POLISH',
          'SYMMETRY',
          'LOCATION',
          'BLACK_INCLUSION',
          'OPEN_TABLE',
          'MILKEY',
          'WHITE_INCLUSION_CROWN',
          'OPEN_CROWN',
          'EYECLEAN',
          'OPEN_PAVILION',
          'ORIGIN',
          'BLACK_INCLUSION_CROWN',
          'H_AND_A',
          'WHITE_INCLUSION_TABLE',
          'FANCY_COLOR',
          'INTENSITY',
          'OVERTONE',
          'KEY_TO_SYMBOLS',
          'GIRDLE',
          'CULET',
          'GIRDLE_COND',
          'CULET_COND',
          'COMPANY_SIZE',
          'BUSINESS_TYPE',
          'NATURE_OF_ORG',
          'DOC_TYPE_PERSONAL',
          'DOC_TYPE_BUSINESS',
        ],
      },
      updateToken: self.state.updateToken, //token
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        if (!_.has(data.data.user, ['isTermsCondAgree']) || !data.data.user.isTermsCondAgree) {
          self.setState({ showTCModal: true });
        } else {
          self.getRedirection(data);
        }
      }
    });
  };

  handleAcceptTerms = () => {
    let self = this;
    let api = '/admin/user/update';
    let objData = {
      method: 'put',
      url: api,
      request: { isTermsCondAgree: true },
      updateToken: self.state.updateToken,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        self.handleUpdateToken();
      }
    });
  };

  getRedirection = (data) => {
    const user = data.data.user;
    // if (
    //   data.data.userPermissions &&
    //   (!data.data.userPermissions.data || !data.data.userPermissions.data.length)
    // ) {
    //   return OpenNotification({
    //     type: "error",
    //     title: "Failed to Login.",
    //   });
    // }
    let stepDisplay = {
      dashboard: !user.isIntoHide,
      diamondSearch: !user.isIntoHide,
      searchResult: !user.isIntoHide,
      diamondDetails: !user.isIntoHide,
    };

    const masters = data.data.masters;
    const columns = data.data.columns;
    const userPermissions = data.data.userPermissions;
    const keywords = data.data.keywords;
    const token = data.data.token.jwt;

    UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
    UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);

    store.dispatch({ type: USER_TOKEN_SET, payload: token });
    store.dispatch({ type: USER_DATA, payload: user });

    localStorage.setItem(`${LOCAL_STORAGE_VAR}-master`, JSON.stringify(masters));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(columns));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-keywords`, JSON.stringify(keywords));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-tooltip-steps`, JSON.stringify(stepDisplay));

    localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, data.data.isTrusted);
    let setPermit = {};
    userPermissions.data.map((x) => {
      if (x.module === 'order' && user.Setting) {
        x.permissions.insert = user.Setting.Is_PlaceOrder ? user.Setting.Is_PlaceOrder : false;
      }

      if (x.module === 'pricing' && user.Setting && user.Setting.Is_NoPrice !== true) {
        x.permissions.view = user.Setting.Is_NoPrice !== true;
        x.permissions.all = user.Setting.Is_NoPrice !== true;
      }

      setPermit[x.module] = x.permissions;
    });

    if (setPermit && !setPermit.pricing) {
      if (user.Setting && user.Setting.Is_NoPrice !== true) {
        setPermit.pricing = {};
        setPermit.pricing.view = user.Setting.Is_NoPrice !== true;
        setPermit.pricing.all = user.Setting.Is_NoPrice !== true;
      }
    }

    // localStorage.setItem(
    //   `${LOCAL_STORAGE_VAR}-userPermissions`,
    //   JSON.stringify(setPermit)
    // );
    UtilService.setLocalStorageItem(setPermit, `${LOCAL_STORAGE_VAR}-userPermissions`);

    axios.defaults.headers.common['access-token'] = 'JWT ' + token;
    this.props.history.push('/dashboard');
  };

  handleValidation = () => {
    let formData = this.state.formData;
    let errors = this.state.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFiels = [];
    if (!formData['username'] || _.isEmpty(formData['username'])) {
      blankField.push('User Name');
      errors['username'] = true;
      formIsValid = false;
    }
    if (!formData['password'] || _.isEmpty(formData['password'])) {
      blankField.push('Password');
      errors['password'] = true;
      formIsValid = false;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalidFiels.length > 0) {
      let arr = invalidFiels;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  createKyc = () => {
    let self = this;
    if (self.state.Kyc && self.state.Kyc.imageOne !== '' && self.state.Kyc.imageTwo !== '') {
      let user = self.state.userData;
      let objReq = {
        url: ACCOUNT_UPDATE_KYC.url + user.data.user.account.id,
        method: ACCOUNT_UPDATE_KYC.method,
        showLoader: ACCOUNT_UPDATE_KYC.showLoader,
        request: {
          kyc: [{ path: self.state.Kyc.imageOne }, { path: self.state.Kyc.imageTwo }],
          isKycUploaded: true,
          isApproved: KYC_STATUS.PENDING,
        },
        auth: user.data.token.jwt,
      };
      UtilService.callApi(objReq, function (err, data) {
        if (err) throw err;
        if (data && data.code === 'OK') {
          user.data.user.account.isKycUploaded = true;
          user.data.user.account.kyc = objReq.request.kyc;
          // UtilService.setLocalStorageItem(user.data.user, `${LOCAL_STORAGE_VAR}-user`)
          self.getRedirection(user);

          OpenNotification({
            type: 'success',
            title: 'Your KYC documents are successfully submitted.',
          });
          self.onClose();
        }
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please upload both the images',
      });
    }
  };
  uploadKyc = (file, name, img) => {
    let self = this;
    let user = self.state.userData;
    let objData = {
      ...UPLOAD_FILE,
      auth: user.data.token.jwt,
      request: file,
    };
    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        if (data.data && data.data.files && data.data.files.length !== 0) {
          if (img === 1) {
            self.setState({
              Kyc: {
                imageTwo: self.state.Kyc.imageTwo,
                imageOne: data.data.files[0].absolutePath,
              },
            });
          } else {
            self.setState({
              Kyc: {
                imageOne: self.state.Kyc.imageOne,
                imageTwo: data.data.files[0].absolutePath,
              },
            });
          }
          OpenNotification({
            type: 'success',
            title: 'Document uploaded successfully.',
          });
        }
      }
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { formData, rememberMe, errors } = this.state;
    return (
      <>
        <div className="loginDetail">
          <div className="loginBox">
            <Loader loading={this.props.loading} />
            <InputBlock
              onChange={(e) => this.handleOnChange('username', e.target.value)}
              value={formData.username}
              type="text"
              label="User Name"
              placeholder="User Name"
              hasError={Boolean(errors['username'])}
            />
            <InputBlock
              onChange={(e) => this.handleOnChange('password', e.target.value)}
              value={formData.password}
              type="password"
              label="Password"
              hasError={Boolean(errors['password'])}
              placeholder="Password"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (this.state.username && this.state.password) {
                    this.handleSubmit();
                  }
                }
              }}
            />
            <div className="d-flex j-space-between align-items-center loginLinkBlock">
              <div className="rememberCheckBox">
                <Checkbox
                  id="remember-check"
                  checked={rememberMe}
                  // value={rememberMe}
                  onChange={this.handleRememberMe}
                />
                <span className="rememberLabel">Remember Me</span>
              </div>
            </div>
            <div className="loginButton mb-30">
              <button className="commonButton" onClick={this.handleSubmit.bind(this)}>
                {/* <IntlMessages id="app.Guest.loginButton" /> */}
                Sign In
              </button>
              <Link to="/forgot-password" className="primary-text forgot-password">
                Forgot Pasword?
              </Link>
            </div>
            {/* <div className="registerLogLink">
                <IntlMessages id="app.signin.dontHaveLogin" />
                <Link to="/signup">
                  <IntlMessages id="app.signin.register" />
                </Link>
              </div> */}
            {/* </form> */}
            {/* <div className="d-flex j-space-between mb-20">
              <Link className="textViewAll mt-10 cursor-pointer" to="/GDPR-compliance" target="_blank">
                This Site is GDPR compliance
              </Link>
            </div> */}

            <Link
              to="/signup"
              // target="_blank"
              style={{ textAlign: 'left' }}
              className="textViewAll mt-10 cursor-pointer"
            >
              Register
            </Link>

            <Link to={'/guest-login'} style={{ textAlign: 'left' }} className="textViewAll mt-10 cursor-pointer">
              Guest Login
            </Link>

            {this.state.verifyEmail && (
              <button className="commonButton" id={'verifyEmailBtn'} onClick={this.handleVerifyEmail}>
                Verify Email
              </button>
            )}
          </div>
        </div>

        <Modal
          visible={this.state.showModal}
          title={[
            <h2 key="deletebuttonH2" className="modal__title primary-text" id="modal-1-title">
              Confirmation
            </h2>,
          ]}
          onCancel={() => this.setState({ showModal: false })}
          footer={[
            <div key="deletebuttonReminder">
              <button
                className="modal__btn button primary-fill"
                style={{ padding: '9px 25px' }}
                onClick={this.handleUpdateToken}
              >
                Yes
              </button>
              <button
                className="button button-outline primary-outline"
                style={{ padding: '9px 25px' }}
                onClick={() => this.setState({ showModal: false })}
              >
                No
              </button>
            </div>,
          ]}
          width={620}
        >
          <h3 align="center" style={{ color: 'rgba(0,0,0,0.70)' }}>
            It appears that, you are already Logged-In on other Device. Do you wish to Continue here and logout from
            earlier device?
          </h3>
        </Modal>
        <CommonModal
          title="Upload KYC"
          handleOk={this.createKyc}
          handleCancel={() => {
            this.onClose();
          }}
          visible={this.state.visible}
          wrapClassName="diamondListinSidebar"
          footerShow
          submitTitle={'Submit'}
          // cancelTitle={'Skip'}
        >
          <UpdateProfile
            kyc
            imageOne={this.state.Kyc.imageOne}
            imageTwo={this.state.Kyc.imageTwo}
            getKycImage={this.uploadKyc}
            onClick={this.onClose}
          />
        </CommonModal>
      </>
    );
  }
}

const mapStateToProps = ({ auth, commonData }) => {
  const { token } = auth;
  const { loading } = commonData;
  return { token, loading };
};

export default withRouter(connect(mapStateToProps, { setLoader })(LoginForm));
