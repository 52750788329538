import React from "react";
import Heading from "../Heading";
import {ReactComponent as CloseWhite} from '../../../assets/svg/crossCopy.svg';
const HeaderRightSidebar = (props) => {
  return (
    <div className={`HeaderRightSidebar ${props.activeClass}`}>
      <div
        className="headerRightOverlay"
        onMouseEnter={props.handleOut}
        onMouseOver={props.handleOut}
        onKeyDown={props.handleOut}
        onClick={props.handleOut}
      ></div>
      <div className="headerRightSidebarTop">
        <Heading title="Notification" />
        <CloseWhite className="closeNoti" onClick={props.handleOut}/>
        {/* {props.showUser &&
          <>
            <div className="headerIcon">
              <img
                className="defulticon"
                src={require("../../../assets/svg/Header/search.svg")}
                alt="icon"
              />
              <img
                className="hoverIcon"
                src={require("../../../assets/svg/Header/search-white.svg")}
                alt="icon"
              />
              <img
                src={require("../../../assets/svg/Header/user.svg")}
                alt="icon"
              />
              <img
                className="hoverIcon"
                src={require("../../../assets/svg/Header/user-white.svg")}
                alt="icon"
              />
              <span>Welcome to Nirav Shah</span>
            </div>

          </>
        } */}
      </div>
      <div className="dropdownRightSide">
        {props.children}</div>
    </div>
  );
};
export default HeaderRightSidebar;
