import React, { memo, useState } from 'react';

import { Drawer } from 'antd';

import { formatDecimal } from 'util/utils';

import { LAB_LINKS, FILE_URLS } from '../../../constants/Common';
import useRowSelect from '../../../util/useRowSelect';
import DiamondDetail from '../../DiamondDetail';
import CheckBox from '../../common/CheckBox';
import { formatNumber } from '../../common/DiamondListing/SelectStone';
import { STATUS, STATUS_COLOR } from '../../common/DiamondListing/Status';
import Image from '../../common/Image';
import { getParams, LISTINGPAGES } from '../DiamondListFunctions';

const DiamondSearchGrid = (props) => {
  const [visible, setVisible] = useState(false);
  const { isRowChecked, toggleRowSelection } = useRowSelect(props.currentType);
  return (
    <div className="diamondGridView">
      {!props.loading &&
        props.data.length > 0 &&
        props.data.map((block) => {
          const isChecked = isRowChecked(block.id);
          return (
            <div key={`grid_${block.id}`} className="daimondGridViewItem">
              <div className="DiamondGridViewImg">
                <div className="diamondGrdiCheckbox">
                  <CheckBox checked={isChecked} onChange={() => toggleRowSelection(block, !isChecked)} />
                </div>
                <Image src={FILE_URLS.img.replace('***', block.rptNo)} />
              </div>
              <div className="gridViewContent">
                <div className="gridLeftContent">
                  <h2>{block.shpNm}</h2>
                  <a
                    // href={`/diamond-detail/${block.id}`}
                    onClick={() => {
                      setVisible(block);
                    }}
                  >
                    {block.vStnId}
                  </a>
                  <p>{getParams(block).join(' ')}</p>
                </div>
                <div className="gridRightContent">
                  <a
                    onClick={() => {
                      if (block.lbNm && block.rptNo) {
                        window.open(LAB_LINKS[block.lbNm.toUpperCase()].replace('', block.rptNo));
                      }
                    }}
                  >
                    {block.lbNm}
                  </a>
                  <span>${formatDecimal(block.amt)}</span>
                  <span className="statusShow">
                    {STATUS[block.wSts]}
                    <span className={'statusIcon ' + STATUS_COLOR[block.wSts]}></span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}

      {visible && (
        <Drawer title={false} onClose={() => setVisible(false)} visible={true} wrapClassName="diamondDetailPopup">
          <DiamondDetail diamondPopup={true} data={visible} onClose={() => setVisible(false)} />
        </Drawer>
      )}
    </div>
  );
};

export default memo(DiamondSearchGrid);
