export class InvalidArgumentError extends Error {
  constructor(pos, types) {
    super(
      `\`iteratee\` must be of type ${types
        ?.map?.((type) => (type?.constructor?.name === 'String' ? type : type?.name))
        ?.join?.(',')} \`${typeof iteratee}\` passed, position - ${pos}`,
    );
    this.name = 'InvalidArgument';
    this.code = 'INVALID_ARGUMENT';
  }
}
