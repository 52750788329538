import React, { Component } from 'react';

import './smallHeader.less';
import '../HeaderStrip/headerstrip.less';
import { LOCAL_STORAGE_VAR } from '../../../../constants/Common';
import { NOTIFICATION_LIST } from '../../../../constants/apiConstant';
import { getSearchList } from '../../../../containers/APIFunctions';
import UtilService from '../../../../services/util';
import LangDropdown from '../../../Header/LangDropdown';
import CountBox from '../../CountBox';
import HeaderRightSidebar from '../../HeaderRightSidebar';
import NotificationDropdown from '../NotificationDropdown';
import HeaderSearch from '../Search';

const SmallHeaderStrip = (props) => {
  return (
    <div onMouseEnter={props.onMouseEnter} onClick={props.onClick} className="smallHeaderBox d-flex flex-wrap">
      <div className="smallHeaderSrtipIcon">
        <img src={props.icon} alt="" />
      </div>
      {props.title && (
        <a href={props.href} className="smallBoxTitle">
          {props.title}
        </a>
      )}
    </div>
  );
};

class SmallHeader extends Component {
  state = {
    isHovered2: false,
    search: false,
    searchList: [],
    count: 0,
  };
  handleHover2 = () => {
    this.setState({
      isHovered2: !this.state.isHovered2,
    });
  };
  handleOut2 = () => {
    this.setState({
      isHovered2: false,
    });
  };
  searchOption = () => {
    this.setState({
      search: !this.state.search,
    });
  };

  componentDidMount() {
    getSearchList((list) => this.setState({ searchList: list }));
    this.getNotify();
  }
  getNotify = () => {
    const self = this;
    const obj = {
      ...NOTIFICATION_LIST,
      request: {},
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) {
        self.setState({ count: 0 });
      } else if (data && data.code === 'OK') {
        if (data.data) {
          self.setState({ count: data.data.unReadCount });
        }
      } else {
        self.setState({ count: 0 });
      }
    });
  };
  getUpdatedCount = (countRead) => {
    this.setState({ count: countRead });
  };
  render() {
    const { count } = this.state;
    const btnClass2 = this.state.isHovered2 ? 'active' : '';
    const isMobile = window.innerWidth <= 767;
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    return (
      <>
        <div className="smallHeaderWrapper">
          <div className="d-flex align-items-center flex-wrap">
            <SmallHeaderStrip
              href="tel:+91 90221 81878"
              icon={require('../../../../assets/svg/Header/call.svg')}
              title="+91 90221 81878"
            />
            <SmallHeaderStrip
              href="mailto:info@cupiddiamonds.com"
              icon={require('../../../../assets/svg/Header/email.svg')}
              title="info@cupiddiamonds.com"
            />
          </div>
          <div className="smallHeaderSrtip d-flex">
            {/* <SmallHeaderStrip onClick={this.searchOption}
                icon={require("../../../../assets/svg/Header/search-white.svg")} /> */}
            <HeaderSearch
              className={this.state.search && 'active'}
              onClick={this.searchOption}
              searchList={this.state.searchList}
            />
            {/* <div className="SelectLanguage"> //<LangDropdown /></div> */}
            {USER_PERMIT.NOTIFICATIONS.view && (
              <>
                <SmallHeaderStrip
                  onClick={this.handleHover2}
                  icon={require('../../../../assets/svg/Header/notification-white.svg')}
                />
                {!this.state.isHovered2 && count !== 0 && count && <CountBox count={count} />}

                <HeaderRightSidebar
                  showUser={this.state.isHovered2}
                  activeClass={`notificationDropdownWarpper ${btnClass2}`}
                  handleOut={this.handleOut2}
                >
                  {this.state.isHovered2 && (
                    <NotificationDropdown
                      handleOut={this.handleOut2}
                      getUpdatedCount={this.getUpdatedCount}
                      getNotify={this.getNotify}
                    />
                  )}
                </HeaderRightSidebar>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default SmallHeader;
