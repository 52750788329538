import {
  SET_LOADER,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SWITCH_LANGUAGE,
  CURRENT_PAGE_DATA,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  CATCH_MASTER_DATA,
  PAGE_PERMISSION,
  RESET_SEARCH,
  MODIFY_SEARCH,
} from '../../constants/ActionTypes';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import UtilService from '../../services/util';

const defaultState = {
  error: '',
  loading: false,
  message: '',
  pageData: null,
  locale: {},
  pagePermission: () => {},
  strip: false,
};

const INIT_STATE = {
  error: '',
  loading: false,
  message: '',
  pageData: null,
  locale: localStorage.getItem(`${LOCAL_STORAGE_VAR}-localisation`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-localisation`))
    : {},
  pagePermission: () =>
    UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`)
      ? UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`)
      : {},
  strip: false,
};
export default (state = INIT_STATE, { type, payload, loading }) => {
  switch (type) {
    case FETCH_START:
      return { ...state, error: '', message: '', loading: true };

    case FETCH_SUCCESS:
      return { ...state, error: '', message: '', loading: false };

    case SHOW_MESSAGE:
      return { ...state, error: '', message: payload, loading: false };

    case FETCH_ERROR:
      return { ...state, loading: false, error: payload, message: '' };

    case HIDE_MESSAGE:
      return { ...state, loading: false, error: '', message: '' };

    case CURRENT_PAGE_DATA:
      return { ...state, pageData: payload };

    case CATCH_MASTER_DATA:
      return { ...state, masterData: payload };

    case SET_LOADER:
      return { ...state, loading: loading };

    case SWITCH_LANGUAGE:
      return { ...state, locale: payload };
    case PAGE_PERMISSION:
      return { ...state, pagePermission: payload };

    case RESET_SEARCH:
      return { ...state, reset: payload };

    case MODIFY_SEARCH:
      return { ...state, modifySearch: payload.modifyData || {} };

    case 'STRIP_SHOW':
      return { ...state, strip: payload };

    case '@@RESET':
      return { ...defaultState };

    default:
      return state;
  }
};
