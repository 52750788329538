import React from "react";
import { DatePicker } from "antd";
import {Link} from "react-router-dom"
import moment from "moment";
import "./datepicker.less";
const { RangePicker } = DatePicker;

const Datepicker = (props) => {
  let groupClass = "from-group datePicker";
  if (props.hasError) {
    groupClass = groupClass + " error-message";
  }
  const dateFormat = 'DD/MM/YYYY';

  return (
    <div className={groupClass}>
      {props.label ? <label className="commonLabel">{props.label}</label> : null}
      {props.single ?
        <>
          <DatePicker
            dropdownClassName="datePickerZindex"
            format="ll"
            placeholderText="Select Date"
            value={props.expiryDate ? moment(props.expiryDate) : undefined}
            onChange={props.handleChange}
            disabledDate={(e) => props.disabledDate(e)}
          />
          {props.showShip &&
          <Link to="/shipping-policy" target="_blank"  style={{ width: '105px', color: '#7bb646', textDecoration: 'underline', cursor: 'pointer' }} className='mt-10'>
            Shipping Policy
          </Link>
            // <div onClick={() => {
            //   props.showShipFn()
            // }} style={{ width: '105px', color: '#4eb45e', textDecoration: 'underline', cursor: 'pointer' }} className='mt-10'>
            //   Shipping Policy
            //             </div>
          }

        </>
        : !props.nodate ?

          <RangePicker
            disabledDate={(e) => props.disabledDate(e)}
            placement="topRight"
            dropdownClassName="rangeDatePicker datePickerZindex"
            className="SelectDateRange"
            defaultValue={[moment(), moment()]}
            format={dateFormat}
          />
          :
          <>
            {props.showShip &&
              <div onClick={() => {
                props.showShipFn()
              }} style={{ width: '105px', color: '#4eb45e', textDecoration: 'underline', cursor: 'pointer' }} className='mt-10'>
                Shipping Policy
                      </div>
            }
          </>

      }

    </div>
  );
};
export default Datepicker;
