import { SET_COUNT } from '../../constants/ActionTypes';

const INIT_STATE = {
  count: false,
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SET_COUNT:
      return { ...state, count: payload };

    case '@@RESET':
      return { ...INIT_STATE };

    default:
      return state;
  }
};
