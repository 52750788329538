import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import IntlMessages from '../../../util/IntlMessages';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';
import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import last from 'lodash/last';
import concat from 'lodash/concat';
import { Input } from 'antd';
import { SEARCH_DIAMOND_LIST } from '../../../constants/apiConstant';
import UtilService from '../../../services/util';
import { LISTINGPAGES } from '../../../components/DiamondList/DiamondListFunctions';

const { Search } = Input;

const HeaderSearch = (props) => {
  let history = useHistory();
  const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState({});
  const [recordOn, setRecordOn] = useState(false);
  const searchBox = useRef();
  let support = true;

  const handleSearchChange = (e) => {
    let value = e.target.value.toLowerCase();
    let searched = [];
    let splitted = value ? split(value, ' ') : [];

    if (!isEmpty(splitted)) value = last(splitted);

    if (value) {
      let others = [];
      let savedSearch = [];
      Object.keys(masterData).map((m) => {
        masterData[m].map((dt) => {
          if (dt.name.toLowerCase().startsWith(value)) {
            others.push({ label: dt.name, type: 'OTHER' });
          }
        });
      });

      props.searchList.map((sl) => {
        if (sl.name.toLowerCase().startsWith(value)) {
          savedSearch.push({ label: sl.name, type: 'SAVEDSEARCH', id: sl.id });
        }
      });

      searched = concat(others.slice(0, 5), savedSearch.slice(0, 5));
    } else {
      searched = [];
    }

    setSearchInput({ str: e.target.value, type: 'OTHER' });
    setSearchData(searched);
  };

  const handleSearch = () => {
    if (searchInput.type === 'OTHER') {
      let obj = {
        search: searchInput.str,
        isReturnCountOnly: true,
        isNotReturnTotal: true,
        page: 1,
        limit: 100,
      };
      let objData = {
        ...SEARCH_DIAMOND_LIST,
        request: obj,
      };
      UtilService.callApi(objData, function (err, data) {
        if (err) throw err;
        if (data && data.code === 'OK') {
          history.push(`/${LISTINGPAGES.LIST}?${data.data[0].filter.id}`);
          setSearchInput({});
          props.onClick();
        }
      });
    }
    if (searchInput.type === 'SAVEDSEARCH') {
      history.push(`/${LISTINGPAGES.LIST}?${searchInput.id}`);
      setSearchInput({});
      props.onClick();
    }
  };

  const stop = (func, reset) => {
    setRecordOn(false);
    catchFunc(func, reset);
    if (!isEmpty(searchInput)) handleSearch();
  };

  const catchFunc = (func, reset) => {
    setSearchInput({ str: props.transcript, type: 'OTHER' });
    props.abortListening();
    func();
    // reset();
  };
  const start = (func) => {
    setRecordOn(true);
    setSearchInput({ str: '', type: 'OTHER' });
    func();
  };
  const {
    transcript,
    resetTranscript,
    recognition,
    browserSupportsSpeechRecognition,
    startListening,
    stopListening,
    abortListening,
  } = props;
  if (!browserSupportsSpeechRecognition) {
    support = false;
  }

  useEffect(() => {
    if (props.transcript !== '') {
      setSearchInput({ str: props.transcript, type: 'OTHER' });
    }
  }, [props.transcript]);

  return (
    <div className={`searchWrapper ${props.className}`}>
      <div className="searchWrapperInner">
        <div className="searchBox d-flex align-items-center">
          <Search
            ref={searchBox}
            value={searchInput.str}
            placeholder="Stone No. / Certificate No"
            autoFocus
            onChange={handleSearchChange}
            suffix={false}
            // onBlur={() => {
            //   setSearchInput({});
            //   setSearchData([]);
            //   setRecordOn(false);
            //   props.onClick();
            // }}
            onPressEnter={handleSearch}
          />

          <div className="searchIconBox">
            <span className="searchIcon">
              <img src={require('../../../assets/svg/Header/search-white.svg')} alt="Search" />
            </span>
            {/* {support && (
              <>
                {!recordOn ? (
                  <span
                    className="searchIcon"
                    onClick={() => {
                      // resetTranscript();
                      start(startListening);
                    }}
                  >
                    <img src={require('../../../assets/svg/Header/voice.svg')} className="voiceSearchImg" />
                  </span>
                ) : (
                  <span
                    className="searchIcon"
                    onClick={() => {
                      props.abortListening();
                      stop(stopListening, resetTranscript);
                    }}
                  >
                    <img src={require('../../../assets/svg/Header/mute.svg')} className="voiceSearchImg" />
                  </span>
                )}
              </>
            )} */}
            {/* <span
              className="searchIconClose"
              onClick={() => {
                setSearchInput({});
                setSearchData([]);
                setRecordOn(false);
                props.onClick();
              }}
            >
              <img src={require('../../../assets/svg/Header/close.svg')} alt="" />
            </span> */}
          </div>
        </div>
        {searchData.length > 0 && (
          <div className="searchDropdown">
            <h2>
              <IntlMessages id="app.SearchRecent" />
            </h2>
            <ul>
              {searchData.map((s, i) => (
                <li
                  key={i}
                  onClick={() => {
                    let str = searchInput.str;
                    let res = str ? str.split(' ') : [];
                    res.pop();
                    res.push(s.label);
                    res = res.join(' ');
                    setSearchInput({
                      str: res + ' ',
                      type: s.type,
                      id: s.id,
                    });
                    searchBox.current.focus();
                    setSearchData([]);
                  }}
                >
                  {s.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const options = {
  autoStart: false,
  continuous: false,
};
export default SpeechRecognition(options)(HeaderSearch);
