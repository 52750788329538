import { Storage } from 'services/Storage';
import { callApi } from 'util/call-api';
import { getUserName } from 'util/utils';

export { callApi } from 'util/call-api';
export { getUserName as getFullName } from 'util/utils';

export default {
  callApi,
  getFullName: getUserName,
  getLocalStorageItem: (key) => Storage.get(key),
  setLocalStorageItem: (value, key) => Storage.set(key, value),
};
