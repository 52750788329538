import React from 'react';
import DiamondTotalCard from './DiamondTotalCard';
import { getPath, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import moment from 'moment';
import { Tooltip } from 'antd';

import CheckBox from '../CheckBox';
import { useGroupSelect } from 'util/useGroupSelect';
import { isEmpty } from 'util/utils';

const GroupCheckBox = React.memo(function GroupCheckBox(props) {
  const { groupKey, groupRows, currentType } = props;

  const [checked, indeterminate, toggle] = useGroupSelect(groupRows, currentType);
  const groupName = React.useMemo(() => groupRows?.[0]?.[groupKey], [groupKey, groupRows]);

  return <CheckBox antd name={groupName} checked={checked} indeterminate={indeterminate} onChange={toggle} />;
});

const TableGrouping = (props) => {
  return (
    <>
      {props.tableHide ? null : (
        <div className="tableGropupingBox">
          <div className="tableGroupTitle">
            <span className="tableGroupTitleInner">
              {props.Collapse && (
                <div className="tableCollapse" onClick={props.onClick}>
                  <img src={props.img} />
                </div>
              )}
              {!isEmpty(props?.groupKey) && !props.noCheckBox && (
                <Tooltip title="Select All">
                  <div className="checkBoxSelect">
                    <GroupCheckBox
                      groupKey={props?.groupKey}
                      groupRows={props?.groupRows}
                      currentType={props.currentType}
                    />
                  </div>
                </Tooltip>
              )}
              {props.groupingTitle && <span>{props.groupingTitle}</span>}
              {props.row && getPath() === LISTINGPAGES.CONFIRM && (
                <span>{moment(props.row.createdAt).format('DD/MM/YYYY')} | </span>
              )}
              {getPath() === LISTINGPAGES.MATCHPAIR && props.row && <span>{props.row.groupingTitle}</span>}
              {(getPath() === LISTINGPAGES.CONFIRM || getPath() === LISTINGPAGES.PURCHASE) && props.row && (
                <span>&nbsp;{'Order No: ' + props.row.memoNo}</span>
              )}
              {getPath() === LISTINGPAGES.QUOTE && props.row && props.row.updatedAt && (
                <span>{moment(props.row.updatedAt).format('DD/MM/YYYY')}</span>
              )}
              {props.row && props.row.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && <span>{props.row.remarks}</span>}
              {props.row && props.row.isOfficeHeader && <span>{moment(props.row.date).format('DD/MM/YYYY')}</span>}
            </span>
            <div className="tableHeaderMain">
              {props.row && props.row.totalDiamonds ? props.row.totalDiamonds + ' Diamond' : null}
              {props.sortingCount && (
                <div className="selectShortingMainBlock">
                  <div className="selectShorting">
                    <span className="selectShortingCount">1</span>
                    <span className="selectShortingArrow">
                      <img src={require('../../../assets/svg/DiamondList/shortinglistdown-white.svg')} />
                    </span>
                  </div>
                  <div className="shortingClose">
                    <img src={require('../../../assets/svg/DiamondList/close.svg')} alt="icon" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <tr className="tableGroupingTr">
        {!props.columns && (
          <td className="groupingTableAmount" style={{ color: 'transparent' }}>
            0
          </td>
        )}
        {props.columns
          ? props.columns.map((x) => {
              return (
                <td className="groupingTableAmount">
                  {props.displayTotal.includes(x.id) && <DiamondTotalCard row={props.row} col={x.id} />}
                </td>
              );
            })
          : null}
        {props.offerDis && (
          <td className="groupingTableAmount">
            <div className="tableInput tableTopOfferInput">
              <input />
            </div>
          </td>
        )}
        {props.offerDis && (
          <td className="groupingTableAmount">
            <div className="tableInput tableTopOfferInput">
              <input />
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default TableGrouping;
