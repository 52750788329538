import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Header/Logo';
import { Modal, Avatar, Icon, Select } from 'antd';
import { userSignOut } from '../../../appRedux/actions/Auth';
import LangDropdown from '../../Header/LangDropdown';
import { Inventory, BASE_DOWNLOAD_URL } from '../../../constants/Common';
import { HeaderAction } from '../../../constants/Traker';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import { setLoader, covidStrip } from '../../../appRedux/actions/Common';
import OpenNotification from '../CommonButton/OpenNotification';
import _ from 'lodash';
import UtilService, { getTrackData } from '../../../services/util';
import { commonLogout } from '../../../constants/Common';
import Slider from 'react-slick';
import Sidebar from '../../Sidebar';
import HeaderRightSidebar from '../HeaderRightSidebar';
import HeaderAccountList from '../HeaderAccountList';
import CartDropDown from '../Header/CartDropdown';
import NotificationDropdown from '../Header/NotificationDropdown';
import HeaderStrip from '../Header/HeaderStrip';
import SmallHeader from './SmallHeader';
import { GET_DASHBOARD_DATA } from '../../../constants/apiConstant';
const { Option } = Select;

class Header extends Component {
  // new Code
  constructor() {
    super();
    this.state = {
      isHovered: false,
      isHovered1: false,
      isHovered2: false,
      covid: true,
      HeaderStripList: false,
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleHover1 = this.handleHover1.bind(this);
    this.handleHover2 = this.handleHover2.bind(this);
  }
  componentDidMount() {
    this.getSidebarCounts();
    this.props.covidStrip(this.state.covid);
  }
  handleHover() {
    this.setState({
      isHovered: !this.state.isHovered,
      isHovered1: false,
      isHovered2: false,
    });
  }
  handleHover1() {
    this.setState({
      isHovered1: !this.state.isHovered1,
      isHovered: false,
      isHovered2: false,
    });
  }
  handleHover2() {
    this.setState({
      isHovered2: !this.state.isHovered2,
      isHovered: false,
      isHovered1: false,
    });
  }
  handleOut = () => {
    this.setState({
      isHovered: false,
    });
  };
  handleOut1 = () => {
    this.setState({
      isHovered1: false,
    });
  };
  handleOut2 = () => {
    this.setState({
      isHovered2: false,
    });
  };
  closeStrip = () => {
    this.props.covidStrip(!this.props.commonData.strip);
    this.setState({
      covid: false,
    });
  };
  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.commonData.strip);
    this.setState({
      HeaderStripList: !this.state.HeaderStripList,
    });
  };
  getSidebarCounts = () => {
    let self = this;
    let obj = {};
    let api = GET_DASHBOARD_DATA.url;
    let objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        self.setState({ details: data.data.tracks });
      }
    });
  };
  // state = { visible: false, showLag: false };

  // constructor() {
  //   super();
  //   this.state = {
  //     settings: {
  //       dots: false,
  //       arrows: false,
  //       infinite: false,
  //       autoplay: true,
  //       autoplaySpeed: 2000,
  //       speed: 500,
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //     Users: {},
  //   };
  // }

  // async componentDidMount() {
  //   this.handleToggleHeader();
  //   const Users = JSON.parse(localStorage.getItem("adma-user"));
  //   this.setState({ Users });
  // }
  // handleToggleHeader = () => {
  //   $(".mobile-menu").on("click", function () {
  //     $(".mobile-slide-bar").addClass("active");
  //   });
  //   $(".mobile-menu-close").on("click", function () {
  //     $(".mobile-slide-bar").removeClass("active");
  //   });
  // };

  // showModal = () => {
  //   this.setState({
  //     visible: true,
  //   });
  //   window.track(HeaderAction.invantory.open);
  // };
  // handleCancel = (e) => {
  //   this.setState({
  //     visible: false,
  //   });
  //   window.track(HeaderAction.invantory.close);
  // };

  // handleSteps = () => {
  //   $(document).ready(function () {
  //     $(function () {
  //       $("body").addClass("active");
  //     });
  //     $(".introjs-overlay,.introjs-donebutton,.introjs-skipbutton").click(function () {
  //       $("body").removeClass("active");
  //     });
  //   });
  //   this.props.setLoader(true);
  //   let steps = JSON.parse(localStorage.getItem("adma-tooltip-steps"));
  //   if (window.shortPath() === "dashboard") {
  //
  //     steps.dashboard = true;
  //   } else if (window.shortPath() === "diamond-search") {
  //     steps.diamondSearch = true;
  //   } else if (window.shortPath() === "search-result") {
  //     steps.searchResult = true;
  //   } else if (window.shortPath() === "diamond-details") {
  //     steps.diamondDetails = true;
  //   }
  //   localStorage.setItem("adma-tooltip-steps", JSON.stringify(steps));
  // };

  // download = (filter, type) => {
  //   this.props.setLoader(true);
  //   let labId = _.find(JSON.parse(localStorage.getItem("adma-master")).LAB, {
  //     webDisplay: "GIA",
  //   });
  //   if (!labId || !labId.id) {
  //     return OpenNotification({
  //       type: "error",
  //       title: "No GIA lab found !",
  //     });
  //   }

  //   if (type === "csv") {
  //     let obj = {
  //       method: "post",
  //       url: "/admin/diamond/csv",
  //       request: {
  //         filter: {
  //           or: [{ carat: filter.carat }],
  //           labId: labId.id,
  //         },
  //         analytics: getTrackData(
  //           HeaderAction.invantory[type](`${filter.carat[">="]}-${filter.carat["<="]}`)
  //         ),
  //       },
  //     };
  //     UtilService.callApi(obj, (err, data) => {
  //       this.handleCancel();
  //       this.props.setLoader(false);
  //       if (err) {
  //         return OpenNotification({
  //           type: "error",
  //           title: err.data.message,
  //         });
  //       }
  //       if (data && data.code === "OK") {
  //         window.open(`${BASE_DOWNLOAD_URL}${data.data.data}`);
  //       }
  //     });
  //   } else {
  //     let obj = {
  //       method: "post",
  //       url: "/admin/diamond/excel",
  //       request: {
  //         filter: {
  //           or: [{ carat: filter.carat }],
  //           labId: labId.id,
  //         },
  //         analytics: getTrackData(
  //           HeaderAction.invantory[type](`${filter.carat[">="]}-${filter.carat["<="]}`)
  //         ),
  //       },
  //     };
  //     UtilService.callApi(obj, (err, data) => {
  //       this.handleCancel();
  //       this.props.setLoader(false);
  //       if (err) {
  //         return OpenNotification({
  //           type: "error",
  //           title: err.data.message,
  //         });
  //       }
  //       if (data && data.code === "OK") {
  //         window.open(`${BASE_DOWNLOAD_URL}${data.data.data}`);
  //       }
  //     });
  //   }
  // };
  // lagClick = () => {
  //   if (this.state.showLag == true) {
  //     this.setState({
  //       showLag: false,
  //     });
  //   } else {
  //     this.setState({
  //       showLag: true,
  //     });
  //   }
  // };

  render() {
    // let { settings } = this.state;
    // const tokens = JSON.parse(localStorage.getItem("adma-token"));
    // const locale = JSON.parse(localStorage.getItem("adma-localisation"))
    //   ? JSON.parse(localStorage.getItem("adma-localisation")).locale
    //   : "en";
    // let setpsPath = ["dashboard", "diamond-search", "search-result", "diamond-details"];
    // let stepDisplay = setpsPath.includes(window.shortPath());
    // const { Users } = this.state;
    // let Name = Users && Users.name ? Users.name : Users && Users.username;
    // let avtarName = Name ? Name.charAt(0) : "";

    // new Code
    const btnClass = this.state.isHovered ? 'active' : '';
    const btnClass1 = this.state.isHovered1 ? 'active' : '';
    const btnClass2 = this.state.isHovered2 ? 'active' : '';

    return (
      <>
        {/* {this.state.covid && (
          <div className="covidStrip">
            <span>Evaluate COVID‑19 symptoms and understand next steps</span>
            <span className="closeImg" onClick={this.closeStrip}>
              <img src={require("../../../assets/svg/Header/close-white.svg")} alt="" />
            </span>
          </div>
        )} */}
        {/* Just remove false to show it */}
        {false && this.state.HeaderStripList && <HeaderStrip onClick={this.HeaderStripCick} />}
        {false && !this.state.HeaderStripList && (
          <div className="downArrow upArrow" onClick={this.HeaderStripCick}>
            <img className="closeImage" src={require('../../../assets/svg/Header/up-arrow.svg')} alt="" />
          </div>
        )}
        {this.state.HeaderStripList && (
          <div className="downArrow " onClick={this.HeaderStripCick}>
            <img className="closeImage" src={require('../../../assets/svg/Header/down-arrow.svg')} alt="" />
          </div>
        )}
        <SmallHeader />
      </>
    );
  }
}

// export default Header;
function mapStateToProps(store) {
  return {
    commonData: store.commonData,
  };
}

export default connect(mapStateToProps, { setLoader, userSignOut, covidStrip })(Header);
