import React, { Component } from 'react';
import _, { isNumber } from 'lodash';
import Heading from '../Heading';
import { getColumn, handleSaveQuote, isMobile } from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/Table';
import SelectStone, { calculate, formatNumber } from './SelectStone';
import { Drawer } from 'antd';
import '../../common/LeftMultipleSelect/leftOption.less';
import DatePicker from '../../common/DatePicker';
import { InputNumber } from 'antd';
import CommonModal from '../CommonModal';
import ShippingPolicy from '../../ShippingPolicy';
import moment from 'moment';
import OpenNotification from '../CommonButton/OpenNotification';
import invert from 'lodash/invert';
import MobileOfferPopup from './MobileOfferPopup';
import { DECIMAL_POINTS } from '../../../constants/Common';

class QuotePopup extends Component {
  state = {
    visible: false,
    columns: [],
    data: this.props.editOffer ? this.props.editOffer : this.props.checked,
    checked: [],
    comment: this.props.editOffer ? this.props.editOffer[0].remarks : '',
    expire: this.props.editOffer ? Number(Number.parseFloat(this.props.editOffer[0].quoteHr).toFixed(2)) : undefined,
    data: [],
    timeError: false,
  };

  offerHeading = () => {
    if (isMobile() && !this.state.checked.length) return null;
    let sum = calculate(this.state.checked);
    let obj = {
      'CT. :': parseFloat(sum.total_carat),
      'Disc% :': parseFloat(sum.final_discount),
      'Price/Ct :': formatNumber(parseFloat(sum.final_price)),
      'Discount Different :': parseFloat(sum.final_term_discount - sum.final_discount),
      'Offer% :': parseFloat(sum.final_term_discount),
      'Offer Value :': formatNumber(parseFloat(sum.final_net_value)),
    };
    if (isMobile()) obj = invert(obj);
    return (
      <div className="DiamondDetailPopup">
        {Object.keys(obj).map((x) => {
          return (
            <div className="DiamondDetailPopupItem">
              <span>{x}</span>
              <span>{isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}</span>
            </div>
          );
        })}
      </div>
    );
  };

  getOfferDiscountInput = (row) => {
    let data = this.state.data;
    let rowOfferDis = row.offerDis;
    let index = _.findIndex(data, { id: row.id });
    return (
      <input
        style={{ color: '#4eb45e' }}
        onChange={(e, id) => {
          let val = e.target.value;
          let check = Number(val);
          let final = 0;
          let dis = `${this.state.data[index]['back']}`; //.replace('-', '')

          if (!isNaN(check)) {
            if (val === '') {
              val = Number(dis);
            } else {
              if (val > 70) {
                val = Number(dis);
              } else if (Number(dis) - 5 > Number(val)) {
                val = Number(dis);
              } else {
                val = e.target.value;
              }
            }
          } else {
            val = Number(dis);
          }

          val = parseFloat(val);
          this.state.data[index]['offerDis'] = val; //* -1;

          // let disCaratprice =
          //   this.state.data[index]["rap"] +
          //   (this.state.data[index]["rap"] * val) / 100;

          let disCaratprices = (1 + val / 100) * this.state.data[index].rap;
          this.state.data[index]['quote'] = disCaratprices;

          final = disCaratprices * this.state.data[index].crt;
          this.state.data[index]['finalquote'] = final;
          // let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].finalquote }])
          // this.state.data[index].newDiscount = sum.final_discount
        }}
        onBlur={(e, id) => {
          let val = e.target.value;
          let check = Number(val);
          let final = 0;
          let dis = `${this.state.data[index]['back']}`; //.replace('-', '')

          if (!isNaN(check)) {
            if (val === '') {
              val = Number(dis);
              OpenNotification({
                type: 'error',
                title: `Cannot allow discount less than ${parseFloat(`${val - 5}`, DECIMAL_POINTS.TWO_DIGITS)}%`,
              });
            } else {
              if (val > 70) {
                val = Number(dis);
                OpenNotification({
                  type: 'error',
                  title: `Cannot allow discount greater than 70%`,
                });
              } else if (Number(dis) - 5 > Number(val)) {
                val = Number(dis);
                OpenNotification({
                  type: 'error',
                  title: `Cannot allow discount less than ${parseFloat(`${val - 5}`, DECIMAL_POINTS.TWO_DIGITS)}%`,
                });
              } else {
                val = e.target.value;
              }
            }
          } else {
            val = Number(dis);
            OpenNotification({
              type: 'error',
              title: `Cannot allow discount less than ${parseFloat(`${val - 5}`, DECIMAL_POINTS.TWO_DIGITS)}%`,
            });
          }

          val = parseFloat(val);
          this.state.data[index]['offerDis'] = val; //* -1;
          // let disCaratprice =
          //   this.state.data[index]["rap"] +
          //   (this.state.data[index]["rap"] * val) / 100;

          let disCaratprices = (1 + val / 100) * this.state.data[index].rap;
          this.state.data[index]['quote'] = disCaratprices;

          final = disCaratprices * this.state.data[index].crt;
          this.state.data[index]['finalquote'] = final;

          // let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].finalquote }])
          // this.state.data[index].newDiscount = sum.final_discount
          this.setState({ data });
        }}
        defaultValue={rowOfferDis ? parseFloat(rowOfferDis) : 0.0}
      />
    );
  };

  getOfferPrInput = (row) => {
    let data = this.state.data;
    let quote = row.quote;
    let index = _.findIndex(data, { id: row.id });
    return (
      <input
        defaultValue={quote ? parseFloat(quote) : 0.0}
        onChange={(e, id) => {
          let val = e.target.value;
          let check = Number(val);
          let final = 0;
          let dis = `${this.state.data[index]['back']}`.replace('-', '');

          let current = this.state.data[index]['rap'];

          let currentSeventy = this.state.data[index]['rap'] - (current * 70) / 100;

          let currentFive = this.state.data[index]['rap'] - (current * (Number(dis) - 5)) / 100;

          if (!isNaN(check)) {
            if (val === '') {
              val = currentFive;
            } else {
              if (Number(val) > currentFive) {
                val = currentFive;
              } else if (Number(val) < currentSeventy) {
                val = currentSeventy;
              } else {
                val = Number(e.target.value);
              }
            }
          } else {
            val = currentFive;
          }

          val = parseFloat(val);

          final = val * this.state.data[index].crt;
          this.state.data[index]['finalquote'] = final;

          this.state.data[index]['quote'] = val;

          // let discount = (val / this.state.data[index].rap - 1) * 100;
          let discount = (val * 100) / this.state.data[index].rap - 100;
          this.state.data[index]['offerDis'] = discount;

          // let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].finalquote }])
          // this.state.data[index].newDiscount = sum.final_discount
        }}
        onBlur={(e) => {
          let val = e.target.value;
          let check = Number(val);
          let final = 0;
          let dis = `${this.state.data[index]['back']}`.replace('-', '');

          let current = this.state.data[index]['rap'];

          let currentSeventy = this.state.data[index]['rap'] - (current * 70) / 100;

          let currentFive = this.state.data[index]['rap'] - (current * (Number(dis) - 5)) / 100;
          let currentNorm = this.state.data[index]['rap'] - (current * Number(dis)) / 100;

          if (!isNaN(check)) {
            if (val === '') {
              val = currentNorm;
              OpenNotification({
                type: 'error',
                title: `Cannot allow discount less than ${parseFloat(`${val - 5}`, DECIMAL_POINTS.TWO_DIGITS)}%`,
              });
            } else {
              if (Number(val) > currentFive) {
                val = currentNorm;
                OpenNotification({
                  type: 'error',
                  title: `Cannot allow discount less than ${parseFloat(`${val - 5}`, DECIMAL_POINTS.TWO_DIGITS)}%`,
                });
              } else if (Number(val) < currentSeventy) {
                val = currentNorm;
                OpenNotification({
                  type: 'error',
                  title: `Cannot allow discount greater than 70%`,
                });
              } else {
                val = Number(e.target.value);
              }
            }
          } else {
            val = currentNorm;
            OpenNotification({
              type: 'error',
              title: `Cannot allow discount less than ${parseFloat(`${val - 5}`, DECIMAL_POINTS.TWO_DIGITS)}%`,
            });
          }

          val = parseFloat(val);

          final = val * this.state.data[index].crt;
          this.state.data[index]['finalquote'] = final;

          this.state.data[index]['quote'] = val;

          // let discount = (val / this.state.data[index].rap - 1) * 100;
          let discount = (val * 100) / this.state.data[index].rap - 100;
          this.state.data[index]['offerDis'] = discount;
          // let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].finalquote }])
          // data[index].newDiscount = sum.final_discount
          this.setState({ data });
        }}
        style={{ width: '70%', color: '#4eb45e' }}
      />
    );
  };

  getAmt = (row) => {
    return (
      <input style={{ color: '#4eb45e' }} disabled defaultValue={row.finalquote ? parseFloat(row.finalquote) : 0.0} />
    );
  };

  submit = () => {
    let self = this;
    let expireDate = null;
    let quoteHr = this.state.expire;
    if (this.state.expire) {
      expireDate = moment().add(this.state.expire, 'hours').toISOString();
    }
    if (this.state.expire) {
      handleSaveQuote(
        this.state.data,
        expireDate,
        this.state.comment,

        () => {
          self.props.onClose();
          self.props.clearAll();
        },
        this.props.editOffer ? true : false,
        quoteHr,
      );
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select offer valid till',
      });
    }
  };

  componentDidMount() {
    let data = (this.props.editOffer ? this.props.editOffer : this.props.checked).map((x) => {
      const rate = (1 + x.back / 100) * x.rap;
      const finalAmonut = rate * x.crt;
      return {
        ...x,
        // quote: parseFloat(x.newPricePerCarat ? x.newPricePerCarat : x.ctPr),
        // finalquote: x.newAmount ? x.newAmount : x.amt,
        // offerDis: parseFloat(
        //   x.newPricePerCarat
        //     ? (x.newPricePerCarat / x.rap - 1) * 100
        //     : (1 - x.ctPr / x.rap) * 100 * -1
        // ),
        offerDis: x.newDiscount ? x.newDiscount : x.back,
        quote: x.newPricePerCarat ? x.newPricePerCarat : rate,
        finalquote: x.newAmount ? x.newAmount : finalAmonut,
        updatedAt: null,
      };
    });
    let Columns = getColumn();
    let index = _.findIndex(Columns, { id: 'ctPr' });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1),
      {
        Header: 'Offer Dis.',
        accessor: 'offerDis',
        id: 'offerDis',
        Cell: ({ row, cell }) => <div className="tableInput">{this.getOfferDiscountInput(row.original)}</div>,
      },
      {
        Header: 'Offer Pr/Ct.',
        accessor: 'quote',
        id: 'quote',
        Cell: ({ row, cell }) => (
          <div className="tableInput">
            {/* <img
                            src={require('../../../assets/img/minus.jpg')}
                            width='15px'
                            onClick={() => this.changeQuotePrice(row.original.stoneId, false)}
                        /> */}
            {this.getOfferPrInput(row.original)}
            {/* <img
                            src={require('../../../assets/img/plus.jpg')}
                            width='15px'
                            onClick={() => this.changeQuotePrice(row.original.stoneId, true)}
                        /> */}
          </div>
        ),
      },
      {
        Header: 'Offer Amt.',
        accessor: 'finalquote',
        id: 'finalquote',
        Cell: ({ row, cell }) => <div className="tableInput">{this.getAmt(row.original)}</div>,
      },

      ...Columns.slice(index + 1, Columns.length),
    ];
    this.setState({ columns, data });
  }

  // changeQuotePrice = (e, stoneId, plus) => {
  //   let data = _.cloneDeep(this.state.data);
  //   let index = _.findIndex(this.state.data, { stoneId });
  //   // let val = _.clone(parseFloat(data[index].quote))
  //   let val = e.target.value;
  //   if (val === "") {
  //     val = 0;
  //   }
  //   data[index].quote = parseFloat(val);

  //   data[index].finalquote = val * data[index].crt;

  //   let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].finalquote }]);
  //   data[index].newDiscount = sum.final_discount;
  //   this.setState({ data });
  // };
  // Old Functions
  // changeQuotePrice = (stoneId, plus) => {
  //     let data = _.cloneDeep(this.state.data)
  //     let index = _.findIndex(this.state.data, { stoneId })
  //     let val = _.clone(parseFloat(data[index].quote))
  //     if (plus) val += 20
  //     else val -= 20
  //     data[index].quote = parseFloat(val)
  //     data[index].finalquote = val * data[index].crt
  //     let sum = calculate([{ ...data[index], ctPr: val, amt: data[index].finalquote }])
  //     data[index].newDiscount = sum.final_discount
  //     this.setState({ data })
  // }
  disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days');
  };

  dateHandleChange = (e, val) => {
    let da = null;
    da = Number(parseFloat(e));
    if (Number(e) < 1 || Number(e) > 24) {
      this.setState({ timeError: true });
    } else {
      this.setState({ timeError: false });
    }
    this.setState({ expire: da });
  };

  getBottom = () => {
    return (
      <div className="confirmListOption mt-10">
        {/* <label>:</label> */}
        {/* <DatePicker
          showShipFn={() => {
            this.setState({ visible: true });
          }}
          expiryDate={this.state.expire}
          handleChange={this.dateHandleChange}
          single
          disabledDate={this.disabledDate}
          label="Offer Valid Till :"
        /> */}
        <div className="commonTextArea from-group">
          <label>Offer Valid Till (Hours):</label>
          <InputNumber
            placeholder={'Enter Hours'}
            style={{ margin: 0 }}
            type="number"
            value={this.state.expire}
            onChange={this.dateHandleChange}
            parser={(val) => Number(parseFloat(val))}
            formatter={(val) => (isNumber(Number(val)) ? `${Number(parseFloat(val))}` : val)}
          />
          {this.state.timeError && (
            <span className="err_span">Enter Offer Time (Hours) * Min Offer Time: 1 | Max Offer Time: 24</span>
          )}
        </div>
        <div className="commonTextArea from-group">
          <label>Comment</label>
          <textarea value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })}></textarea>
        </div>
        {/* <Drawer
          title={false}
          onClose={() =>
            this.setState({
              visible: false,
            })
          }
          visible={this.state.visible}
          wrapClassName="diamondListinSidebar"
        >
        </Drawer> */}
      </div>
    );
  };
  render() {
    const dateFormat = 'DD/MM/YYYY';
    let { expire, timeError } = this.state;
    return isMobile() ? (
      <MobileOfferPopup {...this.state} parent={this} checked={this.state.data} />
    ) : (
      <div>
        <div className="d-flex align-items-center  offerTopBlock mb-20">
          <div className="d-flex align-items-center mr-40">
            <Heading className="popupInnerTitle mb-0" title="Apply Offer" />
          </div>
          {this.state.data.length > 0 && (
            <SelectStone
              sum={{}}
              buy
              checked={this.state.data}
              clearAll={() => this.setState({ checked: [] })}
              profilListing={false ? 'profileStonSelect' : null}
            />
          )}
        </div>
        <div className="searchPopupCommon searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              finalDestination
              {...this.state}
              handleSort={(sort) => this.setState({ sort }, () => this.fetch())}
              handleCheck={(checked) => this.setState({ checked })}
              canSort={[]}
              filterArray={{}}
              selectedFilterArray={{}}
              selectFilter={() => {}}
              nodots
              noCheckBox
            />
          </div>
          <div className="detailPopupHalfBlock">{this.getBottom()}</div>
          <div className="sideBarPopupButton">
            <a className="commonButton" disabled={timeError} onClick={this.submit}>
              Save Offer
            </a>
            <a className="commonOutline" onClick={this.props.onClose}>
              Cancel Offer
            </a>
          </div>
          {/* <CommonModal
                        title="Shipping Policy"
                        modalSize="sm-size popupzIndex"
                        handleOk={this.onClose}
                        handleCancel={() => {

                            this.onClose()
                        }}
                        visible={this.state.visible}
                        wrapClassName="diamondListinSidebar"
                        footerShow
                        cancelTitle='Ok'
                    >
                        <ShippingPolicy />
                    </CommonModal> */}
        </div>
      </div>
    );
  }
}
export default QuotePopup;
