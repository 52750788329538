import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { userSignUp } from '../../appRedux/actions/Auth';
import IntlMessages from '../../util/IntlMessages';
// import SocialIcon from "../common/SocialIcon";
import { Form, notification, Checkbox, Radio, Drawer } from 'antd';
import _ from 'lodash';
import axios from '../../util/Api';
import { bussinessTypeArray, BASE_URL_ATTACH, MIME_TYPES } from '../../constants/Common';
import moment from 'moment';
import UtilService from '../../services/util';
import Loader from '../../components/common/Loader';
import OpenNotification from '../common/CommonButton/OpenNotification';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import InputBlock from '../common/InputBlock';
import SelectOption from '../common/SelectOption';
import Heading from '../common/Heading';
import TermsCondition from '../TermsConditionPopup';
import DatePicker from '../common/DatePicker';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import FileUpload from '../common/ProfileUpload/index';
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  SIGNUP_USER_FRONT,
  GET_MASTERS,
  UPLOAD_FILE,
} from '../../constants/apiConstant';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import { signupValidator } from './SignupValidator';
import TermsConditionPopup from '../TermsConditionPopup';

export const companyDetail = {
  Country: [
    { value: 'Afghanistan ' },
    { value: 'Albania ' },
    { value: 'Algeria ' },
    { value: 'American Samoa' },
    { value: 'Andorra' },
    { value: 'Angola' },
    { value: 'India' },
  ],
  State: [
    { value: 'Maharashtra ' },
    { value: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh' },
    { value: 'Assam ' },
    { value: 'Bihar' },
    { value: 'Dadra and Nagar Haveli ' },
    { value: 'Daman and Diu' },
  ],
  City: [
    { value: 'Achalpur' },
    { value: 'Ahmadnagar' },
    { value: 'Akola' },
    { value: 'Amravati' },
    { value: 'AURANGABAD' },
    { value: 'Miraj' },
    { value: 'MUMBAI' },
  ],
  Designation: [
    { id: 'Buyer', name: 'Buyer' },
    { id: 'Director', name: 'Director' },
    { id: 'Partner', name: 'Partner' },
    { id: 'Manager', name: 'Manager' },
    { id: 'Sales', name: 'Sales' },
  ],
  BusinessType: [
    { key: '1', value: 'Business1' },
    { key: '2', value: 'Business2' },
    { key: '3', value: 'Business3' },
  ],
  Knowus: [
    { id: 'Through Advertisement', name: 'Through Advertisement' },
    { id: 'By Internet / Web search', name: 'By Internet / Web search' },
    {
      id: 'Via Fair Participation / Catalogue/ Trade Visit',
      name: 'Via Fair Participation / Catalogue/ Trade Visit',
    },
    { id: 'Other', name: 'Other' },
  ],
};
let isChanged = {};
class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      mobileData: '',
      mobileValid: true,
      formData: {
        countryCode: '91',
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        phone: '',
        mobile: '',
        address: '',
        fax: '',
        pinCode: '',
        country: {},
        state: {},
        city: {},
        isTermsCondAgree: false,
        updateRequired: false,
        knowUs: '',
        businessType: '',
        natureOfOrg: [],
        designation: '',
        kyc: [],
        dob: null,
        anniversary: null,
      },
      countries: [],
      states: [],
      cities: [],
      isDeviceUrl: false,
      errors: null,
      loading: false,
      BUSINESS_TYPE: [],
      NATURE_OF_ORG: [],
    };
  }

  componentDidMount() {
    let isDeviceUrl = window.location.pathname.includes('/device/signup');
    this.setState({ isDeviceUrl: isDeviceUrl });
    this.fetchCountries();
    this.getMasters();
  }

  getMasters = () => {
    let self = this;

    let obj = {
      ...GET_MASTERS,
      request: { masters: ['BUSINESS_TYPE', 'NATURE_OF_ORG'] },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) throw err;
      else if (data && data.code === 'OK') {
        self.setState({
          BUSINESS_TYPE: data.data.BUSINESS_TYPE || [],
          NATURE_OF_ORG: data.data.NATURE_OF_ORG || [],
        });
      }
    });
  };

  fetchCountries() {
    let self = this;

    let obj = {
      // method: "post",
      // url: "/admin/country/paginate",
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err?.data?.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        self.setState({
          allCountries: data.data || [],
          countries: data.data || [],
        });
      }
    });
  }

  fetchSellers() {
    let self = this;

    let obj = {
      // method: "post",
      // url: "/front/sellers",
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        self.setState({
          countries: data.data || [],
        });
      }
    });
  }

  fetchStates(countryId) {
    let self = this;

    let obj = {
      // method: "post",
      // url: "/admin/state/paginate",
      ...GET_STATE_LIST,
      request: {
        country: countryId,
      },
    };
    self.setState({ loading: true });
    UtilService.callApi(obj, function (err, data) {
      self.setState({ loading: false });
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        self.setState({
          states: data.data || [],
        });
      }
    });
  }

  fetchCities(stateId, countryId) {
    let self = this;

    let obj = {
      // method: "post",
      // url: "/admin/city/paginate",
      ...GET_CITY_LIST,
      request: {
        state: stateId,
        country: countryId,
      },
    };
    self.setState({ loading: true });
    UtilService.callApi(obj, function (err, data) {
      self.setState({ loading: false });
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        self.setState({
          cities: data.data || [],
        });
      }
    });
  }

  handleOnChange(key, value) {
    let { formData } = this.state;
    if (key === 'phone') {
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        if (formData[key].length === 0) {
          if (value === '0') {
            formData[key] = value.trim();
          } else {
            formData[key] = value.trim();
          }
        } else {
          formData[key] = value.trim();
        }
      }
    } else {
      if (key === 'username') {
        formData[key] = value.trim();
      } else {
        formData[key] = value;
      }
    }
    // delete errors[key];
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors[key];
    }

    this.setState({ formData, errors });
  }

  handleCountryChange(e) {
    let self = this;
    let errors = null;
    if (self.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors['country'];
    }
    self.setState({ errors });

    let country = this.state.countries.filter((c) => c.id === e)[0];
    if (country) {
      let formData = this.state.formData;
      formData.country = country ? country : {};
      formData.state = {};
      formData.city = {};
      this.setState(
        {
          formData: formData,
          states: [],
          cities: [],
          allCities: [],
        },
        () => {
          this.fetchStates(e);
          // this.fetchCities(null, e);
        },
      );
    }
  }

  handleStateChange(e) {
    let self = this;
    let errors = null;
    if (self.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors['state'];
    }
    self.setState({ errors });
    let state = this.state.states.filter((c) => c.id === e)[0];
    if (state) {
      let formData = this.state.formData;
      formData.state = state ? state : {};
      this.setState(
        {
          formData: formData,
          cities: [],
        },
        () => {
          this.fetchCities(e);
        },
      );
    }
  }

  handleCityChange(e) {
    let self = this;
    let errors = null;
    if (self.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors['city'];
    }
    self.setState({ errors });
    let city = this.state.cities.filter((c) => c.id === e)[0];
    if (city) {
      let formData = this.state.formData;
      formData.city = city ? city : {};
      this.setState({ formData: formData });
    }
  }

  handleSignup = (e) => {
    e.preventDefault();
    let self = this;

    let reqObj = _.clone(this.state.formData);
    reqObj.city = _.isEmpty(reqObj.city) ? null : reqObj.city.id;
    reqObj.state = _.isEmpty(reqObj.state) ? null : reqObj.state.id;
    reqObj.country = _.isEmpty(reqObj.country) ? null : reqObj.country.id;
    reqObj.emails = _.isEmpty(reqObj.email) ? null : [reqObj.email];
    reqObj.mobiles = _.isEmpty(reqObj.mobile) ? null : [reqObj.mobile];

    let obj = {
      // method: "post",
      // url: "/front/register",
      ...SIGNUP_USER_FRONT,
      request: reqObj,
    };

    const errors = signupValidator({ ...obj.request });
    if (!errors.isValid) {
      self.setState({ errors: errors.errors });
    } else {
      if (self.state.mobileValid) {
        self.setState({ loading: true });
        reqObj.isKycUploaded = true;
        UtilService.callApi(obj, function (err, data) {
          self.setState({ loading: false });
          if (err && err.data) {
            if (err.data.code === 'E_USER_NOT_FOUND') {
              return OpenNotification({
                type: 'error',
                title: err.data.message,
              });
            } else if (err.data.code === 'E_DUPLICATE') {
              return OpenNotification({
                type: 'error',
                title: err.data.message,
              });
            } else {
              return OpenNotification({
                type: 'error',
                title: err.data.message,
              });
            }
          }

          if (data && data.code === 'OK') {
            self.setState({ registerButton: true });
            if (self.state.isDeviceUrl) {
              window.RegisterCallBack.postMessage('RegisterCallBack');

              // if (navigator.appVersion.includes("iPhone")) {
              //   window.webkit.messageHandlers.RegisterCallBack.postMessage(
              //     data.message
              //   );
              // } else if (navigator.appVersion.includes("Android")) {
              //   window.webkit.RegisterCallBack(data.message);
              // }
            }
            localStorage.setItem(`${LOCAL_STORAGE_VAR}-thank-you`, JSON.stringify(true));
            self.props.history.push('/thank-you');
            // OpenNotification({
            //   type: "success",
            //   title: "Your registration is successfully completed",
            // });
          }
        });
      }
    }
  };

  handleValidation() {
    // let formData = this.state.formData;
    // let errors = this.state.errors;
    // let formIsValid = true;
    // let blankField = [];
    // let invalidFields = [];
    // let inValidMobile = false
    // if (!formData["firstName"] || _.isEmpty(formData["firstName"])) {
    //   blankField.push("First Name");
    //   formIsValid = false;
    //   errors["firstName"] = true; //"Please enter valid First Name.";
    // }
    // if (formData["firstName"]) {
    //   if (!formData["firstName"].match(/^[a-zA-Z ]+$/)) {
    //     invalidFields.push("firstName");
    //     formIsValid = false;
    //     errors["firstName"] = true; //"Please enter valid First Name.";
    //   } else {
    //     errors["firstName"] = undefined;
    //   }
    // }

    // if (!formData["lastName"] || _.isEmpty(formData["lastName"])) {
    //   blankField.push("Last Name");
    //   formIsValid = false;
    //   errors["lastName"] = true; //"Please enter valid First Name.";
    // }
    // if (formData["lastName"]) {
    //   if (!formData["lastName"].match(/^[a-zA-Z ]+$/)) {
    //     invalidFields.push("lastName");
    //     formIsValid = false;
    //     errors["lastName"] = true; //"Please enter valid Last Name.";
    //   } else {
    //     errors["lastName"] = undefined;
    //   }
    // }

    // if (!formData["email"] || _.isEmpty(formData["email"])) {
    //   blankField.push("Email Address");
    //   formIsValid = false;
    //   errors["email"] = true; //"Please enter valid First Name.";
    // }
    // if (formData["email"]) {
    //   let lastAtPos = formData["email"].lastIndexOf("@");
    //   let lastDotPos = formData["email"].lastIndexOf(".");

    //   if (
    //     formData["email"] &&
    //     !formData["email"].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)
    //   ) {
    //     formIsValid = false;
    //     invalidFields.push("email");
    //     errors["email"] = true; //"Please enter valid email address.";
    //   }
    // }

    // if (!formData["username"] || _.isEmpty(formData["username"])) {
    //   blankField.push("User Name");
    //   formIsValid = false;
    //   errors["username"] = true; //"Please enter valid user Name.";
    // }
    // // if (formData["username"]) {
    // //   if (
    // //     formData["username"] &&
    // //     !formData["username"].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]$/)
    // //   ) {
    // //     invalidFields.push("username");
    // //     formIsValid = false;
    // //     errors["username"] = true;
    // //   } else {
    // //     errors["username"] = undefined;
    // //   }
    // // }

    // if (!formData["password"]) {
    //   blankField.push(" Password");
    //   formIsValid = false;
    //   errors["password"] = true;
    // } else if (
    //   formData["password"] &&
    //   // (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/) || value.length > 15 || value.length < 6)
    //   formData["password"].length < 3
    // ) {
    //   invalidFields.push("Password");
    //   formIsValid = false;
    //   errors["password"] = true; //"Password must be 3 digit long.";
    // }

    // if (!formData["confirmPassword"]) {
    //   blankField.push("Confirm Password");
    //   formIsValid = false;
    //   errors["confirmPassword"] = true; //"Please enter confirm password";
    // }
    // if (
    //   formData &&
    //   formData["confirmPassword"] &&
    //   formData["confirmPassword"].length &&
    //   formData["confirmPassword"] !== formData.password
    // ) {
    //   invalidFields.push("Confirm Password");
    //   formIsValid = false;
    //   errors["confirmPassword"] = true; //"Please make sure your passwords match.";
    // }

    // if (!formData["address"]) {
    //   blankField.push("Address ");
    //   formIsValid = false;
    //   errors["address"] = true; //"Please enter valid First Name.";
    // }
    // if (formData["address"]) {
    //   if (!formData["address"]) {
    //     invalidFields.push("address");
    //     formIsValid = false;
    //     errors["address"] = true; //"Please enter valid Address 1.";
    //   } else {
    //     errors["address"] = undefined;
    //   }
    // }

    // if (!formData["pinCode"]) {
    //   blankField.push("Pin Code ");
    //   formIsValid = false;
    //   errors["pinCode"] = true; //"Please enter valid First Name.";
    // } else {
    //   if (formData["pinCode"].length !== 6 && formData["pinCode"].length !== 4) {
    //     invalidFields.push("Pin Code ");
    //     errors["pinCode"] = true; //"Please enter valid First Name.";

    //   }
    // }

    // if (!formData["country"] || _.isEmpty(formData["country"])) {
    //   blankField.push("Country");
    //   formIsValid = false;
    //   errors["country"] = true; //"Please enter valid First Name.";
    // }

    // // if (!formData["state"] || _.isEmpty(formData["state"])) {
    // //   blankField.push("State");
    // //   formIsValid = false;
    // // }

    // if (!formData["city"] || _.isEmpty(formData["city"])) {
    //   blankField.push("City");
    //   formIsValid = false;
    //   errors["city"] = true;
    // }

    // if (!formData["mobile"]) {
    //   blankField.push("Mobile No.");
    //   formIsValid = false;
    //   errors["mobile"] = true;
    // }
    // if (formData["mobile"]) {
    //   if (!formData["mobile"].match(/(^[0-9]*)$/)) {
    //     invalidFields.push("mobile");
    //     inValidMobile = true
    //     formIsValid = false;

    //     errors["mobile"] = true; //"Please enter valid Last Name.";
    //   } else {
    //     // errors["mobile"] = undefined;
    //   }
    // }
    // if (formData["phone"]) {
    //   if (formData["phone"].length !== 10) {
    //     invalidFields.push("Phone No.");
    //     inValidMobile = true
    //     formIsValid = false;

    //     errors["phone"] = true; //"Please enter valid Last Name.";
    //   } else {
    //     // errors["mobile"] = undefined;
    //   }
    // }
    // // if (!formData["phone"]) {
    // //   blankField.push("Phone No.");
    // //   formIsValid = false;
    // //   errors["phone"] = true;
    // // }

    // if (!formData["companyName"]) {
    //   blankField.push("Company Name");
    //   formIsValid = false;
    //   errors["companyName"] = true;
    // }
    // if (formData["companyName"]) {
    //   if (!formData["companyName"].match(/^[a-zA-Z ]+$/)) {
    //     invalidFields.push("companyName");
    //     formIsValid = false;
    //     errors["companyName"] = true; //"Please enter valid Last Name.";
    //   } else {
    //     errors["companyName"] = undefined;
    //   }
    // }

    // if (
    //   (formData["registrationType"] === 2 || formData["registrationType"] === 3) &&
    //   _.isEmpty(formData["companyType"])
    // ) {
    //   blankField.push("Company Type");
    //   formIsValid = false;
    //   errors["companyType"] = true;
    // }

    // if (formData["registrationType"] === 2 && _.isEmpty(formData["gstNo"])) {
    //   blankField.push("GST No.");
    //   formIsValid = false;
    //   errors["gstNo"] = true;
    // }

    // if (formData["registrationType"] === 3 && _.isEmpty(formData["panNo"])) {
    //   blankField.push("PAN No.");
    //   formIsValid = false;
    //   errors["panNo"] = true;
    // }

    // // if (_.isEmpty(formData["companyBest"])) {
    // //   blankField.push("Company's Best");
    // //   formIsValid = false;
    // //   errors["companyBest"] = true;
    // // }

    // if (formData["broker"] && _.isEmpty(formData["brokerName"])) {
    //   blankField.push("Broker Name");
    //   formIsValid = false;
    // }

    // if (formData["brokerName"]) {
    //   if (!formData["brokerName"].match(/^[a-zA-Z ]+$/)) {
    //     invalidFields.push("brokerName");
    //     formIsValid = false;
    //     errors["brokerName"] = true; //"Please enter valid First Name.";
    //   } else {
    //     errors["brokerName"] = undefined;
    //   }
    // }

    // if (formData["broker"] && _.isEmpty(formData["brokerMobile"])) {
    //   blankField.push("Broker Mobile No.");
    //   formIsValid = false;
    //   errors["brokerMobile"] = true;
    // }

    // if (formData["brokerMobile"] && errors["brokerMobile"]) {
    //   invalidFields.push("Broker Mobile No.");
    //   formIsValid = false;
    //   errors["brokerMobile"] = errors["brokerMobile"];
    // }

    // if (blankField.length > 0) {
    //   let arr = blankField;
    //   let outStr = "";
    //   if (arr.length === 1) {
    //     outStr = arr[0];
    //   } else if (arr.length === 2) {
    //     outStr = arr.join(" and ");
    //   } else if (arr.length > 2) {
    //     outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
    //   }
    //   notification.error({
    //     message: "Required Fields",
    //     placement: "bottomLeft",
    //     description: outStr,
    //   });
    // }
    // if (errors['mobile'] || invalidFields.length > 0) {
    //   let arr = invalidFields;

    //   if (!inValidMobile && !arr.includes('mobile')) {
    //     if (blankField.length > 0) {
    //       if (!blankField.includes('Mobile No.')) {
    //         if (errors['mobile']) {
    //           arr.push('mobile')
    //         }
    //       }
    //     } else {
    //       if (errors['mobile']) {
    //         arr.push('mobile')
    //       }
    //     }

    //   }
    //   let outStr = "";
    //   if (arr.length === 1) {
    //     outStr = arr[0];
    //   } else if (arr.length === 2) {
    //     outStr = arr.join(" and ");
    //   } else if (arr.length > 2) {
    //     outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
    //   }
    //   if (arr.length !== 0) {
    //     notification.error({
    //       message: "Invalid Fields",
    //       placement: "bottomLeft",
    //       description: outStr,
    //     });
    //   }

    // }
    // this.setState({ errors: errors });
    // return formIsValid;
    let formData = this.state.formData;
    let errors = this.state.errors;
    let formIsValid = true;
    let blankField = [];
    if (
      (formData['registrationType'] === 2 || formData['registrationType'] === 3) &&
      isEmpty(formData['businessType'])
    ) {
      blankField.push('Company Type');
      formIsValid = false;
      errors['businessType'] = true;
    }
  }

  handleCancel() {
    this.setState({
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        countryCode: this.state.formData.countryCode,
        companyName: '',
        address: '',
        country: {},
        state: {},
        city: {},
        pinCode: '',
        fax: '',
        phone: '',
        phone2: '',
        mobile: '',
        broker: false,
        brokerName: '',
        brokerEmail: '',
        brokerMobile: '',
        brokerPhoneNo: '',
        isTermsCondAgree: false,
        updateRequired: false,
        companyBest: [],
        companyType: '',
        seller: '',
        kyc: [],
        dob: null,
        anniversary: null,
      },
      errors: null,
      name1: '',
      name2: '',
    });
  }

  TermPopup = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleSelectChange = (e) => {
    let dropdownObj = _.clone(this.state.formData);
    dropdownObj[e.target.id] = e.target.value;
    // dropdownObj['country'] = e.target.value

    if (e.target.id === 'country') {
      this.fetchState(e.target.value);
      this.fetchCity(e.target.value);
      dropdownObj.state = '';
      dropdownObj.city = '';
    } else if (e.target.id === 'state') {
      this.fetchCity(e.target.value);
      dropdownObj.city = '';
    }
    this.setState({ formData: dropdownObj });
  };

  handleImgRemove = (name) => {
    this.props.form.resetFields(name);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: '',
      },
    }));
  };
  onBlur = (name, val) => {
    let self = this;
    let data = {
      [name]: val,
    };

    const errors = signupValidator(data);
    if (!errors.isValid) {
      self.setState({ errors: errors.errors });
    }
  };

  disabledDate = (current) => {
    return current && current > moment().add(0, 'days');
  };

  dateHandleChange = (e, val, key) => {
    let { formData } = this.state;
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors[key];
    }

    let da = null;
    if (e) {
      da = new Date(e._d).toISOString();
    }
    formData[key] = da;

    this.setState({ formData, errors });
  };

  uploadDocument = (ee, type, img) => {
    const e = cloneDeep(ee);
    let self = this;
    if (e.target.files && e.target.files.length) {
      var blob = e.target.files[0];
      var fileReader = new FileReader();
      fileReader.onloadend = function (ev) {
        var arr = new Uint8Array(ev.target.result).subarray(0, 4);
        var header = '';
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
          return OpenNotification({
            type: 'error',
            title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
          });
        } else {
          const data = new FormData();
          data.append('folder', 'user');
          data.append('file', e.target.files[0]);
          // let userToken = self.state.userData.data.token.jwt;
          let objData = {
            ...UPLOAD_FILE,
            // auth: userToken,
            request: data,
          };
          UtilService.callApi(objData, function (err, data) {
            if (err) throw err;
            if (data && data.code === 'OK') {
              if (data.data && data.data.files && data.data.files.length !== 0) {
                let { formData, errors } = self.state;

                if (type === 'personal') {
                  self.setState({ name1: data.data.files[0].name });

                  formData['kyc'][0] = {
                    path: data.data.files[0].absolutePath,
                    type: 'personal',
                  };
                } else {
                  self.setState({ name2: data.data.files[0].name });

                  formData['kyc'][1] = {
                    path: data.data.files[0].absolutePath,
                    type: 'business',
                  };
                }
                self.setState({ formData });
              }
            }
          });
        }
      };
      fileReader.readAsArrayBuffer(blob);
    }
  };

  render() {
    const { formData, errors, countries, states, cities } = this.state;
    return (
      <>
        <div className="loginDetail registerForm">
          <div className="registerMainScroll">
            <Loader loading={this.state.loading} />
            <div className="registerMainBlock">
              <Form
                autoComplete="off"
                // onSubmit={this.handleSignup}
                className=""
              >
                <div>
                  <Heading className="popupInnerTitle" title="Personal Information" />
                  <div className="registerFiledBlock">
                    <InputBlock
                      required
                      onChange={
                        (e) => this.handleOnChange('firstName', e.target.value)
                        //
                      }
                      onBlur={(e) => this.onBlur('firstName', e.target.value)}
                      hasError={errors !== null && errors['firstName'] ? errors['firstName'] : false}
                      value={formData.firstName}
                      type="text"
                      label="First Name"
                      placeholder="First Name"
                      name="firstName"
                      id="firstName"
                      autoComplete="nope"
                    />

                    <InputBlock
                      required
                      onChange={(e) => this.handleOnChange('lastName', e.target.value)}
                      onBlur={(e) => this.onBlur('lastName', e.target.value)}
                      hasError={errors !== null && errors['lastName'] ? errors['lastName'] : false}
                      value={formData.lastName}
                      type="text"
                      label="Last Name"
                      placeholder="Last Name"
                    />

                    <InputBlock
                      required
                      onChange={(e) => this.handleOnChange('email', e.target.value.replaceAll(/[^A-Za-z0-9@.]/gi, ''))}
                      onBlur={(e) => this.onBlur('email', e.target.value)}
                      hasError={errors !== null && errors['email'] ? errors['email'] : false}
                      value={formData.email}
                      type="text"
                      label="Email"
                      placeholder="Email"
                    />

                    <InputBlock
                      required
                      onChange={(e) => this.handleOnChange('username', e.target.value)}
                      onBlur={(e) => this.onBlur('username', e.target.value.replaceAll(/[^A-Za-z]/gi, ''))}
                      hasError={errors !== null && errors['username'] ? errors['username'] : false}
                      value={formData.username}
                      type="text"
                      label="Username"
                      placeholder="Username"
                    />

                    <InputBlock
                      info
                      required
                      onChange={(e) =>
                        this.handleOnChange('password', e.target.value.replaceAll(/[^A-Za-z0-9!@#$%^&*]/gi, ''))
                      }
                      onBlur={(e) => this.onBlur('password', e.target.value)}
                      hasError={errors !== null && errors['password'] ? errors['password'] : false}
                      value={formData.password}
                      type="password"
                      label="Password"
                      placeholder="Password"
                    />

                    <InputBlock
                      required
                      onChange={(e) =>
                        this.handleOnChange('confirmPassword', e.target.value.replaceAll(/[^A-Za-z0-9!@#$%^&*]/gi, ''))
                      }
                      onBlur={(e) => this.onBlur('confirmPassword', e.target.value)}
                      hasError={errors !== null && errors['confirmPassword'] ? errors['confirmPassword'] : false}
                      value={formData.confirmPassword}
                      type="password"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                    />
                    <DatePicker
                      name="dob"
                      single
                      expiryDate={formData.dob}
                      disabledDate={this.disabledDate}
                      label="Birth Date"
                      handleChange={(e, val) => {
                        this.dateHandleChange(e, val, 'dob');
                      }}
                    />
                    <DatePicker
                      name="anniversary"
                      single
                      expiryDate={formData.anniversary}
                      disabledDate={this.disabledDate}
                      label="Anniversary Date"
                      handleChange={(e, val) => {
                        this.dateHandleChange(e, val, 'anniversary');
                      }}
                    />
                    <div className="from-group inputBlock countryCodeDropdown">
                      <label className="CommonLabel">
                        Mobile No <span style={{ color: formData.mobile ? 'green' : 'red' }}>*</span>
                      </label>
                      <div className="mobileCodeBlock">
                        <span className="countryCode">+{formData.countryCode}</span>
                        <IntlTelInput
                          onPhoneNumberBlur={(status, value, countryData, number, id) => {
                            this.onBlur('mobile', value);
                            if (status) {
                              this.setState({
                                mobileValid: true,
                                mobileData: '',
                              });
                            }
                            // else {
                            //   this.setState({
                            //     mobileValid: false,
                            //     mobileData: 'Please enter the valid Phone number.',
                            //   });
                            // }
                          }}
                          format={false}
                          formatOnInit={false}
                          onPhoneNumberChange={(valid, number, d) => {
                            let { formData } = this.state;
                            let errors = null;
                            if (this.state.errors) {
                              errors = Object.assign('', this.state.errors);
                              delete errors['mobile'];
                            }
                            formData['mobile'] = number ? number : '';

                            this.setState({
                              formData,
                              errors,
                              mobileValid: true,
                              mobileData: '',
                            });
                          }}
                          value={formData.mobile}
                          onSelectFlag={(de, dial, fault) => {
                            let { formData } = this.state;
                            formData['mobile'] = '';
                            formData['countryCode'] = `${dial.dialCode}`;

                            let errors = null;
                            if (this.state.errors) {
                              errors = Object.assign('', this.state.errors);
                              delete errors['mobile'];
                            }

                            this.setState({ formData });
                          }}
                          preferredCountries={['in']}
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          fieldName="mobile"
                          customPlaceholder={(a, obj) => {
                            return `${obj.name}`;
                          }}
                          // placeholder={false}
                        />
                      </div>
                      {errors !== null && errors.mobile ? (
                        <span style={{ color: 'red' }}>{errors.mobile}</span>
                      ) : (
                        !this.state.mobileValid && <span style={{ color: 'red' }}>{this.state.mobileData}</span>
                      )}
                    </div>
                    <InputBlock
                      onChange={(e) => this.handleOnChange('phone', e.target.value)}
                      value={formData.phone}
                      onBlur={(e) => this.onBlur('phone', e.target.value)}
                      hasError={errors !== null && errors['phone'] ? errors['phone'] : false}
                      type="text"
                      label="Telephone No"
                      placeholder="Telephone No"
                    />
                  </div>
                  <Heading className="popupInnerTitle mt-20" title="Business Information" />
                  <div className="registerFiledBlock">
                    <InputBlock
                      required
                      type="text"
                      label="Company Name"
                      placeholder="Company Name"
                      onBlur={(e) => this.onBlur('companyName', e.target.value)}
                      onChange={(e) => this.handleOnChange('companyName', e.target.value)}
                      value={formData.companyName}
                      hasError={errors !== null && errors['companyName'] ? errors['companyName'] : false}
                    />
                    <SelectOption
                      required
                      selectValue={companyDetail.Designation}
                      placeholder="Select Designation"
                      value={
                        isEmpty(formData.designation)
                          ? undefined
                          : find(companyDetail.Designation, {
                              id: formData.designation,
                            }).name
                      }
                      label="Select Designation"
                      onChange={(e) => {
                        this.handleOnChange('designation', e);
                      }}
                      hasError={errors !== null && errors['designation'] ? errors['designation'] : false}
                      showSearch
                    />
                    <SelectOption
                      required
                      placeholder="Select Business Type"
                      value={
                        isEmpty(formData.businessType)
                          ? undefined
                          : find(this.state.BUSINESS_TYPE, {
                              id: formData.businessType,
                            }).name
                      }
                      selectValue={this.state.BUSINESS_TYPE}
                      label="Select Business Type"
                      onChange={(e) => {
                        this.handleOnChange('businessType', e);
                      }}
                      hasError={errors !== null && errors['businessType'] ? errors['businessType'] : false}
                      showSearch
                    />

                    {/* <SelectOption
                      required
                      hasError={
                        errors !== null && errors["country"]
                          ? errors["country"]
                          : false
                      }
                      // defaultValue="Country"
                      value={
                        !_.isEmpty(formData.country)
                          ? formData.country.name
                          : undefined
                      }
                      placeholder="Select Country"
                      selectValue={countries}
                      onChange={(e) => this.handleCountryChange(e)}
                      label="Select Country"
                      showSearch
                    /> */}
                    <SelectOption
                      defaultValue="Country *"
                      value={
                        !isEmpty(formData.country) && formData.registrationType !== 2
                          ? formData.country.name
                          : formData.registrationType === 2
                          ? 'India'
                          : 'Country'
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      selectValue={countries}
                      disabled={formData.registrationType === 2 ? true : false}
                      onChange={(e) => this.handleCountryChange(e)}
                      label="Select Country*"
                      showSearch
                      hasError={errors !== null && errors['country'] ? errors['country'] : false}
                    />
                    <SelectOption
                      required
                      placeholder="Select State"
                      hasError={errors !== null && errors['state'] ? errors['state'] : false}
                      // defaultValue="State"
                      value={!_.isEmpty(formData.state) ? formData.state.name : undefined}
                      selectValue={states}
                      onChange={(e) => this.handleStateChange(e)}
                      label="Select State"
                      showSearch
                    />
                    <SelectOption
                      required
                      placeholder="Select City"
                      hasError={errors !== null && errors['city'] ? errors['city'] : false}
                      // defaultValue="City"
                      value={!_.isEmpty(formData.city) ? formData.city.name : undefined}
                      selectValue={cities}
                      onChange={(e) => this.handleCityChange(e)}
                      showSearch
                      label="Select City"
                    />
                    <InputBlock
                      required
                      onBlur={(e) => this.onBlur('pinCode', e.target.value)}
                      onChange={(e) => this.handleOnChange('pinCode', e.target.value)}
                      hasError={errors !== null && errors['pinCode'] ? errors['pinCode'] : false}
                      type="number"
                      value={formData.pinCode}
                      label="ZipCode"
                      placeholder="ZipCode"
                    />
                    <InputBlock
                      required
                      onBlur={(e) => this.onBlur('address', e.target.value)}
                      className="twoBlock"
                      type="text"
                      label="Address"
                      placeholder="Address"
                      onChange={(e) => this.handleOnChange('address', e.target.value)}
                      value={formData.address}
                      hasError={errors !== null && errors['address'] ? errors['address'] : false}
                    />
                    <InputBlock
                      //required
                      onBlur={(e) => this.onBlur('fax', e.target.value)}
                      className="twoBlock"
                      type="number"
                      label="Fax"
                      placeholder="Fax"
                      onChange={(e) => this.handleOnChange('fax', e.target.value)}
                      value={formData.fax}
                      //hasError={errors !== null && errors['fax'] ? errors['fax'] : false}
                    />
                    <SelectOption
                      placeholder="How do you know us?"
                      selectValue={companyDetail.Knowus}
                      value={
                        isEmpty(formData.knowUs)
                          ? undefined
                          : find(companyDetail.Knowus, {
                              id: formData.knowUs,
                            }).name
                      }
                      label="How do you know us?"
                      onChange={(e) => {
                        this.handleOnChange('knowUs', e);
                      }}
                      showSearch
                    />
                  </div>
                  <Heading className="popupInnerTitle mt-20" title="Terms and Documentations" />
                  <div className="registerFiledBlock terms-con">
                    <div className="fileUploadBlockInner">
                      <FileUpload
                        label="Photo Identity Proof*"
                        title={
                          this.state.name1 ? this.state.name1 : 'Drag an image here or browse for an image to upload'
                        }
                        onChange={(e) => {
                          this.uploadDocument(e, 'personal');
                        }}
                        dec="Passport, Driving Licence (scan and upload only .jpg, .jpeg, .png and .pdf files)"
                        accept=".jpg,.jpeg,.png,.pdf"
                      />
                      <span style={{ color: 'red' }}>
                        {errors !== null && errors['personalDocument'] ? errors['personalDocument'] : ''}
                      </span>
                    </div>
                    <div className="fileUploadBlockInner">
                      <FileUpload
                        label="Business Identity Proof*"
                        onChange={(e) => {
                          this.uploadDocument(e, 'business');
                        }}
                        title={
                          this.state.name2 ? this.state.name2 : 'Drag an image here or browse for an image to upload'
                        }
                        dec="Passport, Driving Licence (scan and upload only .jpg, .jpeg, .png and .pdf files)"
                        accept=".jpg,.jpeg,.png,.pdf"
                      />
                      <span style={{ color: 'red' }}>
                        {errors !== null && errors['businessDocument'] ? errors['businessDocument'] : ''}
                      </span>
                    </div>
                  </div>
                  <div>
                    <Checkbox
                      checked={this.state.formData.isTermsCondAgree}
                      onChange={(e) => this.handleOnChange('isTermsCondAgree', e.target.checked)}
                    >
                      {' '}
                      I have read and accept
                    </Checkbox>
                    <a
                      onClick={() => {
                        this.setState({ visible: true });
                      }}
                      className="linkTag"
                    >
                      <strong>Terms and Conditions.</strong>
                    </a>
                  </div>
                  <div className="mt-10">
                    <Checkbox
                      checked={this.state.formData.updateRequired}
                      onChange={(e) => this.handleOnChange('updateRequired', e.target.checked)}
                    >
                      Promotional offers, newsletters and stock updates
                    </Checkbox>
                  </div>
                </div>
                <div className="mt-30 loginButton">
                  <div className="loginButtonLt">
                    <button
                      style={!formData.isTermsCondAgree ? { cursor: 'not-allowed' } : {}}
                      // disabled={this.state.registerButton}
                      // type="submit"
                      onClick={(e) => this.handleSignup(e)}
                      disabled={!formData.isTermsCondAgree}
                      className="commonButton"
                    >
                      <IntlMessages id="app.signup.registerButton" />
                    </button>
                    <button onClick={(e) => this.handleCancel(e)} className="commonOutline">
                      Reset
                    </button>
                  </div>
                  <div className="registerLogLink">
                    <IntlMessages id="app.already-have-acc" />
                    &nbsp;
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (this.state.isDeviceUrl) {
                          if (navigator.appVersion.includes('iPhone')) {
                            window.webkit.messageHandlers.LoginCallBack.postMessage('LoginCallBack');
                          } else if (navigator.appVersion.includes('Android')) {
                            window.webkit.LoginCallBack('LoginCallBack');
                          }
                        } else {
                          this.props.history.push('/');
                          // window.location.href = "/";
                        }
                      }}
                    >
                      <IntlMessages id="app.sign-In" />
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          <Drawer
            title={false}
            onClose={() => {
              this.setState({ visible: false });
            }}
            visible={this.state.visible}
            wrapClassName="diamondListinSidebar"
          >
            <TermsConditionPopup />
          </Drawer>
        </div>
      </>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    ...props,
    loading: props.commonData.loading,
  };
};

export default withRouter(connect(mapStateToProps, { userSignUp })(Form.create({ name: '' })(SignUpForm)));
