import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import APILoader from 'components/common/APILoader';
import Header from 'components/common/Header';
import MobileHeader from 'components/MobileHeader';
import SidebarHeader from 'components/SidebarHeader';

import { classNames } from 'util/utils';

class App extends Component {
  state = { collapse: true };

  toggleCollapse = () => this.setState((state) => ({ collapse: !state.collapse }));

  render() {
    const isMobile = window.innerWidth <= 767;

    void window?.drift?.on('campaign:dismiss', () => {});
    void window?.drift?.api?.widget?.hide?.();

    return (
      <React.Fragment key="App">
        <APILoader />
        <div className={classNames(['d-flex flex-wrap pageWrapperBlock', this.state.collapse && 'active'])}>
          {isMobile ? <MobileHeader /> : <SidebarHeader />}

          <div className="content-area pageHeight without-footer">
            {!isMobile && <Header />}
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
