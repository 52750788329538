import React, { Component } from "react"
import Magnifier from "react-magnifier";
import axios from 'axios'
class MagnifierImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            activeKey: "Image",
            showDetails: false,
            Path: "",
            content: "",
            zoom: 1,
            maginfierState: false,

        }
    }


    render() {
        let { error } = this.state

        return (

            error ?
                <div className="nodataFoundDetail" >
                    <div className="nodataFoundInner">
                        <img src={this.props.tempSrc} />
                        <span
                        >No Image Found</span>
                    </div>
                </div>
                :
                <Magnifier
                    className={this.props.className}
                    onMouseMove={this.handlechange}
                    src={this.props.image}
                    zoomFactor={this.state.zoom}
                    onWheel={this.handleWheelChange}
                    onMouseEnter={() => {
                        this.setState({ maginfierState: true });
                    }
                    }
                    onMouseOut={() => {
                        this.setState({ maginfierState: false });
                    }}
                    onError={() => {
                        this.setState({ error: true })
                    }}
                />

        )
    }
}
export default MagnifierImage;