import React, { useReducer, useRef, useState, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Drawer } from 'antd';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import SelectStone from '../common/DiamondListing/SelectStone';
import Heading from '../common/Heading';
import TermsConditions from './TermsConditions';
import Countdown from '../Offer/Timer';
import { getGridHeight, emptyData, getState, isArrayEqual } from '../DiamondList';
import Table from '../DiamondList/TableBack';
import { getColumn, TITLE, fetchDiamondPaginate, isMobile } from '../DiamondList/DiamondListFunctions';
import { DIAMOND_BID, DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from '../../constants/Common';
import IntlMessages from '../../util/IntlMessages';
import NewArrivalBidMobile from './NewArrivalBidMobile';

function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(11, 'hours');
  else return moment().startOf('day').add(15, 'hours');
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(59, 'minutes');
  else return moment().startOf('day').add(10, 'hours').add(59, 'minutes');
}

export function getBidEndCounter(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(59, 'minutes');
  else if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf('day').add(10, 'hours').add(59, 'minutes');
  else return moment().startOf('day').add(1, 'days').add(10, 'hours').add(59, 'minutes');
}

export function getBidType() {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
}

const NewArrivalBidIt = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [terms, showTerm] = useState(true);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    fetch();
    return () => {
      window.removeEventListener('resize', forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    fetch();
  }, [state.page, state.limit]);

  const setResponse = (res) => {
    let columns = getColumn();
    if (res) {
      let diamondData = res.data || [],
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d['isDefaultChecked'] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({ ...state, ...res, data: diamondData, defaultChecked, columns, loading: false });
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [sortRef.current],
  );

  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current };
    fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.BID, setResponse);
  }, [state.page, state.limit, sortRef.current, state.sum]);

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: 'RESET_SELECTED_ROWS' });
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  let gridHeight = getGridHeight(
    ['diamondListingAction', 'headerTopMenu', 'selectStonHeader', 'covidStrip', 'HeaderStripBlock'],
    12,
  );

  const getTitle = () => {
    return TITLE.BID + ' (' + state.count + ')';
  };

  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={getBidType() ? 'listingSelected' : ''}
        currentType={currentType.current}
      />
    );
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className={'bidEndBlock' + (!isMobile() ? ' d-flex align-items-center' : '')}>
        <span className="bidEndLabel">
          <IntlMessages id="app.BidEndIn" /> :
        </span>
        <Countdown date={getBidEndCounter(getBidType())} fetch={fetch} />
      </div>
    ) : null;
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        nocart
        nowatch
        noreminder
        noquote
        noconfirm
        nonote
        nooffice
        // nooffice={getBidType() === DIAMOND_BID.TYPE.BLIND}
      />
    );
  };

  const getDrawer = () => {
    return terms ? (
      <Drawer
        title={false}
        onClose={() => showTerm(false)}
        visible={terms}
        wrapClassName="diamondListinSidebar sm-size"
      >
        <TermsConditions />
      </Drawer>
    ) : null;
  };

  if (isMobile()) {
    return (
      <NewArrivalBidMobile
        {...props}
        {...state}
        currentType={currentType.current}
        parent={{ getTitle, getSelection, getBidBlock, getAction, getDrawer }}
      />
    );
  }

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock">
          <Heading title={getTitle()} />
          {getSelection()}
          {getBidBlock()}
        </div>
      </div>
      <div className="searchInnerResult">
        <div className="searchResultTable" style={{ height: gridHeight, overflow: 'auto' }}>
          <Table
            data={state.data}
            loading={state.loading}
            ref={tableRef}
            handleSort={handleSort}
            currentType={currentType.current}
            defaultChecked={state.defaultChecked}
          />
          {emptyData(state.loading, state.data.length)}
        </div>
        {getAction()}
      </div>
      {getDrawer()}
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(React.memo(withRouter(NewArrivalBidIt)));
