import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { useTable } from 'react-table';

import { Drawer } from 'antd';
import { groupBy } from 'lodash';

import CheckBox from 'components/common/CheckBox';
import Status from 'components/common/DiamondListing/Status';
import TableGrouping from 'components/common/DiamondListing/TableGrouping';
import IFrame from 'components/common/IFrame';

import useFilters from 'util/useFilters';
import useRowSelect from 'util/useRowSelect';

import useSortBy from 'util/useSortBy';
import DiamondListingHead from './ListTableHeaderBack';
import DiamondDetail from '../DiamondDetail';
import MagnifierImage from '../DiamondDetail/MagnifierImage';

import { getPath, LISTINGPAGES, getColumn } from './DiamondListFunctions';

import { setDateFormat } from '../../services/Commonfunction';

import { VisitedFieldsActions } from '../../appRedux/reducers/visited-fields';

import { classNames, isEmpty, isString } from 'util/utils';

import { LAB_LINKS, FILE_URLS, LOCAL_STORAGE_VAR, TABLE_PAGE_LIMIT } from 'constants/Common';

import imageSvg from 'assets/svg/camera.svg';
import certSvg from 'assets/svg/certi.svg';
import videoSvg from 'assets/svg/video.svg';

import { getLabLink } from './table-utils';

export const canSortCommon = {
  stoneId: 'Stone No',
  shpNm: 'Shape',
  crt: 'Carat',
  colNm: 'Color',
  clrNm: 'Clarity',
};
export const LIMIT = TABLE_PAGE_LIMIT;
export const FILTER_COLUMNS = ['colNm', 'fluNm', 'shpNm', 'lbNm', 'clrNm'];
export const floatkeys = [
  'ctPr',
  'depPer',
  'ratio',
  'crt',
  'rapAvg',
  'pAng',
  'pHgt',
  'cHgt',
  'back',
  'cAng',
  'fnDis',
  'height',
  'width',
  'length',
  'grdlPer',
  'strLn',
];
const NOCHECKIDS = ['Details', 'Action', 'quote', 'expBack', 'hours', 'bidPricePerCarat', 'note'];
export const roundkeys = ['ctPr', 'amt', 'rap', 'tblPer', 'lwrHal'];
export const DISPLAY_TOTAL = ['ctPr', 'amt', 'rap', 'crt', 'fnCtpr', 'fnAmt'];

const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
      width: item.width ? item.width + 'px' : '100px',
      fontWeight:
        type === 'cell' && item.id === 'dna' //  (item.id === 'stoneId' || item.id === 'vStnId')
          ? '600'
          : '',
      color: type === 'cell' && (item.id === 'lbNm' || item.id === 'rptNo' || item.link) ? '#008cba' : '',
    },
  },
];
const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

const Table = (props) => {
  const {
    data = [],
    columns = false,
    handleSort = () => {},
    //statuses
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    areAllChecked = false,
    groupKey,
    disableRowCheckBox,
    FilterOption = true,
    canSort = true,
  } = props;

  const [loader, setLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [visible, setVisible] = useState(false);

  const [main1Bool, setMain1Bool] = useState(false);
  const [nav5, setNav5] = useState();
  const [nav6, setNav6] = useState();

  const finalColumns = useMemo(() => {
    const primaryColumns = getColumn() || [];
    if (getPath() !== LISTINGPAGES.MATCHPAIR) primaryColumns.map((col) => ({ ...col, canSort: !!col.sort }));
    const allColumns = columns || primaryColumns;
    return nocheck
      ? allColumns
      : [
          {
            id: 'selection',
            Header({ data: displayedRows }) {
              return <CheckboxCustom displayedRows={displayedRows} isHeader={true} />;
            },
            Cell({ row }) {
              return <CheckboxCustom row={row.original} isHeader={false} disabled={disableRowCheckBox} />;
            },
          },
          ...allColumns,
        ];
  }, [columns, nocheck]);

  const isMultiSortEvent = useCallback(() => true, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      columns: finalColumns,
      data,
      manualSortBy: true,
      isMultiSortEvent,
      disableSortBy: !canSort,
    },
    useFilters,
    useSortBy,
  );

  const CheckboxCustom = ({ isHeader, row = {}, displayedRows, disabled }) => {
    const { isRowChecked, isHeaderChecked, toggleRowSelection, toggleAllRowSelection } = useRowSelect(currentType);

    useEffect(() => {
      if (areAllChecked || row.isDefaultChecked) {
        toggleRowSelection(row, true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isChecked = useMemo(() => {
      if (!isHeader) {
        return isRowChecked(row.id);
      } else {
        return isHeaderChecked(displayedRows);
      }
    }, [isRowChecked, isHeader, isHeaderChecked, displayedRows, row.id]);

    const handleChange = () => {
      if (!isHeader) {
        toggleRowSelection(row, !isChecked);
      } else {
        toggleAllRowSelection(displayedRows);
      }
    };
    return (
      <div className="selectActionIcon">
        {!nostatus && <Status status={isHeader ? 'all' : row.wSts} />}
        {!noCheckBox && (
          <div className="selectActionIconWrapper">
            <CheckBox
              key={`selection_${isHeader ? 'header' : row.id}`}
              checked={isChecked}
              onChange={handleChange}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    );
  };

  //handle sorting operation
  useEffect(() => {
    const sortArr = sortBy.map((sort) => ({
      [sort.id]: sort.desc ? 'DESC' : 'ASC',
    }));
    handleSort(sortArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const dispatch = useDispatch();
  const visitedFields = useSelector(({ visitedFields }) => visitedFields);

  //common functions
  const clickHandler = useCallback(
    (e, row, cell) => {
      if (cell.column.id === 'selection') return;

      if (cell.column.link && cell.value) {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        const link = cell.column.link.replace('$' + field + '$', row.original[field]);
        window.open(link);
      } else if (cell.column.id === 'vStnId') {
        dispatch(VisitedFieldsActions.addField(row.original.id));
        setVisible(row.original);
      } else if (cell.column.id === 'ftc' && cell.value) {
        window.open(`/ftc/${row.original.id}`);
      } else if ((cell.column.id === 'lbNm' || cell.column.id === 'rptNo') && row.original.lbNm && row.original.rptNo) {
        const link = getLabLink(row.original);
        if (isString(link)) window.open(link);
      } else if (!NOCHECKIDS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox) return;
        const parentNode = e.currentTarget.parentNode;
        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName('input');
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [dispatch, props.noCheckBox, props.nocheck],
  );

  const grouppedRows = React.useMemo(() => {
    if (isEmpty(groupKey) || isEmpty(rows?.[0]?.original?.[groupKey])) return undefined;
    const originalRows = rows.map((row) => row?.original);
    return groupBy(originalRows, groupKey);
  }, [groupKey, rows]);

  return (
    <>
      {loader && <div className="loading-indicator"></div>}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(headerProps)}
                  style={{ width: (column.width || 100) + 'px' }}
                  key={column?.id}
                >
                  {column.id === 'selection'
                    ? column.render('Header')
                    : column.id !== 'action' && (
                        <DiamondListingHead
                          toggleSortBy={toggleSortBy}
                          setSortBy={setSortBy}
                          sortBy={sortBy}
                          column={column}
                          filters={filters}
                          nodots={nodots}
                          FilterOption={FilterOption}
                        />
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading
            ? null
            : !!rows.length &&
              rows.map((row) => {
                prepareRow(row);
                const groupRows = grouppedRows?.[row?.original?.[groupKey]];

                return (
                  <>
                    {!noGrp && (
                      <>
                        {row.original.isMatchHeader && (
                          <TableGrouping
                            groupKey={groupKey}
                            groupRows={groupRows}
                            currentType={currentType}
                            displayTotal={DISPLAY_TOTAL.filter((el) => finalColumns.find((c) => c.id == el))}
                            columns={columns}
                            row={row.original}
                          />
                        )}
                        {(row.original.isConfirmHeader || row.original.isUpcomingHeader) && (
                          <TableGrouping
                            groupKey={groupKey}
                            groupRows={groupRows}
                            currentType={currentType}
                            groupingTitle={props.officeView ? setDateFormat(row.original.reminderDate) : ''}
                            row={row.original}
                          />
                        )}
                        {row.original.isOfficeHeader && (
                          <TableGrouping
                            groupKey={groupKey}
                            groupRows={groupRows}
                            currentType={currentType}
                            row={row.original}
                          />
                        )}
                        {row.original.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && (
                          <TableGrouping
                            groupKey={groupKey}
                            groupRows={groupRows}
                            currentType={currentType}
                            row={row.original}
                          />
                        )}
                      </>
                    )}
                    <tr
                      {...row.getRowProps()}
                      className={classNames([visitedFields.includes(row.original.id) && 'visit'])}
                    >
                      {row.cells.map((cell) => (
                        <MemoizedCell
                          changeLoader={setLoading}
                          key={cell.column.id + '_cell'}
                          cell={cell}
                          clickHandler={clickHandler}
                          row={row}
                          setDetail={setDetail}
                        />
                      ))}
                    </tr>
                  </>
                );
              })}
        </tbody>
      </table>
      {visible && !props.finalDestination && (
        <Drawer title={false} onClose={() => setVisible(false)} visible={true} wrapClassName="diamondDetailPopup">
          <DiamondDetail
            noDetailAction={props.noDetailAction}
            diamondPopup={true}
            data={visible}
            onClose={() => setVisible(false)}
          />
        </Drawer>
      )}
      {detail && (
        <Drawer
          title={false}
          onClose={() => setDetail(null)}
          visible={detail}
          wrapClassName="diamondListinSidebar onlyImageSlider"
        >
          {detail.detail.i && (
            <div className="diamondImagePopup">
              <Slider asNavFor={nav6} ref={(slider5) => setNav5(slider5)} arrows={false}>
                <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require('../../assets/svg/camera.svg')}
                    image={FILE_URLS.img.replace('***', detail.detail.i.rptNo)}
                  />
                </div>
                {/* <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require("../../assets/svg/camera.svg")}
                    image={FILE_URLS.lightBlack.replace(
                      "***",
                      detail.detail.i.mfgStnId
                    )}
                  />
                </div> */}
              </Slider>
              <Slider
                asNavFor={nav5}
                ref={(slider6) => setNav6(slider6)}
                slidesToShow={5}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                className="smallSliderBlock smallSliderBlockimage"
              >
                <React.Fragment>
                  {!main1Bool && (
                    <div className="smallSliderImage">
                      <img
                        onError={() => setMain1Bool(true)}
                        src={FILE_URLS.img.replace('***', detail.detail.i.rptNo)}
                        alt=""
                      />
                    </div>
                  )}
                </React.Fragment>

                {/* <React.Fragment>
                  {!main2Bool && (
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setMain2Bool(true);
                        }}
                        src={FILE_URLS.lightBlack.replace(
                          "***",
                          detail.detail.i.mfgStnId
                        )}
                        alt=""
                      />
                    </div>
                  )}
                </React.Fragment> */}
              </Slider>
            </div>
            // <Image tempSrc={require('../../assets/svg/camera.svg')} list src={FILE_URLS.img.replace("***", detail.detail.i.vStnId)} />
          )}
          {detail.detail.v && (
            <IFrame
              tempSrc={require('../../assets/svg/video.svg')}
              video
              src={FILE_URLS.videoFile.split('***').join(detail.detail.v.rptNo)}
            />
          )}
          {detail && detail.detail.c && Object.keys(detail.detail.c).length && (
            <IFrame
              tempSrc={require('../../assets/svg/certi.svg')}
              src={FILE_URLS.certFile.replace?.(/(?:\*\*\*){1}/gm, detail.detail.c.rptNo)}
            />
          )}
        </Drawer>
      )}
    </>
  );
};

const MemoizedCell = React.memo(({ cell, clickHandler, row, setDetail, changeLoader }) => {
  if (cell.column.id === 'shpNm') {
    const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    const value = masterData.SHAPE.find((x) => x.code === cell.value)?.webDisplay;
    return (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {value}
      </td>
    );
  }

  return (
    <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
      {cell.column.id === 'Details' ? (
        <Resource changeLoader={changeLoader} original={row.original} setDetail={setDetail} />
      ) : (
        cell.render('Cell')
      )}
    </td>
  );
});
MemoizedCell.displayName = 'MemoizedCell';

const Resource = React.memo(({ original, setDetail }) => {
  const { img } = original;

  const clickHandler = useCallback(
    (detailKey) => {
      setDetail({ detail: { [detailKey]: original } });
    },
    [original, setDetail],
  );

  return (
    <div className="tableSmallImage">
      <img src={imageSvg} width="15px" alt="" onClick={() => clickHandler('i')} />
      <img src={videoSvg} width="15px" alt="" onClick={() => clickHandler('v')} />
      <img src={certSvg} width="15px" alt="" onClick={() => clickHandler('c')} />
    </div>
  );
});
Resource.displayName = 'Icons';

export default React.memo(Table);
