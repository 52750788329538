import React, { Component } from 'react'
import { connect } from 'react-redux'
import Heading from '../Heading'
import { Drawer } from 'antd'
import { ListingCards } from './MobileComfirmStone'
import QuoteSelect from '../MobileDiamondList/QuoteSelect'
import HoursSelect from '../MobileDiamondList/HoursSelect'

const currentType = 'QuotePopup'

class MobileOfferPopup extends Component {
    // constructor(props) {
    //     super(props)
        state = {
            apply: false
        }
    // }

    onClose = () => {
        this.props.parent.onClose()
        this.setState({ apply: false })
    }

    render() {
        return (
            <div className='offerWrapper'>
                <div className='d-flex align-items-center justify-space-between offerTopBlock'>
                    <div className='d-flex align-items-center'>
                        <Heading className='popupInnerTitle' title='Apply Offer' />
                    </div>
                    {this.props.parent.offerHeading()}
                </div>
                <div className='searchInnerResult tabInnerTable'>
                    <div className='diamondListMobile mobileOfferScroll'>
                        <ListingCards
                            data={this.props.data}
                            quote={{prIp: this.props.parent.getOfferPrInput,disIp:this.props.parent.getOfferDiscountInput,getAmt:this.props.parent.getAmt}}
                            currentType={currentType}
                            checked={this.props.checked}
                            // quoteClick={(e, dt) => this.setState({ quote: e, quoteSelect: dt })}
                        />
                    </div>
                    <div className='d-flex justify-content-between offerBottomBox flex-wrap'></div>
                </div>
                <div className='sideBarPopupButton'>
                <a className='commonButton' onClick={()=>this.setState({apply:true})}>
                                    Apply Offer
                                </a>
                                <a className='commonOutline' onClick={this.props.parent.onClose}>
                                    Cancel
                                </a>
                </div>
                {this.state.apply && (
                    <Drawer
                        title={false}
                        onClose={this.onClose}
                        visible={true}
                        wrapClassName='diamondListinSidebar smallSidebarMobilePopup'
                    >
                        <div>
                            {this.props.parent.getBottom()}
                            <div className='sideBarPopupButton'>
                                <a className='commonButton' onClick={this.props.parent.submit}>
                                    Apply Offer
                                </a>
                                <a className='commonOutline' onClick={this.onClose}>
                                    Cancel
                                </a>
                            </div>
                        </div>
                    </Drawer>
                )}
                {/* {this.state.quote && (
                    <Drawer
                        title={false}
                        onClose={this.onClose}
                        visible={true}
                        wrapClassName='diamondListinSidebar smallSidebarMobilePopup onlySelectMobileOption'
                    >
                        {this.state.quote === 'quote' && (
                            <QuoteSelect
                                onQuoteChange={this.props.parent.onQuoteChange}
                                quoteSelect={this.state.quoteSelect}
                                onClose={this.onClose}
                            />
                        )}
                        {this.state.quote === 'hours' && (
                            <HoursSelect
                                onHourChange={this.props.parent.onHourChange}
                                quoteSelect={this.state.quoteSelect}
                                onClose={this.onClose}
                            />
                        )}
                    </Drawer>
                )} */}
            </div>
        )
    }
}

const mapStateToProps = ({ diamondData }) => {
    return { checkedData: diamondData.selectedRows[currentType] || [] }
}

const mapDispatchToProps = dispatch => {
    return {
        handleSelect: (setValue, row) =>
            dispatch({ type: setValue ? 'SELECT_ROW' : 'UNSELECT_ROW', payload: row, currentType })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileOfferPopup)
