import React, { Component } from "react";
import DiamondListingAction from "../../common/DiamondListing/DiamondListingAction";

class MobileDiamondDetail extends Component {
  render() {
    let data = this.props.data;
    let parent = this.props.parent;

    return (
      <div className="mobilediamondDetail">
        <div className="mobilediamond diamondDetailLeft">
          {parent.leftPart(data)}
        </div>
        <div className="diamondDetailRight mobileRightDetail">
          {this.props.params}
        </div>
        <DiamondListingAction
          nofinalcalc
          nonote
          nooffice
          order
          noreminder
          noExportExcel
          noprint
          nopaginate
          checkedData={data ? [data] : []}
          data={data ? [data] : []}
        />
        {parent.getEmailModal(data)}
      </div>
    );
  }
}
export default MobileDiamondDetail;
