import React, { Component } from 'react'

export default class Image extends Component {
    state = {
        src: this.props.src,
        error: false
    }
    onError = () => {
        this.setState({ src: require('../../../assets/img/DiamondDetail/diamond.png'), error: true })
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }
    render() {
        let { error } = this.state
        return (
            <>
                {error ?
                    this.props.list ?
                        <div style={{ marginLeft: '465px' }} className="diamondDetailImageBox htmlViewImage">
                            <img style={{ width: '306px', height: '306px' }} src={this.props.tempSrc} />
                            <br />
                            <br />

                            <span
                                style={{ marginLeft: '92px' }}
                            >No Image Found</span></div>
                        :
                        <span style={{ marginTop: '92px' }} className='GridViewNoImage'>
                            No Image Found
                    </span>

                    :

                    <img onError={this.onError} src={this.state.src} />

                }

            </>
        )
    }
}
