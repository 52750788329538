import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import Auth from './Auth';
import Commonm from './Common';
import DashData from './DashData';
import DeleteReminder from './deleteReminder';
import Diamond from './diamond';
import loader from './loader';
import setTimer from './timer';
import VisitedFields from './visited-fields';

const reducers = combineReducers({
  routing: routerReducer,
  auth: Auth,
  commonData: Commonm,
  diamondData: Diamond,
  deleteReminder: DeleteReminder,
  setTimer: setTimer,
  sales: DashData,
  visitedFields: VisitedFields,
  loader,
});

export default reducers;
