import React from 'react';
import SignUpForm from './Auth/SignUpForm';
import Logo from './Header/Logo';
import StaticHeader from './StaticHeader';
// import EventSlider from "./common/EventSlider";
// import Logo from "./common/Logo";
// import SEO from "./SEO";

const SignUp = (props) => {
  return (
    <>
      <StaticHeader data="/signin" />
      <div className="adma-login-wrap">
        <div className="adma-login-container">
          <div className="loginpage mt-0">
            <div className="adma-login-main-content registerLeftBlock">
              <div className="adma-app-login-content">
                <div className="adma-logo-content-bg">
                  {/* <h1>
                    <Logo />
                  </h1> */}
                  <h2 className="login-title mt-20">
                    Registration to your account
                  </h2>
                </div>
                <SignUpForm />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <SEO url={props.location.pathname.split("/")[1]} /> */}
    </>
  );
};

export default SignUp;
