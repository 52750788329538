const ValidationMessages = {
  // validation messages
  Name: "Please enter the name.",
  Subject: "Please enter subject.",
  FirstName: "Please enter the First Name.",
  LastName: "Please enter the Last name.",
  NameValid: "Please enter only alphabets.",
  EmailRequired: "Please enter the Email address.",
  EmailValid: "Please enter the valid Email address.",
  PhoneRequire: "Please enter the Mobile number.",
  PhoneValid: "Please enter the valid Phone number.",
  Address: "Please enter Address.",
  Fax: "Please enter Fax.",
  City: "Please select the City.",
  State: "Please select the State.",
  Country: "Please select the Country.",
  CompanyName: "Please enter the Company name.",
  CompanyValid: "Please enter the valid Company name",
  pinCode: "Please enter the Zip Code.",
  UserName: "Please enter the Username.",
  Password: "Please enter the Password.",
  ConfirmPassword: "Please enter Confirm Password.",
  SamePasword: "Confirm password must match with the password.",
  PasswordValid: "Please enter valid password.",
  PasswordLength: "Password must be atleast 8 character.",
  Message: "Please enter message.",
  BusinessType: "Please select Business Type",
  Designation: "Please Select Designation",
  UserKyc: "Please Upload Documents",
  PersonalDocument: "Please Upload Personal Document",
  BusinessDocument: "Please Upload Business Document",
};

export default ValidationMessages;
