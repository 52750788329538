import React, { useState } from "react";
import { Dropdown, Select } from "antd";
import { floatkeys } from "../../DiamondList/TableBack";
import { formatNumber } from "./SelectStone";
import sum from "lodash/sum";

const { Option } = Select;

const CALCULATE = {
  sum: "Sum",
  avg: "Average",
  range: "Range",
};
const DISPLAY_TITLE = {
  ctPr: "Price/Carat",
  amt: "Amount",
  rap: "Rap Price",
  crt: "Carat",
};

const DiamondTotalCard = (props) => {
  const [visible, handleClick] = useState(false);
  const [dropdown, handleDropdown] = useState(false);
  const [calculate, handleCalculate] = useState("sum");
  let result =
    (props.col !== "crt" ? "$" : "") +
    formatNumber(
      parseFloat(
        props.col !== "ctPr"
          ? sum(props.row.groupData[props.col])
          : sum(props.row.groupData.amt) / sum(props.row.groupData.crt),
        floatkeys.includes(props.col) ? 2 : 0
      )
    );

  return (
    <Dropdown
      trigger={["click"]}
      onVisibleChange={(e) => {
        if (dropdown) e = true;
        handleClick(e);
      }}
      visible={visible}
      placement="bottomCenter"
      overlayClassName="amountCalalateOverClass"
      overlay={
        <div className="amountCalculateDropdown">
          <div className="calculatorTitle">CALCULATE</div>
          <Select
            value={calculate}
            className="calculatorSelect"
            open={dropdown}
            onDropdownVisibleChange={(e) => handleDropdown(e)}
            onChange={(e) => handleCalculate(e)}
          >
            {Object.keys(CALCULATE).map((x) => (
              <Option value={x}>{CALCULATE[x]}</Option>
            ))}
          </Select>
          <button>Calculate</button>
          <div className="calTotalAmount">
            Total {DISPLAY_TITLE[props.col]}: {result}
          </div>
        </div>
      }
    >
      <span className="selectAmount">{result}</span>
    </Dropdown>
  );
};

export default DiamondTotalCard;
