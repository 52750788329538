import React from 'react';
import { memoize } from 'lodash';
import MainFilterTop from './FilterItem';

const FilterTop = (props) => {
  const { ids, filterList, handleFilterChange, handleRemoveFilter } = props;

  const handleChangeFc = React.useMemo(() => {
    return memoize((id) => () => handleFilterChange(id));
  }, [handleFilterChange]);

  return (
    <div className="searchInnerFilterMain">
      {(filterList ?? ids)?.map?.((filter, index) => (
        <div key={filter?.id ?? index} onClick={handleChangeFc(filter?.id)}>
          <MainFilterTop
            id={filter?.id}
            title={`Filter ${++index}`}
            isActive={filter?.isActive}
            onClose={handleRemoveFilter}
            image={
                          filter?.isActive
                            ? require('assets/svg/DiamondList/eye.svg')
                            : require('assets/svg/DiamondList/eye-close.svg')
                        }
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(FilterTop);
