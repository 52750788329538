import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import queryString from 'query-string';

import { ConnectedProgress } from 'components/ConnectedProgress';

import { Storage } from 'services/Storage';
import { compose } from 'util/hocs';
import { loadable } from 'util/loadable';
import { isEmpty } from 'util/utils';

import { commonLogout, IS_QUICK_SEARCH } from 'constants/Common';

import App from 'App';

const Account = loadable(() => import('components/Account'));
const ChangePassword = loadable(() => import('components/Account/ChangePassword'));
const CompanyInformation = loadable(() => import('components/Account/CompanyInformation'));
const Compare = loadable(() => import('components/DiamondCompare'));
const Dashboard = loadable(() => import('components/Dashboard/index'));
const DiamondColumnListing = loadable(() => import('components/Account/ManageDiamondSequence'));
const DiamondList = loadable(() => import('components/DiamondList'));
const DiamondSearch = loadable(() => import('containers/DiamondSearch'));
const MobileAccount = loadable(() => import('components/Account/MobileAccount'));
const MyDemand = loadable(() => import('components/MyDemand/index'));
const Notification = loadable(() => import('components/Notification'));
const NotificationDropdown = loadable(() => import('components/common/Header/NotificationDropdown'));
const Offer = loadable(() => import('components/Offer'));
const PersonalInformation = loadable(() => import('components/Account/PersonalInformation'));
const QuickSearch = loadable(() => import('components/QuickSearch'));
const SavedSearch = loadable(() => import('components/SavedSearch'));

// const DiamondDetail = loadable(() => import('components/DiamondDetail'));
// const Loader = loadable(() => import('components/common/Loader'));
// const MadeInBotswana = loadable(() => import('components/MadeInBotswana'));
// const MatchPair = loadable(() => import('components/MatchPair/index'));
// const MobileDiamondDetail = loadable(() => import('components/DiamondDetail/MobileDiamondDetail'));
// const MobileDiamondList = loadable(() => import('components/DiamondList/MobileViewList'));
// const NewArrivalBidIt = loadable(() => import('components/NewArrivalBidIt'));
// const StoneOfTheDay = loadable(() => import('components/StoneOfTheDay'));

class FrontRoute extends Component {
  redirectPage = () => {
    const path = this.props.location.pathname.split('/').pop();
    const USER_PERMIT = Storage.get('userPermissions');

    if (isEmpty(USER_PERMIT)) commonLogout();

    if (path === 'device') {
      const values = queryString.parse(this.props.location.search);
      if (values && values.token) Storage.set('token', values.token);
    }
    const isTrusted = Storage.get('isTrusted', false);
    const isLoggedInUser = Storage.get('token');

    if (isLoggedInUser && isLoggedInUser.length && isTrusted === 'false') {
      return <Redirect to="/verify-otp" />;
    } else if (!isLoggedInUser || !isLoggedInUser.length) {
      return <Redirect to="/signin" />;
    }
  };

  render() {
    const P = Storage.get('userPermissions');
    const isMobile = window.innerWidth <= 767;

    return (
      <App>
        <ConnectedProgress />
        <Switch>
          {this.redirectPage()}
          {P.DASHBOARD?.view && <Route exact path="/dashboard" component={Dashboard} />}
          {P.SEARCH_LIST?.view &&
            (isMobile ? (
              <Route exact path="/diamond-list" component={DiamondList} />
            ) : (
              <Route exact path="/diamond-list" component={DiamondList} />
            ))}
          {P.SAVE_SEARCH?.view && isMobile && <Route exact path="/account/saved-search" component={SavedSearch} />}
          {/* {P.DEMAND?.view && isMobile && <Route exact path="/account/my-demand" component={MyDemand} />} */}
          {IS_QUICK_SEARCH && P.QUICK_SEARCH?.view && <Route exact path="/quick-search" component={QuickSearch} />}
          {isMobile && <Route exact path="/account/personal-information" component={PersonalInformation} />}
          {isMobile && <Route exact path="/account/company-profile" component={CompanyInformation} />}
          {isMobile && <Route exact path="/account/change-password" component={ChangePassword} />}
          {P.COMPARE?.view && <Route exact path="/compare" component={Compare} />}
          {isMobile && <Route exact path="/account/change-password" component={ChangePassword} />}
          {isMobile ? (
            <Route exact path="/account" component={MobileAccount} />
          ) : (
            <Route exact path="/account" component={Account} />
          )}
          {/* {isMobile ? (
            <Route exact path="/diamond-detail" component={DiamondDetail} />
          ) : (
            <Route path="/diamond-detail/:id" component={DiamondDetail} />
          )} */}
          {P.CART?.view &&
            (isMobile ? (
              <Route exact path="/account/cart" component={DiamondList} />
            ) : (
              <Route exact path="/account/cart" component={Account} />
            ))}
          {P.WATCHLIST?.view &&
            (isMobile ? (
              <Route exact path="/account/watchlist" component={DiamondList} />
            ) : (
              <Route exact path="/account/watchlist" component={Account} />
            ))}
          {P.OFFICE?.view &&
            (isMobile ? (
              <Route exact path="/account/office-list" component={DiamondList} />
            ) : (
              <Route exact path="/account/office-list" component={Account} />
            ))}
          {P.QUOTE?.view &&
            (isMobile ? (
              <Route exact path="/account/quote-list" component={Offer} />
            ) : (
              <Route exact path="/account/quote-list" component={Account} />
            ))}
          {/* {USER_PERMIT?.ENQUIRY?.view && ( */}
          <Route exact path="/account/enquiry" component={Account} />
          {/* )} */}
          {P.SAVE_SEARCH?.view && <Route exact path="/account/saved-search" component={Account} />}
          {P.DEMAND?.view && <Route exact path="/account/my-demand" component={Account} />}
          {P.NOTIFICATIONS?.view && <Route exact path="/account/notification" component={Account} />}
          {P.NOTES?.view && <Route exact path="/account/notes" component={Account} />}
          {P.NOTIFICATIONS?.view && <Route exact path="/notification" component={Notification} />}
          {/* {USER_PERMIT &&
            USER_PERMIT?.REMINDER.view && (*/}
          <Route exact path="/account/reminder" component={Account} />
          {/* )} */}
          <Route exact path="/account/my-comment" component={Account} />
          <Route exact path="/account/my-order" component={Account} />
          {P.CONFIRM_STONE?.view && <Route exact path="/account/my-purchase" component={Account} />}
          {P.CONFIRM_STONE?.view &&
            (isMobile ? (
              <Route exact path="/account/confirm-list" component={DiamondList} />
            ) : (
              <Route exact path="/account/confirm-list" component={Account} />
            ))}
          {
            // USER_PERMIT &&
            //   USER_PERMIT?.ENQUIRY.view &&
            isMobile ? (
              <Route exact path="/account/my-enquiry" component={DiamondList} />
            ) : (
              <Route exact path="/account/my-enquiry" component={Account} />
            )
          }
          {P.HOLD?.view &&
            (isMobile ? (
              <Route exact path="/account/hold" component={DiamondList} />
            ) : (
              <Route exact path="/account/hold" component={Account} />
            ))}
          <Route exact path="/account/profile" component={Account} />
          {/* <Route
            exact
            path="/account/profile/manage-user"
            component={Account}
          />
          <Route
            exact
            path="/account/profile/manage-address"
            component={Account}
          /> */}
          {isMobile ? (
            <Route exact path="/account/profile/diamond-sequence" component={DiamondColumnListing} />
          ) : (
            <Route exact path="/account/profile/diamond-sequence" component={Account} />
          )}{' '}
          {P.MATCH_PAIR?.view && <Route exact path="/match-pair" component={DiamondList} />}
          {P.UPCOMING?.view && <Route exact path="/upcoming" component={DiamondList} />}
          {P.SEARCH_DIAMOND?.view && <Route path="/diamond-search" component={DiamondSearch} />}
          {P.FANCY_SEARCH?.view && <Route path="/fancy-search" component={DiamondSearch} />}
          {/* <Route exact path="/made-in-botswana" component={MadeInBotswana} /> */}
          {isMobile && <Route exact path="/notification" component={NotificationDropdown} />}
          {/* <Route exact path="/new-arrival-bid-it" component={NewArrivalBidIt} /> */}
          {/* {USER_PERMIT?.BEST_BUY?.view && ( */}
          {P.BESTBUY?.view && <Route exact path="/stone-of-the-day" component={DiamondList} />}
          {/* )} */}
          {/* {USER_PERMIT?.NEW_ARRIVAL?.view && ( */}
          {P.NEW_ARRIVAL_BID_IT?.view && <Route exact path="/new-arrivals" component={DiamondList} />}
          {/* )} */}
          {/* <Route path="*" component={NotFound} /> */}
        </Switch>
      </App>
    );
  }
}

export default compose(withRouter)(FrontRoute);
