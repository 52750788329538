import React, { useState, useEffect } from 'react';
import Heading from '../../common/Heading';
import SendEmailPopup from './SendEmail';
import { Checkbox } from 'antd';
import { LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import map from 'lodash/map';
import OpenNotification from '../../../components/common/CommonButton/OpenNotification';
import { DOWNLOAD_ZIP_EXCEL } from '../../../constants/apiConstant';
import UtilService from '../../../services/util';
import { BASE_URL } from '../../../constants/Common';
import _ from 'lodash';

const MobileShareOption = (props) => {
  const [sendEmail, setSendEmail] = useState(false);

  const WhatsAppShare = () => {
    if (props.checked.length === 1) {
      let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0].id}`;
      window.open(`https://web.whatsapp.com/send?text=${path}`);
    } else if (props.checked.length >= 2) {
      let msg = 'Cupid : Diamonds Detail%0A%0A';

      props.checked.forEach((element) => {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element.id}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.whatsapp.com/send?text=${msg}`);
    }
    props.onClose();
  };

  const SkypeShare = () => {
    if (props.checked.length === 1) {
      let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0].id}`;
      window.open(`https://web.skype.com/share?url=${path}`);
    } else if (props.checked.length >= 2) {
      let msg = 'Cupid : Diamonds Detail%0A%0A';

      props.checked.forEach((element) => {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element.id}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.skype.com/share?url=${msg}`);
    }
    props.onClose();
  };
  const onDownloadClick = (emailData) => {
    let checked = [];
    if (props.checked && props.checked.length !== 0) {
      checked = props.checked;
    } else if (props.noCheckedForDownload && props.noCheckedForDownload.length !== 0) {
      checked = props.noCheckedForDownload;
    } else {
      OpenNotification({
        type: 'error',
        title: 'No stone(stones) to share.',
      });
    }
    if (checked && checked.length !== 0) {
      let obj = {
        isExcel: false,
        img: false,
        certFile: false,
        videoFile: false,
        hAFile: false,
        arrowFile: false,
        assetFile: false,
      };

      if (sendEmail) {
        obj.sendEmail = true;
        obj.isExcel = true;
        obj.email = {
          ...emailData,
        };
      }
      let id = _.map(checked, 'vStnId');
      let objData = {
        ...DOWNLOAD_ZIP_EXCEL,
        request: {
          filter: _.isEmpty(id) ? { vStnId: [...id], isFcCol: false } : { vStnId: [...id], isFcCol: false },
          ...obj,
        },
      };
      UtilService.callApi(objData, async function (err, data) {
        if (err) {
          return OpenNotification({
            type: 'error',
            title: err.data.message || err.data.data.message || 'Failed to Share.',
          });
        }
        if ((await data) && data.code === 'OK') {
          let server = BASE_URL;
          let file_path = `${server}/${data.data}`;
          if (!obj.sendEmail) {
            window.open(file_path, 'Download');
          }
          if (props.onCloseSharePopup) {
            props.onCloseSharePopup();
          }
          setSendEmail(false);
          if (props.clearAll) {
            props.clearAll();
          }
          OpenNotification({
            type: 'success',
            title: data.message,
          });
        } else {
          OpenNotification({
            type: 'error',
            title: data.message,
          });
        }
      });
    }
  };
  return (
    <div>
      <Heading title="Share Stone" className="p-0" />

      <div className="sideBarPopupButton">
        <a onClick={() => WhatsAppShare()} className="commonButton">
          WhatsApp
        </a>
        <a onClick={() => SkypeShare()} className="commonButton">
          Skype
        </a>
        <a
          onClick={() => {
            setSendEmail(true);

            //props.onCloseSharePopup();
          }}
          className="commonButton"
        >
          Email
        </a>
      </div>
      <SendEmailPopup
        sucess={(data) => {
          onDownloadClick(data);
        }}
        sendEmail={sendEmail}
        onCancel={() => setSendEmail(!sendEmail)}
      />
    </div>
  );
};
export default MobileShareOption;
