import React, { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";
import SelectStone, { calculate } from "./SelectStone";
import { round } from "lodash";
const PopupStoneTable = (props) => {
  const [tableData, setTableData] = useState([]);
  const [offerDis, setOfferDis] = useState(0);
  const [quote, setQuote] = useState(0);
  const [finalQuote, setFinalQuote] = useState(0);
  useEffect(() => {
    let tempTable = [];
    if (props.tableData && props.tableData.length) {
      if (props.offer) {
        props.tableData.map((t) => {
          let quote = parseFloat(
            t.newPricePerCarat ? t.newPricePerCarat : t.ctPr
          );
          let finalquote = t.newAmount ? t.newAmount : t.amt;
          let offerDis = parseFloat(
            t.newPricePerCarat
              ? (1 - t.newPricePerCarat / t.rap) * 100 * 1
              : (1 - t.ctPr / t.rap) * 100 * 1
          );
          tempTable.push({
            ...t,
            quote: quote,
            finalquote: finalquote,
            offerDis: offerDis,
          });
          setOfferDis(offerDis);
          setQuote(quote);
          setFinalQuote(finalquote);
          props.getValue(quote, finalquote, offerDis);
        });
        setTableData([...tempTable]);
      } else {
        setTableData([...props.tableData]);
      }
    }
  }, [props.tableData]);
  return (
    <div className="summaryListWrapper">
      <div className="smallTableScroll">
        {!props.offer ? (
          <>
            <table>
              <tr className="summaryListTop">
                <th>Stone No</th>
                <th>Loc</th>
                <th>Lab</th>
                <th>
                  <IntlMessages id="app.Shape" />
                </th>
                <th>Carat</th>
                <th>Color</th>
                <th>Clarity</th>
                <th>Cut</th>
                <th>Pol</th>
                <th>Certi No</th>
                <th>SYM</th>
                <th>Flo</th>
                <th>Rap</th>
                <th>Discount%</th>
                <th>Pr/Ct</th>
                <th>Amount</th>
                <th>Measurement</th>
                <th>Table%</th>
                <th>Depth%</th>
                <th>Black Inc</th>
              </tr>
              {tableData.map((t) => (
                <tr className="summaryListScroll">
                  <td>{t.vStnId}</td>
                  <td>{t.locNm}</td>
                  <td>{t.lbNm}</td>
                  <td>{t.shpNm}</td>
                  <td className="numberValue">{t.crt}</td>
                  <td>{t.colNm}</td>
                  <td>{t.clrNm}</td>
                  <td>{t.cutNm}</td>
                  <td>{t.polNm}</td>
                  <td>{t.rptNo}</td>
                  <td>{t.symNm}</td>
                  <td>{t.fluNm}</td>
                  <td className="numberValue">{t.rap}</td>
                  <td className="numberValue">
                    {t.back ? parseFloat(t.back) : 0}
                  </td>
                  <td className="numberValue">{parseFloat(t.ctPr)}</td>
                  <td className="numberValue">{parseFloat(t.amt)}</td>
                  <td>{t.msrmnt}</td>
                  <td>{t.tblPer}</td>
                  <td>{t.depPer}</td>
                  <td>{t.blkIncNm ? t.blkIncNm : "-"}</td>
                </tr>
              ))}
            </table>
          </>
        ) : (
          <>
            <table>
              <tr className="summaryListTop">
                <th>Rap</th>
                <th>Discount%</th>
                <th>Pr/Ct</th>
                <th>Amount</th>
                <th>Offered Dis%</th>
                <th>Offered Price/Ct</th>
                <th>Offered Amt$</th>
                <th>Stone No</th>
                <th>Loc</th>
                <th>Lab</th>
                <th>
                  <IntlMessages id="app.Shape" />
                </th>
                <th>Carat</th>
                <th>Color</th>
                <th>Clarity</th>
                <th>Cut</th>
                <th>Pol</th>
                <th>Certi No</th>

                <th>SYM</th>
                <th>Flo</th>
                <th>Measurement</th>
                <th>Table%</th>
                <th>Depth%</th>
                <th>Black Inc</th>
              </tr>
              {tableData.map((t, index) => {
                return (
                  <tr className="summaryListScroll">
                    <td className="numberValue">{t.rap}</td>
                    <td className="numberValue">
                      {t.back ? parseFloat(t.back) : 0}
                    </td>
                    <td className="numberValue">{parseFloat(t.ctPr)}</td>
                    <td className="numberValue">{parseFloat(t.amt)}</td>

                    <td>
                      <input
                        onChange={(e) => {
                          let val = e.target.value;
                          let check = Number(val);
                          let final = 0;
                          if (!isNaN(check)) {
                            if (val === "") {
                              val = 0;
                            } else {
                              if (val > 100) {
                                val = 100;
                              } else {
                                val = e.target.value;
                              }
                            }
                          } else {
                            val = 0;
                          }

                          val = parseFloat(val);
                          tableData[index]["offerDis"] = val;
                          let disCaratprice =
                            tableData[index]["quote"] -
                            (tableData[index]["quote"] * val) / 100;
                          tableData[index]["quote"] = disCaratprice;

                          final = disCaratprice * tableData[index].crt;
                          tableData[index]["finalquote"] = final;

                          let sum = calculate([
                            {
                              ...tableData[index],
                              ctPr: val,
                              amt: tableData[index].finalquote,
                            },
                          ]);
                          tableData[index].newDiscount = sum.final_discount;
                          props.getValue(disCaratprice, final, val);
                          setQuote(disCaratprice);
                          setOfferDis(val);
                          setFinalQuote(final);
                        }}
                        onBlur={(e) => {
                          let val = e.target.value;
                          let check = Number(val);
                          let final = 0;
                          if (!isNaN(check)) {
                            if (val === "") {
                              val = 0;
                            } else {
                              if (val > 100) {
                                val = 100;
                              } else {
                                val = e.target.value;
                              }
                            }
                          } else {
                            val = 0;
                          }

                          val = parseFloat(val);
                          tableData[index]["offerDis"] = val;
                          let disCaratprice =
                            tableData[index]["quote"] -
                            (tableData[index]["quote"] * val) / 100;
                          tableData[index]["quote"] = disCaratprice;

                          final = disCaratprice * tableData[index].crt;
                          tableData[index]["finalquote"] = final;

                          let sum = calculate([
                            {
                              ...tableData[index],
                              ctPr: val,
                              amt: tableData[index].finalquote,
                            },
                          ]);
                          tableData[index].newDiscount = sum.final_discount;
                          setTableData([...tableData]);
                        }}
                        value={offerDis ? round(parseFloat(offerDis), 2) : 0.0}
                        style={{ width: "70%" }}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => {
                          let val = e.target.value;
                          let check = Number(val);
                          let final = 0;

                          if (!isNaN(check)) {
                            if (val === "") {
                              val = 0;
                            } else {
                              val = e.target.value;
                            }
                          } else {
                            val = 0;
                          }

                          val = parseFloat(val);

                          final = val * tableData[index].crt;
                          tableData[index]["finalquote"] = final;

                          tableData[index]["quote"] = val;
                          let discount =
                            val === "0.00"
                              ? 100
                              : (1 -
                                  tableData[index]["quote"] /
                                    tableData[index].rap) *
                                  100 +
                                1;
                          tableData[index]["offerDis"] = discount;

                          let sum = calculate([
                            {
                              ...tableData[index],
                              ctPr: val,
                              amt: tableData[index].finalquote,
                            },
                          ]);
                          tableData[index].newDiscount = sum.final_discount;
                          // setTableData([...tableData])
                          setQuote(val);
                          setOfferDis(discount);
                          setFinalQuote(final);
                          props.getValue(val, final, discount);
                        }}
                        onBlur={(e) => {
                          let val = e.target.value;
                          let check = Number(val);
                          let final = 0;

                          if (!isNaN(check)) {
                            if (val === "") {
                              val = 0;
                            } else {
                              val = e.target.value;
                            }
                          } else {
                            val = 0;
                          }

                          val = parseFloat(val);

                          final = val * tableData[index].crt;
                          tableData[index]["finalquote"] = final;

                          tableData[index]["quote"] = val;

                          let discount =
                            val === "0.00"
                              ? 100
                              : (1 -
                                  tableData[index]["quote"] /
                                    tableData[index].rap) *
                                  100 +
                                1;
                          tableData[index]["offerDis"] = discount;

                          let sum = calculate([
                            {
                              ...tableData[index],
                              ctPr: val,
                              amt: tableData[index].finalquote,
                            },
                          ]);
                          tableData[index].newDiscount = sum.final_discount;
                          setTableData([...tableData]);
                        }}
                        value={quote ? round(parseFloat(quote), 2) : 0.0}
                        style={{ width: "70%" }}
                      />
                    </td>
                    <td>
                      <input
                        disabled
                        value={finalQuote ? parseFloat(finalQuote) : 0.0}
                        style={{ width: "70%" }}
                      />
                    </td>
                    <td>{t.vStnId}</td>
                    <td>{t.locNm}</td>
                    <td>{t.lbNm}</td>

                    <td>{t.shpNm}</td>
                    <td className="numberValue">{t.crt}</td>
                    <td>{t.colNm}</td>
                    <td>{t.clrNm}</td>
                    <td>{t.cutNm}</td>
                    <td>{t.polNm}</td>
                    <td>{t.rptNo}</td>

                    <td>{t.symNm}</td>
                    <td>{t.fluNm}</td>
                    <td>{t.msrmnt}</td>
                    <td>{t.tblPer}</td>
                    <td>{t.depPer}</td>
                    <td>{t.blkIncNm ? t.blkIncNm : "-"}</td>
                  </tr>
                );
              })}
            </table>
          </>
        )}
      </div>
    </div>
  );
};
export default PopupStoneTable;
