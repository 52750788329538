import React, { useState, useEffect, useRef } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import MagnifierImage from './MagnifierImage';
import Slider from 'react-slick';
import IFrame from '../common/IFrame';
import { FILE_URLS } from '../../constants/Common';
import Image from '../common/Image';
import { getLabLink } from 'components/DiamondList/table-utils';
import { isString } from 'util/utils';

const DiamondDetailLeft = (props) => {
  const data = props.data;

  const [activeTab, setActiveTab] = useState('1');
  const [main1, setMain1] = useState('');
  const [main2, setMain2] = useState('');
  const [ha1, setHa1] = useState('');
  const [ha2, setHa2] = useState('');
  const [main1Bool, setMain1Bool] = useState(false);
  const [main2Bool, setMain2Bool] = useState(false);

  useEffect(() => {
    if (activeTab) {
      if (activeTab === '2') {
        setMain1(FILE_URLS.img.replace('***', data.rptNo));
        // setMain2(FILE_URLS.lightBlack.replace("***", data.mfgStnId));
      }
      if (activeTab === '3') {
        setHa1(FILE_URLS.arrowFile.replace('***', data.mfgStnId));
        setHa2(FILE_URLS.hAFile.replace('***', data.mfgStnId));
      }
    }
  }, [activeTab, data.mfgStnId, data.rptNo]);
  const toggle = (tab) => {
    if (activeTab === tab) return;
    setActiveTab(tab);
    void props.activeTabChange?.(tab);
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [nav5, setNav5] = useState();
  const [nav6, setNav6] = useState();

  const [certiDone, setCertiDone] = useState(false);
  const [errorCerti, setErrorCerti] = useState(false);

  return (
    <>
      <Nav tabs className="customerMaintab diamondDetailTopTab">
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>
            Video
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>
            Image
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={
              toggle("3")
            }
          >
            H&A
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => toggle('4')}>
            Certificate
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} style={props.style}>
        <TabPane tabId="1">
          {activeTab === '1' && (
            <>
              <Slider asNavFor={nav2} infinite={false} arrows={false} ref={(slider1) => setNav1(slider1)}>
                {data && data.rptNo && (
                  <div className="diamondDetailImageBox htmlViewImage">
                    <video
                      src={FILE_URLS.videoFile.split('***').join(data.rptNo)}
                      height="100%"
                      width="100%"
                      autoPlay
                      controls
                      loop
                      muted
                    />
                  </div>
                )}
              </Slider>
              {/* <Slider
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              slidesToShow={1}
              swipeToSlide={true}
              centerMode={true}
              focusOnSelect={true}
              // infinite={false}
              className="smallSliderBlock"
            >
              <div>
                <div className="smallSliderImage">
                  <img src={require("../../assets/svg/DiamondDetail/video.svg")} alt="" />
                </div>
                <span className="smallimgTitle">Html</span>
              </div>
            </Slider> */}
            </>
          )}
        </TabPane>
        <TabPane tabId="2">
          {activeTab === '2' && (
            <>
              <Slider
                asNavFor={nav6}
                ref={(slider5) => {
                  setNav5(slider5);
                }}
                arrows={false}
              >
                <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require('../../assets/svg/camera.svg')}
                    image={FILE_URLS.img.replace('***', data.rptNo)}
                  />
                </div>
                {/* <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require("../../assets/svg/camera.svg")}
                    image={FILE_URLS.lightBlack.replace("***", data.mfgStnId)}
                  />
                </div> */}
              </Slider>
              <Slider
                asNavFor={nav5}
                ref={(slider6) => setNav6(slider6)}
                slidesToShow={2}
                swipeToSlide={true}
                focusOnSelect={true}
                // infinite={false}
                className="smallSliderBlock smallSliderBlockimage"
                beforeChange={props.currentSubTab}
              >
                {!main1Bool && (
                  <React.Fragment>
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setMain1Bool(true);
                          setMain1(require('./traffic-signal.svg'));
                        }}
                        src={main1}
                        alt=""
                      />
                    </div>
                  </React.Fragment>
                )}
                {!main2Bool && (
                  <React.Fragment>
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setMain2Bool(true);
                          setMain2(require('./traffic-signal.svg'));
                        }}
                        src={main2}
                        alt=""
                      />
                    </div>
                  </React.Fragment>
                )}
              </Slider>
            </>
          )}
        </TabPane>
        {/* <TabPane tabId="3">
          {activeTab === "3" && (
            <>
              <Slider
                asNavFor={nav4}
                ref={(slider3) => setNav3(slider3)}
                arrows={false}
              >
                <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require("../../assets/svg/camera.svg")}
                    image={FILE_URLS.arrowFile.replace("***", data.mfgStnId)}
                  />
                </div>
                <div className="diamondDetailImageBox">
                  <MagnifierImage
                    tempSrc={require("../../assets/svg/camera.svg")}
                    image={FILE_URLS.hAFile.replace("***", data.mfgStnId)}
                  />
                </div>
              </Slider>
              <Slider
                asNavFor={nav3}
                ref={(slider4) => setNav4(slider4)}
                slidesToShow={2}
                swipeToSlide={true}
                focusOnSelect={true}
                // infinite={false}
                className="smallSliderBlock smallSliderBlockimage"
                beforeChange={props.currentSubTab}
              >
                <React.Fragment>
                  {!ha1Bool && (
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setHa1Bool(true);
                          setHa1(require("./traffic-signal.svg"));
                        }}
                        src={ha1}
                        alt=""
                      />
                    </div>
                  )}
                </React.Fragment>
                <React.Fragment>
                  {!ha2Bool && (
                    <div className="smallSliderImage">
                      <img
                        onError={() => {
                          setHa2Bool(true);

                          setHa2(require("./traffic-signal.svg"));
                        }}
                        src={ha2}
                        alt=""
                      />
                    </div>
                  )}
                </React.Fragment>
              </Slider>
            </>
          )}
        </TabPane> */}

        <TabPane tabId="4">
          {activeTab === '4' && (
            <div className="diamondDetailImageBox">
              <IFrame
                errorCerti={errorCerti}
                setErrorCerti={setErrorCerti}
                certiDone={certiDone}
                doneCerti={() => setCertiDone(true)}
                detail
                tempSrc={require('../../assets/svg/certi.svg')}
                src={FILE_URLS.certFile.replace('***', data.rptNo)}
              />
              {/* <iframe
                src={FILE_URLS.certFile.replace("***", data.rptNo)}
                // height="100%"
              ></iframe> */}
            </div>
          )}
        </TabPane>
      </TabContent>
    </>
  );
};

export default DiamondDetailLeft;
