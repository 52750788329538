import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import IntlMessages from '../../../util/IntlMessages';
import UtilService from '../../../services/util';
import { LOCAL_STORAGE_VAR, KYC_STATUS } from '../../../constants/Common';
import OpenNotification from '../CommonButton/OpenNotification';

const HeaderAccountList = (props) => {
  let userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  return (
    <li
      onClick={() => {
        if (props.check) {
          if (userData && userData.account && !userData.account.isKycUploaded) {
            OpenNotification({
              type: 'error',
              title: 'Please upload your KYC documents to access this functionality.',
            });
          } else if (userData && userData.account && userData.account.isApproved !== KYC_STATUS.APPROVED) {
            if (userData.account.isApproved === KYC_STATUS.REJECT) {
              OpenNotification({
                type: 'error',
                title: 'Your KYC is rejected. Please upload your KYC again with right information.',
              });
            } else {
              OpenNotification({
                type: 'error',
                title: 'You will access this functionality once your KYC is approved.',
              });
            }

            // KYC under process
          } else if (userData && userData.account && userData.account.isApproved === KYC_STATUS.APPROVED) {
            props.history.push(props.link);
          } else {
            OpenNotification({
              type: 'error',
              title: 'This functionality is currently disable',
            });
          }
        } else {
          props.history.push(props.link);
        }
        // window.location.assign(props.link)
      }}
    >
      <div className="userDetailListItem" onClick={() => (props.onClick ? props.onClick() : {})}>
        <Link className="detailItemName">{props.title}</Link>
        <img src={require('../../../assets/svg/Header/right.svg')} alt="icon" />
      </div>
      {props.basicDetailnull ? null : (
        <div className="uerDetailBasic">
          <div className="userDetailBasicItem">
            <span>
              <IntlMessages id="app.Pieces" />{' '}
            </span>
            <span>{props.details ? props.details.pieces : 0}</span>
          </div>
          <div className="userDetailBasicItem">
            <span>
              <IntlMessages id="app.Carats" />
            </span>
            <span>{props.details ? parseFloat(props.details.carat) : 0}</span>
          </div>
          <div className="userDetailBasicItem">
            <span>
              <IntlMessages id="app.Value" />
            </span>
            <span>$ {props.details ? parseFloat(props.details.totals) : 0}</span>
          </div>
        </div>
      )}
    </li>
  );
};
export default withRouter(HeaderAccountList);
