import React from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import SortingFilterDropdownItem from './OverlayItem'

const SortingFilterDropdown = props => {
    let leftFilterArray =
        props.FilterOption === false
            ? []
            : props.selectedFilterArray[props.column]
            ? props.filterArray[props.column].filter(el => !props.selectedFilterArray[props.column].includes(el))
            : props.filterArray[props.column]

    return (
        <div className='menuInnerFilterOption'>
            <div className='menuInnerFilterInner'>
                {props.sort.length ? (
                    <>
                        <div className='llSortingTitle'>Sorting Order</div>
                        <div className='llSortingTitleList'>
                            <DndProvider backend={HTML5Backend}>
                                {props.sort.map((x, i) => {
                                    return (
                                        <SortingFilterDropdownItem
                                            index={i}
                                            moverow={props.handleSort}
                                            para={Object.keys(x)[0]}
                                            title={props.canSort[Object.keys(x)[0]]}
                                            sortClick={props.sortClick}
                                            sort={props.sort}
                                        />
                                    )
                                })}
                            </DndProvider>
                        </div>
                    </>
                ) : null}
                {props.FilterOption !== false && props.filterArray[props.column] ? (
                    <div
                        className='llfilterMenuDropdown'
                        style={props.sort.length ? { borderTop: '1px solid #e9ebf0' } : {}}
                    >
                        {props.selectedFilterArray[props.column] ? (
                            <ul className='llfilterSelect'>
                                <div className='llSortingTitle'>Selected Filter</div>
                                {props.selectedFilterArray[props.column].map(x => {
                                    return (
                                        <li>
                                            {x}
                                            <div
                                                className='closeFilter'
                                                onClick={() => props.selectFilter(x, props.column, true)}
                                            >
                                                <img
                                                    src={require('../../assets/svg/DiamondList/close-black.svg')}
                                                    alt='icon'
                                                />
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        ) : null}
                        {leftFilterArray.length ? (
                            <ul>
                                <div className='llSortingTitle'>Select Filter</div>
                                {leftFilterArray.map(x => {
                                    return <li onClick={() => props.selectFilter(x, props.column)}>{x}</li>
                                })}
                            </ul>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default SortingFilterDropdown
