import React, { memo, useEffect, useState } from 'react'
import Heading from '../Heading'
import MobileViewListCard from '../../DiamondList/MobileViewListCard'
import { Drawer } from 'antd'
import useRowSelect from '../../../util/useRowSelect'
import { HeadingCalc } from './ConfirmStonePopup'
import find from 'lodash/find'

/* eslint jsx-a11y/anchor-is-valid: 0 */

export const ListingCards = memo(
    ({
        data,
        currentType,
        finalCalc = false,
        bidPopup = false,
        quote = false,
        checked = null,
        bid = false,
        note = false,
        watchpopup = false,
        quotePage = false
    }) => {
        const { isRowChecked, toggleRowSelection } = useRowSelect(currentType)
        return (
            <>
                {data.map(x => {
                    const isChecked = !checked ? isRowChecked(x.id) : find(checked, { id: x.id }) ? true : false
                    return (
                        <MobileViewListCard
                            quote={quote}
                            quotePage={quotePage}
                            note={note}
                            watchpopup={watchpopup}
                            bidPopup={bidPopup}
                            bid={bid}
                            finalCalc={finalCalc}
                            // quoteClick={quoteClick}
                            data={x}
                            onClick={() => toggleRowSelection(x, !isChecked)}
                            activeClass={isChecked ? 'active' : ''}
                        />
                    )
                })}
            </>
        )
    }
)

const MobileConfirmStone = props => {
    const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType)
    const [menu, showMenu] = useState(false)
    useEffect(() => {
        props.data.map(row => {
            toggleRowSelection(row, true)
            return true
        })
    }, [])

    return (
        <>
            <div className='offerWrapper'>
                <div className='d-flex align-items-center justify-space-between offerTopBlock'>
                    <div className='d-flex align-items-center'>
                        <Heading className='popupInnerTitle' title='Confirm Stone'></Heading>
                    </div>
                    {HeadingCalc(props.data.filter(x => isRowChecked(x.id)))}
                </div>
                <div className='searchInnerResult tabInnerTable'>
                    <div className='diamondListMobile mobileOfferScroll'>
                        <ListingCards data={props.data} currentType={props.currentType} />
                    </div>
                    <div className='d-flex justify-content-between offerBottomBox flex-wrap'></div>
                </div>
                <div className='sideBarPopupButton'>
                    <a
                        className='commonButton'
                        onClick={() => {
                            if (props.parent.checkCheck()) showMenu(true)
                        }}
                    >
                        Confirm Stone
                    </a>
                </div>
            </div>
            {menu && (
                <Drawer
                    title={false}
                    onClose={() => showMenu(false)}
                    visible={true}
                    wrapClassName='diamondListinSidebar smallSidebarMobilePopup'
                >
                    <div>
                        <div className='offerInputBox'>
                            {/* {props.parent.getcompany()}
                            {props.parent.getinvoicedate()} */}
                            {props.parent.gettextarea()}
                        </div>
                        <div className='sideBarPopupButton'>
                            <a className='commonButton' onClick={props.parent.submit}>
                                Comfirm
                            </a>
                            <a className='commonOutline' onClick={props.parent.cancel}>
                                Cancel
                            </a>
                        </div>
                    </div>
                </Drawer>
            )}
        </>
    )
}

export default memo(MobileConfirmStone)
