import React from 'react';

export default class IFrame extends React.Component {
  state = {
    error: false,
    loading: true,
  };

  render() {
    const { error, loading } = this.state;

    if (error)
      return (
        <div className="nodataFoundDetail">
          <div className="nodataFoundInner">
            <img src={this.props.tempSrc} />
            <span>No {this.props.video ? 'Video ' : 'Certificate '}Found</span>
          </div>
        </div>
      );

    return (
      <>
        <iframe
          src={this.props.src}
          height={this.props.detail ? '100%' : undefined}
          width={this.props.detail ? '100%' : undefined}
          onError={() => this.setState({ loading: false, error: true })}
          onLoad={() => this.setState({ loading: false, error: false })}
        />
        {loading && <div className="loading-indicator"></div>}
      </>
    );
  }
}
