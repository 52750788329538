import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Drawer } from 'antd';

import ConfirmStonePopup from './ConfirmStonePopup';
import QuotePopup from './QuotePopup';

import { Notify } from 'util/notify';
import * as _ from 'util/lodash';

import {
  handleInsertTrack,
  compareStones,
  LISTINGPAGES,
  handlePrint,
  handleTrackDelete,
  handleUpsertTrack,
  handleDownloadExcel,
  myAccountExcelTrack,
  myAccountExcelMemo,
  myAccountExcelOffice,
  myAccountPrintTrack,
  myAccountPrintMemo,
  myAccountPrintOffice,
  handlePrintTrack,
  handleDownloadExcelTrack,
  getPath,
  handleDeleteOffice,
  DiamondTrackStatusUpdate,
  myHoldPDFTrack,
  myAccountPDFTrack,
  myHoldExcelTrack,
  bestBuyPrintTrack,
  bestBuyExcelTrack,
} from '../../DiamondList/DiamondListFunctions';
import Pagination from '../Pagination';
import OpenNotification from '../CommonButton/OpenNotification';
import isEmpty from 'lodash/isEmpty';
import FinalCalculations from './FinalCalculations';
import HoldPopup from './HoldPopup';
import ShareDropdown from './ShareDropdown';
import DownloadDropdown from './DownloadDropdown';
import { TRACK_TYPES, LOCAL_STORAGE_VAR, KYC_STATUS } from '../../../constants/Common';
import Reminder from '../Reminder';
import EnquiryPopup from './EnquiryPopup';
import UpdateReminder from '../UpdateReminder';
import BidPopup from './BidPopup';
import IntlMessages from '../../../util/IntlMessages';
import CommonModal from '../CommonModal';
import MobileShareOption from './MobileShareOption';
import MobileDownloadOption from './MobileDownloadOption';
import OfficeSidebarNew from './OfficeSidebarNew';
import NotePopup from './NotePopup';
import { GuestUserPopup } from '../../GuestUserPopup';
import { NoKycPop } from '../../NoKycPop';
import { isNotEmpty } from 'util/utils';
import { useLocation } from 'react-router-dom';
import { useIsMobile } from 'util/hooks';

const DiamondListingAction = (props) => {
  const { clearAll, fetch } = props;

  const location = useLocation();
  const [isMobile, isTablet] = useIsMobile();

  const [visible, setVisible] = React.useState('');
  const [moreSearch, moreSearchOption] = React.useState(false);
  const [more, setMore] = React.useState(false);
  const [reminder, setReminder] = React.useState(false);
  const [excelPopup, setExcelPopup] = React.useState(false);
  const [disclaimer, setDisclaimer] = React.useState(false);
  const [showGuest, setShowGuest] = React.useState(false);
  const [noKyc, setNoKyc] = React.useState(false);

  const checked = useSelector((state) => state.diamondData.selectedRows[props.currentType] ?? props.checkedData ?? []);
  const checkedRowIds = React.useMemo(() => checked?.map?.((rec) => rec?.id) ?? [], [checked]);
  const isGuest = React.useMemo(() => {
    return localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
  }, []);

  const path = location.pathname.split('/')[1];
  const module = location.pathname.split('/')[2];
  const authUser = useSelector((state) => state?.auth?.authUser);

  const kycNotApproved = authUser?.account?.isApproved !== KYC_STATUS.APPROVED;

  const moreSearchDrawer = () => {
    moreSearchOption(true);
  };

  const onClose = () => {
    setVisible('');
    setReminder('');
    moreSearchOption(false);
  };

  const actions = React.useMemo(() => {
    return [
      !props.modify && {
        name: 'Modify search',
        key: 'modify',
        onClick: () => props.history.push(`/${LISTINGPAGES.SEARCH}/${props.searchId}`),
      },
      props.bidStone && {
        name: <IntlMessages id="app.bidStone" />,
        key: 'bid',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to bid.' });
          setVisible('bid');
        },
      },
      !props.noconfirm && {
        name: <IntlMessages id="app.BuyNow" />,
        key: 'confirm',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to confirm.' });
          if (checked.some((a) => ['H', 'M'].includes(a.wSts)))
            return Notify.error({
              message: 'Sorry! Unable to perform action at this time. The selected diamond(s) is in hold/memo.',
            });
          if (checked.map((d) => d.sStsDesc).includes('GIA Result')) return setDisclaimer(true);
          setVisible('confirm');
        },
        img: require('assets/svg/DiamondSearch/confirm.svg'),
      },
      props.permKey === 'offer' && {
        name: 'Reject',
        key: 'rejectoffer',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to reject offer.' });
          DiamondTrackStatusUpdate(
            checked.map((x) => x.trackId),
            { offerStatus: 3 },
            () => {
              void clearAll?.();
              void fetch?.();
            },
          );
        },
      },
      !props.nocart && {
        name: <IntlMessages id="app.Cart" />,
        key: 'cart',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to add in cart.' });
          handleInsertTrack(TRACK_TYPES.CART, checked, () => void clearAll?.());
        },
        img: require('assets/svg/DiamondList/cart.svg'),
      },
      !props.nowatch && {
        name: <IntlMessages id="app.Watch" />,
        key: 'watch',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to add in watch.' });
          handleInsertTrack(TRACK_TYPES.WATCHLIST, checked, () => void clearAll?.());
        },
        img: require('assets/svg/DiamondList/watch.svg'),
      },
      !props.noreminder && {
        name: <IntlMessages id="app.reminder" />,
        key: 'reminder',
        onClick: () => {
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to add in reminder.' });
          setReminder('insert');
        },
      },
      !props.noquote && {
        name: <IntlMessages id="app.Offer" />,
        key: 'quote',
        onClick: () => {
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to apply offer.' });
          setVisible('quote');
        },
      },
      !props.nooffice && {
        name: <IntlMessages id="app.Office" />,
        key: 'office',
        onClick: () => {
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(stones) to add in office.' });
          setVisible('office');
        },
      },
      !props.noenquiry && {
        name: 'Enquiry',
        key: 'enquiry',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) for enquiry.' });
          setVisible('enquiry');
        },
      },
      !props.nohold && {
        name: 'Hold',
        key: 'hold',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) for hold.' });
          setVisible('hold');
        },
      },
      !props.nofinalcalc && {
        name: <IntlMessages id="app.FinalCalculation" />,
        key: 'calc',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) for final calculation.' });
          setVisible('finalCalc');
        },
      },
      !(props.noExportExcel || props.data.length <= 0) && {
        name: 'Excel',
        key: 'exportExcel',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s).' });
          setExcelPopup(true);
        },
      },
      !props.nonocompare &&
        (props.nocompare
          ? {
              name: (
                <span>
                  <IntlMessages id="app.CompareStoneNow" />
                  {`(${props.data.length})`}
                </span>
              ),
              key: 'compare',
              onClick: (e) => {
                void e?.preventDefault?.();
                if (isGuest) return setShowGuest(true);
                if (kycNotApproved) return setNoKyc(true);
                localStorage.setItem(`${LOCAL_STORAGE_VAR}-more`, true);
                props.history.push(JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)));
              },
            }
          : {
              name: <IntlMessages id="app.compare" />,
              key: 'compare',
              img: require('assets/svg/DiamondList/compare.svg'),
              onClick: (e) => {
                void e?.preventDefault?.();
                if (isGuest) return setShowGuest(true);
                if (kycNotApproved) return setNoKyc(true);
                compareStones(checked);
                void clearAll?.();
              },
            }),
      !props.nonote && {
        name: <IntlMessages id="app.comment" />,
        key: 'note',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to add note.' });
          setVisible('note');
        },
      },
      !props.noshare && {
        name: <IntlMessages id="app.Share" />,
        key: 'share',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s) to share.' });
          setVisible('share');
        },
      },
      !props.nodownload && {
        name: <IntlMessages id="app.Download" />,
        key: 'download',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s).' });
          setVisible('download');
        },
      },
      !props.noprint && {
        name: <IntlMessages id="app.PrintList" />,
        key: 'print',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isGuest) return setShowGuest(true);
          if (kycNotApproved) return setNoKyc(true);
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(s).' });

          if (path === 'account') {
            if (checked.length > 0) {
              if (module === 'confirm-list' || module === 'my-purchase') {
                handlePrintTrack({}, checkedRowIds, props.printType, () => {});
              } else {
                handlePrint({}, checkedRowIds, props.printType, () => {});
              }
            } else {
              // FIXME: DEAD CODE ALERT
              if (module === 'watchlist') {
                myAccountPrintTrack(2);
              } else if (module === 'cart') {
                myAccountPrintTrack(1);
              } else if (module === 'quote-list') {
                myAccountPrintTrack(4);
              } else if (module === 'confirm-list') {
                myAccountPrintMemo({ status: 1 });
              } else if (module === 'my-purchase') {
                myAccountPrintMemo({ status: 2 });
              } else if (module === 'office-list') {
                myAccountPrintOffice();
              } else if (module === 'hold') {
                myHoldPDFTrack(1);
              } else if (module === 'my-enquiry') {
                myAccountPDFTrack(6);
              }
            }

            return void clearAll?.();
          }
          // FIXME: MORE DEAD CODE
          if (isEmpty(checked) && getPath() === LISTINGPAGES.STONE_OF_DAY) {
            bestBuyPrintTrack();
            return void clearAll?.();
          }

          // USEFUL CODE
          handlePrint(
            getPath() === LISTINGPAGES.NEWARR
              ? { viewType: 2 }
              : props.seachFilterTag
              ? props.seachFilterTag.searchData
              : {},
            checkedRowIds,
            props.printType,
            () => {},
          );

          void clearAll?.();
        },
      },
      props.update && {
        name: <IntlMessages id="app.Update" />,
        key: 'update',
        onClick: () => {
          if (isEmpty(checked)) return Notify.error({ message: 'Please select stone(stones) to update reminder.' });
          setReminder('update');
        },
      },
      props.delete && {
        name: <IntlMessages id="app.Delete" />,
        key: 'delete',
        onClick: (e) => {
          void e?.preventDefault?.();
          if (isEmpty(checked)) {
            return OpenNotification({ type: 'error', title: 'Please select stone(s) to delete.' });
          }
          if (getPath() === LISTINGPAGES.OFFICE) {
            const schedule = checked.map((ck) => ({ diamonds: [ck.id], id: ck.slottId }));
            const _schedule = Object.keys(_.groupBy(schedule, 'id')).map((g) => ({
              id: g,
              diamonds: _.flatten(_.map(_.groupBy(schedule, 'id')[g], 'diamonds')),
            }));
            handleDeleteOffice({ schedule: _schedule }, () => {
              void clearAll?.();
              void fetch?.();
              OpenNotification({
                type: 'success',
                title: 'Your stone(s) is removed from office successfully.',
              });
            });
            return;
          }

          handleTrackDelete(props.trackType, checkedRowIds, props.inTrackDiamonds, () => {
            void fetch?.();
            void clearAll?.();
          });
        },
      },
      props.shipment && {
        name: 'Shipment',
        key: 'shipment',
      },
    ]
      .filter(Boolean)
      .filter(isNotEmpty);
  }, [
    checked,
    checkedRowIds,
    clearAll,
    fetch,
    isGuest,
    kycNotApproved,
    module,
    path,
    props.bidStone,
    props.data.length,
    props.delete,
    props.history,
    props.inTrackDiamonds,
    props.modify,
    props.noExportExcel,
    props.nocart,
    props.nocompare,
    props.noconfirm,
    props.nodownload,
    props.noenquiry,
    props.nofinalcalc,
    props.nohold,
    props.nonocompare,
    props.nonote,
    props.nooffice,
    props.noprint,
    props.noquote,
    props.noreminder,
    props.noshare,
    props.nowatch,
    props.permKey,
    props.printType,
    props.seachFilterTag,
    props.searchId,
    props.shipment,
    props.trackType,
    props.update,
  ]);

  return (
    <>
      <div className="mobileSearchBottom">
        {actions.slice(0, 3).map((x) => {
          return (
            <div className="mobileSearchActionItem" key={x.key}>
              <a href="#!" onClick={x.onClick}>
                <img alt="" src={x.img} />
                <span>{x.name}</span>
              </a>
            </div>
          );
        })}
        <div className="mobileSearchActionItem">
          <a href="#!" onClick={moreSearchDrawer}>
            <img alt="" src={require('assets/svg/DiamondSearch/more.svg')} />
            <span>More</span>
          </a>
        </div>
      </div>
      {moreSearch && (
        <Drawer onClose={onClose} visible={moreSearch} wrapClassName="mobileSidebar mobileBottomSidebar" destroyOnClose>
          <div className="MoreSearchOptionItem">
            <ul>
              {actions.slice(3, actions.length).map((x) => {
                return (
                  <li onClick={x.onClick} key={x.key}>
                    <a>{x.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
      )}
      <div
        className={
          'botoomStickyBar diamondListingAction' + (props.ActionFalse === false ? ' justify-content-center' : '')
        }
      >
        <div className="d-flex listingAction">
          {actions.slice(0, isTablet ? 3 : 8).map((x) => {
            return (
              <a href="#!" key={x.key} className="bottomStickyButton" onClick={x.onClick}>
                <span>{x.name}</span>
              </a>
            );
          })}
          {((isTablet && actions.length > 3) || actions.length > 8) && (
            <Dropdown isOpen={more} toggle={() => setMore(!more)}>
              <DropdownToggle className="bottomStickyButton" caret>
                <span>
                  <IntlMessages id="app.More" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="diamondListBottomDropdown">
                <ul>
                  {actions.slice(isTablet ? 3 : 8, actions.length).map((x) => {
                    return (
                      <li key={x.title} onClick={x.onClick}>
                        <a href="#!">
                          <div className="dropdownBottomItem">
                            <span>{x.name}</span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {!props.nopaginate && <Pagination {...props} />}
      </div>
      <CommonModal
        visible={disclaimer}
        handleOk={() => {
          setDisclaimer(false);
          setVisible('confirm');
        }}
        handleCancel={() => {
          setDisclaimer(false);
          void clearAll?.();
        }}
        title="Disclaimer"
        modalSize="xs-size"
        submitTitle="Continue"
        cancelTitle="Cancel"
        footerShow
      >
        <div>
          <p>
            Please take note that your confirming a stone that is on Result delivery time may take anything from 2 Days
            to 15 days.
          </p>
        </div>
      </CommonModal>
      <Drawer
        onClose={onClose}
        visible={visible === 'confirm'}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === 'confirm' && <ConfirmStonePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={() => {
          onClose();
          if (props.editOffer) void fetch?.();
        }}
        visible={visible === 'quote' || props.editOffer}
        wrapClassName="diamondListinSidebar offerPopup xl-size"
        destroyOnClose
      >
        {(visible === 'quote' || props.editOffer) && (
          <QuotePopup
            {...props}
            checked={checked}
            onClose={() => {
              onClose();
              void clearAll?.();
              if (props.editOffer) void fetch?.();
            }}
          />
        )}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'bid'}
        wrapClassName="diamondListinSidebar offerPopup xl-size"
        destroyOnClose
      >
        {visible === 'bid' && <BidPopup checked={checked} {...props} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'office'}
        wrapClassName="diamondListinSidebar offerPopup xl-size"
        destroyOnClose
      >
        {visible === 'office' && (
          <OfficeSidebarNew
            {...props}
            checked={checked}
            onClose={() => {
              onClose();
              void clearAll?.();
            }}
          />
        )}
      </Drawer>

      {visible === 'enquiry' && (
        <EnquiryPopup visible={visible === 'enquiry'} checked={checked} handleCancel={onClose} />
      )}
      <Drawer
        onClose={onClose}
        visible={visible === 'hold'}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === 'hold' && <HoldPopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>

      <Drawer
        onClose={onClose}
        visible={visible === 'finalCalc'}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === 'finalCalc' && <FinalCalculations {...props} checked={checked} onClose={onClose} />}
      </Drawer>

      {!isMobile && (
        <CommonModal
          modalSize="xs-size"
          title="Share Stone"
          handleCancel={onClose}
          visible={visible === 'share'}
          wrapClassName=""
        >
          {visible === 'share' && (
            <ShareDropdown
              {...props}
              filter={props.seachFilterTag ? props.seachFilterTag.searchData : {}}
              checked={checked}
              onCloseSharePopup={onClose}
            />
          )}
        </CommonModal>
      )}
      {isMobile && (
        <Drawer
          onClose={onClose}
          visible={visible === 'share'}
          wrapClassName="diamondListinSidebar xl-size"
          destroyOnClose
        >
          {visible === 'share' && <MobileShareOption {...props} checked={checked} onClose={onClose} />}
        </Drawer>
      )}
      {!isMobile && (
        <CommonModal
          modalSize="ListingModal"
          title={<IntlMessages id="app.Download" />}
          handleCancel={onClose}
          visible={visible === 'download'}
        >
          {visible === 'download' && (
            <DownloadDropdown
              fromList={props.fromList}
              filter={props.seachFilterTag ? props.seachFilterTag.searchData : {}}
              {...props}
              checked={checked}
              onCloseDownloadPopup={onClose}
            />
          )}
        </CommonModal>
      )}
      {isMobile && (
        <Drawer
          onClose={onClose}
          visible={visible === 'download'}
          wrapClassName="diamondListinSidebar xl-size"
          destroyOnClose
        >
          {visible === 'download' && (
            <MobileDownloadOption
              fromList={props.fromList}
              filter={props.seachFilterTag ? props.seachFilterTag.searchData : {}}
              {...props}
              checked={checked}
              onCloseDownloadPopup={onClose}
            />
          )}
        </Drawer>
      )}

      <Drawer
        onClose={onClose}
        visible={visible === 'note'}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        <NotePopup {...props} checked={checked} onClose={onClose} />
      </Drawer>
      <Drawer onClose={onClose} visible={reminder === 'insert'} wrapClassName="diamondListinSidebar" destroyOnClose>
        {reminder === 'insert' && (
          <Reminder
            handleCancel={() => {
              setReminder(false);
              void clearAll?.();
            }}
            checked={checked}
            handleSetReminder={(e) =>
              handleInsertTrack(
                TRACK_TYPES.REMINDER,
                checked,
                () => {
                  void clearAll?.();
                  setReminder(false);
                },
                e,
              )
            }
          />
        )}
      </Drawer>
      <Drawer onClose={onClose} visible={reminder === 'update'} wrapClassName="diamondListinSidebar" destroyOnClose>
        {reminder === 'update' && (
          <UpdateReminder
            checked={checked}
            handleCancel={() => {
              setReminder(false);
              void clearAll?.();
            }}
            date={checked && checked[0] ? checked[0].reminderDate : null}
            handleUpdateReminder={(e) =>
              handleUpsertTrack(
                TRACK_TYPES.REMINDER,
                checked,
                props.inTrackDiamonds,
                () => {
                  void clearAll?.();
                  void fetch?.();
                  setReminder(false);
                },
                e,
              )
            }
          />
        )}
      </Drawer>
      {excelPopup && (
        <CommonModal
          title={'Export Excel'}
          modalSize="xs-size"
          visible={excelPopup}
          //onOk={props.handleOk}
          handleCancel={() => {
            setExcelPopup(false);
          }}
          footerShow
        >
          {/* sds */}

          <div className="searchPopupCommon">
            <div className="shareOptionSet d-flex">
              <div className="shareOptionSetItem">
                <ul>
                  <li
                    onClick={() => {
                      if (path === 'account') {
                        if (checked.length > 0) {
                          if (module === 'confirm-list' || module === 'my-purchase') {
                            handleDownloadExcelTrack(
                              true,
                              {},
                              checkedRowIds, ////memoDetail.id
                              () => {},
                            );
                          } else {
                            handleDownloadExcel(true, {}, checkedRowIds, () => {});
                          }
                        } else {
                          if (module === 'watchlist') {
                            myAccountExcelTrack(true, 2);
                          } else if (module === 'cart') {
                            myAccountExcelTrack(true, 1);
                          } else if (module === 'quote-list') {
                            myAccountExcelTrack(true, 4);
                          } else if (module === 'confirm-list') {
                            myAccountExcelMemo(true, { status: 1 });
                          } else if (module === 'my-purchase') {
                            myAccountExcelMemo(true, { status: 2 });
                          } else if (module === 'office-list') {
                            myAccountExcelOffice(true);
                          } else if (module === 'hold') {
                            myHoldExcelTrack(1);
                          } else if (module === 'my-enquiry') {
                            myAccountExcelTrack(true, 6);
                          }
                        }
                      } else if (isEmpty(checked) && getPath() === LISTINGPAGES.STONE_OF_DAY) {
                        bestBuyExcelTrack(true);
                      } else {
                        handleDownloadExcel(
                          true,
                          getPath() === LISTINGPAGES.NEWARR
                            ? { viewType: 2 }
                            : props.seachFilterTag
                            ? props.seachFilterTag.searchData
                            : {},
                          checkedRowIds,
                          () => {},
                        );
                      }
                      setExcelPopup(false);
                      void clearAll?.();
                    }}
                    className="active"
                  >
                    <div className="dropdownBottomItem">
                      <span className="shareOptionLabel">With Summary</span>
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      if (path === 'account') {
                        if (checked.length > 0) {
                          if (module === 'confirm-list' || module === 'my-purchase') {
                            handleDownloadExcelTrack(
                              false,
                              {},
                              checkedRowIds, ////memoDetail.id
                              () => {},
                            );
                          } else {
                            handleDownloadExcel(false, {}, checkedRowIds, () => {});
                          }
                        } else {
                          if (module === 'watchlist') {
                            myAccountExcelTrack(false, 2);
                          } else if (module === 'cart') {
                            myAccountExcelTrack(false, 1);
                          } else if (module === 'quote-list') {
                            myAccountExcelTrack(false, 4);
                          } else if (module === 'confirm-list') {
                            myAccountExcelMemo(false, { status: 1 });
                          } else if (module === 'my-purchase') {
                            myAccountExcelMemo(false, { status: 2 });
                          } else if (module === 'office-list') {
                            myAccountExcelOffice(false);
                          } else if (module === 'hold') {
                            myHoldExcelTrack(1);
                          } else if (module === 'my-enquiry') {
                            myAccountExcelTrack(false, 6);
                          }
                        }
                      } else if (isEmpty(checked) && getPath() === LISTINGPAGES.STONE_OF_DAY) {
                        bestBuyExcelTrack(false);
                      } else {
                        handleDownloadExcel(
                          false,
                          getPath() === LISTINGPAGES.NEWARR
                            ? { viewType: 2 }
                            : props.seachFilterTag
                            ? props.seachFilterTag.searchData
                            : {},
                          checkedRowIds,
                          () => {},
                        );
                      }
                      setExcelPopup(false);
                      void clearAll?.();
                    }}
                    className="active"
                  >
                    <div className="dropdownBottomItem">
                      <span className="shareOptionLabel">Without Summary</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
      {showGuest && (
        <GuestUserPopup
          visible={showGuest}
          register={() => {
            window.open('/signup', '_self');
            window.localStorage.clear();
            window.sessionStorage.clear();
          }}
          handleCancel={() => setShowGuest(false)}
        />
      )}
      {noKyc && <NoKycPop visible={noKyc} handleCancel={() => setNoKyc(false)} />}
    </>
  );
};

export default DiamondListingAction;
