import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tooltip, Input } from 'antd';
import _ from 'lodash';
import groupBy from 'lodash/groupBy';
import size from 'lodash/size';

import { getLabLink } from 'components/DiamondList/table-utils';

import { hasKey, isString, queryStringToObject } from 'util/utils';

import BasicDetail from './BasicDetail';
import DiamondDetailAction from './DiamondDetailAction';
import EnquiryDetail from './EnquiryDetail';
import DiamondDetailLeft from './LeftSection';
import MobileDiamondDetail from './MobileDiamondDetail';
import OfferOrder from './OfferOrder';
import PlaceOrderDetail from './PlaceOrderDetail';

import { trackTypeObj, LOCAL_STORAGE_VAR, KYC_STATUS, FILE_URLS, LAB_LINKS } from '../../constants/Common';
import DetailViewAuth from '../../constants/DiamAuth';
import DetailView from '../../constants/DiamondDetail';
import { VIEW_DIAMOND } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import {
  handleInsertTrack,
  downloadZip,
  isMobile,
  LISTINGPAGES,
  downloadVideo,
  downloadFile,
} from '../DiamondList/DiamondListFunctions';

import { floatkeys, roundkeys } from '../DiamondList/TableBack';
import OpenNotification from '../common/CommonButton/OpenNotification';
import CommonModal from '../common/CommonModal';
import DownloadDropdown from '../common/DiamondListing/DownloadDropdown';
import SendEmail from '../common/DiamondListing/SendEmail';
import { getGridHeight } from '../DiamondList';
import ShareDropdown from '../common/DiamondListing/ShareDropdown';
import IntlMessages from '../../util/IntlMessages';

const { Search } = Input;
let gridHeight = 0;
export function setGridHeightFn() {
  const allContainers = {};
  let totalHeight = window.innerHeight - 10;
  // allContainers.headerHeight = document.getElementsByClassName("headerTopMenu")[0];
  // allContainers.covidStrip = document.getElementsByClassName("covidStrip")[0];
  allContainers.HeaderStripBlock = document.getElementsByClassName('HeaderStripBlock')[0];
  allContainers.diamondDetailBottomBox = document.getElementsByClassName('diamondDetailBottomBox ')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
let gridHeight1 = 0;
export function setGridHeightFn1() {
  const allContainers = {};
  let totalHeight = window.innerHeight - 15;
  allContainers.headerHeight = document.getElementsByClassName('headerTopMenu')[0];
  allContainers.covidStrip = document.getElementsByClassName('covidStrip')[0];
  allContainers.HeaderStripBlock = document.getElementsByClassName('HeaderStripBlock ')[0];
  allContainers.diamondDetailTopTab = document.getElementsByClassName('diamondDetailTopTab')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
class DiamondDetail extends Component {
  get query() {
    return queryStringToObject();
  }

  state = {
    visible: false,
    show: false,
    detail: true,
    data: {},
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    sendEmail: false,
    gridHeight: 0,
    offer: false,
    visibleEmail: false,
    diamondId: '',
    activeTab: '1',
    currentSubTab: 0,
  };
  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) {
      this.forceUpdate();
    }
  }
  placeOrderDetail = () => {
    const self = this;
    self.setState({
      visible: !self.state.visible,
      detail: false,
      show: false,
      offer: false,
    });
  };
  EnquiryDetail = () => {
    this.setState({
      show: !this.state.show,
      detail: false,
      visible: false,
    });
  };
  popupClose = () => {
    this.setState({
      detail: true,
      visible: false,
      show: false,
      offer: false,
    });
  };

  componentDidMount = () => {
    const id = _.last(_.split(window.location.pathname, '/'));

    if (id !== 'diamond-detail') {
      const self = this;
      self.setState({ diamondId: id });
      const api = `${VIEW_DIAMOND.url}/${this.props.data ? this.props.data.id : id}`;
      const objData = {
        method: VIEW_DIAMOND.method,
        url: api,
      };
      UtilService.callApi(objData, async (err, data) => {
        if (err) throw err;
        if (data && data.code === 'OK') {
          self.setState({ data: data.data });
        }
      });
    } else {
      this.setState({ diamondId: '' });
    }
  };
  diamondDetail = (data, key) => {
    const grouped = groupBy(data, key);
    let first = grouped[1] || [];
    const second = grouped[2] || [];
    if ((!first || !first.length) && (!second || !second.length)) {
      first = Object.values(grouped)[0];
    }
    const maxIndex = Math.max(first.length, second.length);
    const arr = [];
    for (let i = 0; i < maxIndex; i++) {
      first[i] && arr.push(first[i]);
      second[i] && arr.push(second[i]);
    }
    return arr;
  };
  leftPart = (data) => {
    const gridHeight1 = getGridHeight(['headerTopMenu', 'covidStrip', 'HeaderStripBlock', 'diamondDetailTopTab'], 15);
    return (
      <div className="diamondDetailInnerDetail">
        {data && size(data) ? (
          <DiamondDetailLeft
            data={data}
            shared={this.state.shared}
            rptNo={data.rptNo}
            pktNo={data.vStnId}
            style={
              isMobile() ? {} : !this.props.diamondPopup ? { height: gridHeight1, overflow: 'auto' } : { height: '' }
            }
            activeTabChange={(tab) => this.setState({ activeTab: tab })}
            currentSubTab={(prev, current) => {
              this.setState({ currentSubTab: current });
            }}
          />
        ) : null}
      </div>
    );
  };
  getEmailModal = (data) => {
    return (
      <SendEmail
        path={`${window.location.origin}/${LISTINGPAGES.DETAIL}/${data.id}`}
        xRay={true}
        sendEmail={this.state.sendEmail}
        onCancel={() => this.setState({ sendEmail: false })}
        onDetailClose={() => this.setState({ sendEmail: false })}
      />
    );
  };
  handleCopyLink = () => {
    const { activeTab, currentSubTab } = this.state;
    const { data } = this.props.data ? this.props : this.state;

    let value = ``;

    if (activeTab === '1') {
      value = FILE_URLS.videoFile.split('***').join(data.rptNo);
    } else if (activeTab === '2') {
      value =
        // currentSubTab === 0 ?
        FILE_URLS.img.replace('***', data.rptNo);
      // : FILE_URLS.lightBlack.replace("***", data.mfgStnId);
    } else if (activeTab === '3') {
      value =
        currentSubTab === 0
          ? FILE_URLS.hAFile.replace('***', data.mfgStnId)
          : FILE_URLS.arrowFile.replace('***', data.mfgStnId);
    } else if (activeTab === '4') {
      value = FILE_URLS.certFile.replace?.(/(?:\*\*\*){1}/gm, data.rptNo);
    }

    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    return OpenNotification({
      type: 'success',
      title: 'Link Copied.',
    });
  };

  handleDownload = () => {
    const { activeTab, data, currentSubTab } = this.state;
    // let { data } = this.props.data ? this.props : this.state;
    if (activeTab === '1') {
      const obj = {
        path: FILE_URLS.mp4Video.split('***').join(data.mfgStnId),
        fileName: `video-${data.mfgStnId}`,
        ext: '.mp4',
        id: data.id,
      };
      downloadVideo(obj);
    } else if (activeTab === '2') {
      const obj = {
        path:
          // currentSubTab === 0 ?
          FILE_URLS.img.replace('***', data.rptNo),
        // : FILE_URLS.lightBlack.replace("***", data.mfgStnId),
        fileName: currentSubTab === 0 && `still-${data.mfgStnId}`,
        // : `light_black-${data.mfgStnId}`,
        // fileName: `still-${data.mfgStnId}`,
        ext: '.jpg',
      };
      downloadFile(obj);
    } else if (activeTab === '3') {
      const obj = {
        path:
          currentSubTab === 0
            ? FILE_URLS.arrowFile.replace('***', data.mfgStnId)
            : FILE_URLS.hAFile.replace('***', data.mfgStnId),
        fileName: currentSubTab === 0 ? `Arrow-${data.mfgStnId}` : `hA-${data.mfgStnId}`,
        ext: '.jpg',
      };
      downloadFile(obj);
    } else if (activeTab === '4') {
      const obj = {
        path: FILE_URLS.certFile.replace?.(/(?:\*\*\*){1}/gm, data.rptNo),
        fileName: `certi-${data.rptNo}`,
        ext: '.pdf',
      };
      downloadFile(obj);
    }
  };

  render() {
    const { visibleEmail } = this.state;
    const { data } = this.props.data ? this.props : this.state;
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    let view = [];
    if (userData && userData.account && userData.account.isApproved === KYC_STATUS.APPROVED) {
      view = DetailView;
    } else {
      view = DetailViewAuth;
    }
    let message = 'Functionality is disabled for sometime.';
    if (userData && userData.account && !userData.account.isKycUploaded) {
      message = 'Please complete your KYC procedure to access the functionality.';
    } else if (userData && userData.account && userData.account.isApproved !== KYC_STATUS.APPROVED) {
      message = 'Please wait to access the functionality untill your KYC documents get verify.';

      // KYC under process
    } else if (userData && userData.account && userData.account.isApproved === KYC_STATUS.APPROVED) {
      message = 'Functionality is disabled for sometime.';

      // disabled
    } else {
      message = 'Register yourself to access the functionality.';

      // Guest user
    }

    gridHeight = setGridHeightFn();
    gridHeight1 = setGridHeightFn1();
    const params = Object.keys(view).map((k) => (
      <div className="diamondDetailMore" key={view[k]['name']}>
        <h2 className="detailSmallTitle">{view[k]['name']}</h2>
        <div className="diamondDetailMoreHalf">
          {this.diamondDetail(view[k]['data'], 'part').map((item) => {
            if (data.wSts === 'U' && ['back', 'ctPr', 'amt'].includes(item.key)) return false;
            return (
              <ul key={item.key}>
                <li>
                  {!item.hide && (
                    <>
                      <span>{item.label}</span>
                      <span>
                        {!data[item['key']]
                          ? '-'
                          : floatkeys.includes(item.key)
                          ? parseFloat(data[item['key']])
                          : roundkeys.includes(item.key)
                          ? Math.round(data[item['key']])
                          : data[item['key']]}
                      </span>
                    </>
                  )}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    ));
    if (isMobile()) return <MobileDiamondDetail params={params} {...this.state} parent={this} />;
    return (
      <div className="diamondDetailWrapper">
        <div className="diamondDetailInner">
          {this.props.diamondPopup && (
            <div className="diamondDetailBottomBox">
              <div className="diamondDetailBottomAction">
                <div className="d-flex">
                  {!this.props.noDetailAction && !this.state.isGuest && (
                    <>
                      <DiamondDetailAction
                        image={require('../../assets/svg/DiamondDetail/cart.svg')}
                        tooltip="Place Order"
                        placement="bottomLeft"
                        onClick={() => {
                          const self = this;
                          if (USER_PERMIT?.CONFIRM_STONE?.insert) {
                            self.setState({
                              visible: true,

                              detail: false,
                              show: false,
                              offer: false,
                            });
                            // self.placeOrderDetail.bind(this)
                          } else {
                            OpenNotification({
                              type: 'error',
                              title: message,
                            });
                          }
                        }}
                      />
                      {/* <DiamondDetailAction
                  image={require("../../assets/svg/DiamondDetail/enquiry.svg")}
                  tooltip="Enquiry"
                  placement="bottomLeft"
                  onClick={permission && permission.insert ? this.EnquiryDetail.bind(this) : ''}
                /> */}
                      <DiamondDetailAction
                        image={require('../../assets/svg/DiamondDetail/add-cart.svg')}
                        tooltip="Add To Cart"
                        placement="bottomLeft"
                        onClick={() => {
                          if (USER_PERMIT?.CART?.insert) {
                            handleInsertTrack(trackTypeObj.CART, [data], () => {});
                          } else {
                            OpenNotification({
                              type: 'error',
                              title: message,
                            });
                          }
                        }}
                      />
                      <DiamondDetailAction
                        image={require('../../assets/svg/DiamondDetail/watchDetail.svg')}
                        tooltip="Add To Watchlist"
                        placement="bottomLeft"
                        onClick={() => {
                          if (USER_PERMIT?.WATCHLIST?.insert) {
                            handleInsertTrack(trackTypeObj.WATCHLIST, [data], () => {});
                          } else {
                            OpenNotification({
                              type: 'error',
                              title: message,
                            });
                          }
                        }}
                      />
                      {/* <DiamondDetailAction
                        image={require('../../assets/svg/DiamondDetail/offer.svg')}
                        tooltip="Apply Offer"
                        placement="bottomLeft"
                        onClick={() => {
                          if (USER_PERMIT?.QUOTE?.insert) {
                            this.setState({
                              offer: true,
                              visible: false,
                              detail: false,
                              show: false,
                            });
                          } else {
                            OpenNotification({
                              type: 'error',
                              title: message,
                            });
                          }
                        }}
                      /> */}
                    </>
                  )}
                  {this.state.isGuest && (
                    <DiamondDetailAction
                      word="Register"
                      // image={require("../../assets/svg/DiamondDetail/enquiry.svg")}
                      tooltip="Register"
                      placement="bottomLeft"
                      onClick={() => {
                        this.props.history.push('/signup');
                        window.localStorage.clear();
                        window.sessionStorage.clear();
                      }}
                    />
                  )}
                  {!this.state.isGuest && (
                    <>
                      <DiamondDetailAction
                        onClick={() => {
                          // if (data.lbNm && data.rptNo) {
                          //   const lab = data?.lbNm?.toUpperCase?.();
                          //   const reportNo = data?.rptNo;
                          //   window.open(LAB_LINKS?.[lab]?.replace?.(/(?:\*\*\*){1}/gm, reportNo));
                          // }
                          const link = getLabLink(this.props.data);
                          if (isString(link)) window.open(link);
                        }}
                        // Link={`https://gia.edu/report-check?reportno=${data.rptNo}`}
                        image={require('../../assets/svg/DiamondDetail/certificate.svg')}
                        tooltip="Verify Certificate"
                        placement="bottomLeft"
                      />

                      <DiamondDetailAction
                        onClick={() => {
                          if (USER_PERMIT?.SHARE_VIA_MAIL?.mailExcel) {
                            this.setState({ sendEmail: !this.state.sendEmail });
                          } else {
                            OpenNotification({
                              type: 'error',
                              title: message,
                            });
                          }
                        }}
                        image={require('../../assets/svg/DiamondDetail/x-ray.svg')}
                        tooltip="Share"
                        placement="bottomLeft"
                      />

                      <DiamondDetailAction
                        onClick={() => {
                          if (USER_PERMIT?.DOWNLOAD?.downloadExcel) {
                            this.setState({ visibleEmail: true });
                            // downloadZip(data.id, () => this.props.onClose())
                          } else {
                            OpenNotification({
                              type: 'error',
                              title: message,
                            });
                          }
                        }}
                        image={require('../../assets/svg/DiamondDetail/download-zip.svg')}
                        tooltip="Download Zip"
                        placement="bottomLeft"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="diamondDetailMainTab">
            {this.props.diamondPopup && (
              <>
                <div className="detailHalfWhite"></div>
                <div className="detailHalfGray"></div>
              </>
            )}
            <div className="diamondDetailLeft">
              <div className="diamondDetailInnerDetail">
                <DiamondDetailLeft
                  data={data}
                  style={!this.props.diamondPopup ? { height: gridHeight1, overflow: 'auto' } : { height: '' }}
                  activeTabChange={(tab) => this.setState({ activeTab: tab })}
                  currentSubTab={(prev, current) => this.setState({ currentSubTab: current })}
                />
                {this.state.activeTab === '4' ? (
                  <div className="d-flex j-content-center">
                    <div
                      className="diamondDetailSperateBlock commonOutline"
                      onClick={() => {
                        this.handleCopyLink();
                      }}
                    >
                      <img className="defualtImg" src={require('../../assets/svg/copylink-theme.svg')} alt="" />
                      <img className="hoverImg" src={require('../../assets/svg/copylink-white.svg')} alt="" />
                      <span>Copy Link</span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex j-content-center">
                    <div className="diamondDetailSperateBlock commonOutline" onClick={() => this.handleDownload()}>
                      <img className="defualtImg" src={require('../../assets/svg/download-theme.svg')} alt="" />
                      <img className="hoverImg" src={require('../../assets/svg/download-white.svg')} alt="" />
                      <span>Download</span>
                    </div>
                    <div
                      className="diamondDetailSperateBlock commonOutline"
                      onClick={() => {
                        this.handleCopyLink();
                      }}
                    >
                      <img className="defualtImg" src={require('../../assets/svg/copylink-theme.svg')} alt="" />
                      <img className="hoverImg" src={require('../../assets/svg/copylink-white.svg')} alt="" />
                      <span>Copy Link</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={`diamondDetailRight ${!this.props.diamondPopup && 'diamondDetailPage'}`}>
              {!this.props.diamondPopup && (
                <div className="diamondDetailBottomBox">
                  <div className="diamondDetailBottomAction">
                    <div className="d-flex">
                      {userData && userData.account ? (
                        !hasKey(this.query, 'shared') ? (
                          <>
                            {!this.props.noDetailAction && (
                              <>
                                <DiamondDetailAction
                                  image={require('../../assets/svg/DiamondDetail/cart.svg')}
                                  tooltip="Place Order"
                                  placement="bottomLeft"
                                  onClick={() => {
                                    const self = this;
                                    if (USER_PERMIT?.CONFIRM_STONE?.insert) {
                                      self.setState({
                                        visible: true,

                                        detail: false,
                                        show: false,
                                        offer: false,
                                      });
                                      // self.placeOrderDetail.bind(this)
                                    } else {
                                      OpenNotification({
                                        type: 'error',
                                        title: message,
                                      });
                                    }
                                  }}
                                />

                                <DiamondDetailAction
                                  image={require('../../assets/svg/DiamondDetail/add-cart.svg')}
                                  tooltip="Add To Cart"
                                  placement="bottomLeft"
                                  onClick={() => {
                                    if (USER_PERMIT?.CART?.insert) {
                                      handleInsertTrack(trackTypeObj.CART, [data], () => {});
                                    } else {
                                      OpenNotification({
                                        type: 'error',
                                        title: message,
                                      });
                                    }
                                  }}
                                />
                                <DiamondDetailAction
                                  image={require('../../assets/svg/DiamondDetail/watchDetail.svg')}
                                  tooltip="Add To Watchlist"
                                  placement="bottomLeft"
                                  onClick={() => {
                                    if (USER_PERMIT?.WATCHLIST?.insert) {
                                      handleInsertTrack(trackTypeObj.WATCHLIST, [data], () => {});
                                    } else {
                                      OpenNotification({
                                        type: 'error',
                                        title: message,
                                      });
                                    }
                                  }}
                                />
                                {/* <DiamondDetailAction
                                image={require('../../assets/svg/DiamondDetail/offer.svg')}
                                tooltip="Apply Offer"
                                placement="bottomLeft"
                                onClick={() => {
                                  if (USER_PERMIT?.QUOTE?.insert) {
                                    this.setState({
                                      offer: true,
                                      visible: false,
                                      detail: false,
                                      show: false,
                                    });
                                  } else {
                                    OpenNotification({
                                      type: 'error',
                                      title: message,
                                    });
                                  }
                                }}
                              /> */}
                              </>
                            )}

                            <DiamondDetailAction
                              onClick={() => {
                                const link = getLabLink(this.props.data);
                                if (isString(link)) window.open(link);
                              }}
                              // Link={`https://gia.edu/report-check?reportno=${data.rptNo}`}
                              image={require('../../assets/svg/DiamondDetail/certificate.svg')}
                              tooltip="Verify Certificate"
                              placement="bottomLeft"
                            />

                            <DiamondDetailAction
                              onClick={() => {
                                if (USER_PERMIT?.SHARE_VIA_MAIL?.mailExcel) {
                                  this.setState({
                                    sendEmail: !this.state.sendEmail,
                                  });
                                } else {
                                  OpenNotification({
                                    type: 'error',
                                    title: message,
                                  });
                                }
                              }}
                              image={require('../../assets/svg/DiamondDetail/x-ray.svg')}
                              tooltip="Share"
                              placement="bottomLeft"
                            />

                            <DiamondDetailAction
                              onClick={() => {
                                if (USER_PERMIT?.DOWNLOAD?.downloadExcel) {
                                  this.setState({ visibleEmail: true });
                                  // downloadZip(data.id, () => this.props.onClose())
                                } else {
                                  OpenNotification({
                                    type: 'error',
                                    title: message,
                                  });
                                }
                              }}
                              image={require('../../assets/svg/DiamondDetail/download-zip.svg')}
                              tooltip="Download Zip"
                              placement="bottomLeft"
                            />
                          </>
                        ) : (
                          <>
                            <DiamondDetailAction
                              onClick={() => {
                                if (USER_PERMIT?.SHARE_VIA_MAIL?.mailExcel) {
                                  this.setState({
                                    sendEmail: !this.state.sendEmail,
                                  });
                                } else {
                                  OpenNotification({
                                    type: 'error',
                                    title: message,
                                  });
                                }
                              }}
                              image={require('../../assets/svg/DiamondDetail/x-ray.svg')}
                              tooltip="Share"
                              placement="bottomLeft"
                            />

                            <DiamondDetailAction
                              onClick={() => {
                                if (USER_PERMIT?.DOWNLOAD?.downloadExcel) {
                                  this.setState({ visibleEmail: true });
                                  // downloadZip(data.id, () => this.props.onClose())
                                } else {
                                  OpenNotification({
                                    type: 'error',
                                    title: message,
                                  });
                                }
                              }}
                              image={require('../../assets/svg/DiamondDetail/download-zip.svg')}
                              tooltip="Download Zip"
                              placement="bottomLeft"
                            />
                          </>
                        )
                      ) : (
                        <>
                          {window.location.search === '?direct-share' && (
                            <DiamondDetailAction
                              word="Buy Now"
                              // image={require("../../assets/svg/DiamondDetail/cart.svg")}
                              tooltip="Buy Now"
                              placement="bottomLeft"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: '/quick-signup',
                                  state: { id: this.state.diamondId },
                                });
                              }}
                            />
                          )}

                          <DiamondDetailAction
                            word="Signin"
                            // image={require("../../assets/svg/DiamondDetail/cart.svg")}
                            tooltip="Signin"
                            placement="bottomLeft"
                            onClick={() => {
                              this.props.history.push('/signin');
                            }}
                          />
                          <DiamondDetailAction
                            word="Register"
                            // image={require("../../assets/svg/DiamondDetail/enquiry.svg")}
                            tooltip="Register"
                            placement="bottomLeft"
                            onClick={() => {
                              this.props.history.push('/signup');
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.show && (
                <EnquiryDetail
                  style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                  checked={[data]}
                  onCancel={() => this.popupClose()}
                  onClose={() => this.props.onClose()}
                />
              )}
              {this.state.visible && (
                <PlaceOrderDetail
                  style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                  checked={[data]}
                  onCancel={() => this.popupClose()}
                  onClose={() => this.props.onClose()}
                />
              )}
              {this.state.offer && (
                <OfferOrder
                  style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                  checked={[data]}
                  onCancel={() => this.popupClose()}
                  onClose={() => this.popupClose()}
                  clearAll={() => this.props.onClose()}
                />
              )}
              {this.state.detail && (
                <div
                  className="mt-10"
                  style={!this.props.diamondPopup ? { height: gridHeight, overflow: 'auto' } : { height: 'auto' }}
                >
                  {/* {this.props.diamondPopup && <BasicDetail data={data} />} */}
                  {_.keys(view).map((k) => (
                    <div className="diamondDetailMore" key={k}>
                      <h2 className="detailSmallTitle">{DetailView[k]['name']}</h2>
                      <div className="diamondDetailMoreHalf">
                        {_.keys(_.groupBy(DetailView[k]['data'], 'part')).map((k1) => {
                          const source = _.groupBy(DetailView[k]['data'], 'part');
                          return source[k1].map((k2) => {
                            return (
                              <ul key={k2}>
                                <li>
                                  <span>{k2.label}</span>
                                  <span>
                                    {k2['key'] === 'lbCmt'
                                      ? (data[k2['key']] = data[k2['key']]?.replace('<br/>', ''))
                                      : k2['key'] === 'lsrInc'
                                      ? (data[k2['key']] = data[k2['key']]?.replace('<br>', ''))
                                      : k2['isFormat']
                                      ? parseFloat(data[k2['key']])
                                      : data[k2['key']]
                                      ? data[k2['key']]
                                      : '-'}
                                    {/* {k2["isFormat"]
                                        ? parseFloat(data[k2["key"]])
                                        : data[k2["key"]]
                                        ? data[k2["key"]]
                                        : "-"} */}
                                  </span>
                                </li>
                              </ul>
                            );
                          });
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <CommonModal
          title={'Share by'}
          handleCancel={() => {
            this.setState({ sendEmail: false });
          }}
          visible={this.state.sendEmail}
          wrapClassName="diamondListinSidebar"
        >
          <ShareDropdown
            {...this.props}
            checked={[data]}
            onCloseSharePopup={() => {
              this.setState({ sendEmail: false });
            }}
          />
        </CommonModal>

        <CommonModal
          title={<IntlMessages id="app.Download" />}
          handleCancel={() => {
            this.setState({ visibleEmail: false });
          }}
          visible={visibleEmail}
          wrapClassName=""
        >
          <DownloadDropdown
            onCloseDownloadPopup={() => {
              this.setState({ visibleEmail: false });
            }}
            checked={[data]}
            {...this.props}
          />
        </CommonModal>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    commonData: state.commonData,
  }),
  {},
)(withRouter(DiamondDetail));
