import React, { useState, useEffect, useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import { calculate, formatNumber } from './SelectStone';
import {
  handleDownloadExcel,
  getColumn,
  handleConfirmStone,
  isMobile,
  getPath,
  LISTINGPAGES,
  getProjectSetting,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import IntlMessages from '../../../util/IntlMessages';
import {
  LOCAL_STORAGE_VAR,
  DIAMOND_BID,
  PROJECT_SETTINGS_TYPE,
  SELECT_STONE_TERMS_NEW,
} from '../../../constants/Common';
import CheckBox from '../CheckBox';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getBidType } from '../../NewArrivalBidIt';
import SendEmailPopup from './SendEmail';
import OpenNotification from '../CommonButton/OpenNotification';
import SelectOption from '../SelectOption';
import FinalCalcMobile from './FinalCalcMobile';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const currentType = 'FinalCalculation';
const FMTERMS = [
  { id: 'COD', name: 'COD Fixed', charge: 1 },
  { id: 'THIRTY', name: '30 Days Fixed', charge: 1.01 },
  { id: 'SIXTY', name: '60 Days Fixed', charge: 1.02 },
];

export function getExtraPer() {
  let terms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`))
    : {};
  let extraPer = terms && terms.extraPer ? terms.extraPer : 0;
  if (extraPer < 0) extraPer = extraPer * -1;
  return extraPer;
}

export function newDiamondPrice(diamond, qt = false) {
  let extraPer = getExtraPer();
  let quotePer = qt === true ? diamond.finalquote * -1 : 0;
  let bidPer = (qt === 'bid' || diamond.isBid) && getBidType() === DIAMOND_BID.TYPE.BLIND ? 0.5 : 0;
  let pricePerCarat =
    qt === 'bid' ? diamond.bidPricePerCarat : qt === true ? diamond.rap - (diamond.rap * quotePer) / 100 : diamond.ctPr;
  if (qt && typeof qt === 'string' && qt.includes('charge') && diamond.fmCharge) {
    extraPer = 0;
    let selectedFM = qt.slice(6, qt.length);
    let newFMamt = pricePerCarat * diamond.crt + diamond.fmCharge;
    let FMcharge = newFMamt * find(FMTERMS, { id: selectedFM }).charge;
    pricePerCarat = FMcharge / diamond.crt;
  }
  if (bidPer) pricePerCarat = pricePerCarat - pricePerCarat * bidPer;
  diamond.calcPricePerCarat = pricePerCarat - (pricePerCarat * extraPer) / 100;
  diamond.calcDiscount = (1 - diamond.calcPricePerCarat / diamond.rap) * 100 * -1;
  diamond.calcAmount = diamond.calcPricePerCarat * diamond.crt;
  return diamond;
}

const FinalCalculations = (props) => {
  const [columns, setColumns] = useState([]);
  const [termCal, setTermCal] = useState('0');

  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [cm, setCm] = useState(false);
  const [fm, setFm] = useState('COD');
  const [settings, setSettings] = useState([]);
  const [email, sendEmail] = useState('');

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      let grp = groupBy(settings, 'shp');
      let shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      let carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  let newdata = props.checked;
  newdata = newdata.map((n) => ({
    ...n,
    fmCharge: n.isFm === 'ELIG' ? getFMCharge(n) : 0,
    isBid: getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID,
  }));
  newdata = newdata.map((x) => newDiamondPrice(x, 'charge' + fm));

  useEffect(() => {
    getProjectSetting(
      props.checked[0].isFm === 'ELIG' ? PROJECT_SETTINGS_TYPE.FM_CHARGES : PROJECT_SETTINGS_TYPE.CM_CHARGES,
      setSettings,
    );
  }, []);

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      props.checked[0].isFm === 'ELIG'
        ? {
            Header: 'FM Charges',
            accessor: 'fmCharge',
            id: 'fmCharge',
            Cell: ({ cell }) => <>{parseFloat(cell.value)}</>,
          }
        : null,
      {
        Header:
          'Final Disc(' +
          parseFloat(getExtraPer()) +
          ((getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID) &&
          getBidType() === DIAMOND_BID.TYPE.BLIND
            ? '+0.5'
            : '') +
          '%)',
        accessor: 'fnBack',
        id: 'fnBack',
        Cell: ({ row }) => <>{parseFloat(row.original.calcDiscount)}</>,
      },
      {
        Header: 'Final Rate',
        accessor: 'fnCtpr',
        id: 'fnCtpr',
        Cell: ({ row }) => <>{parseFloat(row.original.calcPricePerCarat)}</>,
      },
      {
        Header: 'Final Value',
        accessor: 'fnAmt',
        id: 'fnAmt',
        Cell: ({ row }) => <>{parseFloat(row.original.calcAmount)}</>,
      },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));
  }, []);

  const getCmCharge = (arr) => {
    let charge = 0;
    arr.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        let found = settings.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    return charge;
  };

  const getTerms = () => {
    return (
      <div>
        <select
          name="termCal"
          value={termCal}
          placeholder="Select Terms"
          onChange={(e) => {
            // let s = calculate(checked, 2)
            setTermCal(e.target.value);
          }}
        >
          {/* <option value='00' disabled>Select Terms</option> */}

          {SELECT_STONE_TERMS_NEW.map((x) => (
            <option value={x.id}>{x.name}</option>
          ))}
        </select>
      </div>
    );
  };

  let newPricedRows = checked; //.map(x => newDiamondPrice(x, 'charge' + fm))
  // let summation = calculate(newPricedRows);
  let summation = useMemo(() => calculate(newPricedRows, termCal), [checked, termCal]);

  let totalCarat = parseFloat(summation.total_carat);
  let netValue = '$' + formatNumber(parseFloat(summation.final_value));
  let avgDisc = parseFloat(summation.final_discount);
  let addDis = summation.addDis;

  let finalAvgDisc = parseFloat(summation.final_term_discount);
  let finalNetRate = '$' + formatNumber(parseFloat(summation.final_rate));
  let finalNetValue = '$' + formatNumber(parseFloat(summation.final_net_value));
  let CmCharge = parseFloat(getCmCharge(newPricedRows));
  let terms = getTerms();

  let VALUES = isMobile()
    ? {
        'CT.:': totalCarat,
        'Final Disc%:': finalAvgDisc,
        'Amount:': netValue,
        'Final Rate:': finalNetRate,
        'Disc%:': avgDisc,
        'Add Disc%:': addDis,

        'Final Value:': finalNetValue,
        'TERMS:': terms,
      }
    : [
        { title: 'Total Carats', value: totalCarat },
        { title: 'Final Net Rate', value: finalNetRate },
        { title: 'Avg. Discount', value: avgDisc },
        { title: 'Add Discount%', value: addDis },

        { title: 'Final Avg Discount', value: finalAvgDisc },
        { title: 'Net Value', value: netValue },
        {
          title: 'Final Net Value',
          value: finalNetValue + (cm && CmCharge > 0 ? ' + $' + CmCharge + ' (CM Charges)' : ''),
        },
        {
          title: 'TERMS',
          value: terms,
        },
      ];
  let user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };
  const submit = () => {
    if (!checkCheck()) return;
    handleConfirmStone(
      checked.map((x) => x.id),
      '',
      // moment().toISOString(),
      // user && user.account ? user.account.companyName : "",
      (flag) => {
        props.onClose();
        props.clearAll();
        if (flag && props.fetch) props.fetch();
      },
    );
  };

  return isMobile() ? (
    <FinalCalcMobile
      data={newdata}
      VALUES={VALUES}
      currentType={currentType}
      parent={{ submit, checkCheck, onClose: props.onClose }}
    />
  ) : (
    <div>
      <h2 className="popupInnerTitle">
        <IntlMessages id="app.FinalCalculation" />
      </h2>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll">
          <Table
            data={newdata}
            columns={columns}
            areAllChecked={true}
            nodots
            noGrp
            currentType={currentType}
            canSort={false}
          />
        </div>
        <div className="mt-10 finalCalDetail">
          {/* {props.checked[0].isFm === "ELIG" ? (
            <SelectOption
              className="finalFMSelect"
              selectValue={FMTERMS}
              label="FM Terms*"
              value={fm}
              onChange={(e) => setFm(e)}
            />
          ) : (
            <CheckBox
              label="CM Charge"
              checked={cm}
              onChange={() => setCm(!cm)}
            />
          )} */}
          <div className="DiamondDetailPopup mt-10">
            {VALUES.map((x) => (
              <div className="DiamondDetailPopupItem">
                <span>{x.title}:</span>
                <span>{x.value}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            Confirm Stone
          </a>
          <a className="commonButton" onClick={props.onClose}>
            Cancel Stone
          </a>
          <a
            className="commonButton"
            onClick={() =>
              handleDownloadExcel(
                true,
                {},
                checked.map((x) => x.id),
                () => {},
              )
            }
          >
            Excel Export
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (checked.length) sendEmail('stock');
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            Email Stock
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (checked.length) sendEmail('img');
              else
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
            }}
          >
            Email Pic
          </a>
        </div>
      </div>
      {email !== '' && (
        <SendEmailPopup
          sendEmail={email}
          onCancel={() => sendEmail('')}
          ids={checked.map((x) => x.id)}
          img={email === 'img'}
          isStock={email === 'stock'}
        />
      )}
    </div>
  );
};

export default FinalCalculations;
