import React from "react"

const MobileGroupCard = (props) => {
    return(
        <div className="mobileGroupingCard">
            <div className="mobileGroupingHead">{props.title}</div>
            <div className="mobileGroupingList">
                {props.children}
            </div>
        </div>
    )
}
export default MobileGroupCard;