import React, { Component } from 'react';
// import EventSlider from "./common/EventSlider";
import LoginForm from './Auth/LoginForm';
import Logo from './Header/Logo';
import Diamond from '../assets/svg/fm-diamond-bullet.svg';
import IntlMessages from '../util/IntlMessages';
// import SEO from "./SEO";
import GDPRCompliance from './Auth/GDPRCompliance';
import { Drawer } from 'antd';
import { LOCAL_STORAGE_VAR } from '../constants/Common';
import { Link } from 'react-router-dom';
import StaticHeader from './StaticHeader';

import topBannerPng from 'assets/img/StaticImg/TopBanner.jpeg';
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: true,
      visible: false,
    };
  }

  componentDidMount() {
    let showInfo = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-cookie-policy`));
    if (showInfo) {
      this.setState({ showInfo: false });
    }
  }
  GDPRClick = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  setCookie = () => {
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-cookie-policy`, true);
    this.setState({ showInfo: false });
  };
  render() {
    return (
      <div>
        <StaticHeader data="/signup" />
        <div className="adma-login-wrap login">
          <div className="adma-login-container">
            <div className="loginpage scroll-remove">
              <div className="adma-login-left">
                {/* <img src={require("../assets/img/StaticImg/GoGreenDiamondsLogo.png")} alt="" style={{width : '200px', height: '230px', margin: 'auto',} }/> */}
                <img src={topBannerPng} alt="" />

                {/* <div className="videoBG" style={{width : '100%', height: '100%', position: 'absolute', left:'0',bottom: '0',right: '0',top:'0',} }>
                <video autoPlay  style={{width : '100%', height: '100%', position: 'absolute'} }>
                  <source src={require("../assets/img/green-video.mp4")} />

                </video>
                </div> */}
              </div>
              <div className="adma-login-main-content">
                <div className="adma-app-login-content mobile-center">
                  <div className="adma-logo-content-bg">
                    {/* <h1>
                  <Logo />
                  </h1> */}
                    <h2 className="login-title">Sign in to your account</h2>
                  </div>
                  <LoginForm history={this.props.history} />
                </div>
              </div>
            </div>
          </div>
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.visible}
            wrapClassName="diamondListinSidebar"
          >
            <GDPRCompliance />
          </Drawer>
        </div>
      </div>
    );
  }
}

export default SignIn;
