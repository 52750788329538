import React from 'react'
import moment from 'moment'
import clone from 'lodash/clone'
import { DatePicker } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import OpenNotification from './CommonButton/OpenNotification'
import Heading from '../common/Heading'

let ip = 'blank'
function disabledDate(current) {
    return current && current < moment().startOf('day')
}

class UpdateReminder extends React.Component {
    handleOk = () => {
        if (ip === 'blank') return
        if (ip === '') ip = moment().toISOString()
        this.props.handleUpdateReminder(clone(ip))
        ip = ''
    }

    handleCancel = () => {
        this.props.handleCancel()
    }

    render() {
        let currentReminderTime = moment(this.props.date) || moment()

        return (
            <div className='modalBackground'>
                <Heading className='popupInnerTitle' title={<IntlMessages id='app.updatereminder' />} />
                <div className='d-flex align-items-center'>
                    <div className='leftSelectItem'>
                        <div className='leftSelectTitle'>
                            <h2>
                                <IntlMessages id='app.Date' />
                            </h2>
                        </div>
                        <DatePicker
                            dropdownClassName='datePickerDropdown'
                            className='SelectDateRange'
                            onChange={e => {
                                if (!e) {
                                    OpenNotification({
                                        type: 'error',
                                        title: 'Please select date.'
                                    })
                                    ip = 'blank'
                                    return
                                }
                                if (e._d <= moment()) {
                                    OpenNotification({
                                        type: 'error',
                                        title: 'Please select time for current date.'
                                    })
                                    ip = moment().toISOString()
                                }
                                ip = e._d.toISOString()
                            }}
                            format='ll'
                            disabledDate={disabledDate}
                            placeholderText={<IntlMessages id='app.selectdate' />}
                            defaultValue={currentReminderTime}
                            minTime={moment()}
                            showTime={{
                                use12Hours: true,
                                format: 'HH:mm a',
                                minTime: moment()
                            }}
                            style={{ paddingLeft: '20px' }}
                        />
                    </div>
                </div>
                <div className='sideBarPopupButton'>
                    <a className='commonButton' onClick={this.handleOk}>
                        <IntlMessages id='app.updatereminder' />
                    </a>
                    <a className='commonButton' onClick={this.handleCancel}>
                        <IntlMessages id='app.Cancel' />
                    </a>
                </div>
            </div>
        )
    }
}
export default UpdateReminder
