import React, { Component } from 'react';
import Heading from '../../common/Heading';
import UploadImage from '../../common/UploadImage/index';

class UpdateProfile extends Component {
  render() {
    return (
      <>
        <div style={{ marginBottom: '15px' }} className="d-flex j-content-center updateProfileItem">
          <UploadImage
            title={'Personal Identity Proof'}
            image={this.props.imageOne}
            fileApi={(file, name, one) => this.props.getKycImage(file, name, 1)}
          />
          <UploadImage
            title={'Business Identity Proof'}
            image={this.props.imageTwo}
            fileApi={(file, name, two) => this.props.getKycImage(file, name, 2)}
          />
        </div>
        {this.props.kyc && (
          <>
            <strong style={{ color: '#4eb45e' }}>Note :</strong>
            <br />

            <span>
              <img style={{ width: '15px', height: '15px' }} src={require('../../../assets/svg/Header/right.svg')} />

              <span>Photo Identification Proof(PAN Card or Passport).</span>
              <br />
              <br />
              <span>
                <img style={{ width: '15px', height: '15px' }} src={require('../../../assets/svg/Header/right.svg')} />
                Business Identification proof(GST or PAN Card or Business Registration Certificate).
              </span>
            </span>
          </>
        )}
      </>
    );
  }
}

export default UpdateProfile;
