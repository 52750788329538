import _, { isArray } from 'lodash';
import UtilService from '../services/util';
import OpenNotification from '../components/common/CommonButton/OpenNotification';
import {
  SEARCH_DIAMOND_LIST,
  SEARCH_LIST,
  CREATE_SAVED_SEARCH,
  CREATE_MATCH_PAIR,
  SELECT_SEARCH,
  CREATE_DEMAND,
} from '../constants/apiConstant';
import { isEmpty } from 'util/utils';

export const handleSearch = async (filter, newArr, cb) => {
  let apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    //else if (_.isArray(val) && _.isEmpty(val)) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  let obj = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  if (newArr) obj.viewType = 2;

  let objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      }
    } else if (data && data.code === 'OK') {
      if (data.data[0].count === 0) {
        cb({ NOSTONE: 'NOSTONE', id: data.data[0].filter.id });
      } else {
        cb({ NOSTONE: '', id: data.data[0].filter.id });
      }
    }
  });
};

export const getSearchList = (cb) => {
  let obj = {
    type: 2,
    isAppendMasters: true,
  };
  let api = SEARCH_LIST.url;
  let objData = {
    method: SEARCH_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(data.data.list);
    }
  });
};

export const handleSavedSearch = (filter, title, id, cb) => {
  let apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isEmpty(val)) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === '') {
    return OpenNotification({
      type: 'error',
      title: 'Please enter search title.',
    });
  }

  let obj = {
    filter: apiObj,
    name: title,
    searchType: 2,
    sort: [{ createdAt: 'DESC' }],
    id: id,
  };

  let api = CREATE_SAVED_SEARCH.url;
  let objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      OpenNotification({
        type: 'error',
        title: err.data.message || 'Something went wrong.',
      });
    } else if (data && data.code === 'OK') {
      cb(data.data.id || data.data[0].id);
    }
  });
};

export const handleMatchPair = (filter, cb) => {
  let apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isEmpty(val)) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });
  let obj = {
    filter: apiObj,
    page: 1,
    limit: 1000,
  };
  let api = CREATE_MATCH_PAIR.url;
  let objData = {
    method: CREATE_MATCH_PAIR.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err && err.data && err.data.message) {
      return OpenNotification({
        type: 'error',
        title: err.data.message,
      });
    } else if (data && data.code === 'OK') {
      cb(data.data.filter.id);
    }
  });
};

export const handleSelectSearch = (searchId, cb) => {
  let obj = {
    id: searchId,
    isAppendMasters: true,
  };
  let api = SELECT_SEARCH.url;
  let objData = {
    method: SELECT_SEARCH.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(data.data.list[0].searchData, data.data.list[0].name, data.data.list[0].type);
    }
  });
};

export const getCount = (filter, cb) => {
  _.each(filter, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete filter[key];
    //else if (_.isEmpty(val)) delete filter[key];
    else if (_.isArray(val)) filter[key] = _.uniq(val);
  });
  let obj = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: true,
    filters: [filter],
  };

  let api = SEARCH_DIAMOND_LIST.url;
  let objData = {
    method: SEARCH_DIAMOND_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) {
      cb(0);
    }
    if (data && data.code === 'OK') {
      cb(data.data[0].count);
    } else {
      cb(0);
    }
  });
};

export const handleAddDemand = (filter, title, date, cb) => {
  let apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isEmpty(val)) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === '') {
    return OpenNotification({
      type: 'error',
      title: 'Please enter demand title.',
    });
  }

  if (!date) {
    return OpenNotification({
      type: 'error',
      title: 'Please select date for adding demand.',
    });
  }

  let obj = {
    filter: apiObj,
    name: title,
    searchType: 3,
    expiryDate: date,
  };

  let api = CREATE_DEMAND.url;
  let objData = {
    method: CREATE_DEMAND.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      OpenNotification({
        type: 'error',
        title: err.data.message || 'Something went wrong.',
      });
    } else if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: 'Your demand is added successfully.',
      });
    }
  });
};

export const searchMultipleFilters = (_filters, cb) => {
  const filters = _filters.map((filter) => {
    const object = { ...filter };
    if (!isEmpty(filter.isFm)) object.lbNm = 'FM';
    if (isArray(filter.wSts) && filter.wSts.includes('B')) delete object['prcStg'];

    Object.entries(object).forEach((key, val) => {
      if (!isArray(val)) return;
      void (!isEmpty(val) ? delete object[key] : (object[key] = val));
    });

    return object;
  });

  const request = { filters, isNotReturnTotal: true, isReturnCountOnly: true };
  const objData = { ...SEARCH_DIAMOND_LIST, request };

  UtilService.callApi(objData, (err, res) => {
    if (res?.code === 'OK') return cb({ ids: res.data.map((record) => record?.filter?.id) });
    if (err?.data?.message) OpenNotification({ type: 'error', title: err.data.message });
  });
};
