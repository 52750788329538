import React, { Component } from "react";
import SelectOption from "../../SelectOption";
import "./headerstrip.less";
import { Select, Dropdown } from "antd";
import PriceCalculator from "../../../PriceCalculator";
import LangDropdown from "../../../Header/LangDropdown";
import IntlMessages from "../../../../util/IntlMessages";

let time = new Date().toLocaleString();
const { Option } = Select;

const HeaderStirpItem = (props) => {
  return (
    <div className="headerStripItem">
      <span>{props.title}</span>
      <span className="headerStripValue">{props.value}</span>
    </div>
  );
};
class HeaderStrip extends Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date(),
    });
  }
  render() {
    return (
      <>
        <div className={`HeaderStripBlock ${this.props.className}`}>
          <div className="HeaderStripLeft">
            <HeaderStirpItem
              title={<IntlMessages id="app.7Days" />}
              value="73.75"
            />
            <HeaderStirpItem
              title={<IntlMessages id="app.AdvancePayment" />}
              value="73.75"
            />
            <HeaderStirpItem title="COD" value="73.75" />
            <HeaderStirpItem
              title={<IntlMessages id="app.Time" />}
              value={this.state.date.toLocaleTimeString()}
            />
          </div>
          {/* <div className="HeaderStripRight d-flex align-items-center">
            <Dropdown overlay={
                       <PriceCalculator onClick={this.calculatorClick}/>
                    } trigger={['click']}>
                        <a className="headerStipIcon" onClick={e => e.preventDefault()}>
                            <img src={require("../../../../assets/svg/PriceCalc/calculator.svg")} />
                        </a>
                    </Dropdown>
            <div className="SelectLanguage">
              <LangDropdown />
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
export default HeaderStrip;
