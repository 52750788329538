import React from "react";
import IntlMessages from "../../util/IntlMessages";

export default function getData(permissionData) {
  let sidebarArr = [];
  // if (permissionData.dashboard && permissionData.dashboard.view) {
  sidebarArr.push({
    name:<IntlMessages id="app.Search" />,
    url: "/diamond-search",
    // subMenu:true,
  });
  // }
  // if () {
  sidebarArr.push({
    name: "Stone of the day",
    url: "/stone-of-the-day",
    // subMenu1:true,
    // url:
    //   permissionData.searchDiamond && permissionData.searchDiamond.view
    //     ? "/diamond-search"
    //     : "/signin",
    //     subMenu1:true,
  });
  // }
  // if (permissionData.fancySearch && permissionData.fancySearch.view) {
  // sidebarArr.push({
  //   name: "My Offer",
  //   url: "/account/offer-list",
  //   // subMenu2:true,
  // });

  // sidebarArr.push({
  //   name: "My Transaction",
  //   url: "/diamond-search",
  //   // subMenu3:true,
  // });
  return sidebarArr;
}
