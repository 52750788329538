import React, { useState, useEffect } from 'react';
import { InputNumber } from 'antd';
import find from 'lodash/find';
import Heading from '../Heading';
import findIndex from 'lodash/findIndex';
import {
  handleInsertTrack,
  getColumn,
  getPath,
  isMobile,
  LISTINGPAGES,
  getDiamondBlockMaster,
  handleAddBlock,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import SelectStone from './SelectStone';
import IntlMessages from '../../../util/IntlMessages';
import { trackTypeObj, DIAMOND_BID } from '../../../constants/Common';
import OpenNotification from '../CommonButton/OpenNotification';
import { HeadingCalc } from './ConfirmStonePopup';
import { getBidType } from '../../NewArrivalBidIt';
import { useSelector } from 'react-redux';
import WatchPopupMobile from './WatchPopupMobile';
import InputBlock from '../InputBlock';
import TextArea from '../TextArea';
import { Select, Row, Col } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getExtraPer } from './FinalCalculations';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const { Option } = Select;
const currentType = 'HoldStonePopup';

const HoldStonePopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [comment, setComment] = useState('');
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(props.checked.map((x) => ({ ...x, expBack: x.back - 1 })));
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [blockMaster, setBlockMaster] = useState({});
  const [time, setTime] = useState(undefined);
  const [timeError, setTimeError] = useState(false);

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    let columns = [...Columns.slice(0, index + 1)];
    columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      props.checked[0].isFm === 'ELIG'
        ? {
            Header: 'FM Charges',
            accessor: 'fmCharge',
            id: 'fmCharge',
            Cell: ({ cell }) => <>{parseFloat(cell.value)}</>,
          }
        : null,
      // {
      //   Header:
      //     'Final Disc(' +
      //     parseFloat(getExtraPer()) +
      //     ((getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID) &&
      //     getBidType() === DIAMOND_BID.TYPE.BLIND
      //       ? '+0.5'
      //       : '') +
      //     '%)',
      //   accessor: 'fnBack',
      //   id: 'fnBack',
      //   Cell: ({ row }) => <>{parseFloat(row.original.calcDiscount)}</>,
      // },
      // {
      //   Header: 'Final Rate',
      //   accessor: 'fnCtpr',
      //   id: 'fnCtpr',
      //   Cell: ({ row }) => <>{parseFloat(row.original.calcPricePerCarat)}</>,
      // },
      // {
      //   Header: 'Final Value',
      //   accessor: 'fnAmt',
      //   id: 'fnAmt',
      //   Cell: ({ row }) => <>{parseFloat(row.original.calcAmount)}</>,
      // },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));
  }, [exp]);

  useEffect(() => {
    getDiamondBlockMaster('H', (data) => {
      setBlockMaster(data);
      setMinTime(data.blockTimes[0]);
      setMaxTime(data.blockTimes[data.blockTimes.length - 1]);
      //setTimeDuratoins(data.blockTimes);
    });
  }, []);

  const dateHandleChange = (e, val) => {
    let da = null;
    da = parseInt(e);
    if (Number(e) < minTime || Number(e) > maxTime) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
    // this.setState({ expire: da });
    setTime(da);
  };

  const getHoldInput = () => {
    return (
      // <Row gutter={16}>
      //   <Col md={12}>
      <div className="confirmListOption mt-10">
        <div className="commonTextArea from-group">
          <label>Enter Hold Time (Hours) *</label>
          {/* <Select
              value={time}
              onChange={(value) => setTime(value)}
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              placeholder="Select Time"
              dropdownClassName="selectDopdown"
            >
              {!isEmpty(blockMaster) &&
                blockMaster.blockTimes.map((t) => (
                  <Option value={t}>{t}</Option>
                ))}
            </Select> */}
          <InputNumber
            placeholder={'Enter Hours'}
            style={{ margin: 0 }}
            type="number"
            value={time}
            onChange={(e) => dateHandleChange(e)}
          />
          {timeError && (
            <span className="err_span">
              {`Enter Hold Time (Hours) * Min Hold Time: ${minTime} | Max Hold Time: ${maxTime}`}
            </span>
          )}
        </div>
        {/* </Col> */}
        {/* <Col md={12}> */}
        <div className="commonTextArea from-group">
          <label>Comment</label>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
        {/* </Col>
      </Row> */}
      </div>
    );
  };

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              return OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to hold.',
              });
            }
            let diams = [];
            let _time = moment().add(time, 'hours').toISOString();
            checked.map((c) => {
              diams.push({
                diamond: c.id,
                blockPricePerCarat: c.ctPr,
                blockAmount: c.amt,
                vStnId: c.vStnId,
                blockSetting: blockMaster.id,
              });
            });

            if (time) {
              handleAddBlock(1, diams, _time, comment, (cb) => {
                if (cb) {
                  props.onClose();
                  props.clearAll();
                }
              });
            } else {
              OpenNotification({
                type: 'error',
                title: 'Please select hold valid till',
              });
            }
          }}
          disabled={timeError}
          className="commonButton"
        >
          Hold Stone
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };
  return (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <div className="d-flex align-items-center mr-40">
          <Heading className="popupInnerTitle mb-0" title="Hold Stone" />
        </div>
        {data.length > 0 && (
          <SelectStone sum={{}} buy checked={data} profilListing={false ? 'profileStonSelect' : null} />
        )}
        {/* {HeadingCalc(checked)} */}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked={true}
            noGrp
            canSort={false}
            currentType={currentType}
            noCheckBox
          />
        </div>
        <div className="offerBottomBox flex-wrap mt-20">{getHoldInput()}</div>

        {btnAction()}
      </div>
    </div>
  );
};

export default HoldStonePopup;
