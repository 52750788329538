import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

import Root from 'config/Root';
import { logWarn } from 'util/utils';
import { store } from 'appRedux/store';

import * as serviceWorker from 'registerServiceWorker';

const logging = parseFloat;
// eslint-disable-next-line no-global-assign
parseFloat = function (msg, precision = 2) {
  return logging(msg).toFixed(precision);
};

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  React.useEffect(() => {
    if (!isLatestVersion) emptyCacheStorage();
  }, [isLatestVersion, emptyCacheStorage]);

  return <Root />;
};

serviceWorker.unregister();

ReactDOM.render(
  <ClearCacheProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </ClearCacheProvider>,
  document.getElementById('root'),
);

try {
  window.history.scrollRestoration = 'manual';
} catch (error) {
  logWarn(error);
}
