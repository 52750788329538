import antdEn from "antd/lib/locale-provider/en_US";
import appLocaleData from "react-intl/locale-data/en";
import enMessages from "../locales/en_US.json";


const allMessages = {...enMessages};
const EnLang = {
    messages: {
        ...allMessages
    },
    antd: antdEn,
    locale: 'en-US',
    data: appLocaleData
};
export default EnLang;
