import React, { useState } from 'react';
import LangDropdown from '../Header/LangDropdown';
import { Link } from 'react-router-dom';
import Logo from '../Header/Logo';
import './statisHeader.less';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import UtilService from '../../services/util';

const StaticHeader = (props) => {
  const [menu, SetMenus] = useState(false);
  const sidebarHandle = () => {
    SetMenus(true);
  };
  const drawerClose = () => {
    SetMenus(false);
  };
  const currentPage = window.location.pathname;
  const isMobile = window.innerWidth <= 991;
  let userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  return (
    <div className="staticHeaderWrapper d-flex j-space-between align-items-center">
      <div className="staticHeaderLogo">
        <Link to="/">
          {/* <Logo /> */}
          <img src={require('../../assets/img/logo.png')} alt="" />
        </Link>
      </div>
      <div className={`staticMenus ${menu && 'active'}`}>
        {isMobile && <div className="sidebarOverLay" onClick={drawerClose}></div>}
        <ul className="d-flex">
          <li>
            <Link to="/home" className={currentPage == '/home' ? 'active' : ''}>
              Home
            </Link>
            <Link to="/about-us" className={currentPage == '/about-us' ? 'active' : ''}>
              About Us
            </Link>
            <Link to="/our-diamond" className={currentPage == '/our-diamond' ? 'active' : ''}>
              Our Diamonds
            </Link>
            <Link to="/services" className={currentPage == '/services' ? 'active' : ''}>
              Our Services
            </Link>
            {/* <Link to="/jewelry" className={currentPage == '/jewelry' ? 'active' : ''}>
              Jewellery
            </Link> */}
            <Link to="/contact-us" className={currentPage == '/contact-us' ? 'active' : ''}>
              Contact Us
            </Link>
            {/* <Link to="/signup">Register</Link>
            <Link to="/signin">Login</Link> */}
          </li>
        </ul>
      </div>
      <div className="d-flex staticHeaderRight">
        {/* <LangDropdown/> */}
        {userData ? (
          <div className="headerIcon">
            <img className="defulticon" src={require('../../assets/svg/Header/user.svg')} alt="icon" />
            <img className="hoverIcon" src={require('../../assets/svg/Header/user-white.svg')} alt="icon" />
            {isMobile ? null : (
              <Link className="headerUserName" to="/account/cart">
                My Account
              </Link>
            )}
          </div>
        ) : (
          <div className="headerIcon withBG">
            {/* <img
              className="defulticon"
              src={require("../../assets/svg/Header/user.svg")}
              alt="icon"
            /> */}
            <img className="defulticon" src={require('../../assets/svg/Header/user-white.svg')} alt="icon" />
            {isMobile ? null : (
              <Link className="headerUserName beforeLogin" to={props.data}>
                Login/Register
              </Link>
            )}
          </div>
        )}
      </div>
      {isMobile && (
        <div className="mobileMenu" onClick={sidebarHandle}>
          <img src={require('../../assets/svg/MobileHeader/menu.svg')} alt="menuIcon" />
        </div>
      )}
    </div>
  );
};
export default StaticHeader;
