import React, { useState, useEffect } from 'react';
import CommonModal from './common/CommonModal';
import { KYC_STATUS, LOCAL_STORAGE_VAR, MIME_TYPES } from '../constants/Common';
import UtilService from '../services/util';
import SelectOption from './common/SelectOption';
import UploadImage from './common/UploadImage/index';
import find from 'lodash/find';
import { Modal } from 'antd';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import OpenNotification from './common/CommonButton/OpenNotification';
import { UPLOAD_FILE, ADMIN_UPDATE_USER } from '../constants/apiConstant';

export const NoKycPop = (props) => {
  const [docP, setDocP] = useState('');
  const [docB, setDocB] = useState('');
  const [PERSONAL_DOCS, setPERSONAL_DOCS] = useState([]);
  const [BUSINESS_DOCS, setBUSINESS_DOCS] = useState([]);
  const [visible, setVisible] = useState(false);
  const [kyc, setKyc] = useState({
    imageOne: '',
    imageTwo: '',
  });
  useEffect(() => {
    let authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;
    if (authUser && authUser.isApproved === KYC_STATUS.APPROVED) {
      props.handleCancel();
      window.location.reload();
    }
    const PERSONAL_DOCS =
      (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
        JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_PERSONAL) ||
      [];
    if (PERSONAL_DOCS && PERSONAL_DOCS.length) {
      setPERSONAL_DOCS([...PERSONAL_DOCS]);
    }
    const BUSINESS_DOCS =
      (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
        JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_BUSINESS) ||
      [];
    if (BUSINESS_DOCS && BUSINESS_DOCS.length) {
      setBUSINESS_DOCS([...BUSINESS_DOCS]);
    }
  }, []);
  const uploadDocument = (ee, type, img) => {
    const e = cloneDeep(ee);

    if (img === 1) {
      if (!docP) {
        return OpenNotification({
          type: 'error',
          title: 'Please Select document type first.',
        });
      }
    }
    if (img === 2) {
      if (!docB) {
        return OpenNotification({
          type: 'error',
          title: 'Please Select document type first.',
        });
      }
    }
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: 'error',
          title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
        });
      } else {
        const data = new FormData();
        data.append('folder', 'user');
        data.append('file', e.target.files[0]);
        let objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === 'OK') {
            if (data.data && data.data.files && data.data.files.length !== 0) {
              if (img === 1) {
                setKyc({ imageTwo: kyc.imageTwo, imageOne: data.data.files[0].absolutePath });
              } else {
                setKyc({ imageTwo: data.data.files[0].absolutePath, imageOne: kyc.imageOne });
              }
            }
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  const createKyc = () => {
    if (kyc && kyc.imageOne !== '' && kyc.imageTwo !== '') {
      let objReq = {
        ...ADMIN_UPDATE_USER,
        request: {
          photoId: kyc.imageOne,
          businessId: kyc.imageTwo,
          kyc: [
            { path: kyc.imageOne, type: 'personal', docType: docP[0] },
            { path: kyc.imageTwo, type: 'business', docType: docB[0] },
          ],
          isKycUploaded: true,
          isApproved: 1,
        },
      };
      UtilService.callApi(objReq, function (err, data) {
        if (err) throw err;
        if (data && data.code === 'OK') {
          let user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

          user.account.isKycUploaded = true;
          user.account.isApproved = KYC_STATUS.PENDING;
          user.kyc = objReq.request.kyc;
          user.account.kyc = objReq.request.kyc;
          UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);
          setVisible(false);
          props.handleCancel();
          OpenNotification({
            type: 'success',
            title: 'Your KYC documents are successfully submitted.',
          });
        }
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please upload both the images',
      });
    }
  };
  let authUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`).account;
  return (
    <div>
      <CommonModal
        visible={props.visible}
        handleOk={() => {
          if (authUser.isKycUploaded) {
            if (authUser.isApproved === KYC_STATUS.PENDING) {
              if (props.search) {
                window.open('/dashboard', '_self');
              } else {
                props.handleCancel();
              }
            } else {
              setVisible(true);
            }
          } else {
            if (authUser.kyc && authUser.kyc.length && authUser.isApproved !== KYC_STATUS.REJECT) {
              props.handleCancel();
            } else {
              setVisible(true);
            }
          }
        }}
        handleCancel={() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.open('/signin', '_self');
        }}
        noCancel
        title="Note"
        modalSize="xs-size"
        footerShow
        submitTitle={`${
          !authUser?.isKycUploaded
            ? authUser?.kyc && authUser.kyc?.length && authUser?.isApproved !== KYC_STATUS.REJECT
              ? 'Ok'
              : 'Upload KYC'
            : authUser?.isApproved === KYC_STATUS.PENDING
            ? 'Ok'
            : 'Upload KYC'
        }`}
        cancelTitle="Logout"
      >
        <div>
          <p>
            {authUser && !authUser.isKycUploaded
              ? authUser?.isApproved === KYC_STATUS.REJECT
                ? 'Please upload proper KYC Document, its been rejected.'
                : authUser?.kyc && authUser.kyc?.length
                ? 'Your KYC verification is pending. You can use this feature once your KYC is approved.'
                : 'Please upload KYC document to use this feature.'
              : authUser?.isApproved === KYC_STATUS.REJECT
              ? 'Please upload proper KYC Document, its been rejected.'
              : authUser?.isApproved === KYC_STATUS.PENDING &&
                'Your KYC verification is pending. You can use this feature once your KYC is approved.'}
          </p>
        </div>
      </CommonModal>
      <Modal
        visible={visible}
        className="commonModal"
        title={[
          <h2 key="deletebuttonH2" className="modal__title primary-text" id="modal-1-title">
            Upload KYC - Identity Proof
          </h2>,
        ]}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <div key="deletebuttonReminder">
            <button className="commonButton" onClick={createKyc}>
              Submit
            </button>
          </div>,
        ]}
        width={620}
      >
        <div className="d-flex uploadKycPopup">
          <div className="kycDetails">
            <SelectOption
              value={find(PERSONAL_DOCS, { id: docP }) ? find(PERSONAL_DOCS, { id: docP }).name : undefined}
              placeholder={'Select Document'}
              selectValue={PERSONAL_DOCS}
              onChange={(value) => {
                setDocP(value);
              }}
              label="Document Type*"
            />
            <UploadImage
              title="Personal Identity"
              file={kyc.imageOne}
              leftAlign
              onUpload={(e) => uploadDocument(e, 'photoId', 1)}
            />
          </div>
          <div className="kycDetails">
            <SelectOption
              //value={data.kyc ? data.kyc.name : undefined}
              value={find(BUSINESS_DOCS, { id: docB }) ? find(BUSINESS_DOCS, { id: docB }).name : undefined}
              placeholder={'Select Document'}
              selectValue={BUSINESS_DOCS}
              onChange={(value) => {
                setDocB(value);
              }}
              label="Document Type"
            />
            <UploadImage
              title="Business Identity"
              file={kyc.imageTwo}
              leftAlign
              onUpload={(e) => uploadDocument(e, 'photoId', 2)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
