import React from 'react';

import { Select } from 'antd';
import { memoize } from 'lodash';

import IntlMessage from 'util/IntlMessages';

import leftArrowSvg from 'assets/svg/DiamondList/left-arrow.svg';
import rightArrowSvg from 'assets/svg/DiamondList/right-arrow.svg';

const LIMITS = [50, 100, 250, 500, 1000];
const dots = '...';

const getPages = memoize(
  (totalPages, currentPage) => {
    const pages = [];
    const prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
    if (prevPage > 1) pages.push(1);
    if (prevPage > 1) pages.push(dots);

    for (let i = prevPage; i <= prevPage + 2 && i <= totalPages; i++) pages.push(i);

    if (prevPage + 3 < totalPages) pages.push(dots);
    if (prevPage + 2 < totalPages) pages.push(totalPages);

    return pages;
  },
  (...args) => JSON.stringify(args),
);

// count - total count
// page - current page
// limit - current limit
// noLimitChange - display limit Selection
// noItemCount - display items 1-10 of 1000
// data - actual page data length

export const Pagination = (props) => {
  const { count, data, limit, noItemCount, noLimitChange, onPaginationChange, page } = props;
  const totalPage = count === 0 ? 1 : parseInt((count + limit - 1) / limit);

  return (
    <div className="listingPagePagination">
      <div className="clientPagination">
        {!noLimitChange && (
          <Select value={limit} onChange={(_limit) => onPaginationChange?.(1, _limit)} className="paginationSelect">
            {LIMITS.map((_limit) => (
              <Select.Option value={_limit} key={_limit}>
                {_limit}
              </Select.Option>
            ))}
          </Select>
        )}
        <ul>
          <li>
            <img onClick={() => page !== 1 && onPaginationChange?.(page - 1, limit)} src={leftArrowSvg} alt="arrow" />
          </li>
          {getPages(totalPage, page).map((x) => (
            <li
              key={x}
              className={x === page ? 'active' : null}
              onClick={() => x !== dots && onPaginationChange?.(x, limit)}
            >
              {x}
            </li>
          ))}
          <li>
            <img
              onClick={() => page !== totalPage && onPaginationChange?.(page + 1, limit)}
              src={rightArrowSvg}
              alt="arrow"
            />
          </li>
        </ul>
        {!noItemCount && (
          <span className="paginationText">
            {count > 0 ? (page - 1) * limit + 1 : 0} - {count > 0 ? (page - 1) * limit + data.length : 0} of {count}{' '}
            <IntlMessage id="app.item" />
          </span>
        )}
      </div>
    </div>
  );
};

export default Pagination;
