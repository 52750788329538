import React from "react";
import { Dropdown, Tooltip } from "antd";
import SortingFilterDropdown from "./OverlayDropdown";
import { FILTER_COLUMNS } from "./Table";

const _ = require("lodash");

const DiamondListingHead = (props) => {
  let item = !props.sort ? [] : props.sort.filter((el) => el.hasOwnProperty(props.column.id));
  let asc = item.length && item[0][props.column.id] === "ASC";
  let desc = item.length && item[0][props.column.id] === "DESC";

  return (
    <Dropdown
      overlayClassName="tabelDropdownClass"
      overlay={
        (props.sort && props.sort.length) ||
        (props.FilterOption !== false && FILTER_COLUMNS.includes(props.column.id)) ? (
          <SortingFilterDropdown {...props} column={props.column.id} />
        ) : (
          <></>
        )
      }
      trigger={["click"]}
    >
      <div
        className={`tableHeaderMain ${props.nodots && "squeceChange"} ${
          props.canSort &&
          props.canSort.hasOwnProperty(props.column.id) &&
          !(asc || desc) &&
          "shortingBoxShow"
        }`}
        onClick={(e) => e.preventDefault()}
      >
        {!props.nodots && (
          <span className="listIcon">
            <img src={require("../../assets/svg/DiamondList/sorting.svg")} alt="icon" />
          </span>
        )}
        {props.column.desc ? (
          <Tooltip title={props.column.desc}>
            <span className="taleHeadTitle">{props.header}</span>
          </Tooltip>
        ) : (
          <span className="taleHeadTitle">{props.header}</span>
        )}
        {props.canSort && props.canSort.hasOwnProperty(props.column.id) && (asc || desc) ? (
          <div className="selectShortingMainBlock">
            <div className="selectShorting" onClick={() => props.sortClick(props.column.id)}>
              <span className="selectShortingCount" style={!(asc || desc) ? { margin: 0 } : {}}>
                {props.sort.indexOf(item[0]) + 1}
              </span>
              {asc || desc ? (
                <span className="selectShortingArrow">
                  <img
                    src={
                      asc
                        ? require("../../assets/svg/DiamondList/shortinglistup-white.svg")
                        : require("../../assets/svg/DiamondList/shortinglistdown-white.svg")
                    }
                  />
                </span>
              ) : null}
            </div>
            <div
              className="shortingClose"
              onClick={() => {
                let sort = [];
                let id = props.column.id;
                _.each(props.sort, function (val) {
                  if (!val.hasOwnProperty(id)) sort.push(val);
                });
                props.handleSort(sort);
              }}
            >
              <img src={require("../../assets/svg/DiamondList/close.svg")} alt="icon" />
            </div>
          </div>
        ) : null}
        {props.canSort && props.canSort.hasOwnProperty(props.column.id) && !(asc || desc) && (
          <div className="shortingRound" onClick={() => props.sortClick(props.column.id)}>
            <div className="shortingRoundInner">
              <img src={require("../../assets/svg/DiamondList/shortinglistup.svg")} alt="icon" />
              <img src={require("../../assets/svg/DiamondList/shortinglistdown.svg")} alt="icon" />
            </div>
          </div>
        )}
      </div>
    </Dropdown>
  );
};
export default DiamondListingHead;
