import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import IntlMessages from '../../util/IntlMessages'
import OpenNotification from './CommonButton/OpenNotification'
import Heading from '../common/Heading'
import { HeadingCalc } from './DiamondListing/ConfirmStonePopup'

let input = 'blank'
function disabledDate(current) {
    return current && current < moment().startOf('day')
}

class Reminder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentImg: 0
        }
    }

    handleCancel = () => {
        this.props.handleCancel()
    }

    handleOk = e => {
        if (this.state.currentImg === 0) {
            OpenNotification({
                type: 'error',
                title: 'Please select any option to add reminder'
            })
            return
        }
        if (input === 'blank') return
        if (input === '') input = moment().toISOString()
        this.props.handleSetReminder(input)
    }

    laterToday = () => {
        this.setState({ currentImg: 1 })
        let ips = moment()
            .startOf('day')
            .add(18, 'hour')
        if (ips > moment()) {
            input = ips.toISOString()
        }
    }
    tomorrow = () => {
        this.setState({ currentImg: 2 })
        let ips = moment()
            .startOf('day')
            .add(1, 'day')
            .add(8, 'hour')
        if (ips > moment()) {
            input = ips.toISOString()
        }
    }
    nextWeek = () => {
        this.setState({ currentImg: 3 })
        let ips = moment()
            .startOf('day')
            .add(1, 'week')
            .add(8, 'hour')
        if (ips > moment()) {
            input = ips.toISOString()
        }
    }
    pick = () => {
        this.setState({ currentImg: 4 })
        input = ''
    }

    render() {
        return (
            <div>
                {/* <div className='d-flex align-items-center justify-space-between offerTopBlock offerWrapper'> */}
                <Heading className='popupInnerTitle' title={<IntlMessages id='app.addreminder' />} />
                {/* {HeadingCalc(this.props.checked)}
                </div> */}
                <div role='dialog' aria-modal='true' aria-labelledby='modal-1-title'>
                    <div className='card-table-body no-padding'>
                        <div>
                            <div className='d-flex justify-content-between flex-wrap'>
                                <div
                                    className={this.state.currentImg === 1 ? 'reminder-block active' : 'reminder-block'}
                                    onClick={this.laterToday}
                                >
                                    <div className='reminder-wrapper'>
                                        <div className='reminderImageBlock'>
                                            <img src={require('../../assets/img/today.png')} alt='' />
                                        </div>
                                        <div className='reminderDetail'>
                                            <h3>
                                                <IntlMessages id='app.laterToday' />
                                            </h3>
                                            <span>6:00 PM</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.currentImg === 2 ? 'reminder-block active' : 'reminder-block'}
                                    onClick={this.tomorrow}
                                >
                                    <div className='reminder-wrapper'>
                                        <div className='reminderImageBlock'>
                                            <img src={require('../../assets/img/tomorrow.png')} alt='' />
                                        </div>
                                        <div className='reminderDetail'>
                                            <h3>
                                                <IntlMessages id='app.tomorrow' />
                                            </h3>
                                            <span>
                                                {moment()
                                                    .startOf('day')
                                                    .add(1, 'day')
                                                    .add(8, 'hour')
                                                    .format('dddd')
                                                    .slice(0, 3)
                                                    .toUpperCase()}
                                                &nbsp; 8:00 AM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.currentImg === 3 ? 'reminder-block active' : 'reminder-block'}
                                    onClick={this.nextWeek}
                                >
                                    <div className='reminder-wrapper'>
                                        <div className='reminderImageBlock'>
                                            <img src={require('../../assets/img/next-week.png')} alt='' />
                                        </div>
                                        <div className='reminderDetail'>
                                            <h3>
                                                <IntlMessages id='app.nextWeek' />
                                            </h3>
                                            <span>
                                                {moment()
                                                    .startOf('day')
                                                    .add(1, 'week')
                                                    .add(8, 'hour')
                                                    .format('dddd')
                                                    .slice(0, 3)
                                                    .toUpperCase()}
                                                &nbsp; 8:00 AM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={this.state.currentImg === 4 ? 'reminder-block active' : 'reminder-block'}
                                    onClick={this.pick}
                                >
                                    <div className='reminder-wrapper'>
                                        <div className='reminderImageBlock'>
                                            <img src={require('../../assets/svg/reminder-date.svg')} alt='' />
                                        </div>
                                        <div className='reminderDetail'>
                                            <h3>
                                                <IntlMessages id='app.pickdatetime' />
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.currentImg === 4 && (
                            <div className='align-items-center mt-30'>
                                <div className='leftSelectItem'>
                                    <div className='leftSelectTitle'>
                                        <h2>
                                            <IntlMessages id='app.Date' />
                                        </h2>
                                    </div>
                                    <DatePicker
                                        dropdownClassName='datePickerDropdown'
                                        className='SelectDateRange'
                                        onChange={e => {
                                            if (!e) {
                                                input = 'blank'
                                                return
                                            }
                                            if (e._d <= moment()) {
                                                OpenNotification({
                                                    type: 'error',
                                                    title: 'Please select time for current date.'
                                                })
                                                input = 'blank'
                                                return
                                            }
                                            input = e._d.toISOString()
                                        }}
                                        format='ll'
                                        placeholderText={<IntlMessages id='app.selectdate' />}
                                        disabledDate={disabledDate}
                                        defaultValue={moment()}
                                        minTime={moment()}
                                        showTime={{
                                            use12Hours: true,
                                            format: 'HH:mm a',
                                            minTime: moment()
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='sideBarPopupButton'>
                        <a className='commonButton' onClick={this.handleOk}>
                            <IntlMessages id='app.addreminder' />
                        </a>
                        <a className='commonButton' onClick={this.handleCancel}>
                            <IntlMessages id='app.Cancel' />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
export default Reminder
