import React from 'react';

import { classNames } from 'util/utils';

import './checkBox.less';

const CheckBox = React.memo(({ parentClassName, label, ...props }) => {
  return (
    <div className={classNames(['customCheckBox d-flex flex-wrap', parentClassName ?? props.className])}>
      <input type="checkbox" {...props} />
      <span className="customCheckBoxLebel"></span>
      {label && <label>{label}</label>}
    </div>
  );
});

CheckBox.displayName = 'CheckBox';
export default CheckBox;
