import React from 'react';
import './title.less';

const Heading = (props) => {
  return (
    <h2 className={`mainHeadingTitle `}>{props.title}</h2>
    // <h2 className={`mainHeadingTitle ${props.className ? props.className : null}`}>{props.title}</h2>
  );
};
export default Heading;
