import React from 'react'
import { DIAMOND_BID } from '../../../constants/Common'

export const STATUS = {
    A: 'Available',
    M: 'On Memo',
    NA: "Not Available",
    H: 'Hold',
    U: 'Upcoming',
    B: 'Bid',
    C: 'Confirm',
    F: 'Forever Mark',
    O: 'Office',
    S: 'Show',
    D: 'Hold',
    all: 'All'
}

export const STATUS_COLOR = {
    A: 'green',
    M: 'blue',
    C: 'blue',
    H: 'red',
    U: 'tanOrange',
    D: 'pink'
}

const Status = props => {
    let status = !props.winloss ? props.status : props.winloss === DIAMOND_BID.STATUS.WIN ? 'W' : props.winloss === DIAMOND_BID.STATUS.LOSS ? 'L' : props.status
    return (
        <div className='showSatusBlock'>
            {props.status !== 'all' && <div className={'SelectcolorWiseList ' + STATUS_COLOR[status]}></div>}
            <span>{STATUS[props.status]}</span>
        </div>
    )
}

export default Status
