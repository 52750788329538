import React, { useReducer, useRef, useState, useEffect, useCallback, useMemo, memo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { Spin } from 'antd';
import { cloneDeep, isEmpty, isEqual, xorWith } from 'lodash';
import invert from 'lodash/invert';

import FilterTop from 'components/common/DiamondListing/FilterTop';

import { isArray, isString } from 'util/utils';

import DiamondGridView from './DiamondGrid';
import {
  getColumn,
  fetchDiamondList,
  fetchDiamondPaginate,
  getPath,
  LISTINGPAGES,
  fetchCart,
  fetchWatch,
  TITLE,
  fetchReminder,
  fetchConfirmStone,
  fetchStoneOfDay,
  fetchMatchPair,
  handleExclusiveSearchListId,
  fetchNotes,
  fetchOffice,
  isMobile,
  isIpad,
  fetchOfficeList,
  fetchBlockDiamonds,
  fetchEnquiries,
} from './DiamondListFunctions';
import MasterTags from './MasterTags';
import MobileViewList from './MobileViewList';
import Table, { LIMIT } from './TableBack';

import {
  trackTypeObj,
  DIAMOND_FEATURED_GROUP_TYPE,
  DIAMOND_WEB_STATUS,
  DIAMOND_DISPLAY_TYPE,
  LOCAL_STORAGE_VAR,
  PrintType,
  MULTIPLE_SEARCH,
  SEARCH_TAGS,
} from '../../constants/Common';
import UtilService from '../../services/util';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import ListingTop from '../common/DiamondListing/ListingTop';
import SelectStone, { formatNumber } from '../common/DiamondListing/SelectStone';
import Heading from '../common/Heading';
import NoDataShow from '../common/NoDataShow';

function getElementByClass(name) {
  if (document.getElementsByClassName(name) && document.getElementsByClassName(name)[0])
    return document.getElementsByClassName(name)[0];
  else return null;
}

export function getGridHeight(allClasses, initial) {
  const allContainers = {};
  allClasses.map((classname) => {
    allContainers[classname] = getElementByClass(classname) ? getElementByClass(classname) : 0;
    return true;
  });
  let totalHeight = window.innerHeight - initial;
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}

export function emptyData(loading, length) {
  if (loading)
    return (
      <NoDataShow
        message={
          // <Spin />
          <div className="loading-indicator"></div>
        }
      />
    );
  else if (length === 0) return <NoDataShow />;
  else return <></>;
}

export function getState(props) {
  const stateVar = {
    listview: true,
    data: [],
    page: 1,
    limit: LIMIT,
    sum: {},
    columns: [],
    count: 0,
    loading: false,
    searchId: isString(props?.location?.search) ? props.location.search?.split?.('?')?.[1] : undefined,
    inTrackDiamonds: [],
    defaultChecked: [],
  };
  return stateVar;
}

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const DiamondList = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [payReq, setPayReq] = React.useState({});

  const currentType = useRef(location.pathname);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(getState(props));
  const sortRef = useRef([]);
  const [listView, setListView] = useState(true);

  const searchId = useRef(isString(props?.location?.search) ? props.location.search?.split?.('?')?.[1] : undefined);

  const [filters, setFilters] = useState([]);

  let isaccount = useMemo(() => location.pathname.split('/')[1] === 'account', [location]);
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  useEffect(() => {
    if (isaccount && !isMobile()) {
      props.onSumChange && props.onSumChange(state.sum, currentType.current);
    }
  }, [state.sum, currentType.current]);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        //clear selected rows on path changes
        dispatch({ type: 'RESET_SELECTED_ROWS' });
        isaccount = route.pathname.split('/')[1] === 'account';
        setState(cloneDeep(getState(props)));
      }
      if (props.location.search !== route.search) {
        setState(cloneDeep(getState(props)));
      }
    });
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);

    searchId.current = (location.search?.split?.('?')?.pop?.() ?? '')?.split?.(',');

    if (!searchId.current && getPath() === LISTINGPAGES.LIST) {
      history.push('/' + LISTINGPAGES.DASHBOARD);
    }

    const searchParam = (isString(location?.search) ? location.search.split('?')[1] : undefined) ?? '';
    setFilters(
      searchParam.split(',').map((id, index) => {
        const obj = { id };
        if (index === 0) obj['isActive'] = true;
        return obj;
      }),
    );

    return () => {
      window.removeEventListener('resize', forceUpdate);
    };
  }, [location]);

  const setResponse = (res) => {
    const columns = getColumn();
    if (res) {
      // CHECK THIS
      const diamondData = res.data || [];
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d['isDefaultChecked'] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState((state) => ({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        columns,
        loading: false,
        count: res.count,
      }));
    } else {
      setState((state) => ({
        ...state,
        loading: false,
        columns,
        defaultChecked: [],
        count: 0,
      }));
    }
  };
  const handleSort = (currentSort) => {
    if (
      currentSort.length !== sortRef.current.length ||
      JSON.stringify(currentSort) !== JSON.stringify(sortRef.current)
    ) {
      sortRef.current = currentSort;
      fetch();
    }
  };

  React.useEffect(() => {
    if (isArray(state.searchId)) setFilters(state.searchId);
  }, [state?.searchId]);

  const fetch = useCallback(() => {
    setState((state) => ({ ...state, loading: true, data: [] }));
    const payload = { ...state, sort: sortRef.current };
    searchId.current = filters.find((filter) => !!filter.isActive)?.id;

    try {
      if (getPath() === LISTINGPAGES.LIST) {
        searchId.current && fetchDiamondList(payload, [{ diamondSearchId: searchId.current }], setResponse, true);
      } else if (getPath() === LISTINGPAGES.UPCOMING) {
        searchId.current
          ? fetchDiamondList(payload, [{ diamondSearchId: searchId.current }], setResponse)
          : fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.UPCOMING, setResponse);
      } else if (getPath() === LISTINGPAGES.CART) {
        fetchCart(payload, setResponse);
      } else if (getPath() === LISTINGPAGES.WATCHLIST) {
        fetchWatch(payload, setResponse);
      } else if (getPath() === LISTINGPAGES.NOTE) {
        fetchNotes(payload, setResponse);
      } else if (getPath() === LISTINGPAGES.REMINDER) {
        fetchReminder(payload, setResponse);
      } else if (getPath() === LISTINGPAGES.CONFIRM) {
        const _payload = { ...payload, status: payReq?.status ?? 1 };
        fetchConfirmStone(_payload, payReq.status, setResponse);
      } else if (getPath() === LISTINGPAGES.STONE_OF_DAY) {
        fetchStoneOfDay(payload, DIAMOND_FEATURED_GROUP_TYPE.STONE_OF_DAY, setResponse);
      } else if (getPath() === LISTINGPAGES.NEWARR) {
        fetchDiamondPaginate(payload, { viewType: DIAMOND_DISPLAY_TYPE.NEW }, setResponse);
      } else if (getPath() === LISTINGPAGES.MATCHPAIR) {
        fetchMatchPair(payload, window.location.search.split('?')[1], setResponse);
      } else if (getPath() === LISTINGPAGES.OFFICE) {
        fetchOfficeList(payload, setResponse);
        // fetchOffice(payload, setResponse)
      } else if (getPath() === LISTINGPAGES.PURCHASE) {
        fetchConfirmStone(payload, true, setResponse);
      } else if (getPath() === LISTINGPAGES.ENQUIRY) {
        fetchEnquiries(payload, setResponse);
      } else if (getPath() === LISTINGPAGES.HOLD) {
        fetchBlockDiamonds(1, setResponse);
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.limit, filters, payReq.status]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: 'RESET_SELECTED_ROWS' });
  }, [dispatch]);

  const getSelection = () => {
    if (isaccount && !isMobile()) return null;
    return (
      <SelectStone
        sum={state.sum}
        profilListing={isaccount ? 'profileStonSelect' : 'diamondlisting'}
        currentType={currentType.current}
      />
    );
  };

  const getListTop = (props) => {
    return (
      <ListingTop
        confirmList={getPath() === LISTINGPAGES.CONFIRM}
        getStatus={(status) => setPayReq((state) => ({ ...state, sort: sortRef.current, status }))}
        modify={getPath() === LISTINGPAGES.LIST ? true : false}
        onModify={() => props.history.push('/' + LISTINGPAGES.SEARCH + '/' + state.searchId)}
        listGridIcon={window.location.pathname.split('/')[1] === 'account' ? null : true}
        Cart={getPath() === LISTINGPAGES.CART || getPath() === LISTINGPAGES.WATCHLIST ? true : null}
        onClick={() => setListView((v) => !v)}
        image={
          listView ? require('../../assets/svg/DiamondList/grid.svg') : require('../../assets/svg/DiamondList/list.svg')
        }
      />
    );
  };

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState((state) => ({ ...state, page, limit }));
    },
    [state.sum],
  );

  const handleFilterChange = (id) => {
    setFilters((list) => list.map((filter) => ({ ...filter, isActive: filter?.id === id })));
    setState((state) => ({ ...state, page: 1 }));
  };

  const handleFilterDelete = (id) => {
    setFilters((list) => {
      const newList = isArray(list) ? list.filter((filter) => filter?.id !== id) : [];
      history.push(`/${LISTINGPAGES.LIST}?${newList.map((item) => item?.id).join(',')}`);
      return newList;
    });
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        fromList={getPath() === LISTINGPAGES.LIST ? true : false}
        printType={
          getPath() === LISTINGPAGES.WATCHLIST
            ? PrintType.WATCH_LIST
            : getPath() === LISTINGPAGES.PURCHASE
            ? PrintType.MEMO
            : getPath() === LISTINGPAGES.CONFIRM
            ? PrintType.ORDER
            : getPath() === LISTINGPAGES.CART
            ? PrintType.CART
            : getPath() === LISTINGPAGES.STONE_OF_DAY
            ? PrintType.STONE
            : getPath() === LISTINGPAGES.QUOTE
            ? PrintType.OFFER
            : getPath() === LISTINGPAGES.OFFICE && PrintType.OFFICE_VIEW
        }
        {...state}
        {...props}
        // permKey={
        //   getPath() === LISTINGPAGES.WATCHLIST
        //     ? "watchlist"
        //     : getPath() === LISTINGPAGES.PURCHASE
        //     ? "purchase"
        //     : getPath() === LISTINGPAGES.CONFIRM
        //     ? "order"
        //     : getPath() === LISTINGPAGES.CART
        //     ? "cart"
        //     : getPath() === LISTINGPAGES.REMINDER
        //     ? "reminder"
        //     : getPath() === LISTINGPAGES.SAVEDSEARCH
        //     ? "mySavedSearch"
        //     : getPath() === LISTINGPAGES.MYDEMAND
        //     ? "myDemand"
        //     : getPath() === LISTINGPAGES.LIST
        //     ? "searchResult"
        //     : getPath() === LISTINGPAGES.MATCHPAIR
        //     ? "matchPair"
        //     : getPath() === LISTINGPAGES.STONE_OF_DAY
        //     ? "stoneoftheday"
        //     : getPath() === LISTINGPAGES.NEWARR
        //     ? "newGoods"
        //     : getPath() === LISTINGPAGES.UPCOMING
        //     ? "upcomingDiamonds"
        //     : getPath() === LISTINGPAGES.QUOTE
        //     ? "offer"
        //     : getPath() === LISTINGPAGES.OFFICE
        //     ? "officeList"
        //     : ""
        // }
        nonocompare={(USER_PERMIT && !USER_PERMIT?.COMPARE.view) || getPath() === LISTINGPAGES.PURCHASE}
        nowatch={
          (USER_PERMIT && !USER_PERMIT?.WATCHLIST.view) ||
          getPath() === LISTINGPAGES.WATCHLIST ||
          getPath() === LISTINGPAGES.PURCHASE ||
          getPath() === LISTINGPAGES.CONFIRM
        }
        nocart={
          (USER_PERMIT && !USER_PERMIT?.CART.view) ||
          getPath() === LISTINGPAGES.CART ||
          getPath() === LISTINGPAGES.PURCHASE ||
          getPath() === LISTINGPAGES.CONFIRM
        }
        // noreminder={
        //   //(USER_PERMIT && !USER_PERMIT?.REMINDER.view) ||
        //   getPath() === LISTINGPAGES.REMINDER ||
        //   getPath() === LISTINGPAGES.PURCHASE ||
        //   getPath() === LISTINGPAGES.CONFIRM
        // }
        noreminder
        delete={
          (USER_PERMIT?.CART?.delete && getPath() === LISTINGPAGES.CART) ||
          (USER_PERMIT?.WATCHLIST?.delete && getPath() === LISTINGPAGES.WATCHLIST) ||
          // USER_PERMIT &&
          // USER_PERMIT?.REMINDER.delete &&
          getPath() === LISTINGPAGES.REMINDER ||
          (USER_PERMIT?.OFFICE?.delete && getPath() === LISTINGPAGES.OFFICE)
        }
        noconfirm={
          (USER_PERMIT && !USER_PERMIT?.CONFIRM_STONE.view) ||
          getPath() === LISTINGPAGES.PURCHASE ||
          getPath() === LISTINGPAGES.CONFIRM
        }
        // nooffice={
        //   (USER_PERMIT && !USER_PERMIT?.OFFICE.view) ||
        //   getPath() === LISTINGPAGES.OFFICE ||
        //   getPath() === LISTINGPAGES.PURCHASE ||
        //   getPath() === LISTINGPAGES.CONFIRM
        // }
        nooffice
        noquote
        // noquote={
        //   (USER_PERMIT && !USER_PERMIT?.QUOTE.view) ||
        //   getPath() === LISTINGPAGES.QUOTE ||
        //   getPath() === LISTINGPAGES.PURCHASE ||
        //   getPath() === LISTINGPAGES.CONFIRM ||
        //   getPath() === LISTINGPAGES.STONE_OF_DAY
        // }
        modify={
          getPath() === LISTINGPAGES.STONE_OF_DAY ||
          getPath() === LISTINGPAGES.NEWARR ||
          window.location.pathname.split('/')[1] === 'account'
        }
        noenquiry={
          (USER_PERMIT && !USER_PERMIT?.ENQUIRY.view) ||
          getPath() === LISTINGPAGES.CONFIRM ||
          getPath() === LISTINGPAGES.PURCHASE ||
          getPath() === LISTINGPAGES.ENQUIRY
        }
        update={
          (USER_PERMIT?.NOTES?.update && getPath() === LISTINGPAGES.NOTE) ||
          // USER_PERMIT &&
          // USER_PERMIT?.REMINDER.update &&
          getPath() === LISTINGPAGES.REMINDER
        }
        noshare={
          USER_PERMIT &&
          !USER_PERMIT?.SHARE_VIA_MAIL.view &&
          !USER_PERMIT?.SHARE_VIA_WHATSAPP.view &&
          !USER_PERMIT?.SHARE_VIA_SKYPE.view
        }
        nonote={
          (USER_PERMIT && !USER_PERMIT?.NOTES.view) ||
          getPath() === LISTINGPAGES.HOLD ||
          getPath() === LISTINGPAGES.CONFIRM
        }
        nohold={
          (USER_PERMIT && !USER_PERMIT?.HOLD.view) ||
          getPath() === LISTINGPAGES.HOLD ||
          getPath() === LISTINGPAGES.PURCHASE ||
          getPath() === LISTINGPAGES.CONFIRM
        }
        nofinalcalc={
          (USER_PERMIT && !USER_PERMIT?.FINAL_CALCULATIONS.view) ||
          getPath() === LISTINGPAGES.UPCOMING ||
          getPath() === LISTINGPAGES.PURCHASE ||
          getPath() === LISTINGPAGES.CONFIRM
        }
        nopaginate={getPath() === LISTINGPAGES.STONE_OF_DAY}
        noExportExcel={USER_PERMIT && !USER_PERMIT?.DOWNLOAD.downloadExcel}
        nodownload={USER_PERMIT && !USER_PERMIT?.DOWNLOAD.view}
        noprint={USER_PERMIT && !USER_PERMIT?.PRINT.view}
        count={state.count}
        noCheckedForDownload={state.data}
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        dataLength={state.data.length}
        currentType={currentType.current}
      />
    );
  };

  const gridHeight = isaccount
    ? getGridHeight(
        [
          'headerTopMenu',
          'accountTopBlock',
          'accountTabBox',
          'filterTopMainBlock',
          'diamondListingAction',
          'covidStrip',
          'HeaderStripBlock',
          'smallHeaderWrapper',
        ],
        15,
      )
    : getGridHeight(
        [
          'diamondListingAction',
          'headerTopMenu',
          'selectStonHeader',
          'covidStrip',
          'HeaderStripBlock',
          'smallHeaderWrapper',
        ],
        0,
      );

  const getGridView = () => {
    return (
      <>
        <DiamondGridView data={state.data} loading={state.loading} currentType={currentType.current} />
        {emptyData(state.loading, state.data.length)}
      </>
    );
  };

  const getTitle = () => {
    return TITLE[getPath()] + ' (' + formatNumber(state.count) + ')';
  };

  return (
    <>
      {isMobile() ? (
        <>
          <MobileViewList
            {...props}
            {...state}
            listView={listView}
            currentType={currentType.current}
            parent={{
              getTitle,
              getListTop,
              getSelection,
              getGridView,
              getAction,
            }}
          />
        </>
      ) : isaccount ? (
        <div className="searchResultListWrapper profilePage">
          <div className="searchInnerResult">
            <div className="position-relative">
              {getSelection()}
              {[LISTINGPAGES.CONFIRM].includes(getPath()) && getListTop()}
              {/* {(getPath() === LISTINGPAGES.CART || getPath() === LISTINGPAGES.WATCHLIST) && getListTop()} */}
            </div>
            <div className="searchResultTable profilePageTable" style={{ height: gridHeight, overflow: 'auto' }}>
              <Table
                noDetailAction={props.noDetailAction}
                nostatus={getPath() === LISTINGPAGES.PURCHASE ? true : false}
                officeView={props.officeView}
                data={state.data}
                loading={state.loading}
                ref={tableRef}
                handleSort={handleSort}
                currentType={currentType.current}
                defaultChecked={state.defaultChecked}
                // noGrp={false}
              />
              {emptyData(state.loading, state.data.length)}
            </div>
            {getAction()}
          </div>
        </div>
      ) : (
        <div className="searchResultListWrapper">
          <div className="selectStonHeader">
            <div
              style={{ overflow: 'auto' }}
              className="d-flex justify-content-between align-items-center width-100 listingTopBlock"
            >
              <Heading title={getTitle()} count={state.count} />
              {getSelection()}
              {getListTop(props)}
            </div>
          </div>
          <div className="d-flex align-items-center">
            {SEARCH_TAGS && (
              <div className="d-flex width-100 listTag flex-wrap">
                {state.seachFilterTag && <MasterTags filterData={state.seachFilterTag.displayData} />}
              </div>
            )}
            <div>
              {MULTIPLE_SEARCH && filters.length > 1 && (
                <FilterTop
                  handleRemoveFilter={handleFilterDelete}
                  handleFilterChange={handleFilterChange}
                  ids={filters}
                />
              )}
            </div>
            {/* {getListTop()} */}
          </div>
          <div className="searchInnerResult">
            {listView ? (
              <div className="searchResultTable" style={{ height: gridHeight, overflow: 'auto' }}>
                <Table
                  noDetailAction={props.noDetailAction}
                  nostatus={getPath() === LISTINGPAGES.PURCHASE}
                  groupKey={getPath() === LISTINGPAGES.MATCHPAIR ? 'groupNo' : undefined}
                  disableRowCheckBox={getPath() === LISTINGPAGES.MATCHPAIR}
                  data={state.data}
                  loading={state.loading}
                  handleSort={handleSort}
                  currentType={currentType.current}
                  defaultChecked={state.defaultChecked}
                />
                {emptyData(state.loading, state.data.length)}
              </div>
            ) : (
              <div className="searchResultGridView" style={{ height: gridHeight, overflow: 'auto' }}>
                {getGridView()}
              </div>
            )}
            {getAction()}
          </div>
        </div>
      )}
    </>
  );
};

export default compose(
  connect((store) => ({ commonData: store.commonData })),
  withRouter,
)(DiamondList);
