import React, { Component } from "react";
import { connect } from "react-redux";
import languageData from "../../constants/languageData";
import { switchLanguage } from "../../appRedux/actions/Common";
import { Select } from "antd";
import ipapi from "ipapi.co";
import _ from "lodash";

const { Option } = Select;

class LangDropdown extends Component {
  state = {
    country_code: "",
    langDefaultVal:
      (this.props.commonData.locale && this.props.commonData.locale.name) || languageData[0].name,
  };

  componentDidMount() {
    let self = this;
    var callback = function (res) {
      if (res && res.country_code) {
        let findCountry = _.find(languageData, { country_code: res.country_code });
        let setLanguage = {
          country_code: res.country_code,
        };
        if (findCountry && findCountry.languageId) {
          setLanguage.langDefaultVal = findCountry.name;
          self.props.switchLanguage(findCountry.languageId);
        }
        self.setState(setLanguage);
      }
    };

    ipapi.location(callback);
  }

  render() {
    return (
      <Select
        className="formSelect from-group dropdownLag"
        defaultValue={this.state.langDefaultVal}
        style={{ width: 100 }}
        onChange={this.props.switchLanguage}
      >
        {languageData.map((language) => (
          <Option key={language.languageId} value={language.languageId}>
            {language.name}
          </Option>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps, { switchLanguage })(LangDropdown);
