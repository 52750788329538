import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import { commonLogout, LOCAL_STORAGE_VAR, KYC_STATUS, IS_QUICK_SEARCH } from '../../constants/Common';
import UtilService from '../../services/util';
import IntlMessages from '../../util/IntlMessages';
import OpenNotification from '../common/CommonButton/OpenNotification';
import Logo from '../common/Logo';

class SidebarHeader extends Component {
  render() {
    const crnt_url = window.location.pathname;
    let actionPerform = true;
    let messagePerform = true;
    const userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    if (userData && userData.account && !userData.account.isKycUploaded) {
      actionPerform = false;
      messagePerform = 'Please upload your KYC documents to access this functionality.';
    } else if (userData && userData.account && userData.account.isApproved !== KYC_STATUS.APPROVED) {
      if (userData.account.isApproved === KYC_STATUS.REJECT) {
        messagePerform = 'Your KYC is rejected. Please upload your KYC again with right information.';
      } else {
        messagePerform = 'You will access this functionality once your KYC is approved.';
      }
      actionPerform = false;
      // KYC under process
    }

    return (
      <div className="sidebarHeader">
        <div className="sidebarLogo">
          <Logo />
        </div>
        <ScrollArea
          speed={0.8}
          className="settingLeftScroll area sideBarScroll"
          contentClassName="content"
          smoothScrolling={Boolean}
          horizontal={false}
        >
          <div className="sidebarMenuList">
            <ul>
              {USER_PERMIT && USER_PERMIT.DASHBOARD.view && (
                <li className={`menuItem ${crnt_url === `/dashboard` && `active`}`}>
                  <Link to="/dashboard" title="Dashboard">
                    {crnt_url === `/dashboard` ? (
                      <img src={require('../../assets/svg/sidebar/Dashboard-color.svg')} />
                    ) : (
                      <img src={require('../../assets/svg/sidebar/Dashboard.svg')} />
                    )}
                    <span>Dashboard</span>
                  </Link>
                </li>
              )}
              {USER_PERMIT && USER_PERMIT.SEARCH_DIAMOND.view && (
                <li className={`menuItem ${crnt_url === `/diamond-search` && `active`}`}>
                  <Link to="/diamond-search" title="Search">
                    {crnt_url === `/diamond-search` ? (
                      <img src={require('../../assets/svg/sidebar/search-color.svg')} />
                    ) : (
                      <img src={require('../../assets/svg/sidebar/search.svg')} />
                    )}
                    <span>
                      <IntlMessages id="app.Search" />
                    </span>
                  </Link>
                </li>
              )}
              {IS_QUICK_SEARCH && USER_PERMIT && USER_PERMIT.QUICK_SEARCH.view && (
                <li className={`menuItem ${crnt_url === `/quick-search` && `active`}`}>
                  <Link to="/quick-search" title="Quick Search">
                    {crnt_url === `/quick-search` ? (
                      <img src={require('../../assets/svg/sidebar/quick-color.svg')} />
                    ) : (
                      <img src={require('../../assets/svg/sidebar/quick.svg')} />
                    )}
                    <span>
                      <IntlMessages id="app.Quick" />
                    </span>
                  </Link>
                </li>
              )}
              {USER_PERMIT && USER_PERMIT.BESTBUY.view && (
                <li className={`menuItem ${crnt_url === `/stone-of-the-day` && `active`}`}>
                  <Link to="/stone-of-the-day" title="Best Buy">
                    {crnt_url === `/stone-of-the-day` ? (
                      <img src={require('../../assets/svg/sidebar/diamond-color.svg')} />
                    ) : (
                      <img src={require('../../assets/svg/sidebar/diamond.svg')} />
                    )}
                    <span>
                      <IntlMessages id="app.stoneOfTheDay" />
                    </span>
                  </Link>
                </li>
              )}
              {/* {USER_PERMIT && USER_PERMIT.NEW_ARRIVAL.view && ( */}
              {USER_PERMIT && USER_PERMIT.NEW_ARRIVAL_BID_IT.view && (
                <li className={`menuItem ${crnt_url === `/new-arrivals` && `active`}`}>
                  <Link to="/new-arrivals" title="New Arrivals">
                    {crnt_url === `/new-arrivals` ? (
                      <img src={require('../../assets/svg/sidebar/new-arrivals-color.svg')} />
                    ) : (
                      <img src={require('../../assets/svg/sidebar/new-arrivals.svg')} />
                    )}
                    <span>
                      <IntlMessages id="app.NewArrivals" />
                    </span>
                  </Link>
                </li>
              )}
              {/* )} */}
              <li className={`menuItem ${crnt_url.split('/')[1] === `account` && `active`}`}>
                <Link to="/account/profile" title="My Account">
                  {crnt_url.split('/')[1] === `account` ? (
                    <img src={require('../../assets/svg/Header/user-color.svg')} />
                  ) : (
                    <img src={require('../../assets/svg/Header/user-b.svg')} />
                  )}
                  <span>
                    <IntlMessages id="app.myAccount" />
                  </span>
                </Link>
              </li>
              {/* <li className="menuItem" onClick={() => commonLogout()}>
              <Link title="Logout">
                <img src={require("../../assets/svg/Header/logout.svg")} />
                <span>
                  <IntlMessages id="app.Logout" />
                </span>
              </Link>
            </li> */}
            </ul>
          </div>
        </ScrollArea>

        <div className="sidebarMenuList sidebarListBottom">
          <ul>
            {/* <li onClick={() => commonLogout()}>
              <Link>
                <img src={require("../../assets/svg/Header/logout.svg")} />
                <span>
                  <IntlMessages id="app.Logout" />
                </span>
              </Link>
            </li> */}
            <li className="menuItem" onClick={() => commonLogout()}>
              <Link title="Logout">
                <img src={require('../../assets/svg/Header/logout.svg')} />
                {/* <span>
                  <IntlMessages id="app.Logout" />
                </span> */}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(SidebarHeader);
