import React from "react";
import { Select } from "antd";
import "./selectOption.less";
const { Option } = Select;

const SelectOption = (props) => {
  let selectValue = props.selectValue;
  let groupClass = "formSelect from-group";
  if (props.hasError) {
    groupClass = groupClass + " erroroMess";
  }

  return (
    <div className={groupClass}>
      <label>{props.diamond ? props.defaultValue && props.defaultValue.length !== 0 && props.label : props.label}
        {props.required &&
          <span style={{ marginLeft: '2px', color: props.value ? 'green' : 'red' }}>*</span>
        }
      </label>
      {!props.diamond ?
        <Select
        showAction={['focus', 'click']}
          dropdownClassName="selectDopdown"
          className={props.className ? props.className : null}
          mode={props.mode ? props.mode : null}
          placeholder={props.placeholder ? props.placeholder : null}
          onChange={props.onChange ? props.onChange : null}
          value={props.value}
          disabled ={props.disabled}
          onBlur={props.onBlur ? props.onBlur : null}
          showSearch={props.showSearch}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

          }
        >

          {selectValue.map((d, index) => (
            <Option value={props.arrs ? d.id[0] : d.id}>{d.name}</Option>
          ))}


        </Select>
        : <Select
          dropdownClassName="selectDopdown"
          // className={props.className ? props.className : null}
          mode={props.mode ? props.mode : null}
          placeholder={props.placeholder ? props.placeholder : null}
          onChange={props.onChange ? props.onChange : null}
          defaultValue={props.defaultValue}
          onBlur={props.onBlur ? props.onBlur : null}
          showSearch={props.showSearch}
          onSearch={(e) => {
            props.getFil(e)
          }}
          filterOption={false}
        >
          {props.diamond && props.diamondInput.length < 3 && selectValue.length===0 &&
            <Option value='word' disabled >Please enter 3 words to search</Option>

          }
          {selectValue.map((d, index) => (
            <Option key={d.id}>{d.name}</Option>
          ))}


        </Select>}

      <span style={{ color: 'red' }}>
        {props.hasError ? props.hasError : ''}
      </span>
    </div >
  );
};
export default SelectOption;
