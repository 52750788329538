import React, { useState, useEffect, useReducer } from 'react';

import { Checkbox } from 'antd';
import _ from 'lodash';

import { Notify } from 'util/notify';

import { DOWNLOAD_OPTIONS } from '../../../constants/Common';
import IntlMessages from '../../../util/IntlMessages';
import { downloadZip } from '../../DiamondList/DiamondListFunctions';
import OpenNotification from '../CommonButton/OpenNotification';

const DownloadDropdown = (props) => {
  const [totalChecked, setTotalChecked] = useState([]);
  const [images, setImages] = useState(false);
  const [excels, setExcels] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const ShareOption = (props) => {
    return (
      <li id={props.uiid} className={props.activeClass}>
        <div className="dropdownBottomItem">
          <div className="dropdownIconCheckBox">
            <Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
          </div>
          <span className="shareOptionLabel">{props.title}</span>
        </div>
      </li>
    );
  };

  const handleDownloadClick = () => {
    const trueAny = Object.values(totalChecked).some((x) => x === true);
    let totalTrue = 0;
    Object.values(totalChecked).forEach((x) => x === true && totalTrue++);
    // if (
    //   (props.checked.length > 1 && totalTrue > 1) ||
    //   (props.checked.length > 1 && (totalChecked.videoFile || totalChecked.isCert))
    // ) {
    //   Notify.error({ message: 'Image,Video,Certificate should be download together for Single Stone only.' });
    // } else {
    if (trueAny) {
      let obj = {};
      if (totalTrue >= 2 || props.checked.length >= 2) {
        obj = { zip: true };
      } else {
        if (totalChecked.isCert) {
          obj = { zip: true };
        } else {
          obj = { zip: false };
        }
      }
      const { isCert, ...checkedItems } = totalChecked;
      obj = {
        ...obj,
        filter: {
          vStnId: _.map(props.checked, 'vStnId'),
        },
        certFile: isCert,
        ...checkedItems,
        ...(props.orderDiamond && { orderDiamond: true }),
      };
      downloadZip(obj, () => {
        props.onCloseDownloadPopup();
        Notify.success({
          message: 'Wait for sometime, your download is in process. File(s) will be downloaded soon.',
        });
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select atleast 1 option to download.',
      });
    }
  };

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Images) {
      const mappedImagesKeys = _.map(DOWNLOAD_OPTIONS.Images.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedImagesKeys.includes(v) && (totalChecked[v] = images);
      });
      forceUpdate();
    }
  }, [images]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Videos) {
      const mappedVideosKeys = _.map(DOWNLOAD_OPTIONS.Videos.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedVideosKeys.includes(v) && (totalChecked[v] = videos);
      });
      forceUpdate();
    }
  }, [videos]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Certificates) {
      const mappedCertificatesKeys = _.map(DOWNLOAD_OPTIONS.Certificates.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedCertificatesKeys.includes(v) && (totalChecked[v] = certificates);
      });
      forceUpdate();
    }
  }, [certificates]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Roughs) {
      const mappedRoughsKeys = _.map(DOWNLOAD_OPTIONS.Roughs.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedRoughsKeys.includes(v) && (totalChecked[v] = roughs);
      });
      forceUpdate();
    }
  }, [roughs]);

  useEffect(() => {
    if (DOWNLOAD_OPTIONS.Excels) {
      const mappedExcelssKeys = _.map(DOWNLOAD_OPTIONS.Excels.subLevel, 'key');
      Object.keys(totalChecked).forEach((v) => {
        mappedExcelssKeys.includes(v) && (totalChecked[v] = excels);
      });
      forceUpdate();
    }
  }, [excels]);

  const getAllKeys = () => {
    const keys = _.uniq(
      _.flattenDeep(
        _.map(DOWNLOAD_OPTIONS, (val, key) => {
          let tmp = [];
          if (val && val.key) {
            tmp.push(val.key);
          }
          if (val && val.subLevel && val.subLevel.length) {
            tmp = _.concat(tmp, _.map(val.subLevel, 'key'));
          }
          return tmp;
        }),
      ),
    );
    return keys;
  };

  useEffect(() => {
    const definedKeys = getAllKeys();
    definedKeys.forEach((key) => {
      setTotalChecked((totalChecked) => ({
        ...totalChecked,
        [key]: false,
      }));
    });
  }, []);

  return (
    <>
      <div>
        <div className="searchPopupCommon">
          <div className="shareOptionSet d-flex">
            <div className="shareOptionSetItem">
              {/* Images */}
              {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Images && DOWNLOAD_OPTIONS.Images.subLevel ? (
                <ul>
                  {DOWNLOAD_OPTIONS.Images.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={images}
                            onChange={() => {
                              setImages(!images);
                            }}
                          >
                            <span>{DOWNLOAD_OPTIONS.Images.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {DOWNLOAD_OPTIONS.Images.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            key={record.label}
                            activeClass={totalChecked[record.key] ? 'active' : ''}
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : DOWNLOAD_OPTIONS.Images ? (
                <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Images.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Images.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Images.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Images.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}
              {/* Images */}

              {/* Videos */}
              {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Videos && DOWNLOAD_OPTIONS.Videos.subLevel ? (
                <ul>
                  {DOWNLOAD_OPTIONS.Videos.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={videos}
                            onChange={() => {
                              setVideos(!videos);
                            }}
                          >
                            <span>{DOWNLOAD_OPTIONS.Videos.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {DOWNLOAD_OPTIONS.Videos.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            key={record.label}
                            activeClass={totalChecked[record.key] ? 'active' : ''}
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : DOWNLOAD_OPTIONS.Videos ? (
                <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Videos.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Videos.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Videos.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Videos.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}
              {/* Videos */}

              {/* Certificates */}
              {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Certificates && DOWNLOAD_OPTIONS.Certificates.subLevel ? (
                <ul>
                  {DOWNLOAD_OPTIONS.Certificates.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={certificates}
                            onChange={() => {
                              setCertificates(!certificates);
                            }}
                          >
                            <span>{DOWNLOAD_OPTIONS.Certificates.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {DOWNLOAD_OPTIONS.Certificates.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            key={record.label}
                            activeClass={totalChecked[record.key] ? 'active' : ''}
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : DOWNLOAD_OPTIONS.Certificates ? (
                <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Certificates.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Certificates.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Certificates.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Certificates.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}
              {/* Certificates */}

              {/* Roughs */}
              {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Roughs && DOWNLOAD_OPTIONS.Roughs.subLevel ? (
                <ul>
                  {DOWNLOAD_OPTIONS.Roughs.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={roughs}
                            onChange={() => {
                              setRoughs(!roughs);
                            }}
                          >
                            <span>{DOWNLOAD_OPTIONS.Roughs.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {DOWNLOAD_OPTIONS.Roughs.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            key={record.label}
                            activeClass={totalChecked[record.key] ? 'active' : ''}
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : DOWNLOAD_OPTIONS.Roughs ? (
                <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Roughs.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Roughs.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Roughs.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Roughs.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}
              {/* Roughs */}

              {/* Excels */}
              {DOWNLOAD_OPTIONS && DOWNLOAD_OPTIONS.Excels && DOWNLOAD_OPTIONS.Excels.subLevel ? (
                <ul>
                  {DOWNLOAD_OPTIONS.Excels.subLevel.length > 0 && (
                    <>
                      <div className="dropdownMainTitle">
                        <div className="dropdownIconCheckBox">
                          <Checkbox
                            checked={excels}
                            onChange={() => {
                              setExcels(!excels);
                            }}
                          >
                            <span>{DOWNLOAD_OPTIONS.Excels.label}</span>
                          </Checkbox>
                        </div>
                      </div>
                      <div className="dropdownRightCheck">
                        {DOWNLOAD_OPTIONS.Excels.subLevel.map((record) => (
                          <ShareOption
                            title={record.label}
                            key={record.label}
                            activeClass={totalChecked[record.key] ? 'active' : ''}
                            checked={totalChecked[record.key]}
                            onChange={(e) => {
                              setTotalChecked({
                                ...totalChecked,
                                [record.key]: e.target.checked,
                              });
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </ul>
              ) : DOWNLOAD_OPTIONS.Excels ? (
                <div className="singleOnly">
                  <ShareOption
                    title={DOWNLOAD_OPTIONS.Excels.label}
                    activeClass={totalChecked[DOWNLOAD_OPTIONS.Excels.key] ? 'active' : ''}
                    checked={totalChecked[DOWNLOAD_OPTIONS.Excels.key]}
                    onChange={(e) => {
                      setTotalChecked({
                        ...totalChecked,
                        [DOWNLOAD_OPTIONS.Excels.key]: e.target.checked,
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="commonModalButton mt-50">
            <button className="fillButton" onClick={handleDownloadClick}>
              <IntlMessages id="app.Download" />
            </button>
            <button onClick={() => props.onCloseDownloadPopup()} className="outLineButton ml-5">
              <IntlMessages id="app.Cancel" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadDropdown;
