import React from 'react';

export function Loader({ loading = true, fixed }) {
  if (!loading) return null;

  return (
    <div
      id="loader"
      className={`main-loader ${fixed ? '--fixed' : ''}`}
      style={{ width: fixed ? 'calc(100% + 30px)' : '100%' }}
    >
      <div id="loader-inner">
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
