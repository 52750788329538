import { TABLE_PAGE_LIMIT } from 'constants/Common';

export const LAB_LINKS = {
  GSI: 'http://wg.gemscience.net/vr/veri.aspx',
  IGI: 'https://www.igi.org/reports/verify-your-report?r=***',
  GIA: 'https://www.gia.edu/report-check?reportno=***',
  HRD: 'https://my.hrdantwerp.com/?id=34&record_number=***',
};

export const SORTABLE_COLUMNS = {
  carat: 'Carat',
  clrNm: 'Clarity',
  colNm: 'Color',
  color: 'Color',
  createdAt: 'Register Date',
  crt: 'Carat',
  name: 'Name',
  shape: 'Shape',
  shpNm: 'Shape',
  stoneId: 'Packet No',
};

export const COLLAPSIBLE_ROW_HEADERS = false;
export const SELECT_ROW_ON_CLICK = true;
export const HIGHLIGHT_ROW_BY_LAB = true;

export const ALT_COLUMNS = ['back', 'cAng', 'cHgt', 'clrNm', 'colNm', 'crt', 'cutNm', 'depPer', 'fluNm', 'grdlPer', 'height', 'lbNm', 'length', 'pAng', 'pHgt', 'polNm', 'rap', 'rptNo', 'symNm', 'tblPer', 'width']; // prettier-ignore
export const BOLD_COLUMNS = ['amt', 'back', 'bidAmount', 'bidDiscount', 'bidPricePerCarat', 'brlncyNm', 'clrNm', 'colNm', 'crt', 'ctPr', 'cutNm', 'fluNm', 'fnAmt', 'fnBack', 'fnCtpr', 'polNm', 'prvBack', 'prvCtPr', 'rap', 'rptNo', 'shdNm', 'shpNm', 'stoneId', 'symNm', 'vStnId']; // prettier-ignore
export const FILTER_COLUMNS = ['blkSdNm', 'blkTblNm', 'brlncyNm', 'clrNm', 'colNm', 'cultNm', 'cutNm', 'eClnNm', 'fluNm', 'girdleStr', 'hANm', 'lbNm', 'locNm', 'opCrwnNm', 'opPavNm', 'opTblNm', 'polNm', 'shdNm', 'shpNm', 'symNm', 'wSdNm', 'wTblNm'] // prettier-ignore
export const FROZEN_COLUMNS = ['srNo', 'selection', 'lbNm', 'Details', 'vStnId', 'shpNm', 'crt', 'colNm', 'clrNm']; // prettier-ignore
export const NOCHECK_COLUMNS = ['Action', 'active', 'approve', 'bidAmount', 'bidDiscount', 'bidPricePerCarat', 'confirm', 'Details', 'dna', 'expBack', 'filePaths', 'finalquote', 'FutureDisc', 'hours', 'lbNm', 'note', 'quote', 'reject', 'stockCount', 'video']; // prettier-ignore
export const TOTAL_COLUMNS = ['ctPr', 'amt', 'rap', 'crt']; // prettier-ignore
export const FLOAT_COLUMNS = ['amt', 'back', 'bidAmount', 'bidDiscount', 'bidPricePerCarat', 'cAng', 'cHgt', 'crt', 'ctPr', 'depPer', 'fnDis', 'grdlPer', 'height', 'length', 'lwrHal', 'pAng', 'pHgt', 'prvBack', 'prvCtPr', 'rapAvg', 'ratio', 'strLn', 'tblPer', 'width']; // prettier-ignore
export const ROUND_COLUMNS = ['amt', 'rap']; // prettier-ignore
export const CURRENCY_COLUMNS = ['amt', 'ctPr']; // prettier-ignore

export const PAGE_LIMIT = TABLE_PAGE_LIMIT;
