import React from "react";
import "./uploadImage.less";
import UtilService from "../../../services/util.old";
import OpenNotification from "../CommonButton/OpenNotification";
import { BASE_URL } from "../../../constants/Common";

const UploadImage = (props) => {
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      let blob = e.target.files[0];

      UtilService.fileMimeType(blob, true, (valid) => {
        if (blob.name.match(/.(pdf|jpg|jpeg|png)$/i)) {
          if (blob.size / 1024 > 5000) {
            OpenNotification({
              type: 'error',
              title: "File size should be less than 5 mb."
            })
          } else {
            if (valid) {
              const name = "user";
              if (blob) {
                var fd = new FormData();
                var img_file = blob;
                fd.append("folder", `${name}`);
                fd.append("file", img_file);
              }
              props.fileApi(fd, name);
            } else {
              OpenNotification({
                type: "error",
                title: "File you trying to upload is inappropriate.",
              });
            }
          }
        } else {
          OpenNotification({
            type: "error",
            title: "The image should be a PNG, JPG, PDF or JPEG file",
          });
        }
      });
    }
  };
  // imageUploaded
  return (
    <div className="uploadeImageBlock">
      <input onChange={handleFileChange} type="file" />
      <div className="uploadeBlock">
        {props.image ?
          props.image.split('/').slice(-1)[0].match(/.(pdf)$/i) ?
            <img src={require('../../common/UploadImage/pdf.svg')} alt="" />
            :
            <img src={`${BASE_URL}/${props.image}`} alt="" />

          : (
            <img src={require("../../../assets/svg/plus-thin.svg")} alt="" />
          )}
      </div>
      <span className="title">{props.title}</span>
    </div>
  );
};

export default UploadImage;
