import React, { Component } from 'react'
import Heading from "../common/Heading"
import './ship.less'
import "../TermsOfUse/staticContent.less";
import StaticHeader from "../StaticHeader"
export default class ShippingPolicy extends Component {
    state = {
        policy: [
            { title: 'The above charges are for shipment made from Mumbai(India) to Port of Discharge(airport) only.' },
            { title: 'The charges from the destination port (airport) to your door-step like local tax, duty, custom clearance and any other charges applicable for the shipment are payable by you(the Consignee).' },
            { title: 'The diamonds will be shipped only upon the confirmation of receipt of advance payment into your account.' },
            { title: 'The diamonds will be shipped to your door steps through carriers like Brinks, Malca Amit, BVC Express, on C&F basis.' },
            { title: 'Door-to-door insurance will be covered by the above mentioned carriers.' },
            { title: 'The Company reserves rights to refuse shipments to certain internationals destinations.' },
            { title: 'All the shipments within the boundary of India will be shipped from our Corporate Office in Mumbai.' }
        ]
    }
    render() {
        let { policy } = this.state
        return (
            <>
                <StaticHeader data="/signin"/>
                <div className="staticBoxMain">
                    <div className="statiContentBox">
                        <Heading title="Shipping Policy" className="staticMainTitle text-center mb-50" />
                        <div className="staticList">
                            <ul>
                                <table
                        >
                            <tr>
                                <th

                                >Shipment values (In US $)</th>
                                <th>Expenses (In US $)</th>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'center' }}>Upto 5000</td>
                                <td style={{ textAlign: 'center' }}>150</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'center' }}>+5000 to 15000</td>
                                <td style={{ textAlign: 'center' }}>100</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'center' }}>+15000 & Above</td>
                                <td style={{ textAlign: 'center' }}>Free</td>
                            </tr>

                        </table>
                            </ul>
                        </div>
                        <div className="staticContentWrapper">
                            <Heading title="Notes" className="popupInnerTitle"/>
                            <div className="staticList">
                                <ul>
                                    {policy.map(pol => {
                                        return <li className="staticContentList">

                                            {/* <img className="staticListImg" src={require('../../assets/svg/Header/right.svg')} /> */}
                                           {pol.title}
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
