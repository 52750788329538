import React, { useReducer, useRef, useState, useEffect, useCallback, useMemo, memo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tooltip } from 'antd';
import { cloneDeep, isEmpty, isEqual, xorWith } from 'lodash';
import moment from 'moment';

import { trackTypeObj, LOCAL_STORAGE_VAR, PrintType } from '../../constants/Common';
import UtilService from '../../services/util';
import { getGridHeight, emptyData, getState } from '../DiamondList';
import {
  getStoneInfo,
  fetchOffer,
  handleTrackDelete,
  isMobile,
  TITLE,
  getPath,
} from '../DiamondList/DiamondListFunctions';
import MobileViewList from '../DiamondList/MobileViewList';
import Table from '../DiamondList/TableBack';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import { formatNumber } from '../common/DiamondListing/SelectStone';

export const OFFERSTATUS = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
};

const Offer = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState(props)));

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  useEffect(() => {
    if (!isMobile()) {
      props.onSumChange && props.onSumChange(state.sum, currentType.current);
    }
  }, [state.sum, currentType.current]);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        dispatch({ type: 'RESET_SELECTED_ROWS' });
        setState(cloneDeep(getState(props)));
      }
      if (props.location.search !== route.search) {
        setState(cloneDeep(getState(props)));
      }
    });
    return function cleanup() {
      listener();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    fetch();
    return () => {
      window.removeEventListener('resize', forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    fetch();
  }, [state.page, state.limit]);

  const setResponse = (res) => {
    if (res) {
      const diamondData = res.data || [];
      let defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d['isDefaultChecked'] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const fetch = useCallback(() => {
    setState({ ...state, editOffer: null, loading: true, data: [] });
    fetchOffer(
      {
        ...state,
        offerStatus: 1,
      },
      setResponse,
    );
  }, [state.page, state.limit, state.sum]);

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: 'RESET_SELECTED_ROWS' });
  }, [dispatch]);

  const getLoggedInUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  const fetchcolumn = useMemo(() => {
    const columns = [
      {
        Header: 'No',
        accessor: 'no',
        Cell: ({ row, cell }) => <span>{row.index + 1}</span>,
      },
      // {
      //     Header: 'Days',
      //     accessor: 'day',
      //     Cell: ({ row, cell }) => (
      //         <span>
      //             <Countdown date={moment()} />
      //         </span>
      //     )
      // },
      {
        Header: 'Stone Info',
        accessor: 'stoneinfo',
        Cell: ({ row, cell }) => <span>{getStoneInfo(row.original).join(' ')}</span>,
      },
      {
        Header: 'Disc % / Price/Ct',
        accessor: 'dispricCt',
        Cell: ({ row, cell }) => (
          <span>
            {(row.original.back ? parseFloat(row.original.back) + ' / ' : '') + parseFloat(row.original.ctPr)}
          </span>
        ),
      },
      {
        Header: 'Quote % / Price/Ct',
        accessor: 'quotepricect',
        Cell: ({ row, cell }) => (
          <span>
            {(row.original.newDiscount ? parseFloat(row.original.newDiscount) + ' / ' : '') +
              parseFloat(row.original.newPricePerCarat)}
          </span>
        ),
      },
      {
        Header: 'Bargain',
        accessor: 'bargain',
        Cell: ({ row }) => {
          return (
            <div>
              {!row.original.bargainTrack || !row.original.bargainTrack.length ? (
                <Tooltip title={moment(row.original.createdAt).format('DD/MM/YYYY hh:mm A')}>
                  <span className="offerPrice current">{parseFloat(row.original.newDiscount)}</span>
                </Tooltip>
              ) : (
                row.original.bargainTrack
                  .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
                  .map((b, i) => {
                    return i === 0 ? (
                      <Tooltip key={i} title={moment(b.createdAt).format('DD/MM/YYYY hh:mm A')}>
                        <span className="offerPrice current">{parseFloat(b.trackDiscount)}</span>
                      </Tooltip>
                    ) : getLoggedInUser.id === b.id ? (
                      <Tooltip key={i} title={moment(b.updatedAt).format('DD/MM/YYYY hh:mm A')}>
                        <span className="offerPrice admin">{parseFloat(b.trackDiscount)}</span>
                      </Tooltip>
                    ) : (
                      <Tooltip key={i} title={moment(b.updatedAt).format('DD/MM/YYYY hh:mm A')}>
                        <span className="offerPrice client">{parseFloat(b.trackDiscount)}</span>
                      </Tooltip>
                    );
                  })
              )}
            </div>
          );
        },
      },
      {
        Header: 'Comment',
        accessor: 'remarks',
        Cell: ({ row, cell }) => <>{cell.value || '-'}</>,
      },
      // {
      //     Header: 'Saleman',
      //     accessor: 'vndNm'
      // },
      // {
      //     Header: 'Company',
      //     accessor: 'vndNm'
      // },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row, cell }) => (
          <div className="activeStatus pending">
            <span>{OFFERSTATUS[row.original.offerStatus]}</span>
          </div>
        ),
      },
      {
        Header: 'Action',
        Cell: ({ row, cell }) => (
          <div className="actionButton">
            {row.original.offerStatus !== 3 && (
              <div className="actionAction" onClick={() => setState({ ...state, editOffer: [row.original] })}>
                <img src={require('../../assets/svg/Account/edit.svg')} />
              </div>
            )}

            <div
              className="actionAction"
              onClick={() => {
                handleTrackDelete(trackTypeObj.QUOTE, [row.original.id], state.inTrackDiamonds, () => {
                  clearAllSelectedRows();
                  fetch();
                });
              }}
            >
              <img src={require('../../assets/svg/Account/delettable.svg')} />
            </div>
          </div>
        ),
      },
    ].map((c) => ({ ...c, id: c.accessor }));
    return columns;
  }, [state]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  const getTitle = () => {
    return TITLE[getPath()] + ' (' + formatNumber(state.count) + ')';
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        permKey={`${USER_PERMIT?.QUOTE?.delete ? 'offer' : ''}`}
        printType={PrintType.OFFER}
        trackType={trackTypeObj.QUOTE}
        {...state}
        {...props}
        currentType={currentType.current}
        delete={USER_PERMIT?.QUOTE?.delete}
        nofinalcalc={USER_PERMIT && !USER_PERMIT?.FINAL_CALCULATIONS.view}
        nonocompare={USER_PERMIT && !USER_PERMIT?.COMPARE.view}
        noconfirm={USER_PERMIT && !USER_PERMIT?.CONFIRM_STONE.view}
        nocart={USER_PERMIT && !USER_PERMIT?.CART.view}
        nowatch={USER_PERMIT && !USER_PERMIT?.WATCHLIST.view}
        nooffice={USER_PERMIT && !USER_PERMIT?.OFFICE.view}
        nonote={USER_PERMIT && !USER_PERMIT?.NOTES.view}
        noshare={
          USER_PERMIT &&
          !USER_PERMIT?.SHARE_VIA_MAIL.view &&
          !USER_PERMIT?.SHARE_VIA_WHATSAPP.view &&
          !USER_PERMIT?.SHARE_VIA_SKYPE.view
        }
        nodownload={USER_PERMIT && !USER_PERMIT?.DOWNLOAD.view}
        noprint={USER_PERMIT && !USER_PERMIT?.PRINT.view}
        // noenquiry={USER_PERMIT && !USER_PERMIT?.ENQUIRY.insert}
        noExportExcel={USER_PERMIT && !USER_PERMIT?.DOWNLOAD.downloadExcel}
        noquote
        modify
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
      />
    );
  };

  const gridHeight = getGridHeight(
    [
      'headerTopMenu',
      'accountTopBlock',
      'accountTabBox',
      'filterTopMainBlock',
      'diamondListingAction',
      'covidStrip',
      'HeaderStripBlock',
    ],
    46,
  );

  return isMobile() ? (
    <MobileViewList
      listView={true}
      {...props}
      {...state}
      currentType={currentType.current}
      parent={{ getTitle, getAction }}
    />
  ) : (
    <div className="searchResultListWrapper profilePage">
      <div className="searchInnerResult">
        <div className="searchResultTable profilePageTable" style={{ height: gridHeight, overflow: 'auto' }}>
          <Table
            data={state.data}
            columns={fetchcolumn}
            loading={state.loading}
            ref={tableRef}
            nostatus
            canSort={false}
            currentType={currentType.current}
            defaultChecked={state.defaultChecked}
          />
          {emptyData(state.loading, state.data.length)}
        </div>
        {getAction()}
      </div>
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(Offer));
