import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../common/Logo";
import { Drawer } from "antd";
import { connect } from "react-redux";
import { setLoader,covidStrip } from "../../appRedux/actions/Common";
import MobileSidebar from "./sidebar"
import HeaderStrip from "../common/Header/HeaderStrip"
import CovidStrip from "../common/Header/CovidStrip"
import UpDownBox from "../common/UpDownBox"

class MobileHeader extends Component {
  constructor(){
    super();
    this.state = {
        isHovered: false,
        isHovered1: false,
        isHovered2: false,
        visible: false,
        covid: true,
        HeaderStripList: true,
    };
    this.handleHover = this.handleHover.bind(this);
    this.handleHover1 = this.handleHover1.bind(this);
    this.handleHover2 = this.handleHover2.bind(this);
  }
  componentDidMount() {
    this.props.covidStrip(this.state.covid);
  }
  closeStrip = () => {
    this.props.covidStrip(!this.props.data.commonData.strip);
    this.setState({
      covid: false,
    });
  };
  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.data.commonData.strip);
    this.setState({
      HeaderStripList: !this.state.HeaderStripList,
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  handleHover(){
      this.setState({
          isHovered: !this.state.isHovered,
          isHovered1:false,
          isHovered2:false
      });
  }
  handleHover1(){
    this.setState({
        isHovered1: !this.state.isHovered1,
        isHovered:false,
        isHovered2:false
    });
  }
  handleHover2(){
    this.setState({
        isHovered2: !this.state.isHovered2,
        isHovered:false,
        isHovered1:false
    });
  }
  handleOut = () => {
    this.setState({
      isHovered:false
    });
  }
  handleOut1 = () => {
    this.setState({
      isHovered1:false
    });
  }
  handleOut2 = () => {
    this.setState({
      isHovered2:false
    });
  }
 
  render() {
    const btnClass = this.state.isHovered ? "active" : "";
    const btnClass1= this.state.isHovered1 ? "active" : "";
    const btnClass2= this.state.isHovered2 ? "active" : "";

    return (
      <>
      {this.state.covid && (
            <CovidStrip onClick={this.closeStrip}/>
          )}
        <div className="headerTopMenu">
          <div className="headerWrapper">
            <div className="mobileMenu" onClick={this.showDrawer}> 
                <img src={require("../../assets/svg/MobileHeader/menu.svg")} alt="menuIcon"/>
            </div>
            <div className="headerLeft">
                <div className="headerLogo">
                    <Link to="/" className="">
                      <Logo />
                    </Link>
                </div>
                <div className="headerMobileIcon">
                    <Link className="headerMobileIconItem" to="/diamond-search">
                        <img src={require("../../assets/svg/Header/search.svg")} />
                    </Link>
                    <Link className="headerMobileIconItem" to="/account">
                        <img src={require("../../assets/svg/Header/user.svg")} />
                    </Link>
                    {/* <Link className="headerMobileIconItem" to="/account/cart">
                        <img src={require("../../assets/svg/Header/cart.svg")} />
                    </Link> */}
                    <Link className="headerMobileIconItem" to="/notification">
                        <img src={require("../../assets/svg/Header/notification.svg")} />
                    </Link>
                </div>
              </div>
          </div>
         
        </div>
       
          {/* <div className="position-relative mobileHeaderStrip">
          {this.state.HeaderStripList && (
            <UpDownBox onClick={this.HeaderStripCick}/>
          )}
          
          {!this.state.HeaderStripList && (
            <UpDownBox downArrow="upArrow" onClick={this.HeaderStripCick}/>
          )}
          </div>
           {this.state.HeaderStripList && (
          <HeaderStrip
            onClick={this.HeaderStripCick}
          />
          )} */}
        <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.visible}
            wrapClassName="mobileSidebar mobileSidebarHeader"
        >
            <div>
                <MobileSidebar/>
            </div>
        </Drawer>
      </>
    );
  }
}

// export default Header;
function mapStateToProps(props) {
  return { data: props };
}

export default connect(mapStateToProps, { setLoader,covidStrip })(MobileHeader);
