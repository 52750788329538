import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import getData from '../common/svgSidebar';
import _ from 'lodash';
import { connect } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import IntlMessages from '../../util/IntlMessages';
import HeaderStrip from '../common/Header/HeaderStrip';
import { commonLogout, LOCAL_STORAGE_VAR, KYC_STATUS } from '../../constants/Common';
import SmallHeader from '../common/Header/SmallHeader';
import UtilService from '../../services/util';
import OpenNotification from '../common/CommonButton/OpenNotification';

class MobileSidebar extends Component {
  constructor() {
    super();
    this.state = {
      visible: true,
      visible1: false,
      visible2: false,
      visible3: false,
    };
  }
  submenu = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  submenu1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };
  submenu2 = () => {
    this.setState({
      visible2: !this.state.visible2,
    });
  };
  submenu3 = () => {
    this.setState({
      visible3: !this.state.visible3,
    });
  };
  render() {
    const crnt_url = window.location.pathname;
    let permissionData = this.props.commonData.pagePermission();
    let sidebarSvgFiles = getData(permissionData);
    let cuIndex = _.findIndex(sidebarSvgFiles, { url: crnt_url });

    let actionPerform = true;
    let messagePerform = true;
    let userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    if (userData && userData.account && !userData.account.isKycUploaded) {
      actionPerform = false;
      messagePerform = 'Please upload your KYC documents to access this functionality.';
    } else if (userData && userData.account && userData.account.isApproved !== KYC_STATUS.APPROVED) {
      if (userData.account.isApproved === KYC_STATUS.REJECT) {
        messagePerform = 'Your KYC is rejected. Please upload your KYC again with right information.';
      } else {
        messagePerform = 'You will access this functionality once your KYC is approved.';
      }
      actionPerform = false;
      // KYC under process
    }

    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <SmallHeader
          // className={btnClass || btnClass1 || btnClass2}
          // onClick={this.HeaderStripCick}
          />
          <ul id="links" className="">
            <li>
              <Link className="menuItem" to="/dashboard">
                Dashboard
              </Link>
            </li>
            <li className={'d-flex align-items-center'}>
              <Link to="/diamond-search" className="menuItem active">
                Search
              </Link>
            </li>
            <li>
              <a
                onClick={() => {
                  if (!actionPerform) {
                    OpenNotification({
                      type: 'error',
                      title: messagePerform,
                    });
                  } else {
                    this.props.history.push('/stone-of-the-day');
                  }
                }}
                className="menuItem"
              >
                Best Buy
              </a>
            </li>
            <li>
              <a
                className="menuItem"
                onClick={() => {
                  if (!actionPerform) {
                    OpenNotification({
                      type: 'error',
                      title: messagePerform,
                    });
                  } else {
                    this.props.history.push('/new-arrivals');
                  }
                }}
              >
                <IntlMessages id="app.NewArrivals" />
              </a>
            </li>

            {/* <li>
              <Link className="menuItem" to="/upcoming">
                <IntlMessages id="app.Upcoming" />
              </Link>
            </li> */}
            <li>
              <Link className="menuItem" to="/account">
                <IntlMessages id="app.myAccount" />
              </Link>
            </li>
            <li onClick={commonLogout}>
              <a className="menuItem">Log Out</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = function (props) {
  return props;
};

export default connect(mapStateToProps)(withRouter(MobileSidebar));
