import React, { memo } from 'react'
import Heading from '../common/Heading'
import { ListingCards } from '../common/DiamondListing/MobileComfirmStone'
import { emptyData } from './index'
import { getPath, LISTINGPAGES } from './DiamondListFunctions'
import MobileGroupCard from '../common/MobileDiamondList/MobileGroupCard'
import moment from 'moment'

const getGroupedData = (arr, key) => {
    let data = []
    let iter = -1
    arr.map(x => {
        if (x[key]) iter += 1
        if (!data[iter]) data[iter] = []
        data[iter].push(x)
        return true
    })
    return data
}

const MobileViewList = props => {
    // state = {}

    // showDrawer = () => {
    //     this.setState({
    //         visible: true
    //     })
    // }
    // onClose = () => {
    //     this.setState({
    //         filterOpen: false,
    //         visible: false
    //     })
    // }
    // filter = () => {
    //     this.setState({
    //         filterOpen: true
    //     })
    // }

    let self = props.parent
    let groupData =
        getPath() === LISTINGPAGES.QUOTE
            ? getGroupedData(props.data, 'isOfferHeader')//props.data.map(dt => [dt])
            : getPath() === LISTINGPAGES.OFFICE
                ? getGroupedData(props.data, 'isOfficeHeader')
                : getPath() === LISTINGPAGES.MATCHPAIR
                    ? getGroupedData(props.data, 'isMatchHeader')
                    : getPath() === LISTINGPAGES.CONFIRM
                        ? getGroupedData(props.data, 'isConfirmHeader')
                        : getPath() === LISTINGPAGES.UPCOMING
                            ? getGroupedData(props.data, 'isUpcomingHeader')
                            : null
    // <MobileGroupCard title="ROUND 0.21 H IF 2.96% EX EX EX 1297.3$/Cts">
    const title = item =>
        getPath() === LISTINGPAGES.QUOTE
            ? moment(item.updatedAt).format('DD/MM/YYYY')
            : getPath() === LISTINGPAGES.OFFICE
                ? moment(item.date).format('DD/MM/YYYY') +
                ' - [' +
                moment(item.cabinSlot[0].start).format('hh:mm A') +
                ' - ' +
                moment(item.cabinSlot[0].end).format('hh:mm A') +
                ']'
                : getPath() === LISTINGPAGES.MATCHPAIR
                    ? item.groupingTitle
                    : getPath() === LISTINGPAGES.CONFIRM
                        ? item.memoNo
                        : getPath() === LISTINGPAGES.UPCOMING
                            ? moment(item.inDt).format('DD/MM/YYYY') + ' 3 PM IST'
                            : null

    return (
        <div className='searchResultListWrapper'>
            <div className='selectStonHeader'>
                <div className='listingTopBlock width-100'>
                    <div className='d-flex justify-content-between align-items-center width-100'>
                        <Heading title={self.getTitle()} />
                        {self.getListTop ? self.getListTop() : null}

                    </div>
                    {self.getSelection ? self.getSelection() : null}
                </div>
            </div>
            <div className='searchInnerResult'>
                {props.listView ? (
                    <div className='diamondListMobile'>
                        {groupData ? (
                            groupData.map(lItem => {
                                return (
                                    <MobileGroupCard title={title(lItem[0])}>
                                        <ListingCards
                                            quotePage={getPath() === LISTINGPAGES.QUOTE}
                                            data={lItem}
                                            currentType={props.currentType}
                                        />
                                    </MobileGroupCard>
                                )
                            })
                        ) : (
                                <ListingCards
                                    data={props.data}
                                    note={getPath() === LISTINGPAGES.NOTE}
                                    currentType={props.currentType}
                                />
                            )}
                        {emptyData(props.loading, props.data.length)}
                    </div>
                ) : (
                        <div className='searchResultGridView'>{self.getGridView()}</div>
                    )}
                {self.getAction()}
            </div>
            {/* <Drawer
                title={false}
                onClose={this.onClose}
                visible={this.state.filterOpen}
                wrapClassName='mobileFilterSideBar'
            >
                <MobileFilterSidebar />
            </Drawer> */}
        </div>
    )
}

export default memo(MobileViewList)
