import React, { useState } from 'react';
import PopupStoneTable from '../common/DiamondListing/PopupStoneTable';
import { handleConfirmStone } from '../../components/DiamondList/DiamondListFunctions';
import IntlMessages from '../../util/IntlMessages';
import { Checkbox } from 'antd';
import OpenNotification from '../common/CommonButton/OpenNotification';

const PlaceOrderDetail = (props) => {
  const [remark, setRemark] = useState('');
  const [check, setCheck] = useState(false);
  return (
    <div className="diamondDetailRightPopup mt-10" style={props.style}>
      <h2 className="popupInnerTitle">Place Order</h2>
      <PopupStoneTable tableData={props.checked} />
      <div className="commonTextArea from-group">
        <label>Remarks</label>
        <textarea onChange={(e) => setRemark(e.target.value)}></textarea>
      </div>
      <div className="mt-10">
        <Checkbox
          checked={check}
          onChange={() => {
            setCheck(!check);
          }}
        >
          I have read and accept <a className="linkTag">Terms and Conditions.</a>
        </Checkbox>
      </div>
      <div className="sideBarPopupButton">
        <a
          className="commonButton"
          onClick={() => {
            if (!check) {
              OpenNotification({
                type: 'error',
                title: 'You must be agree to terms and condition to buy stone',
              });
            } else {
              if (props.checked[0].wSts == 'H' || props.checked[0].wSts == 'M') {
                OpenNotification({
                  type: 'error',
                  title: 'Selected stdone is in Hold or Memo',
                });
              } else {
                handleConfirmStone([props.checked[0].id], remark, () => {
                  props.onClose();
                });
              }
            }
          }}
        >
          <IntlMessages id="app.BuyNow" />
        </a>
        <a className="commonButton" onClick={props.onCancel}>
          Cancel Stone
        </a>
      </div>
    </div>
  );
};
export default PlaceOrderDetail;
