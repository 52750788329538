import React, { Component } from "react";
import _ from "lodash";
import Heading from "../Heading";
import {
  getColumn,
  handleSaveQuote,
  handleInsertTrack,
  isMobile,
} from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/Table";
import SelectStone, { calculate } from "./SelectStone";
import { Drawer } from "antd";
import "../../common/LeftMultipleSelect/leftOption.less";
import DatePicker from "../../common/DatePicker";
import CommonModal from "../CommonModal";
import moment from "moment";
import OpenNotification from "../CommonButton/OpenNotification";
import { trackTypeObj } from "../../../constants/Common";
import { CREATE_SCHEDULE, GET_SLOTS } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";
import head from "lodash/head";
import last from "lodash/last";
import split from "lodash/split";
import OfficeSidebarMobile from "./OfficeSidebarMobile";
import map from "lodash/map";
// const { RangePicker } = DatePicker;

class OfficeSidebarNew extends Component {
  state = {
    visible: false,
    columns: [],
    data: this.props.checked ? this.props.checked : [],
    checked: [],
    comment: "",
    expire: null,
    slots: [],
    date: moment().toISOString(),
  };

  componentDidMount() {
    let data = this.props.checked;
    let Columns = getColumn();
    let index = _.findIndex(Columns, { id: "ctPr" });
    if (!index) index = 1;
    let columns = [...Columns];
    this.getCabinSchedule();
    this.setState({ columns, data });
  }
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };
  dateHandleChange = (e, val) => {
    let da = null;
    if (e) {
      da = new Date(e._d).toISOString();
    }
    this.setState({ expire: da });
  };

  handleSave = () => {
    let self = this;
    let { comment, slot, date, apiSlot, meetingType, slots } = this.state;

    if (!date)
      return OpenNotification({
        type: "error",
        title: "Please select date.",
      });

    let start = moment(`${date} 9:00AM`, "YYYY-MM-DD HH:mm:ss").toISOString();
    let end = moment(`${date} 5:30PM`, "YYYY-MM-DD HH:mm:ss").toISOString();

    let obj = {
      purpose: comment,
      date: moment(date).toISOString(),
      type: 2,
      start: start,
      end: end,
      cabinSlot: [slots[0]],
      diamonds: map(this.props.checked, "id"),
    };

    let objData = {
      ...CREATE_SCHEDULE,
      request: obj,
    };

    UtilService.callApi(objData, function (err, data) {
      if (err) {
        return OpenNotification({
          type: "success",
          title: err.data.message,
        });
      }
      if (data && data.code === "OK") {
        OpenNotification({
          type: "success",
          title: data.message,
        });
        self.props.onClose();
        self.props.clearAll();
      }
    });
  };
  getCabinSchedule = () => {
    let self = this;
    let objData = {
      ...GET_SLOTS,
      request: { sort: [{ end: "ASC" }] },
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        self.setState({ slots: data.data.list });
      }
    });
  };
  getBottom = () => {
    return (
      <div className="confirmListOption mt-10">
        {/* <label>:</label> */}
        <DatePicker
          expiryDate={this.state.date ? this.state.date : ""}
          handleChange={(dt) => {
            this.setState({ date: moment(dt).toISOString() });
          }}
          single
          disabledDate={(current) => {
            return (
              current <= moment().startOf("day") ||
              moment(current).isoWeekday() === 7
            );
          }}
          label="Office Visit Date :"
        />
        <div className="commonTextArea from-group">
          <label>Comment</label>
          <textarea
            value={this.state.comment}
            onChange={(e) => this.setState({ comment: e.target.value })}
          ></textarea>
        </div>
      </div>
    );
  };
  render() {
    let { expire } = this.state;
    return isMobile() ? (
      <OfficeSidebarMobile
        {...this.state}
        checked={this.state.checked}
        parent={this}
      />
    ) : (
      <div>
        <div className="d-flex mb-20 popupTopStrip">
          <Heading
            className="popupInnerTitle mr-40 mb-0"
            title="Book office"
          ></Heading>
          {this.state.data.length > 0 && (
            <SelectStone
              sum={{}}
              buy
              checked={this.state.data}
              clearAll={() => this.setState({ checked: [] })}
              profilListing={false ? "profileStonSelect" : null}
            />
          )}
        </div>
        <div className="searchPopupCommon searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              finalDestination
              {...this.state}
              handleSort={(sort) => this.setState({ sort }, () => this.fetch())}
              handleCheck={(checked) => this.setState({ checked })}
              canSort={[]}
              filterArray={{}}
              selectedFilterArray={{}}
              selectFilter={() => {}}
              nodots
              noGrp
              noCheckBox
            />
          </div>
          <div className="detailPopupHalfBlock">{this.getBottom()}</div>
          <div className="sideBarPopupButton">
            <a
              className="commonButton"
              onClick={() => {
                this.handleSave();
                // let self = this
                // if (expire) {
                //     handleInsertTrack(trackTypeObj.OFFICE, self.state.data, () => {
                //         this.props.clearAll()
                //         this.props.onClose()
                //     }, expire, self.state.comment)
                // } else {
                //     OpenNotification({
                //         type: 'error',
                //         title: 'Please select office date'
                //     })
                // }
              }}
            >
              Book Office
            </a>
            <a className="commonButton" onClick={this.props.onClose}>
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default OfficeSidebarNew;
