import { SALES_DATA } from '../../constants/ActionTypes';

const INIT_STATE = {
  salesperson: {},
  fetaureData: [],
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SALES_DATA:
      return { ...state, salesPerson: payload };

    case '@@RESET':
      return { ...INIT_STATE };

    default:
      return state;
  }
};
