import React, { Component } from "react";
import SelectOption from "../common/SelectOption"
import InputBlock from "../common/InputBlock"
import "../../styles/PriceCalculator.less";
import { InputNumber } from 'antd';


const calc = {
  shape:[
    {name:"Round"},
    {name:"Round"},
    {name:"Round"},
    {name:"Round"}
  ],
  color:[
    {name:"D"},
    {name:"D"},
    {name:"D"},
    {name:"D"}
  ],
  clarity:[
    {name:"IF"},
    {name:"IF"},
    {name:"IF"},
    {name:"IF"}
  ]
}
class PriceCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        size: "",
        ct: "",
        total:"",
      },
    };
  }
  handleOnChange = (key, value) => {
    let { formData } = this.state;
    formData[key] = value;
    this.setState(formData);
  };
  render() {
    let {formData}  = this.state
    return (
      <>
        <div className="priceCalculatorBlock">
          <div className="calcClose" onClick={this.props.onClick}>
            <img src={require("../../assets/svg/close.svg")} />
          </div>
            <div className="priceCalculatorInner">
                <div className="calcTopBlock">
                  <h2>Your Diamond</h2>
                  <div className="priceSelectBlock">
                      <InputBlock 
                      value={formData.size}
                      onChange={(e) => this.handleOnChange("size", e.target.value)}
                       placeholder="Size" type="number" label="Size"/>
                      <SelectOption selectValue={calc.shape} placeholder="Round" label="Shape"></SelectOption>
                      <SelectOption selectValue={calc.color} placeholder="D" label="Color"></SelectOption>
                      <SelectOption selectValue={calc.clarity} placeholder="IF" label="Clarity"></SelectOption>
                  </div>
                </div>
                <div className="priceBlock">
                  <div className="priceViewLeft">
                    <h3>List Price</h3>
                      <div className="priceViewBlock">
                          <div className="priceValueItem">
                            <label>$/CT</label>
                            <span>162,500</span>
                          </div>
                          <div className="priceValueItem">
                            <label>$Total</label>
                            <span>5,687,500</span>
                          </div>
                      </div>
                  </div>
                  <div className="priceEditRight">
                    <h3>Your Price</h3>
                    <div className="priceEditBlock">
                        <div className="priceEditLeft">
                            <div className="priceEditItem">   
                                <InputBlock
                                value={formData.ct}
                                type="number"
                                onChange={(e) => this.handleOnChange("ct", e.target.value)}
                                label="$/CT"/>
                            </div>
                            <div className="priceEditItem">   
                                <InputBlock
                                value={formData.total}
                                type="number"
                                onChange={(e) => this.handleOnChange("total", e.target.value)}
                                label="$/Total"/>
                            </div>
                        </div>
                        <div className="rapPriceRight">
                            <label className="commonLabel">Rap%</label>
                            <InputNumber min={1} max={10} defaultValue={3} />
                        </div>
                    </div>
                  </div>
                </div>
                <div className="priceCalcType">
                    <div className="priceCalcTypeItem">7</div>
                    <div className="priceCalcTypeItem">8</div>
                    <div className="priceCalcTypeItem">9</div>
                    <div className="priceCalcTypeItem">4</div>
                    <div className="priceCalcTypeItem">5</div>
                    <div className="priceCalcTypeItem">6</div>
                    <div className="priceCalcTypeItem">1</div>
                    <div className="priceCalcTypeItem">2</div>
                    <div className="priceCalcTypeItem">3</div>
                    <div className="priceCalcTypeItem">+/-</div>
                    <div className="priceCalcTypeItem">0</div>
                    <div className="priceCalcTypeItem">
                      <img src={require("../../assets/svg/PriceCalc/remove.svg")} alt=""/>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  }
}

export default PriceCalculator;
