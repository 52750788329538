import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import find from 'lodash/find';
import { isMobile, getPath, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import { SELECT_STONE_TERMS_NEW } from '../../../constants/Common';
import { isArray } from 'util/utils';
import { isEmpty } from 'lodash';

const _ = require('lodash');

export function formatNumber(num) {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
}

export function calculate(dataList, termCals) {
  let sum_total = {
    total_pieces: 0,
    total_carat: 0,
    total_avg_rap: 0,
    final_discount: 0,
    final_price: 0,
    final_value: 0,
    total_rapaport: 0,
    avg_discount: 0,
    final_net_value: 0,
    final_rate: 0,
    final_term_discount: 0,
    avg_term_discount: 0,
  };
  let total = {
    fancy_total: { ...sum_total },
    non_fancy_total: { ...sum_total },
  };
  total.fancy_total.isFancy = 1;
  total.non_fancy_total.isFancy = 0;
  _.each(dataList, function (lst) {
    let use_total_key = lst.isFancy ? 'fancy_total' : 'non_fancy_total';
    total[use_total_key].total_pieces += 1;
    total[use_total_key].total_carat += lst.crt && Number(parseFloat(lst.crt)) ? Number(parseFloat(lst.crt)) : 0;
    lst.rap_avg = lst.rap ? Number(parseFloat(lst.rap)) * Number(parseFloat(lst.crt || 0)) : 0;
    total[use_total_key].total_avg_rap += lst.rap_avg;
    total[use_total_key].total_rapaport += lst.rap ? Number(parseFloat(lst.rap)) : 0;
    total[use_total_key].final_value += lst.amt || 0;
    total[use_total_key].final_net_value += lst.calcAmount || 0;
  });

  total.fancy_total.final_price =
    total.fancy_total.final_value && total.fancy_total.total_carat
      ? total.fancy_total.final_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_price =
    total.non_fancy_total.final_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.final_rate =
    total.fancy_total.final_net_value && total.fancy_total.total_carat
      ? total.fancy_total.final_net_value / total.fancy_total.total_carat
      : 0;
  total.non_fancy_total.final_rate =
    total.non_fancy_total.final_net_value && total.non_fancy_total.total_carat
      ? total.non_fancy_total.final_net_value / total.non_fancy_total.total_carat
      : 0;
  total.fancy_total.total_avg_rap = total.fancy_total.total_avg_rap
    ? total.fancy_total.total_avg_rap / total.fancy_total.total_carat
    : 0;
  total.non_fancy_total.total_avg_rap = total.non_fancy_total.total_avg_rap
    ? total.non_fancy_total.total_avg_rap / total.non_fancy_total.total_carat
    : 0;
  total.fancy_total.final_discount =
    total.fancy_total.final_price && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_price / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_discount =
    total.non_fancy_total.final_price && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_price / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  total.fancy_total.final_term_discount =
    total.fancy_total.final_rate && total.fancy_total.total_avg_rap
      ? (1 - total.fancy_total.final_rate / total.fancy_total.total_avg_rap) * -100
      : 0;
  total.non_fancy_total.final_term_discount =
    total.non_fancy_total.final_rate && total.non_fancy_total.total_avg_rap
      ? (1 - total.non_fancy_total.final_rate / total.non_fancy_total.total_avg_rap) * -100
      : 0;
  let allTotal = {
    total_pieces: total.fancy_total.total_pieces + total.non_fancy_total.total_pieces,
    total_carat: total.fancy_total.total_carat + total.non_fancy_total.total_carat,
    final_rapaport: total.fancy_total.total_avg_rap + total.non_fancy_total.total_avg_rap,
    final_discount: total.fancy_total.final_discount
      ? total.fancy_total.final_discount
      : total.non_fancy_total.final_discount,
    final_value: total.fancy_total.final_value + total.non_fancy_total.final_value,
    final_price:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_value + total.non_fancy_total.final_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    total_rapaport: total.fancy_total.total_rapaport + total.non_fancy_total.total_rapaport,
    final_net_value: total.fancy_total.final_net_value + total.non_fancy_total.final_net_value,
    final_rate:
      total.fancy_total.total_carat + total.non_fancy_total.total_carat
        ? (total.fancy_total.final_net_value + total.non_fancy_total.final_net_value) /
          (total.fancy_total.total_carat + total.non_fancy_total.total_carat)
        : 0,
    final_term_discount: total.fancy_total.final_term_discount
      ? total.fancy_total.final_term_discount
      : total.non_fancy_total.final_term_discount,
  };
  allTotal.avg_discount = allTotal.final_rapaport ? allTotal.final_price / allTotal.final_rapaport : 0;
  allTotal.avg_term_discount = allTotal.final_rapaport ? allTotal.final_rate / allTotal.final_rapaport : 0;
  // if (termCal) {
  //   let avg = allTotal.final_discount + (((100 - allTotal.final_discount) * termCal) / 100);
  //   let final = ((100 + avg) / 100) * allTotal.final_rapaport
  //   let amount = final * allTotal.total_carat
  //   // Calculation for term
  //   allTotal.addDis = termCal
  //   allTotal.final_discount = avg
  //   allTotal.final_term_discount = avg
  //   allTotal.final_price = final
  //   allTotal.final_rate = final
  //   allTotal.final_net_value = amount
  //   allTotal.final_value = amount
  // }
  if (termCals) {
    let termCal = find(SELECT_STONE_TERMS_NEW, { id: termCals }).value;
    let avg = allTotal.final_discount + ((100 - allTotal.final_discount) * termCal) / 100;
    let final = ((100 + avg) * allTotal.final_rapaport) / 100;
    let amount = final * allTotal.total_carat;

    // Calculation for term
    allTotal.addDis = termCal;
    allTotal.addDis = termCal;
    allTotal.final_discount = avg;
    allTotal.final_term_discount = avg;
    allTotal.final_price = final;
    allTotal.final_rate = final;
    allTotal.final_net_value = amount;
    allTotal.final_value = amount;
    // allTotal.final_term_discount = avg
    // allTotal.final_net_value = amount
    // allTotal.final_rate = final
  }
  return allTotal;
}

const SelectStone = (props) => {
  const [termCal, setTermCal] = useState('3');

  const selected = useSelector((state) => state.diamondData?.selectedRows?.[props.currentType]);
  const checked = React.useMemo(() => {
    if (isArray(props.checked) && !isEmpty(props.checked)) return props.checked;
    if (isArray(selected) && !isEmpty(selected)) return selected;
    return [];
  }, [props.checked, selected]);

  const dispatch = useDispatch();
  const clearAll = useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);

  const PATH = getPath() === LISTINGPAGES.CART || getPath() === LISTINGPAGES.QUOTE;
  const summation = useMemo(() => calculate(checked, termCal), [checked, termCal]);

  const objects = useMemo(() => {
    const sum = props.sum || {};

    return [
      {
        label: <IntlMessages id="app.Pieces" />,
        check: summation.total_pieces,
        sum: sum.count || sum.pieces || 0,
      },
      {
        label: <IntlMessages id="app.Carats" />,
        check: parseFloat(summation.total_carat),
        sum: parseFloat(sum.totalCarat || 0),
      },
      {
        label: <span>Rap Price</span>,
        check: parseFloat(summation.final_rapaport),
        sum: parseFloat(sum.rapPrice || 0),
      },
      {
        label: <span>Avg. Dis</span>,
        check: parseFloat(summation.final_discount),
        sum: parseFloat(sum.avgDiscount || 0),
      },
      getPath() === LISTINGPAGES.CART || getPath() === LISTINGPAGES.QUOTE
        ? {
            label: <span>Add Dis</span>,
            check: summation.addDis ? summation.addDis : 0,
            sum: Math.round(summation.addDis || 0),
          }
        : null,

      {
        label: <span>Total Pr/Ct</span>,
        check: Math.round(summation.final_price),
        sum: Math.round(sum.totalPricePerCarat || 0),
      },
      {
        label: <IntlMessages id="app.Amount" />,
        check: Math.round(summation.final_value),
        sum: Math.round(sum.totalAmount || 0),
      },
    ];
  }, [props.sum, summation]);
  // let userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  // return (
  //     <div className={`selectStoneValueBlock ${props.profilListing}`}>
  //         {objects.map(item => {
  //             return (
  //                 <div className='selectStoneValueItem'>
  //                     <span className='selectStoneLabel'>{item.label} : </span>
  //                     <span className='selectStopnValue'>
  //                         <span className='redColor'>{formatNumber(item.check)}</span>
  //                         {!props.buy &&
  //                             <>
  //                                 /{formatNumber(item.sum)}
  return getPath() === LISTINGPAGES.UPCOMING || getPath() === 'my-purchase' || getPath() === 'confirm-list' ? (
    <></>
  ) : isMobile() ? (
    <div className="mobileStoneSelect">
      {objects.map((item, ind) => {
        return (
          <>
            {item && (
              <div className="mobileSelectStoneItem" key={item.label + ind}>
                {checked.length ? (
                  <span style={{ color: 'red', fontWeight: '500' }}>{formatNumber(item.check)}/</span>
                ) : null}
                <span style={{ fontWeight: '600', color: '#000' }}>{formatNumber(isNaN(item.sum) ? 0 : item.sum)}</span>
                <span>{item.label}</span>
              </div>
            )}
          </>
        );
      })}
      {PATH && (
        <div className="mobileSelectStoneItem">
          <span style={{ fontWeight: '600', color: '#000' }}>TERMS : </span>
          <select
            name={termCal}
            value={termCal}
            placeholder="Select Terms"
            onChange={(e) => {
              // let s = calculate(checked, 2)
              setTermCal(e.target.value);
            }}
          >
            {/* <option disabled>Select Terms</option> */}

            {SELECT_STONE_TERMS_NEW.map((x) => (
              <option value={x.id}>{x.name}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  ) : (
    checked.length > 0 && (
      <div className={`selectStoneValueBlock ${props.profilListing}`} style={props.blockStyle || {}}>
        {objects.map((item, i) => {
          return (
            <>
              {item && (
                <div className="selectStoneValueItem" key={item.label + i}>
                  <span className="selectStoneLabel">{item.label} : </span>
                  <span className="selectStopnValue">
                    <span className="redColor">{formatNumber(item.check)}</span>
                    {!props.buy && <>/{formatNumber(item.sum)}</>}
                  </span>
                </div>
              )}
            </>
          );
        })}
        {PATH && (
          <div className="selectStoneValueItem">
            <span className="selectStoneLabel">TERMS : </span>
            <select
              name={termCal}
              value={termCal}
              placeholder="Select Terms"
              onChange={(e) => {
                // let s = calculate(checked, 2)
                setTermCal(e.target.value);
              }}
            >
              {/* <option value='0' disabled>Select Terms</option> */}

              {SELECT_STONE_TERMS_NEW.map((x) => (
                <option value={x.id}>{x.name}</option>
              ))}
            </select>
          </div>
        )}
        {!props.buy && props.profilListing && (
          <div className="searchStoneClose" onClick={clearAll} style={props.clearStyle || {}}>
            Clear all
          </div>
        )}
      </div>
    )
  );
};
export default memo(SelectStone);
