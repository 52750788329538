import React from "react";
import CommonModal from "./common/CommonModal";
export const GuestUserPopup = (props) => {
  return (
    <div>
      <CommonModal
        visible={props.visible}
        handleOk={props.register}
        handleCancel={() => props.handleCancel()}
        title="Note"
        modalSize="xs-size"
        footerShow
        submitTitle="Register"
        cancelTitle="Continue as a Guest"
      >
        <div>
          <p>
            You are currently logged in as a guest user. Please register
            yourself to access this page!
          </p>
        </div>
      </CommonModal>
    </div>
  );
};
