import React from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import SortingFilterDropdownItem from './OverlayItemBack'

const SortingFilterDropdown = props => {
  const { column, sortBy, setSortBy, toggleSortBy } = props;

  return (
    <div className='menuInnerFilterOption'>
      <div className='menuInnerFilterInner'>
        {!!sortBy.length && (
          <>
            <div className='llSortingTitle'>Sorting Order</div>
            <div className='llSortingTitleList'>
              <DndProvider backend={HTML5Backend}>
                {sortBy.map((sort, i) => {
                  return (
                    <SortingFilterDropdownItem
                      key={sort.id}
                      index={i}
                      setSortBy={setSortBy}
                      currentSort={sort}
                      sortBy={sortBy}
                      toggleSortBy={toggleSortBy}
                    />
                  )
                })}
              </DndProvider>
            </div>
          </>
        )}
        {
          column.canFilter && column.render('Filter')
        }
      </div>
    </div>
  )
}
export default SortingFilterDropdown
