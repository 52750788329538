import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../util/IntlMessages';
import { NOTIFICATION_LIST, NOTIFICATION_READ } from '../../../constants/apiConstant';
import UtilService from '../../../services/util';
import { NOTIFY_REDIRECT } from '../../../constants/Common';
import { withRouter } from 'react-router-dom';
import { NOTIFY_STATUS } from '../../../constants/Common';
const NotificationItem = (props) => {
  return (
    <>
      <div className="notificationTitle">{props.status && props.status}</div>

      <div onClick={props.onClick} className={`notificationDropdownDetail ${!props.read ? '' : 'readMessage'}`}>
        <h2>{props.title}</h2>
        {props.pcv && (
          <div className="d-flex flex-wrap basicDetail">
            <div className="detail">
              <span className="mr-5">{props.pcv}</span>
            </div>
            {/* <div className="detail"><span className="mr-5">Carat : </span><span>0.10</span></div>
                    <div className="detail"><span>Value : </span><span>$1,000,00</span></div> */}
          </div>
        )}
        <span>{props.time}</span>
      </div>
    </>
  );
};

const NotificationDropdown = (props) => {
  const [notifyData, setNotifyData] = useState([]);
  const [tempFalg, setTempFalg] = useState(true);

  useEffect(() => {
    if (tempFalg) {
      getNotify();
      setTempFalg(false);
    }
  }, []);
  const getNotify = () => {
    let obj = {
      ...NOTIFICATION_LIST,
      request: { page: 1, limit: 10 },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
        setNotifyData([]);
      } else if (data && data.code === 'OK') {
        if (data.data && data.data.list && data.data.list.length) {
          setNotifyData([...data.data.list]);
          props.getUpdatedCount(data.data.unReadCount);
        }
      } else {
        setNotifyData([]);
      }
    });
  };
  const readNotify = (id) => {
    props.handleOut();

    let obj = {
      ...NOTIFICATION_READ,
      request: {
        id: id,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === 'OK') {
        props.getNotify();
      }
    });
  };
  const redirectionNotify = (activity) => {
    switch (activity) {
      case NOTIFY_REDIRECT.OFFER:
        props.history.push('/account/quote-list');

        break;
      case NOTIFY_REDIRECT.CART:
        props.history.push('/account/cart');

        break;
      case NOTIFY_REDIRECT.WATCHLIST:
        props.history.push('/account/watchlist');
        break;
      case NOTIFY_REDIRECT.ORDER:
        props.history.push('/account/confirm-list');

        break;
      case NOTIFY_REDIRECT.ENQUIRY:
        props.history.push('/account/enquiry');

        break;
      case NOTIFY_REDIRECT.OFFICE:
        props.history.push('/account/office-list');

        break;

      default:
        props.history.push('/account/cart');

        break;
    }
  };
  const getDateShow = (actualDates) => {
    let second = 1000;
    let minute = second * 60;
    let hour = minute * 60;
    let day = hour * 24;
    let months;

    let dateDisplay = {
      hours: '',
      status: '',
    };
    // let today = new Date()
    let todays = new Date(actualDates);
    let oneDayLess = new Date(new Date().setDate(new Date().getDate() - 2));
    let timediff = new Date() - todays;
    let todayDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    let oneLess = new Date(oneDayLess.getFullYear(), oneDayLess.getMonth(), oneDayLess.getDate());

    let dynamicDate = new Date(todays.getFullYear(), todays.getMonth(), todays.getDate());
    //
    if (todayDate <= dynamicDate) {
      let hours = Math.floor(timediff / hour);
      if (hours < 1) {
        let m = Math.floor(timediff / minute);
        let disMinute = `${m}`.replace('-', '');

        dateDisplay = {
          hours: `${disMinute} minutes ago`,
          status: NOTIFY_STATUS.TODAY,
        };
      } else {
        let disHours = `${hours}`.replace('-', '');

        dateDisplay = {
          hours: `${disHours} hours ago`,
          status: NOTIFY_STATUS.TODAY,
        };
      }
    } else if (oneLess <= dynamicDate) {
      let hours = Math.floor(timediff / hour);
      let disHour = `${hours}`.replace('-', '');

      dateDisplay = {
        hours: `${disHour} hours ago`,
        status: NOTIFY_STATUS.YESTERDAY,
      };
    } else {
      let daysCount = Math.floor(timediff / day);
      if (daysCount > 30) {
        months = (todays.getFullYear() - new Date().getFullYear()) * 12;
        months -= new Date().getMonth() + 1;
        months += todays.getMonth();
        let dis = `${months}`.replace('-', '');
        dateDisplay = {
          hours: `${dis} months ago`,
          status: NOTIFY_STATUS.PAST,
        };
      } else {
        let disDay = `${daysCount}`.replace('-', '');
        dateDisplay = {
          hours: `${disDay} days ago`,
          status: NOTIFY_STATUS.PAST,
        };
      }
    }
    return dateDisplay;
  };

  let tempFlagToday;
  let tempFlagYesterday;
  let tempFlagPast;
  return (
    <div className="notificationDropdown">
      <div className="notificationItem">
        <div className="notificationInner">
          {notifyData && notifyData.length !== 0
            ? notifyData.map((not, ind) => {
                let display = getDateShow(not.createdAt);
                let tempStatus = '';
                if (display && display.status === NOTIFY_STATUS.TODAY) {
                  if (!tempFlagToday) {
                    tempFlagToday = true;
                    tempStatus = NOTIFY_STATUS.TODAY;
                  } else {
                    tempStatus = '';
                  }
                } else if (display && display.status === NOTIFY_STATUS.YESTERDAY) {
                  if (!tempFlagYesterday) {
                    tempFlagYesterday = true;
                    tempStatus = NOTIFY_STATUS.YESTERDAY;
                  } else {
                    tempStatus = '';
                  }
                } else {
                  if (!tempFlagPast) {
                    tempFlagPast = true;
                    tempStatus = NOTIFY_STATUS.PAST;
                  } else {
                    tempStatus = '';
                  }
                }
                return (
                  <NotificationItem
                    onClick={() => {
                      redirectionNotify(not.activityType);
                      readNotify(not.id);
                    }}
                    pcv={not.message}
                    status={tempStatus}
                    read={not.isRead}
                    title={not.title}
                    time={display && display.hours ? display.hours : '--'}
                  />
                );
              })
            : 'No Notification Found'}
          <div className="bg-color-white">
            <a href="/notification" className="viewLink notification-sticky">
              <span>View All Notifications</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(NotificationDropdown);
