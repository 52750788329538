import React, { Component } from "react";
import Heading from "../common/Heading";
import IntlMessages from "../../util/IntlMessages";

class TermsConditions extends Component {
  render() {
    return (
      <div className="dotList">
        <Heading title={<IntlMessages id="app.signup.temsCondition" />} className="p-0 mb-20" />
        <Heading className="popupInnerTitle mt-20" title="Bid Details:" />
        <p>Blind Bidding will start from 03:00 PM IST daily and End next day at 10:59 AM IST.</p>
        <p>
          Look & Bid will start at 11:00 AM IST daily and End same day at 02:59 PM IST (Except
          Holidays).
        </p>
        <Heading className="popupInnerTitle mt-20" title="Bid Result Details:" />
        <p>The results for Blind Bid will be declared daily at 11:00 AM IST.</p>
        <p>The results for Look & Bid will be declared daily at 03:00 PM IST (Except Holidays).</p>
        <Heading className="popupInnerTitle mt-20" title="Disclaimer:" />
        <p>
          Bidders will be able to bid at the Base Price or Higher Price. No bids will be accepted
          below the Base Price.
        </p>
        <p>
          Daily at 03:00 PM IST inventory under “New Arrivals” will be replaced with the new
          inventory and bidding process will start again.
        </p>
        <p>
          Please note inventory uploaded under “New Arrivals” on Saturday at 03:00 PM IST will be
          available till Monday for Blind Bid till 10:59 AM and for Look & Bid till 02:59 PM.
        </p>
        <p>
          Finestar Jewellery & Diamonds Pvt. Ltd. will notify the successful bidder via an e-mail
          and the respective sales person will contact you.
        </p>
        <p>
          Stone(s) will be available for bid to multiple bidders and will not be on Hold for any
          single bidder. If stone(s) have multiple bid(s) at the same price then the
          earliest/quickest bid will be considered.
        </p>
        <p>
          {" "}
          The identity of the Bidder and the amount of bid will remain confidential with Finestar
          Jewellery & Diamonds Pvt. Ltd.
        </p>
        <p>All the bids will be US Dollar per carat.</p>
        <p>
          Highest bid will be considered as Sale and bidder will have to make the payment as per the
          bidding terms and they will not have an option to deny the purchase.
        </p>
        <p>
          Once the New Arrival stones come in regular stock, Quote option will not be available on
          stones which are below 3ct for the next 7 days.
        </p>
        <p>
          Finestar Jewellery & Diamonds Pvt. Ltd. holds the right to change/modify or eliminate any
          bid(s)/rule(s) at its own discretion.
        </p>
        <p>For any further queries/clarifications please contact your respective sales person.</p>
        {/* <button className="commonButton">Ok</button> */}
      </div>
    );
  }
}
export default TermsConditions;
