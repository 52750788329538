import React from 'react';
import closeThemeSvg from 'assets/svg/DiamondList/close-theme.svg';
import { isFunction } from 'lodash';
import { classNames } from 'util/utils';

function MainFilterTop(props) {
  const { closeOff, id, title, handleRemoveFilter } = props;
  const { className, isActive,image,  noClose, onClose, ...rest } = props;

  const handleClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      if (isFunction(onClose)) return onClose(id);
      if (isFunction(handleRemoveFilter)) return handleRemoveFilter(id);
    },
    [id, onClose, handleRemoveFilter],
  );

  const canClose = React.useMemo(() => !noClose || !closeOff, [noClose, closeOff]);

  return (
    <div className={classNames([`searchInnerFilterItem`, className, isActive && 'active'])} {...rest}>
      <span className="searchInnerFilterTitle">{title}</span>
      <span className="searchMainFilterIcon filterView">
        <img src={image} alt="" />
      </span>
      {canClose && (
        <span onClick={handleClick} className="searchMainFilterIcon filterClose">
          <img src={closeThemeSvg} alt="close" />
        </span>
      )}
    </div>
  );
}

export default React.memo(MainFilterTop);
