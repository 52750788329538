import React, { useCallback } from 'react';
import { floatkeys, roundkeys, FILTER_COLUMNS, DISPLAY_TOTAL } from './TableBack';
import { formatNumber } from 'components/common/DiamondListing/SelectStone';
import OpenNotification from 'components/common/CommonButton/OpenNotification';
import UtilService from 'services/util';
import {
  BASE_DOWNLOAD_URL,
  trackTypeObj,
  LOCAL_STORAGE_VAR,
  DIAMONDCONFIRM,
  BASE_URL,
  KYC_STATUS,
  DECIMAL_POINTS,
} from 'constants/Common';
import moment from 'moment';
import {
  SEARCH_DIAMOND_LIST,
  FETCH_CONFIRM_STONE,
  FETCH_DIAMOND_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  PRINT_PDF,
  EXPORT_EXCEL,
  CONFIRM_STONE,
  DELETE_TRACK,
  CREATE_BID,
  SEND_EMAIL_XRAY,
  FETCH_NOTES,
  CREATE_MATCH_PAIR,
  DOWNLOAD_ZIP,
  COUNTER_OFFER,
  DOWNLOAD_FILE,
  NOTE_UPSERT,
  DOWNLOAD_ZIP_EXCEL,
  PROJECT_SETTING,
  FETCH_OFFICE_DIAMOND,
  DELETE_OFFICE_DIAMOND,
  BLOCK_MASTERS,
  ADD_HOLD,
  GET_BLOCK_DIAMONDS,
  DIAMOND_TRACK_STATUS_UPDATE,
  GET_DIAMOND_DATA,
} from 'constants/apiConstant';
import { Tooltip } from 'antd';
import { isEmpty, isArray, isNumeric, formatDecimal, isNumber } from 'util/utils';
import { get, split, cloneDeep, each, find, groupBy, last, omit, size, uniq, uniqBy } from 'lodash';

import indiaSvg from 'assets/img/Flags/india.svg';
import hongkongPng from 'assets/img/Flags/hongkong.png';
import usaJpg from 'assets/img/Flags/usa.jpg';
import dubaiPng from 'assets/img/Flags/dubai.png';
import belgiumPng from 'assets/img/Flags/belgium.png';

const FLAGICON = {
  INDIA: indiaSvg,
  HONGKONG: hongkongPng,
  NEWYORK: usaJpg,
  DUBAI: dubaiPng,
  BELGIUM: belgiumPng,
  MUMBAIOFFICE: indiaSvg,
};

export const LISTINGPAGES = {
  LIST: 'diamond-list',
  DETAILS: 'diamond-detail',
  DETAIL: 'diamond-detail',
  UPCOMING: 'upcoming',
  DASHBOARD: 'dashboard',
  SEARCH: 'diamond-search',
  FANCY_SEARCH: 'fancy-search',
  CART: 'cart',
  WATCHLIST: 'watchlist',
  REMINDER: 'reminder',
  CONFIRM: 'confirm-list',
  BID: 'new-arrival-bid-it',
  QUOTE: 'quote-list',
  STONE_OF_DAY: 'stone-of-the-day',
  NEWARR: 'new-arrivals',
  MATCHPAIR: 'match-pair',
  OFFICE: 'office-list',
  PURCHASE: 'my-purchase',
  SAVEDSEARCH: 'saved-search',
  MYDEMAND: 'my-demand',
  ENQUIRY: 'my-enquiry',
  HOLD: 'hold',
  NOTE: 'notes',
};

export const TITLE = {
  [LISTINGPAGES.LIST]: 'Diamond List',
  [LISTINGPAGES.UPCOMING]: 'Upcoming',
  ['BID']: "New Arrival 'Just Bid It'",
  [LISTINGPAGES.STONE_OF_DAY]: 'Best Buy',
  [LISTINGPAGES.NEWARR]: 'New Arrivals',
  [LISTINGPAGES.MATCHPAIR]: 'Match Pair',
  [LISTINGPAGES.QUOTE]: 'My Offer',
  [LISTINGPAGES.CART]: 'My Cart',
  [LISTINGPAGES.WATCHLIST]: 'My Watchlist',
  [LISTINGPAGES.REMINDER]: 'My Reminder',
  [LISTINGPAGES.CONFIRM]: 'My Order List',
  [LISTINGPAGES.OFFICE]: 'My Office List',
  [LISTINGPAGES.PURCHASE]: 'My Purchase',
  [LISTINGPAGES.HOLD]: 'My Hold List',
  [LISTINGPAGES.ENQUIRY]: 'My Enquiry',
};

export function getPath() {
  return last(window.location.pathname.split('/'));
}

const ColumnFilter = React.memo(function ColumnFilter(props) {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={require('assets/svg/DiamondList/close-black.svg')} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions.length ? (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
});

function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;

export function isMobile() {
  return window.innerWidth <= 767 ? true : false;
}
export function isIpad() {
  return window.innerWidth <= 991 ? true : false;
}

export function getColumn(columnList = null) {
  let Columns = [];
  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`) || columnList) {
    let getColumnList = columnList ? columnList : JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`));
    getColumnList.map((dataa) => {
      let data = {};
      Object.keys(dataa).map((x) => (data[x.trim()] = dataa[x]));
      if (data.isActive && data.field) {
        data.title = data.title.trim();
        data.field = data.field.trim();
        let userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
        // if (userData && userData.account) {
        //   if (userData.account.isApproved !== KYC_STATUS.APPROVED) {
        //     if (data.field === 'amt' || data.field === 'ctPr' || data.field === 'back') return false;
        //   }
        // } else {
        //   if (data.field === 'amt' || data.field === 'ctPr' || data.field === 'back') return false;
        // }
        const canFilter = FILTER_COLUMNS.includes(data.field);
        if (data.field === 'reminderDate' && getPath() !== LISTINGPAGES.REMINDER) return false;
        // if (data.field === "remarks" && getPath() !== LISTINGPAGES.CONFIRM)
        //   return false;
        let remarksList = [LISTINGPAGES.ENQUIRY, LISTINGPAGES.NOTE];

        if (data.field === 'remarks' && !remarksList.includes(getPath())) return false;
        if (data.field === 'remarks' && remarksList.includes(getPath()))
          data.title = getPath() === LISTINGPAGES.NOTE ? 'Notes' : 'Enquiry';

        Columns.push({
          Header: data.title,
          accessor: data.field,
          id: data.field,
          desc: data.desc,
          link: data.link,
          Filter: canFilter ? ColumnFilter : <></>,
          filter: filterArrayIncludes,
          Cell({ row, cell }) {
            // className certificateNo redColor greenColor
            return (
              <div
                className={
                  data.field === 'stoneId' || data.field === 'vStnId'
                    ? 'underline'
                    : floatkeys.includes(data.field) || roundkeys.includes(data.field)
                    ? 'numberValue'
                    : null
                }
              >
                {data.isFlag && cell.value ? (
                  typeof data.sLabel === 'string' ? (
                    data.sLabel
                  ) : (
                    'Yes'
                  )
                ) : data.isFlag && !cell.value ? (
                  typeof data.nLabel === 'string' ? (
                    data.nLabel
                  ) : (
                    '-'
                  )
                ) : cell.value === true ? (
                  'Y'
                ) : data.field === 'amt' ? (
                  <div className={'priceChange'}>
                    {isNumeric(row.original.amt) ? formatDecimal(row.original.amt) : '-'}
                  </div>
                ) : data.field == 'remarks' ? (
                  <pre> {row.original.remarks} </pre>
                ) : data.field === 'reminderDate' ? (
                  moment(row.original.reminderDate).format('LLL')
                ) : row.original.isFcCol === true && data.field === 'colNm' ? (
                  <div className="normalTableDropdown">
                    <span className="tableDropdownTitle">{`${row.original.intenNm} ${row.original.ovrtnNm}`}</span>
                    <div className="tableDropdown">
                      {[row.original.intenNm, row.original.ovrtnNm, row.original.fcColNm].filter(Boolean).map((d) => (
                        <span key={d}>{d}</span>
                      ))}
                    </div>
                  </div>
                ) : data.field === 'locNm' ? (
                  <>
                    <Tooltip title={cell.value && cell.value.toUpperCase()}>
                      {FLAGICON[cell.value && cell.value.toUpperCase().split(' ').join('')] ? (
                        <img
                          className="tablemapImg"
                          width="30px"
                          src={FLAGICON[cell.value && cell.value.toUpperCase().split(' ').join('')]}
                          alt={row.original[data.field]}
                        />
                      ) : (
                        cell.value
                      )}
                    </Tooltip>
                  </>
                ) : floatkeys.includes(data.field) ? (
                  isNumeric(cell.value) ? (
                    formatNumber(parseFloat(cell.value))
                  ) : (
                    '-'
                  )
                ) : roundkeys.includes(data.field) ? (
                  isNumeric(cell.value) ? (
                    Math.round(cell.value)
                  ) : (
                    '-'
                  )
                ) : data.field === 'lbNm' && row.original.sSts === 'C' ? (
                  (cell.value = row.original.sStsDesc)
                ) : !cell.value ? (
                  '-'
                ) : data.field === 'shdNm' && row.original.shdNm === 'NONE' ? (
                  '-'
                ) : data.field === 'mlkNm' && row.original.mlkNm === 'NONE' ? (
                  '-'
                ) : data.field === 'mixTintNm' && row.original.mixTintNm === 'NONE' ? (
                  '-'
                ) : data.field == 'remarks' ? (
                  <pre> (row.original.remarks) </pre>
                ) : (
                  cell.value
                )}
              </div>
            );
          },
        });
      }
    });
  }
  return Columns;
}

export function handleAlter(array, item, key) {
  let checked = cloneDeep(array) || [];
  if (find(checked, { [key]: item[key] })) checked = checked.filter((el) => el[key] !== item[key]);
  else checked.push(item);
  return checked;
}

export function getParams(props_row) {
  let params = [
    // inside ? null : props_row.shpNm,
    // inside ? null : props_row.lbNm,
    parseFloat(props_row.crt),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    // inside ? null : props_row.fluNm
  ].filter(function (el) {
    return el && el;
  });
  return params;
}

export function getStoneInfo(props_row) {
  let params = [
    props_row.vStnId,
    props_row.shpNm,
    parseFloat(props_row.crt),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter(function (el) {
    return el && el;
  });
  return params;
}

export function getStoneTitle(obj) {
  let params = obj.map((p) => ({
    shp: p.shpNm,
    crt: parseFloat(p.crt),
    col: p.isFcCol ? p.fcColNm : p.colNm,
    clr: p.clrNm,
    cut: p.cutNm,
    pol: p.polNm,
    sym: p.symNm,
  }));
  let display = [];
  let keyArr = ['shp', 'crt', 'col', 'clr', 'cut', 'pol', 'sym'];
  keyArr.map((key) => {
    let word = groupBy(params, key);
    if (size(word) === 1) display.push(params[0][key]);
  });
  display = display.filter((el) => el && el).join(' ');
  return display;
}

export function compareStones(list) {
  if (list.length === 0) {
    OpenNotification({
      type: 'error',
      title: 'Please select minimum two diamonds to compare.',
    });
    return;
  }
  if (list.length > 15) {
    OpenNotification({
      type: 'error',
      title: 'You can compare maximum 15 diamonds.',
    });
    return;
  }
  let data =
    // localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
    //     ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
    //     :
    [];

  list.map((x) => data.push(window.location.pathname === '/account/confirm-list' ? x.diamondId : x.id));
  data = uniq(data);
  if (data.length === 1) {
    OpenNotification({
      type: 'error',
      title: 'Please select minimum two stones to compare.',
    });
    return;
  }
  if (data.length > 15) {
    data = data.slice(data.length - 15, data.length);
  }
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(data));
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-now`, JSON.stringify(window.location.pathname + window.location.search));
  window.open('/compare');
}

export function fetchDiamondList(state, filters, cb) {
  let obj = {
    method: SEARCH_DIAMOND_LIST.method,
    url: SEARCH_DIAMOND_LIST.url,
    request: {
      filters,
      isAppendMasters: true,
      page: state.page,
      limit: state.limit,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === 'OK') {
      if (isArray(data.data) && !isEmpty(data.data)) {
        rows = data.data[0].diamonds;

        sum =
          // isArray(data.data[0].total) && !isEmpty(data.data[0].total)?
          data.data[0].total[0];
        // : sum;
        count =
          // isArray(data.data[0].count) && !isEmpty(data.data[0].count)?
          data.data[0].count;
        // : count;

        seachFilterTag =
          // isArray(data.data[0].filter) && !isEmpty(data.data[0].filter)  ?
          data.data[0].filter;
        // : seachFilterTag;

        FILTER_COLUMNS.forEach((x) => {
          filterArray[x] = uniq(rows.map((r) => r[x]));
        });
        inTrackDiamonds = data.data[0].inTrackDiamonds;
      }
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchStoneOfDay(state, type, cb) {
  // let obj = FEATURE_STONE
  let obj = {
    ...SEARCH_DIAMOND_LIST,
  };

  obj.request = {
    filters: [
      {
        //   wSts: ["D"],
      },
    ],
    // type,
    page: state.page,
    limit: 50,
    sort: [{ back: 'DESC' }],
    isBestBuyPrice: true,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === 'OK') {
      //seachFilterTag = data.data[0].filter
      // Old code has list
      // rows = data.data.list //flatten(data.data.list.map(x => x.featuredPair))
      seachFilterTag = data.data[0].filter;
      sum = data.data[0].total[0];
      rows = data.data[0].diamonds;
      count = data.data[0].count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0].inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchMatchPair(state, diamondSearchId, cb) {
  let obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: state.page,
    limit: state.limit,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === 'OK') {
      let grp = groupBy(data.data.list, 'groupNo');
      Object.keys(grp).forEach((grpNo) => {
        const val = grp[grpNo];
        val[0].isMatchHeader = true;
        val[0].groupingTitle = getStoneTitle(val);
        val[0].totalDiamonds = val.length;
        val[0].groupData = {};
        DISPLAY_TOTAL.forEach((i) => {
          val[0].groupData[i] = [];
          val.forEach((x) => {
            if (x[i]) val[0].groupData[i].push(x[i]);
          });
        });
        rows = [...rows, ...val];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchConfirmStone(state, purchase, cb) {
  let obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      sort: [{ memoNo: 'DESC' }],
      page: state.page,
      limit: state.limit,
    },
  };
  obj.request.filter = {
    status: isNumber(purchase) ? purchase : purchase ? DIAMONDCONFIRM.STATUS.ACCEPTED : DIAMONDCONFIRM.STATUS.PENDING,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let filterArray = {};
    if (data && data.code === 'OK') {
      let grp = data.data.list.filter((x) => x.memoDetails && x.memoDetails.length);

      each(grp, (x) => {
        let val = x.memoDetails;
        let obj = omit(x, 'memoDetails');

        val[0] = {
          ...obj,
          ...val[0],
          isConfirmHeader: true,
          totalDiamonds: val.length,
        };
        rows = [...rows, ...val];
      });
      rows = uniqBy(rows, 'vStnId');
      count = data.data.count;
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchCart(state, cb) {
  let obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.CART,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => x.diamond);
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchOffer(state, cb) {
  let obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.QUOTE,
      sort: state.sort,
      offerStatus: state.offerStatus,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        bargainTrack: x.bargainTrack,
        remarks: x.remarks,
        newDiscount: x.newDiscount,
        newAmount: x.newAmount,
        newPricePerCarat: x.newPricePerCarat,
        offerStatus: x.offerStatus,
        updatedAt: x.updatedAt,
        trackId: x.id,
        quoteHr: x.quoteHr,
        offerValidDate: x.offerValidDate,
        grpBy: x.offerValidDate.split('T')[0],
        createdAt: x.createdAt,
      }));
      let grp = groupBy(rows, 'grpBy');
      rows = [];
      Object.keys(grp).forEach((key) => {
        grp[key][0].isOfferHeader = true;
        rows = [...rows, ...grp[key]];
      });
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchWatch(state, cb) {
  let obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.WATCHLIST,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => x.diamond);
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchReminder(state, cb) {
  let obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.REMINDER,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        reminderDate: x.reminderDate,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchOffice(state, cb) {
  let obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.OFFICE,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      let grp = [];

      data.data.list.map((x) => {
        grp.push({ ...x.diamond, reminderDate: x.reminderDate });
      });

      let grpData = groupBy(grp, 'reminderDate');

      Object.keys(grpData).map((grpNo) => {
        let val = grpData[grpNo];

        val[0] = {
          ...val[0],
          isConfirmHeader: true,
          totalDiamonds: val.length,
        };
        rows = [...rows, ...val];
      });
      rows = uniqBy(rows, 'vStnId');

      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchDiamondPaginate(state, type, cb) {
  let obj = {
    method: SEARCH_DIAMOND_LIST.method,
    url: SEARCH_DIAMOND_LIST.url,
    request: {
      page: state.page,
      limit: state.limit,
      filters: [type.viewType ? {} : { wSts: type }],
      sort: state.sort,
      viewType: type.viewType,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data[0].diamonds;
      sum = data.data[0].total[0];
      count = data.data[0].count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const handleInsertTrack = (trackType, selected, cb, date, remarks) => {
  let diamonds = [];
  selected.map((s) =>
    diamonds.push({
      diamond: s.id,
      trackPricePerCarat: s.ctPr,
      trackAmount: s.amt,
    }),
  );
  let obj = {
    reminderDate: date,
    trackType,
    diamonds,
    remarks,
  };

  let objData = {
    ...CREATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    let flag =
      trackType !== trackTypeObj.REMINDER
        ? true
        : data &&
          data.data &&
          data.data.created &&
          data.data.created.length === 0 &&
          data.data.exists &&
          data.data.exists.length !== 0
        ? false
        : true;
    if (data && data.code === 'OK' && flag) {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
};

export function handleNoteUpsert(selected, cb) {
  let obj = NOTE_UPSERT;
  obj.request = {
    diamonds: selected.map((d) => ({ diamond: d.id, remarks: d.note.trim() })),
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export const handleUpsertTrack = (trackType, selected, inTrackDiamonds, cb, date) => {
  let ids = selected.map((x) => x.id);
  let id = [];
  let diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];
  if (diamonds) {
    each(ids, (diamondId) => {
      let trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  let obj = {
    reminderDate: date,
    trackType,
    id,
  };
  // let api = UPDATE_TRACK.url;
  // let objData = {
  //   method: UPDATE_TRACK.method,
  //   url: api,
  //   request: obj,
  // };
  let objData = {
    ...UPDATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
};

export const handlePrint = (filter, id, type, cb) => {
  let objData = {
    ...PRINT_PDF,
    request: isEmpty(id) ? { filter } : { id },
  };
  if (filter.viewType) {
    let req = objData.request;
    //req.viewType = 2;
    if (id.length === 0) {
      req.viewType = 2;
      req.filter = {
        isFm: {
          nin: ['CERT', 'ELIG'],
        },
      };
    } else {
      req.filter = {};
      delete [req.viewType];
    }
    objData.request = req;
  }
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handlePrintTrack = (filter, orderDiamond, type, cb) => {
  let objData = {
    ...PRINT_PDF,
    request: isEmpty(orderDiamond) ? { filter } : { orderDiamond },
  };
  if (filter.viewType) {
    let req = objData.request;
    //req.viewType = 2;
    if (orderDiamond.length === 0) {
      req.viewType = 2;
      req.filter = {
        isFm: {
          nin: ['CERT', 'ELIG'],
        },
      };
    } else {
      req.filter = {};
      delete [req.viewType];
    }
    objData.request = req;
  }
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handleDownloadExcel = (allSummary, filter = {}, id, cb) => {
  let objData = {
    ...EXPORT_EXCEL,
    request: isEmpty(id) ? { allSummary, filter } : { allSummary, id },
  };
  if (filter.viewType) {
    let req = objData.request;
    //req.viewType = 2;
    if (id.length === 0) {
      req.viewType = 2;
      req.filter = {
        isFm: {
          nin: ['CERT', 'ELIG'],
        },
      };
    } else {
      req.filter = {};
      delete [req.viewType];
    }
    objData.request = req;
  }
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, 'Download');
      cb(true);
    }
  });
};

export const myHoldExcelTrack = (blockType) => {
  let obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { isExcel: true, page: 1, limit: 100000, blockType, sort: {} },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const bestBuyExcelTrack = (allSummary) => {
  let obj = {
    ...GET_DIAMOND_DATA,
    request: {
      filters: [
        {
          //   wSts: ["D"],
        },
      ],
      page: 1,
      limit: 50,
      sort: [{ back: 'DESC' }],
      isBestBuyPrice: true,
      isExcel: true,
      allSummary,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data[0].data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const handleDownloadExcelTrack = (allSummary, filter = {}, orderDiamond, cb) => {
  let objData = {
    ...EXPORT_EXCEL,
    request: isEmpty(orderDiamond) ? { allSummary, filter } : { allSummary, orderDiamond },
  };
  if (filter.viewType) {
    let req = objData.request;
    //req.viewType = 2;
    if (orderDiamond.length === 0) {
      req.viewType = 2;
      req.filter = {
        isFm: {
          nin: ['CERT', 'ELIG'],
        },
      };
    } else {
      req.filter = {};
      delete [req.viewType];
    }
    objData.request = req;
  }
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, 'Download');
      cb(true);
    }
  });
};

export const handleDownloadMultiple = (filter = {}, id = [], obj, cb) => {
  let self = this;

  let objData = {
    ...DOWNLOAD_ZIP_EXCEL,
    request: { filter: isEmpty(filter) ? { id } : { filter }, ...obj },
  };
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if ((await data) && data.code === 'OK') {
      let server = BASE_URL;
      let file_path = `${server}/${data.data}`;
      window.open(file_path, '_blank');
      // window.open(file_path, 'Download')
      cb(true);
    }
  });
};
export function handleConfirmStone(ids, comment, cb) {
  let obj = {
    ...CONFIRM_STONE,
    request: {
      diamonds: ids,
      comment,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export function handleTrackDelete(trackType, ids, inTrackDiamonds, cb) {
  let id = [];
  let diamonds = find(inTrackDiamonds, { _id: trackType }) ? find(inTrackDiamonds, { _id: trackType }).diamonds : [];
  if (diamonds) {
    each(ids, (diamondId) => {
      let trackId = find(diamonds, { id: diamondId });
      if (trackId) id.push(find(diamonds, { id: diamondId }).trackId);
    });
  }
  let obj = {
    ...DELETE_TRACK,
    request: {
      trackType,
      id,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
    }
  });
}

export const handleSendEmail = (apiObj, cb) => {
  let obj = {
    ...EXPORT_EXCEL,
    request: apiObj,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export function handleBidUpsert(rows, type, cb) {
  let obj = {
    ...CREATE_BID,
    request: {
      bidType: type,
      // "remarks": "aaa",
      diamonds: rows.map((x) => ({
        diamond: x.id,
        bidPricePerCarat: x.bidPricePerCarat,
        bidAmount: x.bidAmount,
        bidDiscount: x.bidDiscount,
        newPricePerCarat: x.bidPricePerCarat,
        newDiscount: x.bidDiscount,
        newAmount: x.bidAmount,
        vnd: x.vnd,
        vStnId: x.vStnId,
      })),
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data.message,
      });
      cb(false);
    }
  });
}

function offerObject(item, expire) {
  return {
    diamond: item.id,
    trackPricePerCarat: item.ctPr,
    trackAmount: item.amt,
    trackDiscount: item.back,
    vStnId: item.vStnId,
    newAmount: item.finalquote,
    newPricePerCarat: item.quote,
    newDiscount: Number(item.offerDis),
    offerValidDate: moment(expire),
  };
}

function offerUpdateObject(item, expire) {
  // if(Number(item.offerDis) <= item.back +2 ){
  return {
    tracks: [
      {
        diamond: item.id,
        id: item.trackId,
        newAmount: item.finalquote,
        newPricePerCarat: item.quote,
        newDiscount: Number(item.offerDis),
        offerValidDate: moment(expire),
      },
    ],
  };
}
// else{
//     OpenNotification({
//       type:'error',
//       title:'Final quote must be less than discount'
//     })
//   }
//}

export function handleSaveQuote(selectedItems, expire, comment, cb, update, quoteHr) {
  // let obj = {
  //     method: update ? UPDATE_TRACK.method : CREATE_TRACK.method,
  //     url: update ? UPDATE_TRACK.url : CREATE_TRACK.url,
  //     request: {
  //         trackType: trackTypeObj.QUOTE,
  //         remarks: comment,
  //         date: expire,
  //         diamonds: selectedItems.map(x => ({
  //             diamond: x.id,
  //             trackPricePerCarat: x.ctPr,
  //             trackAmount: x.amt,
  //             trackDiscount: x.back,
  //             vStnId: x.vStnId,
  //             newAmount: x.finalquote,
  //             newPricePerCarat: parseFloat(x.quote),
  //             newDiscount: x.offerDis
  //         }))
  //     }
  // }
  let obj = update ? COUNTER_OFFER : CREATE_TRACK;
  let item = selectedItems[0];
  obj.request = update
    ? {
        // trackType: trackTypeObj.QUOTE,
        // remarks: comment,
        // id: item.trackId,
        ...offerUpdateObject(item, expire),
        // quoteHr,
      }
    : {
        trackType: trackTypeObj.QUOTE,
        remarks: comment,
        diamonds: selectedItems.map((x) => ({
          ...offerObject(x, expire),
          diamond: x.id,
          quoteHr,
        })),
      };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const created = get(data, 'data') || [];

      OpenNotification({
        type: created ? 'success' : 'error',
        title: data && data.message,
      });

      cb(Boolean(created.length));
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : 'Something went wrong.',
      });
      cb(false);
    }
  });
}

export const handleSendXRayEmail = (apiObj, path, cb) => {
  apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
  let obj = {
    ...SEND_EMAIL_XRAY,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: 'error',
        title: data.message,
      });
    }
  });
};

export function allSortObject(columns) {
  let sort = {};
  each(columns, (col) => {
    if (col.id !== 'Details') sort[col.id] = col.Header;
  });
  return sort;
}

export function getProjectSetting(type, cb) {
  let obj = PROJECT_SETTING;
  obj.request = {
    filter: {
      type,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') cb(data.data.data);
    else cb([]);
  });
}

export const downloadZip = (dt, cb) => {
  let obj = {
    ...DOWNLOAD_ZIP,
    request: { ...dt },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let file_path = BASE_URL + '/' + data.data;
      let xhr = new XMLHttpRequest();
      xhr.open('GET', file_path, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (xhr.statusText === 'OK' || xhr.status === 200) {
          let urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(this.response);
          let tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = last(split(data.data || data.data[0], '/'));
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        } else {
          OpenNotification({
            type: 'error',
            title: 'Resource Not Found.',
          });
        }
      };
      setTimeout(function () {
        xhr.send();
      }, 5000);
    } else {
      return OpenNotification({
        type: 'error',
        title: err?.data?.message || data?.message,
      });
    }
    cb(true);
  });
};

export const downloadFile = (apiObj) => {
  document.body.classList.add('loading-indicator');
  let obj = {
    ...DOWNLOAD_FILE,
    request: apiObj,
  };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.path;
      let xhr = new XMLHttpRequest();
      xhr.open(
        'GET',
        file_path, //apiObj.path,
        true,
      );
      xhr.responseType = 'blob';
      xhr.onload = function () {
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };

      xhr.onerror = (err) => {
        document.body.classList.remove('loading-indicator');
      };
      document.body.classList.remove('loading-indicator');
      xhr.send();
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const downloadVideo = (apiObj) => {
  document.body.classList.add('loading-indicator');
  let obj = {
    ...DOWNLOAD_ZIP_EXCEL,
    request: {
      diamonds: [apiObj.id],
      videoFile: true,
      zip: false,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_URL;
      let file_path = server + '/' + data.data[0];
      //let file_path = "https://s3.us-east-2.wasabisys.com/stonevideos/StoneImage_04-01-2020/1001-33/video.mp4"

      let xhr = new XMLHttpRequest();
      xhr.open(
        'GET',
        file_path, //apiObj.path,
        true,
      );
      xhr.responseType = 'blob';
      xhr.onload = function () {
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };

      xhr.onerror = (err) => {
        document.body.classList.remove('loading-indicator');
      };
      setTimeout(function () {
        document.body.classList.remove('loading-indicator');
        xhr.send();
      }, 10000);
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export function fetchOfficeList(state, cb) {
  let obj = FETCH_OFFICE_DIAMOND;
  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: [{ date: 'ASC' }],
    // type: 1
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      if (!isEmpty(data.data)) {
        let grp = data.data.list.filter((x) => x.diamonds && x.diamonds.length);
        each(grp, (x) => {
          let val = x.diamonds;
          val[0] = {
            ...val[0],
            isOfficeHeader: true,
            cabinSlot: x.cabinSlot,
            date: x.date,
            // slottId: x.id,
          };
          each(val, (all) => {
            all.slottId = x.id;
          });
          rows = [...rows, ...val];
        });
        // rows = uniqBy(rows, "vStnId");
        count = data.data.count;
        sum = data.data.total;
        FILTER_COLUMNS.map((x) => {
          filterArray[x] = uniq(rows.map((r) => r[x]));
        });
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const handleDeleteOffice = (filter, cb) => {
  let self = this;

  let objData = {
    ...DELETE_OFFICE_DIAMOND,
    request: filter,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(true);
    }
  });
};

export const myAccountExcelTrack = (allSummary, trackType) => {
  let obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isExcel: true,
      allSummary,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountExcelMemo = (allSummary, filter) => {
  let obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isExcel: true,
      allSummary,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};
export const myAccountExcelOffice = (allSummary) => {
  let obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isExcel: true,
      allSummary,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountPrintTrack = (trackType) => {
  let obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const bestBuyPrintTrack = () => {
  let obj = {
    ...GET_DIAMOND_DATA,
    request: {
      filters: [
        {
          //   wSts: ["D"],
        },
      ],
      page: 1,
      limit: 50,
      sort: [{ back: 'DESC' }],
      isBestBuyPrice: true,
      isPdf: true,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountPrintMemo = (filter) => {
  let obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};
export const myAccountPrintOffice = () => {
  let obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myHoldPDFTrack = (blockType) => {
  let obj = {
    ...GET_BLOCK_DIAMONDS,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      blockType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountPDFTrack = (trackType) => {
  let obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      let server = BASE_DOWNLOAD_URL;
      let file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const getDiamondBlockMaster = (code, cb) => {
  let obj = {
    ...BLOCK_MASTERS,
    request: {
      filter: { blockCode: code },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(data.data[0]);
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data?.message || data?.message,
      });
    }
  });
};

export const handleAddBlock = (block, checked, time, comment, cb) => {
  let user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  let obj = {
    ...ADD_HOLD,
    request: {
      blockType: block,
      diamonds: checked,
      user: user.id,
      userAccount: user.account.id,
      seller: user.seller,
      comment,
    },
  };
  if (block === 1) obj.request = { ...obj.request, validTillDate: time };
  if (block === 2)
    obj.request = {
      ...obj.request,
      assistantSeller: user.seller,
      shippingCountry: user.country,
      shippingCity: user.city,
      stage: checked[0].blockSetting,
      broker: user.account.broker,
      shipMode: 'Ship To Direct',
    };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const fetchBlockDiamonds = (blockType, cb) => {
  let obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { blockType: 1, status: 2 },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    let filterArray = {};
    if (data && data.code === 'OK') {
      seachFilterTag = data.data.filter ? data.data.filter : {};
      data.data.list &&
        data.data.list.map((l) => {
          rows.push({ ...l.diamond, ids: l.id });
        });
      sum = data.data.total ? data.data.total[0] : {};
      count = data.data.count;
      // FILTER_COLUMNS.map((x) => {
      //   filterArray[x] = uniq(rows.map((r) => r[x]));
      // });
      inTrackDiamonds = data.data.inTrackDiamonds ? data.data.inTrackDiamonds : [];
      inBlockDiamonds = data.data.inBlockDiamonds ? data.data.inBlockDiamonds : [];
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
};

export function fetchNotes(state, cb) {
  let obj = FETCH_NOTES;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        remarks: x.remarks,
        trackId: x.id,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchEnquiries(state, cb) {
  let obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
    trackType: trackTypeObj.ENQUIRY,
  };

  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        remarks: x.remarks,
        ...x.diamond,
        trackId: x.id,
        createdAt: x.createdAt ? moment(x.createdAt).format('DD/MM/YYYY hh:mm A') : '-',
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      let grp = groupBy(rows, 'remarks');
      rows = [];
      Object.keys(grp).forEach((x) => {
        grp[x][0].isEnquiryRemarks = true;
        rows = [...rows, ...grp[x]];
      });

      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const DiamondTrackStatusUpdate = (id, offerStatus, cb) => {
  let objData = {
    ...DIAMOND_TRACK_STATUS_UPDATE,
    request: { id, ...offerStatus },
  };
  UtilService.callApi(objData, async function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.data.message || data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
      cb(false);
    }
  });
};
