import React from 'react';
import { diamondParameters } from '../../constants/Common';
import _ from 'lodash';
import { Tag } from 'antd';

const MasterTags = (props) => {
  let { filterData } = props;

  return filterData ? (
    Object.keys(filterData).map((filter, ind) => {
      let param = _.find(diamondParameters, { key: filter });
      if (param) {
        if (param.key === 'or') {
          if (filterData['or'] && _.has(filterData['or'][0], 'crt')) {
            let arr = filterData['or'].map((carat) => {
              return carat['crt']['>='] + ' to ' + carat['crt']['<='];
            });
            return (
              <Tag className="listTagList">
                <span className="listLabel">Carat :</span>
                <span>{_.join(arr, ', ')}</span>
              </Tag>
            );
          }
        } else {
          if (_.isObject(filterData[filter]) && !_.isArray(filterData[filter])) {
            if (filter === 'kToSArr') {
              let arr = Object.values(filterData['kToSArr'])[0];
              let key = Object.keys(filterData['kToSArr'])[0];

              return (
                <div className="savedSearchValue" key={ind}>
                  <span>
                    {param.name}&nbsp;:&nbsp;
                    <span>{key === 'in' ? 'Contains' : 'Not contains'}</span>
                  </span>
                  <span>{_.join(arr, ', ')}</span>
                </div>
              );
            } else {
              return (
                <Tag className="listTagList">
                  <span className="listLabel">{param.name} :</span>
                  <span>{filterData[filter]['>='] + '  to  ' + filterData[filter]['<=']}</span>
                </Tag>
              );
            }
          } else if (typeof filterData[param.key] === 'boolean') {
            return (
              <Tag className="listTagList">
                <span className="listLabel">{param.name} :</span>
                <span>{filterData[param.key] === true ? 'YES' : 'NO'}</span>
              </Tag>
            );
          } else {
            let disp = filterData[param.key];

            return (
              <Tag className="listTagList">
                <span className="listLabel">{param.name} :</span>
                <span>{_.join(disp, ', ')}</span>
              </Tag>
            );
          }
        }
      }
    })
  ) : (
    <></>
  );
};
export default MasterTags;
