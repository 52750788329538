import React, { Component } from 'react';

class Logo extends Component {
  render() {
    return (
      <>
        <img
          width="auto"
          height="80px"
          style={{ objectFit: 'contain', textAlign: 'left' }}
          src={require('../../assets/img/login/GoGreenDiamondsLogo.png')}
          alt="Cupid"
        />
      </>
    );
  }
}

export default Logo;
