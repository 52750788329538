const DetailViewAuth = {
    section1: {
        name: "Basic Details",
        data: [
            {
                part: 1,
                label: "Stone ID",
                key: "vStnId",
            },
            {
                part: 1,
                label: "Lab",
                key: "lbNm",
            },
            {
                part: 1,
                label: "Cert. No.",
                key: "rptNo",
            },
            {
                part: 1,
                label: "Location",
                key: "locNm",
            },
            {
                part: 1,
                label: "Shape",
                key: "shpNm",
            },

            {
                part: 2,
                label: "Carat",
                key: "crt",
            },
            {
                part: 2,
                label: "Color",
                key: "colNm",
            },
            {
                part: 2,
                label: "Clarity",
                key: "clrNm",
            },
            {
                part: 2,
                label: "Cut",
                key: "cutNm",
            },
            {
                part: 2,
                label: "Polish",
                key: "polNm",
            },

            {
                part: 2,
                label: "Symmetry",
                key: "symNm",
            },
            {
                part: 2,
                label: "Fluorescence",
                key: "fluNm",
            },
            {
                part: 2,
                label: "Rap (%)",
                key: "rap",
            },


            {
                label: "Eye Clean",
                key: "eClnNm",
            },
            {
                label: "Brown",
                key: "shdNm",
            },
            {
                label: "Milky",
                key: "mlkNm",
            },
            {
                label: "Mix Tinge",
                key: "mixTintNm",
            },
        ],
    },
    section2: {
        name: "Measurements",
        data: [
            {
                label: "Dimensions (MM)",
                key: "msrmnt",
            },
            {
                label: "Girdle (%)",
                key: "grdlPer",
            },

            {
                label: "Table %",
                key: "tblPer",
            },
            {
                label: "Crown Angle",
                key: "cAng",
            },
            {
                label: "Depth %",
                key: "depPer",
            },
            {
                label: "Pav Angle",
                key: "pAng",
            },

            {
                label: "Crown Height (%)",
                key: "cHgt",
            },
            {
                label: "Pavilion Depth (%)",
                key: "pHgt",
            },
            {
                label: "Star Length",
                key: "strLn",
            },
            {
                label: "Lower Half",
                key: "lwrHal",
            },
            {
                label: "Girdle",
                key: "girdleStr",
            },
            {
                label: "Girdle Faceted",
                key: "grdlCondNm",
            },
            {
                label: "Culet",
                key: "cultNm",
            },
            {
                label: "Ratio",
                key: "ratio",
            },
        ],
    },
    section3: {
        name: "Inclusion Details",
        data: [
            {
                label: "Black Inclusion",
                key: "blkIncNm",
            },
            {
                label: "Other Inclusion",
                key: "wInclNm",
            },
            {
                label: "Open Inclusion",
                key: "opIncNm",
            },
            {
                label: "Extra Facet",
                key: "eFact",
            },
            {
                label: "Natural",
                key: "natural",
            },
            {
                label: "H & A",
                key: "hANm",
            },
        ],
    },
    section4: {
        name: "Other Information",
        data: [
            {
                label: "Key to Symbol",
                key: "kToSStr",
            },
            {
                label: "Lab Comments",
                key: "lbCmt",
            },
            {
                label: "Laser Ins",
                key: "lsrInc",
            },
        ],
    },
};

export default DetailViewAuth;