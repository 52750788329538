import React, { Component } from "react";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import { connect } from "react-redux";
import Heading from "../Heading";
import {
  getColumn,
  handleBidUpsert,
  isMobile,
} from "../../DiamondList/DiamondListFunctions";
import Table from "../../DiamondList/TableBack";
import { calculate, formatNumber } from "./SelectStone";
import { getBidType } from "../../NewArrivalBidIt";
import { newDiamondPrice, getExtraPer } from "./FinalCalculations";
import { HeadingCalc } from "./ConfirmStonePopup";
import { DIAMOND_BID } from "../../../constants/Common";
import BIDStoneMobile from "./BIDStoneMobile";
import OpenNotification from "../CommonButton/OpenNotification";

const currentType = "bidStones";

class BidPopup extends Component {
  state = {
    columns: [],
    checked: [],
    data: [],
  };

  getNewBid = (stoneId, minus) => {
    let data = cloneDeep(this.state.data);
    let index = findIndex(data, { stoneId });
    let val = minus
      ? data[index].bidPricePerCarat - 20
      : data[index].bidPricePerCarat + 20;
    if (val < data[index].ctPr && minus) return;
    data[index].bidPricePerCarat = val;
    data[index].bidAmount = (val || 0) * data[index].crt;
    let sum = calculate([
      { ...data[index], ctPr: val, amt: data[index].bidAmount },
    ]);
    data[index].bidDiscount = sum.final_discount;
    data[index] = newDiamondPrice(data[index], "bid");
    this.setState({ data }, () => {
      let checked = this.state.data.filter((el) =>
        find(this.state.checked, { id: el.id })
      );
      this.setState({ checked });
    });
  };

  getBidPrCell = (row) => {
    return (
      <div className="tableInput">
        <img
          src={require("../../../assets/img/minus.jpg")}
          width="15px"
          onClick={() => this.getNewBid(row.stoneId, true)}
        />
        <input style={{ width: "70%" }} value={row.bidPricePerCarat} />
        <img
          src={require("../../../assets/img/plus.jpg")}
          width="15px"
          onClick={() => this.getNewBid(row.stoneId, false)}
        />
      </div>
    );
  };

  componentDidMount() {
    let data = this.props.checked
      .map((x) => ({
        ...x,
        bidPricePerCarat: Math.round(
          parseFloat((x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr) || 0)
        ),
        bidAmount: x.bidAmount ? x.bidAmount : x.amt,
        bidDiscount: x.bidDiscount ? x.bidDiscount : x.back,
      }))
      .map((x) => newDiamondPrice(x, "bid"));
    let Columns = getColumn();
    let index = findIndex(Columns, { id: "rap" });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1).filter(
        (el) => !["back", "amt", "ctPr"].includes(el.id)
      ),
      find(Columns, { id: "back" }),
      find(Columns, { id: "ctPr" }),
      find(Columns, { id: "amt" }),
      {
        Header: "Bid Price/Ct",
        accessor: "bidPricePerCarat",
        id: "bidPricePerCarat",
        width: 230,
        Cell: ({ row }) => this.getBidPrCell(row.original),
      },
      {
        Header: "Bid Disc(%)",
        accessor: "bidDiscount",
        id: "bidDiscount",
        Cell: ({ row }) => (
          <div className="tableInput">
            <input value={parseFloat(row.original.bidDiscount || 0)} />
          </div>
        ),
      },
      {
        Header: "Bid Value",
        accessor: "bidAmount",
        id: "bidAmount",
        Cell: ({ row }) => (
          <div className="tableInput">
            <input value={parseFloat(row.original.bidAmount || 0)} />
          </div>
        ),
      },
      {
        Header:
          "Final Disc(" +
          parseFloat(getExtraPer()) +
          (getBidType() === DIAMOND_BID.TYPE.BLIND ? "+0.5" : "") +
          "%)",
        accessor: "fnBack",
        id: "fnBack",
        Cell: ({ row }) => <>{parseFloat(row.original.calcDiscount)}</>,
      },
      {
        Header: "Final Rate",
        accessor: "fnCtpr",
        id: "fnCtpr",
        Cell: ({ row }) => <>{parseFloat(row.original.calcPricePerCarat)}</>,
      },
      {
        Header: "Final Value",
        accessor: "fnAmt",
        id: "fnAmt",
        Cell: ({ row }) => <>{parseFloat(row.original.calcAmount)}</>,
      },
      ...Columns.slice(index + 1, Columns.length).filter(
        (el) => !["back", "amt", "ctPr"].includes(el.id)
      ),
    ].filter((el) => el && el);
    this.setState({ columns, data, checked: data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length != this.state.checked.length) {
      this.setState({ checked: nextProps.checkedData });
    }
  }

  checkCheck = () => {
    if (!this.state.checked.length) {
      OpenNotification({
        type: "error",
        title: "Please select stone(s) to bid.",
      });
      return false;
    } else return true;
  };

  submit = () => {
    let self = this;
    if (!this.checkCheck()) return;
    handleBidUpsert(this.state.checked, getBidType(), (flag) => {
      self.props.onClose();
      self.props.clearAll();
      if (flag && self.props.fetch) self.props.fetch();
    });
  };

  render() {
    let summation = calculate(this.state.checked);
    let totalCarat = parseFloat(summation.total_carat);
    let netValue = formatNumber(parseFloat(summation.final_value));
    let avgDisc = parseFloat(summation.final_discount);
    let finalAvgDisc = parseFloat(summation.final_term_discount);
    let finalNetRate = formatNumber(parseFloat(summation.final_rate));
    let finalNetValue = formatNumber(parseFloat(summation.final_net_value));
    let VALUES = isMobile()
      ? {
          "CT.:": totalCarat,
          "Final Disc%:": finalAvgDisc,
          "Amount:": netValue,
          "Final Rate:": finalNetRate,
          "Disc%:": avgDisc,
          "Final Value:": finalNetValue,
        }
      : [
          { title: "Total Carats", value: totalCarat },
          { title: "Final Net Rate", value: finalNetRate },
          { title: "Avg. Discount", value: avgDisc },
          { title: "Final Avg Discount", value: finalAvgDisc },
          { title: "Net Value", value: netValue },
          { title: "Final Net Value", value: finalNetValue },
        ];

    return isMobile() ? (
      <BIDStoneMobile
        {...this.state}
        currentType={currentType}
        onClose={this.props.onClose}
        VALUES={VALUES}
        parent={this}
      />
    ) : (
      <div>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-20" title="Bid Stone" />
          {HeadingCalc(this.state.checked)}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              nodots
              areAllChecked={true}
              handleCheck={(e) => this.setState({ checked: e })}
              currentType={currentType}
              canSort={false}
              noGrp
              FilterOption={false}
            />
          </div>
          <div className="mt-10 finalCalDetail">
            <div className="DiamondDetailPopup">
              {VALUES.map((x) => (
                <div className="DiamondDetailPopupItem">
                  <span>{x.title} : </span>
                  <span>{x.value}</span>
                </div>
              ))}
            </div>
            <p className="offerNote mt-20">
              <b>Note:</b> The additional {parseFloat(getExtraPer())}
              {getBidType() === DIAMOND_BID.TYPE.BLIND ? "+0.5" : ""}% on amount
              value has already been added in the final calculation.
            </p>
          </div>
          <div className="sideBarPopupButton">
            <a id="bid-stone" className="commonButton" onClick={this.submit}>
              Bid Stone
            </a>
            <a className="commonOutline" onClick={this.props.onClose}>
              Cancel Bid
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(BidPopup);
