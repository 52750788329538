import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Drawer } from 'antd';
import moment from 'moment';
import InputBlock from '../InputBlock';
import SelectOption from '../SelectOption';
import Heading from '../Heading';
import { handleConfirmStone, handleDownloadExcel, getColumn, isMobile } from '../../DiamondList/DiamondListFunctions';
import _ from 'lodash';
import OpenNotification from '../CommonButton/OpenNotification';
import { formatNumber, calculate } from './SelectStone';
import SendEmailPopup from './SendEmail';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';
import MobileConfirmStone from './MobileComfirmStone';
import TextArea from '../TextArea';
import Table from '../../DiamondList/TableBack';
import SelectStone from './SelectStone';
import invert from 'lodash/invert';
import size from 'lodash/size';
import TermsConditionPopup from '../../TermsConditionPopup';
import DatePicker from '../../common/DatePicker';

/* eslint jsx-a11y/anchor-is-valid: 0 */

export const savedSearch = {
  saleman: [{ value: 'John Doe' }, { value: 'John Doe' }],
  invoicDate: [
    { id: '1', name: 'Today' },
    { id: '2', name: 'Tommorrow' },
    { id: '3', name: 'Later' },
  ],
  country: [{ value: 'India' }, { value: 'Hong Kong' }, { value: 'US' }],
  city: [{ value: 'Gujarat' }, { value: 'baroda' }, { value: 'Vapi' }],
  billType: [{ value: 'Dollar Bill' }],
  couriername: [{ value: 'Any' }, { value: 'Malca Amit4' }],
  sale: [{ value: 'Idex Ok' }, { value: 'Blue Nile Ok' }],
  terms: [{ value: '7 Days' }, { value: 'Advance' }, { value: 'COD' }],
  assi: [{ value: 'Vishal Virani' }, { value: 'Vishal Virani' }, { value: 'Vishal Virani' }],
};
const currentType = 'ConfirmStonePopup';

export function HeadingCalc(checked, VALUES = null) {
  if (isMobile() && !checked.length && !VALUES) return null;
  let obj = {};
  if (VALUES) obj = VALUES;
  else {
    let sum = calculate(checked);
    obj = {
      'CT. :': parseFloat(sum.total_carat),
      'Disc% :': parseFloat(sum.final_discount),
      'Price/Ct :': formatNumber(parseFloat(sum.final_price)),
      'Total Value :': formatNumber(parseFloat(sum.final_value)),
    };
  }
  if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;
  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>{isMobile() ? obj[x].slice(0, obj[x].length - 1) : obj[x]}</span>
          </div>
        );
      })}
    </div>
  );
}

const ConfirmStonePopup = (props) => {
  let user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const [comment, setComment] = useState('');
  const [company, setCompany] = useState(user && user.account ? user.account.companyName : '');
  const [date, setDate] = useState('1');
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [check, setChecked] = useState(isMobile() ? true : false);
  const [terms, setTerms] = useState(false);
  const [visible, setVisible] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  let gridHeight = 360; //getGridHeight(['confirmListOption', 'sideBarPopupButton', 'popupInnerTitle'], 0)
  const cancel = () => {
    props.onClose();
    if (props.clearAll) props.clearAll();
  };

  const disabledDate = (current) => {
    return current && (current <= moment().startOf('day') || current >= moment().add(7, 'days'));
  };
  const dateHandleChange = (e, val) => {
    let da = null;
    if (e) {
      da = new Date(e._d).toISOString();
    }
    setInvoiceDate(da);
  };
  const gettextarea = () => {
    return isMobile() ? (
      <TextArea value={comment} onChange={(e) => setComment(e.target.value)} label="Comment" placeholder="Comment" />
    ) : (
      // <TextArea
      //   label="Comment"
      //   placeholder="Comment"
      //   value={comment}
      //   onChange={(e) => setComment(e.target.value)}
      // />
      <div className="confirmListOption mt-10">
        {/* <label>:</label> */}
        <DatePicker
          showShipFn={() => {
            setDatePickerVisible(true);
          }}
          expiryDate={invoiceDate}
          handleChange={dateHandleChange}
          single
          disabledDate={disabledDate}
          label="Invoice Date :"
        />
        <div className="commonTextArea from-group">
          <label>Comment</label>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
        {/* <Drawer
          title={false}
          onClose={() =>
            this.setState({
              visible: false,
            })
          }
          visible={this.state.visible}
          wrapClassName="diamondListinSidebar"
        >
        </Drawer> */}
      </div>
    );
  };

  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };
  const submit = () => {
    // if (!invoiceDate) {
    //     OpenNotification({ type: 'error', title: 'Please select invoice date.' })
    //     return
    // }
    // if (!company) {
    //     OpenNotification({ type: 'error', title: 'Please enter company name.' })
    //     return
    // }
    if (checked && checked.length && check) {
      handleConfirmStone(
        props.checked.map((x) => x.id),
        // company,
        // invoiceDate === '1'
        //     ? moment()
        //     : invoiceDate === '2'
        //     ? moment().add(1, 'day')
        //     : moment().add(-1, 'day'),
        comment,
        (flag) => {
          props.onClose();
          if (flag) props.fetch();
          props.clearAll();
        },
      );
    } else if (!check) {
      OpenNotification({
        type: 'error',
        title: 'You must be agree to terms and condition to buy stone',
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s)',
      });
    }
  };

  if (isMobile())
    return (
      <MobileConfirmStone
        data={props.checked}
        currentType={currentType}
        parent={{ submit, cancel, gettextarea, checkCheck }}
      />
    );
  return (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <Heading className="popupInnerTitle mr-40" title="Confirm Stone"></Heading>
        {checked.length > 0 && <SelectStone checked={props.checked} buy currentType={currentType} />}
      </div>
      <div className={`searchPopupCommonTable ${props.popupClass ? props.popupClass : null}`} style={props.style}>
        <div className="searchResultTable tabInnerTableScroll" style={{ height: gridHeight, overflow: 'auto' }}>
          <Table
            finalDestination
            data={props.checked}
            nodots
            areAllChecked={true}
            currentType={currentType}
            canSort={false}
            nocheck={props.detail ? true : false}
            noGrp
            noCheckBox
          />
        </div>

        <div className="confirmListOption finalCalDetail">
          <div className="DiamondDetailPopup width-100">
            {gettextarea()}
            <div className="mt-10 width-100">
              <Checkbox
                checked={check}
                onChange={() => {
                  setChecked(!check);
                }}
              >
                I have read and accept
              </Checkbox>
              <a
                onClick={() => {
                  setTerms(true);
                }}
                className="linkTag"
              >
                <strong>Terms and Conditions.</strong>
              </a>
            </div>
            {/* <DatePicker
              showShip
              nodate
              showShipFn={() => {
                setVisible(true)
              }}
            /> */}
          </div>
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            Confirm Stone
          </a>
          <a
            className="commonOutline"
            onClick={() => {
              props.onClose();
              props.clearAll();
            }}
          >
            Cancel Stone
          </a>
        </div>
      </div>
      <Drawer
        title={false}
        onClose={() => {
          setTerms(false);
          setVisible(false);
        }}
        visible={terms}
        wrapClassName="diamondListinSidebar"
      >
        <TermsConditionPopup />
      </Drawer>
      <Drawer
        title={false}
        onClose={() => {
          setTerms(false);
          setVisible(false);
        }}
        visible={visible}
        wrapClassName="diamondListinSidebar"
      ></Drawer>
    </div>
  );
};

export default ConfirmStonePopup;
