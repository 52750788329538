import React, { Component } from "react";
import { useTable } from "react-table";
import { Drawer } from "antd";
import DiamondDetail from "../DiamondDetail";
import DiamondListingHead from "./ListTableHeader";
import Status from "../common/DiamondListing/Status";
import { handleAlter, getPath, LISTINGPAGES } from "./DiamondListFunctions";
import TableGrouping from "../common/DiamondListing/TableGrouping";
import { LAB_LINKS, FILE_URLS } from "../../constants/Common";
import CheckBox from "../common/CheckBox";
import Slider from "react-slick";
import MagnifierImage from "../DiamondDetail/MagnifierImage";
import IFrame from "../common/IFrame";
import axios from "axios";
import OpenNotification from "../common/CommonButton/OpenNotification";
const _ = require("lodash");

export const canSortCommon = {
  stoneId: "Stone No",
  shpNm: "Shape",
  crt: "Carat",
  colNm: "Color",
  clrNm: "Clarity",
};
export const LIMIT = 100;
export const FILTER_COLUMNS = ["colNm", "fluNm", "shpNm", "lbNm", "clrNm"];
export const floatkeys = [
  "depPer",
  "ratio",
  "crt",
  "rapAvg",
  "pAng",
  "pHgt",
  "cHgt",
  "back",
  "cAng",
  "fnDis",
  "height",
  "width",
  "length",
  "grdlPer",
  "strLn",
  "newDiscount",
  "calcDiscount",
  "calcAmount",
  "newAmount",
  "calcPricePerCarat",
  "newPricePerCarat",
];
export const roundkeys = ["ctPr", "amt", "rap", "tblPer", "lwrHal"];
export const DISPLAY_TOTAL = [
  "ctPr",
  "amt",
  "rap",
  "crt",
  "fnCtpr",
  "fnAmt",
  "calcAmount",
];
const NOCHECKIDS = [
  "Details",
  "Action",
  "quote",
  "expBack",
  "hours",
  "bidPricePerCarat",
  "note",
];

const headerProps = (props, { column }) => getStyles(props, column, "header");
const cellProps = (props, { cell }) => getStyles(props, cell.column, "cell");
const getStyles = (props, item, type) => [
  props,
  {
    style: {
      textAlign: item.cellClass
        ? item.cellClass.replace("text-", "")
        : "center",
      width: item.width ? item.width + "px" : "100px",
      fontWeight:
        type === "cell" && item.id === "dna" //  (item.id === 'stoneId' || item.id === 'vStnId')
          ? "600"
          : "",
      color:
        type === "cell" &&
        (item.id === "shpNm" ||
          item.id === "lbNm" ||
          item.id === "rptNo" ||
          item.link)
          ? "#008cba"
          : "",
    },
  },
];

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      detail: null,
      nav5: "",
      nav6: "",
      main1: "",
      main2: "",
      main1Bool: false,
      main2Bool: false,
      loader: false,
    };
  }

  handleSort = (id) => {
    // let id = _.find(this.props.columns, { id: colid }).sort
    let sort = _.cloneDeep(this.props.sort);
    let found = false;
    _.each(sort, function (val) {
      if (val.hasOwnProperty(id)) found = val;
    });
    if (!found) sort.push({ [id]: "ASC" });
    else {
      let index = sort.indexOf(found);
      if (found[id] === "DESC")
        sort = sort.filter((el) => !el.hasOwnProperty(id));
      else {
        found[id] = "DESC";
        sort[index] = found;
      }
    }
    this.props.handleSort(sort);
  };

  selectFilter = (x, colId, remove = false) => {
    let selection = _.cloneDeep(this.props.selectedFilterArray);
    if (!remove) {
      if (selection[colId]) selection[colId].push(x);
      else selection[colId] = [x];
    } else {
      selection[colId] = selection[colId].filter((el) => el !== x);
      if (!selection[colId].length) selection = _.omit(selection, colId);
    }
    this.props.selectFilter(selection);
  };
  addDefaultSrc = () => {
    this.setState({ detail: { i: "" } });
  };
  render() {
    let { detail, nav5, nav6, loader, main1Bool, main2Bool } = this.state;
    let self = this;
    let columns = this.props.nocheck
      ? this.props.columns
      : [
          {
            Header: "",
            width: 50,
            accessor: "action",
            id: "action",
            Cell: ({ row, cell }) => (
              <div className="selectActionIcon">
                {!self.props.nostatus && <Status status={row.original.wSts} />}
                {!self.props.noCheckBox && (
                  <div className="selectActionIconWrapper">
                    <CheckBox
                      checked={_.find(self.props.checked, {
                        stoneId: row.original.stoneId,
                      })}
                    />
                  </div>
                )}
              </div>
            ),
          },
          ...this.props.columns,
        ];
    let allCheck =
      !self.props.nocheck &&
      !self.props.noCheckBox &&
      self.props.data.length === self.props.checked.length &&
      self.props.data.length !== 0 &&
      JSON.stringify(self.props.checked.map((x) => x.stoneId).sort()) ===
        JSON.stringify(self.props.data.map((x) => x.stoneId).sort());

    const Table = ({ columns, data }) => {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
      });
      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(headerProps)}
                    style={{
                      width: column.width ? column.width + "px" : "100px",
                    }}
                  >
                    {column.id !== "action" && (
                      <DiamondListingHead
                        {...self.props}
                        header={column.render("Header")}
                        column={column}
                        selectFilter={self.selectFilter}
                        sortClick={(k) => self.handleSort(k)}
                      />
                    )}
                    {column.id === "action" && (
                      <div className="selectActionIcon">
                        <div className="selectActionIconWrapper">
                          {!self.props.nostatus && <Status status="all" />}
                          {!self.props.noCheckBox && (
                            <CheckBox
                              checked={allCheck}
                              onChange={() => {
                                if (self.props.nocheck || self.props.noCheckBox)
                                  return;
                                self.props.handleCheck(
                                  allCheck ? [] : self.props.data
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {self.props.loading ? (
            <></>
          ) : (
            <tbody {...getTableBodyProps()}>
              {rows.length ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <>
                      {row.original.isMatchHeader && (
                        <TableGrouping
                          displayTotal={DISPLAY_TOTAL.filter((el) =>
                            _.find(columns, { id: el }) ? true : false
                          )}
                          columns={columns}
                          row={row.original}
                        />
                      )}
                      {(row.original.isConfirmHeader ||
                        row.original.isUpcomingHeader ||
                        (getPath() === LISTINGPAGES.QUOTE &&
                          row.original.updatedAt)) && (
                        <TableGrouping row={row.original} />
                      )}
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps(cellProps)}
                              onClick={() => {
                                if (cell.column.link && cell.value) {
                                  let field = cell.column.link.slice(
                                    cell.column.link.indexOf("$") + 1,
                                    cell.column.link.length
                                  );
                                  field = field.slice(0, field.indexOf("$"));
                                  let link = cell.column.link.replace(
                                    "$" + field + "$",
                                    row.original[field]
                                  );
                                  window.open(link);
                                } else if (
                                  cell.column.id === "vStnId" ||
                                  cell.column.id === "shpNm"
                                ) {
                                  self.setState({ visible: row.original });
                                } else if (
                                  cell.column.id === "ftc" &&
                                  cell.value
                                ) {
                                  window.open(`/ftc/${row.original.id}`);
                                } else if (
                                  (cell.column.id === "lbNm" ||
                                    cell.column.id === "rptNo") &&
                                  row.original.lbNm &&
                                  row.original.rptNo
                                ) {
                                  window.open(
                                    LAB_LINKS[
                                      row.original.lbNm.toUpperCase()
                                    ].replace("***", row.original.rptNo)
                                  );
                                } else if (
                                  !NOCHECKIDS.includes(cell.column.id)
                                ) {
                                  if (
                                    self.props.nocheck ||
                                    self.props.noCheckBox
                                  )
                                    return;
                                  let newchecked = handleAlter(
                                    self.props.checked,
                                    row.original,
                                    "stoneId"
                                  );
                                  self.props.handleCheck(newchecked);
                                }
                              }}
                            >
                              {cell.column.id !== "Details" &&
                                cell.render("Cell")}
                              {cell.column.id === "Details" && (
                                <>
                                  {/* {row.original.img && ( */}
                                  <img
                                    style={{ marginRight: "5px" }}
                                    src={require("../../assets/svg/camera.svg")}
                                    width="15px"
                                    alt=""
                                    onClick={async () => {
                                      this.setState({
                                        detail: { i: row.original },
                                      });
                                      // await axios.get(FILE_URLS.img.replace("***", row.original.vStnId), {
                                      //     responseType: 'blob'
                                      // }).then((resp) => {
                                      //     if (resp.status === 200 && resp.statusText === 'OK') {
                                      //         this.setState({
                                      //             detail: { i: row.original }
                                      //         })
                                      //     } else {
                                      //         OpenNotification({
                                      //             type: 'error',
                                      //             title: 'Image is not available'
                                      //         })
                                      //     }
                                      // }).catch((error) => {
                                      //     OpenNotification({
                                      //         type: 'error',
                                      //         title: 'Image is not available'
                                      //     })
                                      // })
                                    }}
                                  />
                                  {/* )} */}
                                  {/* {row.original.videoFile && ( */}
                                  <img
                                    style={{ marginRight: "5px" }}
                                    src={require("../../assets/svg/video.svg")}
                                    width="15px"
                                    alt=""
                                    onClick={async () => {
                                      this.setState({
                                        detail: { v: row.original },
                                      });
                                      // this.setState({ loader: true });
                                      // await axios
                                      //   .get(
                                      //     FILE_URLS.videoFile
                                      //       .split("***")
                                      //       .join(row.original.rptNo) + '?' + new Date(),
                                      //     {
                                      //       responseType: "blob",
                                      //     }
                                      //   )
                                      //   .then((resp) => {
                                      //     this.setState({ loader: false });

                                      //     if (
                                      //       resp.status === 200 &&
                                      //       resp.statusText === "OK"
                                      //     ) {
                                      //       this.setState({
                                      //         detail: { v: row.original },
                                      //       });
                                      //     } else {
                                      //       OpenNotification({
                                      //         type: "error",
                                      //         title: "Video is not available",
                                      //       });
                                      //     }
                                      //   })
                                      //   .catch((error) => {
                                      //     this.setState({ loader: false });

                                      //     OpenNotification({
                                      //       type: "error",
                                      //       title: "Video is not available",
                                      //     });
                                      //   });
                                    }}
                                  />
                                  {/* )} */}
                                  {/* {row.original.certFile && ( */}
                                  <img
                                    src={require("../../assets/svg/certi.svg")}
                                    width="15px"
                                    alt=""
                                    onClick={async () => {
                                      this.setState({
                                        detail: { c: row.original },
                                      });
                                      // this.setState({ loader: true });

                                      // await axios
                                      //   .get(
                                      //     FILE_URLS.certFile.replace(
                                      //       "***",
                                      //       row.original.rptNo
                                      //     ) + '?' + new Date(),
                                      //     {
                                      //       responseType: "blob",
                                      //     }
                                      //   )
                                      //   .then((resp) => {
                                      //     this.setState({ loader: false });

                                      //     if (
                                      //       resp.status === 200 &&
                                      //       resp.statusText === "OK"
                                      //     ) {
                                      //       this.setState({
                                      //         detail: { c: row.original },
                                      //       });
                                      //     } else {
                                      //       OpenNotification({
                                      //         type: "error",
                                      //         title:
                                      //           "Certificate is not available",
                                      //       });
                                      //     }
                                      //   })
                                      //   .catch((error) => {
                                      //     this.setState({ loader: false });

                                      //     OpenNotification({
                                      //       type: "error",
                                      //       title:
                                      //         "Certificate is not available",
                                      //     });
                                      //   });
                                    }}
                                  />
                                  {/* )} */}
                                </>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          )}
        </table>
      );
    };

    let filterRows = this.props.data;
    let selectedFilter = this.props.selectedFilterArray
      ? this.props.selectedFilterArray
      : {};
    Object.keys(selectedFilter).map((key) => {
      filterRows = filterRows.filter((el) =>
        selectedFilter[key].includes(el[key])
      );
    });

    return (
      <>
        {loader && <div className="loading-indicator"></div>}

        <Table columns={columns} data={filterRows} />
        {this.state.visible && !this.props.finalDestination && (
          <Drawer
            title={false}
            onClose={() => this.setState({ visible: false })}
            visible={this.state.visible}
            wrapClassName="diamondDetailPopup"
          >
            <DiamondDetail
              diamondPopup={true}
              data={this.state.visible}
              onClose={() => this.setState({ visible: false })}
            />
          </Drawer>
        )}
        {detail && (
          <Drawer
            title={false}
            onClose={() => this.setState({ detail: null })}
            visible={detail}
            wrapClassName="diamondListinSidebar onlyImageSlider"
          >
            {detail.i && (
              <div className="diamondImagePopup">
                <Slider
                  asNavFor={nav6}
                  ref={(slider5) => this.setState({ nav5: slider5 })}
                  arrows={false}
                >
                  <div className="diamondDetailImageBox">
                    <MagnifierImage
                      tempSrc={require("../../assets/svg/camera.svg")}
                      image={FILE_URLS.img.replace("***", detail.i.rptNo)}
                    />
                  </div>
                  {/* <div className="diamondDetailImageBox">
                    <MagnifierImage
                      tempSrc={require("../../assets/svg/camera.svg")}
                      image={FILE_URLS.lightBlack.replace(
                        "***",
                        detail.i.mfgStnId
                      )}
                    />
                  </div> */}
                </Slider>
                <Slider
                  asNavFor={nav5}
                  ref={(slider6) => this.setState({ nav6: slider6 })}
                  slidesToShow={5}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  centerMode={true}
                  // infinite={false}
                  className="smallSliderBlock smallSliderBlockimage"
                >
                  <React.Fragment>
                    {!main1Bool && (
                      <div className="smallSliderImage">
                        <img
                          onError={() => {
                            this.setState({ main1Bool: true });
                          }}
                          src={FILE_URLS.img.replace("***", detail.i.rptNo)}
                          alt=""
                        />
                      </div>
                    )}
                  </React.Fragment>
                  {/* <React.Fragment>
                    {!main2Bool && (
                      <div className="smallSliderImage">
                        <img
                          onError={() => {
                            this.setState({ main2Bool: true });
                          }}
                          src={FILE_URLS.lightBlack.replace(
                            "***",
                            detail.i.mfgStnId
                          )}
                          alt=""
                        />
                      </div>
                    )}
                  </React.Fragment> */}
                </Slider>
              </div>
            )}
            {detail.v && (
              <IFrame
                tempSrc={require("../../assets/svg/video.svg")}
                video
                src={FILE_URLS.videoFile.split("***").join(detail.v.rptNo)}
              />
            )}
            {detail && detail.c && Object.keys(detail.c).length && (
              <IFrame
                tempSrc={require("../../assets/svg/certi.svg")}
                src={FILE_URLS.certFile.replace("***", detail.c.rptNo)}
              />
            )}
          </Drawer>
        )}
      </>
    );
  }
}

export default Table;
