import { FILE_TYPES_PDF, FILE_TYPES } from '../constants/Common';

import { CREATE_ANALYTICS } from 'constants/apiConstant';
import { callApi } from 'services/util';
import { isEmpty } from 'util/utils';

const UtilService = {
  fileMimeType(blob, pdf, cb) {
    let type = blob.type;
    let fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      let arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      let validity = false;
      if (pdf) {
        if (type in FILE_TYPES_PDF) {
          validity = FILE_TYPES_PDF[type].includes(header.toLowerCase());

          cb(validity);
        } else {
          cb(false);
        }
      } else {
        if (type in FILE_TYPES) {
          validity = FILE_TYPES[type].includes(header.toLowerCase());
          cb(validity);
        } else {
          cb(false);
        }
      }
    };
    fileReader.readAsArrayBuffer(blob);
  },
};

export const track = (data) => {
  if (isEmpty(Storage.get('token'))) return;
  const [page, section, action] = data.split('/');
  return callApi({ ...CREATE_ANALYTICS, request: { page, section, action } });
};

window.track = track;

export const loaderActivityFn = (showLoader) => {
  let getLoaderEle = document.querySelectorAll('#loader.APILoader')[0];
  let getProgressBar = document.querySelectorAll('#loader.APILoader .showProgress')[0];
  if (showLoader) {
    getLoaderEle.classList.add('loading');
  } else if (getLoaderEle && getLoaderEle.classList && getLoaderEle.classList.contains('loading')) {
    getLoaderEle.classList.remove('loading');
    if (getProgressBar && getProgressBar.classList && !getProgressBar.classList.contains('display-none')) {
      getProgressBar.classList.add('display-none');
    }
  }
};

export default UtilService;
